import { Slide, Stack, Typography } from '@mui/material';
import If from 'app/components/If';
import { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { RoundtripList } from '../RoundtripList';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { useTranslation } from 'react-i18next';
import { NoData } from '../../../NoData';
import AddRoundTripEmpty from 'assets/img/roundTrips/add-roundtrips-empty-state.svg';
import { StateSetter } from 'types';
import { RoundtripDetailsDrawer } from '../RoundtripDetailsDrawer';
import { CollapseButton } from './components/CollapseButton';

interface Props {
  roundtrips: any[];
  updateRoundtripStatus: Function;
  userContext: AgentCategories;
  selectedRoundtrips?: string[];
  handleSelectRoundtrip?: Function;
  handleClickOpen: Function;
  handleClickOpenDelete: Function;
  viewMode?: boolean;
  setAddModalOpen?: StateSetter<boolean>;
  selectedRoundtripDetails?: any;
  setSelectedRoundtripDetails?: StateSetter<any>;
  orders?: any;
  setOrders?: any;
  handleOrderClick: (id: string) => void;
  setRoundtripEdit: StateSetter<any>;
  pinnedOrders: string[];
  hoveringOverRt: string;
  setHoveringOverRt: StateSetter<any>;
  hoveredStop: string;
  setHoveredStop: StateSetter<any>;
  assignedOrders: any[];
}

interface ContextProps extends Props {
  collapsed?: boolean;
  setCollapsed: StateSetter<boolean>;
}

export const LeftMenuContext = createContext<ContextProps>({
  collapsed: true,
  roundtrips: [],
  updateRoundtripStatus: () => {},
  userContext: AgentCategories.Logistic,
  selectedRoundtrips: [],
  handleSelectRoundtrip: () => {},
  handleClickOpen: () => {},
  handleClickOpenDelete: () => {},
  setCollapsed: () => {},
  handleOrderClick: () => {},
  setRoundtripEdit: () => {},
  pinnedOrders: [],
  hoveringOverRt: '',
  setHoveringOverRt: () => {},
  hoveredStop: '',
  setHoveredStop: () => {},
  assignedOrders: [],
});

export const LeftMenu = ({
  roundtrips,
  updateRoundtripStatus,
  userContext,
  selectedRoundtrips,
  handleSelectRoundtrip,
  handleClickOpen,
  handleClickOpenDelete,
  viewMode,
  setAddModalOpen,
  selectedRoundtripDetails,
  setSelectedRoundtripDetails,
  orders,
  setOrders,
  handleOrderClick,
  setRoundtripEdit,
  pinnedOrders,
  hoveringOverRt,
  setHoveringOverRt,
  hoveredStop,
  setHoveredStop,
  assignedOrders,
}: Props) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(!!roundtrips?.length);

  useEffect(() => {
    if (roundtrips?.length === 0) {
      setCollapsed(false);
    }
  }, [roundtrips?.length]);

  return (
    <LeftMenuContext.Provider
      value={{
        collapsed,
        setCollapsed,
        roundtrips,
        updateRoundtripStatus,
        userContext,
        selectedRoundtrips,
        handleSelectRoundtrip,
        handleClickOpen,
        handleClickOpenDelete,
        viewMode: viewMode || false,
        selectedRoundtripDetails,
        setSelectedRoundtripDetails,
        handleOrderClick,
        setRoundtripEdit,
        pinnedOrders,
        hoveringOverRt,
        setHoveringOverRt,
        hoveredStop,
        setHoveredStop,
        assignedOrders
      }}
    >
      <DrawerWrapper
        collapsed={collapsed}
        detailsOpen={!!selectedRoundtripDetails}
        viewMode={viewMode}
      >
        <If
          condition={!!roundtrips?.length}
          otherwise={
            <>
              <Typography
                fontSize={'0.8125rem'}
                fontWeight={700}
                textTransform={'capitalize'}
                sx={{
                  borderBottom: `1px solid ${themes.default.lightFill}`,
                  padding: '0 8px 4px 10px',
                  marginBottom: '16px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('roundtrips')}
              </Typography>
              <EmptyDataWrapper>
                <NoDataContainer>
                  <NoData
                    Icon={AddRoundTripEmpty}
                    addRoundtrip={!viewMode}
                    setAnchorElActivate={setAddModalOpen}
                    height={`calc(100vh - ${themes.default.topBarHeight} - ${themes.default.toolBarHeight} - 68px)`} //68px fixed top section of left menu
                  />
                </NoDataContainer>
              </EmptyDataWrapper>
            </>
          }
        >
          <Slide
            direction="right"
            in={!selectedRoundtripDetails}
            mountOnEnter
            unmountOnExit
            style={{
              width: !collapsed ? '250px' : viewMode ? '110px' : '110px',
            }}
          >
            <div>
              <HeaderContainer collapsed={collapsed}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={'2px'}
                  sx={{
                    borderBottom: `1px solid ${themes.default.lightFill}`,
                    padding: '0 8px 4px 10px',
                    marginBottom: '16px',
                  }}
                >
                  <Stack direction={'row'} gap={'8px'}>
                    <Typography
                      fontSize={'0.8125rem'}
                      fontWeight={700}
                      textTransform={'capitalize'}
                    >
                      {t(collapsed ? 'roundtrips_abbreviation' : 'roundtrips')}
                    </Typography>
                    <CountContainer>{roundtrips?.length || 0}</CountContainer>
                  </Stack>

                  <CollapseButton />
                </Stack>
              </HeaderContainer>
              <div style={{position: 'relative'}}>
                <RoundtripList
                  setSelectedRoundtripDetails={setSelectedRoundtripDetails}
                  orders={orders}
                  setOrders={setOrders}
                  hoveringOverRt={hoveringOverRt}
                  setHoveringOverRt={setHoveringOverRt}
                />
              </div>
            </div>
          </Slide>

          <Slide
            direction="right"
            in={!!selectedRoundtripDetails}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <RoundtripDetailsDrawer
                selectedRoundtripDetails={selectedRoundtripDetails}
                setSelectedRoundtripDetails={setSelectedRoundtripDetails}
                hoveredStop={hoveredStop}
                setHoveredStop={setHoveredStop}
              />
            </div>
          </Slide>
        </If>
      </DrawerWrapper>
    </LeftMenuContext.Provider>
  );
};

interface DrawerWrapperProps {
  collapsed?: boolean;
  detailsOpen?: boolean;
  viewMode?: boolean;
}

const DrawerWrapper = styled.div<DrawerWrapperProps>`
  width: ${props =>
    props.detailsOpen || !props.collapsed
      ? '250px'
      : props.viewMode
      ? '110px'
      : '110px'};
  padding: 8px 0;
  border-right: 1px solid ${props => props.theme.lightFill};
  background: white;
  height: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 101;
`;

const CountContainer = styled.div`
  border-radius: 2px;
  background: ${props => props.theme.whisper};
  padding: 4px;
  height: 17px;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 700;
`;

const EmptyDataWrapper = styled.div`
  width: 250px;
  position: relative;
`;
const NoDataContainer = styled.div`
  position: absolute;
  width: 100%;

  div {
    background: transparent;
  }
`;

const HeaderContainer = styled.div<{collapsed: boolean}>`
  width: ${props => (props.collapsed ? '110px' : '250px')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;
