import { useEffect } from 'react';

export function useUsersData(
  usersData,
  roundTripCopy,
  users,
  data,
  setUsersData,
) {
  // State and setters for debounced value
  useEffect(() => {
    let arr = [...users];
    const inRoundtrip: any[] = [];
    const available: any[] = [];

    arr.filter(a => a.isActive);

    arr = arr?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    });

    if (roundTripCopy) {
      arr?.map(t => {
        const existingRoundtrip =
          roundTripCopy?.find(d => d?.agents[0]?._user?._id === t?._id) ||
          roundTripCopy?.find(d => d?.agents?.[1]?._user?._id === t?._id);
        const existingDataRoundtrip = data?.find(
          d => d?.agents[0]?._user === t?._id,
        );
        const existingCode =
          existingRoundtrip?.code || existingDataRoundtrip?.code;
        if (existingDataRoundtrip || existingRoundtrip) {
          inRoundtrip.push({
            ...t,
            roundtripCode: existingCode,
            disabled: true,
          });
        } else {
          available.push({
            ...t,
            roundtripCode: existingCode,
            disabled: false,
          });
        }
      });

      setUsersData(available.concat(inRoundtrip));
    }
  }, [users, data, roundTripCopy]);
  return 0;
}
