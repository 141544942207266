/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from 'react-i18next';
import Truck from 'assets/img/roundTrips/truck.svg';
import styled, { css } from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';

interface TruckIconProps {
  vehicle: boolean;
  driver: boolean;
  running: boolean;
  style?: React.CSSProperties;
  iconStyle?: {
    icon: React.CSSProperties;
    img: React.CSSProperties;
  };
  notTracked?: boolean;
  mapOpen?: boolean;
  status?: string;
  marker?: boolean;
}

// TODO: revamp this component
export default function TruckIcon({
  vehicle,
  driver,
  running,
  style,
  iconStyle,
  notTracked,
  mapOpen,
  status,
  marker,
}: TruckIconProps) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  let truckIcon = <></>;
  let title = t(`roundtrips.tooltip.truck.${status || 'inactive'}`);

  if (vehicle || driver) {
    truckIcon = <RedPulse style={iconStyle?.icon} marker={!!marker}/>;
    title = t('roundtrips.tooltip.truck.warning');
  } else if (status === 'available') {
    if (running) {
      truckIcon = (
        <GreenPulse style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </GreenPulse>
      );
    } else {
      truckIcon = (
        <GreenIcon style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </GreenIcon>
      );
    }
  } else if (status === 'active') {
    if (running) {
      truckIcon = (
        <BluePulse style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </BluePulse>
      );
    } else {
      truckIcon = (
        <BlueIcon style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </BlueIcon>
      );
    }
  } else if (status === 'emergency') {
    if (running) {
      truckIcon = (
        <RedPulse style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </RedPulse>
      );
    } else {
      truckIcon = (
        <RedIcon style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </RedIcon>
      );
    }
  } else if (status === 'returning') {
    if (running) {
      truckIcon = (
        <LightBluePulse style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </LightBluePulse>
      );
    } else {
      truckIcon = (
        <LightBlueIcon style={iconStyle?.icon} marker={!!marker}>
          <img src={Truck} style={iconStyle?.img} />
        </LightBlueIcon>
      );
    }
  } else if (status === 'offline') {
    truckIcon = (
      <BlackIcon style={iconStyle?.icon} marker={!!marker}>
        <img src={Truck} style={iconStyle?.img} />
      </BlackIcon>
    );
  } else if (status === 'out-of-service') {
    truckIcon = (
      <GreyIcon style={iconStyle?.icon} marker={!!marker}>
        <img src={Truck} style={iconStyle?.img} />
        <Slash />
      </GreyIcon>
    );
  } else if (status === 'inactive' || !status) {
    truckIcon = (
      <GreyIcon style={iconStyle?.icon} marker={!!marker}>
        <img src={Truck} style={iconStyle?.img} />
      </GreyIcon>
    );
  }

  let trackedIcon = <></>;
  if (notTracked && !(vehicle || driver)) {
    title += ` ${t('roundtrips.tooltip.truck.not_tracked').toLowerCase()}`;
    trackedIcon = (
      <div>
        <PriorityHighIcon />
      </div>
    );
  } else if (running) {
    title += ` - ${t('truck.moving')}`;
  }

  if (mapOpen) {
    truckIcon = (
      <TruckIconWrapper
        className="truck-icon-wrapper"
        style={{ border: `2px solid ${theme.primaryActiveColor}` }}
      >
        {truckIcon}
      </TruckIconWrapper>
    );
  } else {
    truckIcon = (
      <TruckIconWrapper className="truck-icon-wrapper">
        {truckIcon}
      </TruckIconWrapper>
    );
  }

  return (
    <TruckIcons style={style}>
      <ManagedTooltip title={title}>{truckIcon}</ManagedTooltip>
      {trackedIcon}
    </TruckIcons>
  );
}

const sharedStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 10px; */
  border-radius: 50%;
  width: 14px;
  height: 14px;

  img {
    width: 7.39px;
    height: 5.91px;
  }
`;

const sharedStylesMap = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 10px; */
  border-radius: 50%;
  width: 26px;
  height: 26px;

  img {
    width: 13.72px;
    height: 10.97px;
  }
`;

export const Slash = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  transform: rotate(125deg);
`;

export const RedIcon = styled.div<{marker: boolean}>`
  background: #df0000;
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const RedPulse = styled.div<{marker: boolean}>`
  background: #df0000;
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  animation: redPulse 1.5s infinite;

  @keyframes redPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.15);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
  }

  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const GreenIcon = styled.div<{marker: boolean}>`
  background: rgba(4, 188, 133, 1);
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const GreenPulse = styled.div<{marker: boolean}>`
  background: rgba(4, 188, 133, 1);
  box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.5);
  animation: greenPulse 1.5s infinite;
  cursor: pointer;

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(4, 188, 133, 0.2);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(4, 188, 133, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(4, 188, 133, 0.2);
    }
  }

  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const LightBlueIcon = styled.div<{marker: boolean}>`
  background: #29b6f6;
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const LightBluePulse = styled.div<{marker: boolean}>`
  background: #29b6f6;
  box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.5);
  animation: bluePulse 1.5s infinite;
  cursor: pointer;

  @keyframes bluePulse {
    0% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(41, 182, 246, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
  }

  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const BlueIcon = styled.div<{marker: boolean}>`
  background: #2a59ff;
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const BluePulse = styled.div<{marker: boolean}>`
  background: #2a59ff;
  box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.5);
  animation: bluePulse 1.5s infinite;
  cursor: pointer;

  @keyframes bluePulse {
    0% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(41, 182, 246, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
  }

  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const GreyIcon = styled.div<{marker: boolean}>`
  background: rgba(206, 204, 204, 1);
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

export const BlackIcon = styled.div<{marker: boolean}>`
  background: #2a2d33;
  cursor: pointer;
  ${props => props.marker ? sharedStylesMap : sharedStyles}
`;

const TruckIcons = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const TruckIconWrapper = styled.div(props => ({
  borderRadius: '50%',
  padding: '4px',
}));
