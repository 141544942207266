import { FormControlLabel, Popover, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterContainer, FilterName, FilterSection } from 'app/components/FilterComponents/styles';
import CheckboxGrid from 'app/components/FilterComponents/components/CheckboxGrid';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { handleCheckboxFilterChange, handleSelectFilterChange } from 'app/helpers/helpers';
import { useCheckValues } from 'app/components/FilterComponents/hooks/useCheckValues';
import { useSelector } from 'react-redux';
import { selectCreditScores, selectCustomerStatus, selectCustomerTypes } from 'common/store/organization/selectors';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useLazyGetUsersWebQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useGetCustomersQuery } from 'common/services/customerApi';
import IfUserAcl from 'app/components/IfUserAcl';
import { themes } from 'styles/theme/themes';

interface VehicleFilterProps {
  setFilters: React.Dispatch<React.SetStateAction<{
    status: { value: string, label: string }[];
    customerTypes: { value: string, label: string }[];
    salesman: { value: string, label: string }[];
    creditScores: { value: string, label: string }[]
  }>>;
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  filters: {
    status: { value: string, label: string }[];
    customerTypes: { value: string, label: string }[];
    salesman: { value: string, label: string }[];
    creditScores: { value: string, label: string }[]
  },
}

const CustomerFilter = ({
  filters,
  setFilters,
  filterOn,
  handleCloseFilter,
}: VehicleFilterProps) => {
  const [triggerSalesmen, { data: salesData }] = useLazyGetUsersWebQuery();
  const { data: facetsData } = useGetCustomersQuery('page=1&limit=1');

  const { t } = useTranslation();
  const { checkedValues, setCheckedValues } = useCheckValues(filters);

  const openFilter = Boolean(filterOn);
  const popOverId = openFilter ? 'filter' : undefined;

  const allStatuses = useSelector(selectCustomerStatus);
  const statusesFilterItems = allStatuses?.filter(status => status !== 'stopped');
  const customerTypes = useSelector(selectCustomerTypes);
  const creditScores = useSelector(selectCreditScores);
  const orderFrequency = ['increase', 'stable', 'decrease'];

  const facets = useMemo(() => {
    return facetsData?.facets?.[0] || {}
  }, [facetsData]);

  const salesQueryParams = useQueryParams({
    roles: 'sales',
    context: 'users',
    page: 1,
    limit: 20,
  })

  const salesmen = useMemo(() => {
    return salesData?.docs || []
  }, [salesData]);


  const searchSalesmen = (search: string) => {
    triggerSalesmen(`${salesQueryParams}&text=${search}`)
  }

  const toggleStopped = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilters({
        ...filters,
        status: [...filters.status, { value: 'stopped', label: t('customer.status.stopped') }]
      })
    }
    else {
      setFilters({
        ...filters,
        status: [...filters.status?.filter(status => status.value !== 'stopped')]
      })
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, label: string, filterKey: string) => {
    handleCheckboxFilterChange(event, label, filterKey, checkedValues, setCheckedValues, setFilters);
  }
  const handleOptionsChange = (filterKey: string, options: { value: string, label: string }[]) => {
    handleSelectFilterChange(filterKey, options, setFilters)
  }

  const handleClearAll = (e: React.MouseEvent) => {
    setCheckedValues({});
    setFilters({
      status: [],
      customerTypes: [],
      salesman: [],
      creditScores: []
    });
  }

  return (
    <Popover
      id={popOverId}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          <CheckboxGrid
            openByDefault={true}
            filterName={t('common.status')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => { handleCheckboxChange(e, label, 'status') }}
            filterItems={statusesFilterItems?.map(status => {
              return { value: status, label: t(`customer.status.${status}`), count: facets?.status?.find((stat: any) => stat._id === status)?.count }
            })} />
          <MultiSelect
            filterName={t('customer.type')}
            selectedOptions={filters.customerTypes}
            handleFilterChange={(options) => { handleOptionsChange('customerTypes', options) }}
            filterItems={customerTypes?.map(type => {
              return { value: type._id, label: type?.label, count: facets?._customerType?.find((stat: any) => stat._id === type._id)?.count }
            })} />
          {/* <CheckboxGrid
            openByDefault={false}
            filterName={t('customer.frequently_order')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => { }}
            filterItems={orderFrequency?.map(frequency => {
              return { value: frequency, label: t(`customer.frequency.${frequency}`) }
            })} /> */}

          <IfUserAcl acl={['manageCustomers']}>
            <MultiSelect
              filterName={t('customer.salesman')}
              selectedOptions={filters.salesman}
              handleFilterChange={(options) => { handleOptionsChange('salesman', options) }}
              filterItems={salesmen?.map(sale => {
                return { value: sale.id, label: sale?.fullName, count: facets?._salesman?.find((stat: any) => stat._id === sale.id)?.count }
              })}
              onSearch={searchSalesmen}
            />
          </IfUserAcl>

          <MultiSelect
            filterName={t('customer.creditScore')}
            selectedOptions={filters.creditScores}
            handleFilterChange={(options) => { handleOptionsChange('creditScores', options) }}
            filterItems={creditScores?.map(score => {
              return { value: score, label: score }
            })} />
          <FormControlLabel
            control={
              <Switch
                size='small'
                onChange={toggleStopped}
                checked={!!filters.status?.find(status => status.value === 'stopped')}
                value={!!filters.status?.find(status => status.value === 'stopped')}
              />
            }
            label={<FilterName>{t(`show_stopped_clients`)}</FilterName>}
            sx={{
              marginLeft: 0,
              marginTop: '16px',
              color: !!filters.status?.find(
                status => status.value === 'stopped',
              )
                ? themes.default.primaryActiveColor
                : '',
            }}
          />
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};

export default CustomerFilter;
