import * as React from 'react';

import DisabledSign from '../../assets/img/users/disabled-sign.svg';
import { ActiveText, NameField } from 'styles/components/users';
import { createData } from 'utils/pages/users';
import { Chip } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import {
  useUpdateUserStatusMutation,
  useInviteUserMutation,
} from 'common/services/userApi';
import i18next from 'i18next';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import { useToaster } from 'hooks/useToaster';
import EmployeeStatus from '../../app/components/EmployeeTable/EmployeeStatus';
import { selectOrganization } from 'common/store/organization/selectors';
import { A } from 'app/components/A';
import { AgentStatuses } from 'app/pages/Users/data/types';
import userAvatar from 'assets/img/users/user-avatar.png';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { UserImg } from 'utils/users/UserImg';

interface UserData {
  id: string | number;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  blocked: false;
  email: string;
  phone: string;
  role: string;
  validationStatus: string;
  status: string;
  cache: any;
  agents: [{ _role: { localeName: { en: string } }; _organization: any }];
  lastLoginAt: string | null;
  isActive: boolean;
  isLocked: boolean;
  handleClickOpen: () => void;
  setIdUserAction: string;
  onlineStatus: 'offline' | 'online' | 'locked';
  lastViewAt: string;
}

const MenuText = styled.div`
  padding-left: ${props => props.theme?.popoverLabelPadding};
  padding-right: ${props => props.theme?.popoverLabelPadding};
  width: 100%;
  display: flex;
  align-items: center;
`;

export const useFormatUsersData = ({
  isLoading,
  rowsData,
  users,
  handleEditUserClick,
  setUser,
  setRows,
  handleClickOpen,
  setIdUserAction,
  pageType,
}) => {
  const toast = useToaster();
  const authOrganization: any = useSelector(selectOrganization);
  const { t } = useTranslation();
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const [inviteUser] = useInviteUserMutation();
  const theme = useSelector(selectTheme);

  const handleInvite = async ({
    role,
    email,
  }: {
    role: string;
    email?: string;
  }) => {
    try {
      if (!email) {
        return toast(3000, 'error', 'customer.invitation.missingEmail');
      }
      const response = await inviteUser({
        role,
        email,
      }).unwrap();

      if (!isEmpty(response)) {
        toast(3000, 'success', 'user_invited_successfully');
      }
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      }
    }
  };

  React.useEffect(() => {
    if (isLoading || !users) return;
    rowsData = [];
    for (let i = 0; i < users.length; i++) {
      let userObj: UserData = users[i];

      const prefferedAgent =
        userObj?.agents?.find(a => a._organization === authOrganization._id) ||
        userObj?.cache?.agents[authOrganization._id];

      if (users[i]?.soft_delete === true) {
        continue;
      } else {
        rowsData.push({
          data: createData(
            {
              value: userObj.id,
            },
            {
              value:
                userObj?.firstName && userObj?.firstName !== '-' ? (
                  <NameField status={userObj.isActive}>
                    <UserImg user={userObj} />
                    <A
                      role="button"
                      className="link"
                      onClick={e => {
                        setUser(userObj);
                        handleEditUserClick(e);
                      }}
                    >
                      <span className="name">{`${
                        userObj.lastName ? `${userObj.lastName},` : ''
                      } ${userObj.firstName || ''}`}</span>
                    </A>
                  </NameField>
                ) : (
                  <div>
                    <span className="name">______</span>
                  </div>
                ),
              sort: `${userObj?.lastName ?? ''}, ${userObj?.firstName ?? ''}`,
            },
            {
              value: (
                <ActiveText status={userObj.isActive}>
                  {userObj.email}
                </ActiveText>
              ),
              sort: userObj.email,
            },
            {
              value: (
                <ActiveText status={userObj.isActive}>
                  {userObj.phone && userObj.phone !== '-'
                    ? userObj.phone
                    : '______'}
                </ActiveText>
              ),
              sort: userObj.phone,
            },
            {
              value: (
                <ActiveText status={userObj.isActive}>
                  {prefferedAgent?._role?.localeName[
                    i18next.resolvedLanguage ? i18next.resolvedLanguage : 'en'
                  ] || ''}
                </ActiveText>
              ),
              sort: userObj.role,
            },
            {
              value: (
                <EmployeeStatus
                  lastViewAt={userObj.lastViewAt}
                  toInvite={
                    prefferedAgent?.status !== AgentStatuses.Invited &&
                    prefferedAgent?.status !== AgentStatuses.Active
                  }
                  invitationSent={
                    prefferedAgent?.status === AgentStatuses.Invited &&
                    prefferedAgent?.status !== AgentStatuses.Active
                  }
                  isActive={prefferedAgent?.status === AgentStatuses.Active}
                  role={prefferedAgent?._role.key}
                  email={userObj?.email}
                />
              ),
              sort: userObj.status,
            },
            {
              value: (
                <div
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <ActionPopover
                    id={userObj.id}
                    handleAction={async (e, action, id) => {
                      setUser(userObj);
                      if (action === 'edit') {
                        handleEditUserClick(e);
                      }
                    }}
                    actions={[
                      // element: (
                      //   <>
                      //     <PopoverEl
                      //       redStatus={theme.redStatus}
                      //       style={{ width: '100%' }}
                      //     >
                      //       <span className="popover-item">{t('clear')}</span>
                      //     </PopoverEl>
                      //   </>
                      // ),
                      {
                        action: 'edit',
                        element: (
                          <>
                            <PopoverEl
                              redStatus={theme.redStatus}
                              style={{ width: '100%' }}
                            >
                              <span className="popover-item">
                                {t('common.edit')}
                              </span>
                            </PopoverEl>
                          </>
                        ),
                      },
                      {
                        action: 'status',
                        onClick: async () => {
                          await updateUserStatus({
                            id: userObj.id,
                            isLocked: !userObj.isLocked,
                          }).unwrap();
                          toast(3000, 'success', 'model_action_success', {
                            action: t(
                              !userObj.isLocked
                                ? 'user.edit_user.deactivated'
                                : 'common.labels.active',
                            ),
                            model: t(`common.${pageType}`),
                          });
                        },
                        element: (
                          <div style={{ width: '100%' }}>
                            {userObj.isLocked === false ? (
                              <>
                                <PopoverEl
                                  redStatus={theme.redStatus}
                                  style={{ width: '100%' }}
                                >
                                  <span className="popover-item">
                                    {t('common.buttons.deactivate')}
                                  </span>
                                </PopoverEl>
                              </>
                            ) : (
                              <>
                                <PopoverEl
                                  redStatus={theme.redStatus}
                                  style={{ width: '100%' }}
                                >
                                  <span className="popover-item">
                                    {t('common.buttons.reactivate')}
                                  </span>
                                </PopoverEl>
                              </>
                            )}
                          </div>
                        ),
                      },
                      {
                        action: 'invite',
                        onClick: async () => {
                          handleInvite({
                            role: prefferedAgent?._role.key,
                            email: userObj.email,
                          });
                        },
                        element:
                          prefferedAgent.status !== 'active' ? (
                            <div style={{ width: '100%' }}>
                              <MenuText>
                                <PopoverEl redStatus={theme.redStatus}>
                                  {prefferedAgent.status === 'invited'
                                    ? t('common.buttons.reinvite')
                                    : t('common.buttons.invite')}
                                </PopoverEl>
                              </MenuText>
                            </div>
                          ) : null,
                      },
                      {
                        seperator: true,
                        action: 'seperator',
                        element: (
                          <>
                            <div
                              className="seperator"
                              style={{
                                height: `0.5px`,
                                width: '100%',
                                background: themes?.default?.gainsboro2,
                              }}
                            ></div>
                          </>
                        ),
                      },
                      {
                        action: 'delete',
                        onClick: () => {
                          handleClickOpen();
                          setIdUserAction(userObj.id);
                        },
                        element: (
                          <>
                            <PopoverEl
                              redStatus={theme.redStatus}
                              style={{ width: '100%' }}
                            >
                              <div className="delete popover-item">
                                {t('delete')}
                              </div>
                            </PopoverEl>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            },
          ),
        });
      }
    }
    setRows(rowsData);
  }, [isLoading, users]);
};
