import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import {
  checkExistingRoundtrips,
  updateAutocomplete,
} from 'app/components/RoundTrips/function';
import { useEffect } from 'react';

export const useCheckWarehouses = ({
  triggerByMode,
  begin,
  end,
  roundTripsByMode,
  openActivate,
  warehouse,
  setDisabledProceed,
  setAlreadyExists,
  mode,
  setIsLoading,
  warehouses,
  setDefaultWarehouse,
  setWarehouse,
  warehouseId,
  isFetching,
  userContext,
}) => {
  useEffect(() => {
    if (roundTripsByMode) {
      checkExistingRoundtrips({
        warehouse,
        setDisabledProceed,
        setAlreadyExists,
        mode: mode,
        data: roundTripsByMode,
        setIsLoading,
      });
    }
  }, [roundTripsByMode, isFetching, warehouse, setDisabledProceed, setAlreadyExists, mode, setIsLoading]);

  useEffect(() => {
    if (warehouses?.decks?.length) {
      setDefaultWarehouse(warehouses?.decks[warehouseId || 0]);
      setWarehouse(warehouses?.decks[warehouseId || 0]);
    }
  }, [warehouseId, warehouses]);

  useEffect(() => {
    setAlreadyExists(false);
    setIsLoading(false);

    if (warehouses?.decks?.length) {
      if (triggerByMode) {
        updateAutocomplete({
          triggerByMode,
          mode,
          warehouses,
          begin,
          end,
          selectedIndex: warehouseId || 0,
          userContext,
        });
      }
    }
  }, [openActivate, begin, warehouseId, setAlreadyExists, setIsLoading, warehouses, triggerByMode, mode, warehouse, end, setDisabledProceed, userContext]);
};
