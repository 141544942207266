import * as React from 'react';
import { Box, Tab, Button, Grid, IconButton, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { OrderNotesTab } from './OrderNotesTab';
import { TrackOrderTab } from './TrackOrderTab';
import { DeliveryInformationTab } from './DeliveryInformationTab';
import { StatusChips } from '../StatusChips';
import { useTranslation } from 'react-i18next';

const editableStatuses = [
  'initiated',
  'pending-updates',
  'confirmed',
  'prepared',
  'canceled',
];

export const getStatusColors = status => {
  switch (status) {
    case 'initiated':
      return {
        'box-background': 'rgba(250, 250, 250, 1)',
        border: 'rgba(0, 0, 0, 0.12)',
      };
    case 'pending':
      return {
        'box-background': 'rgba(255, 252, 246, 1)',
        border: 'rgba(251, 140, 0, 1)',
      };
    case 'confirmed':
      return {
        'box-background': 'rgba(245, 252, 252, 1)',
        border: 'rgba(77, 182, 172, 1)',
      };
    case 'prepared':
      return {
        'box-background': 'rgba(247, 244, 251, 1)',
        border: 'rgba(103, 58, 183, 1)',
      };
    case 'loaded':
      return {
        'box-background': 'rgba(241, 250, 254, 1)',
        border: 'rgba(3, 169, 244, 1)',
      };
    case 'on-the-road':
      return {
        'box-background': 'rgba(255, 252, 246, 1)',
        border: 'rgba(251, 140, 0, 1)',
      };
    case 'canceled':
      return {
        'box-background': 'rgba(253, 246, 246, 1)',
        border: 'rgba(211, 47, 47, 1)',
      };
    case 'delivered':
      return {
        'box-background': 'rgba(248, 252, 248, 1)',
        border: 'rgba(46, 125, 50, 1)',
      };
    case 'ready-for-delivery':
      return {
        'box-background': 'rgba(248, 252, 248, 1)',
        border: 'rgba(46, 125, 50, 1)',
      };
    default:
      return { 'box-background': '', border: '' };
  }
};

export function OrderDetailsDrawer({ closeDrawer, order, defaultTab = '1' }) {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <IconButton onClick={closeDrawer} sx={{ mr: '8px' }}>
          <ArrowBackIcon color="action" />
        </IconButton>
        <Typography fontSize="1.429rem" fontWeight="500">
          {t('order_details.title')}
        </Typography>
        <IconButton onClick={closeDrawer} sx={{ ml: 'auto' }}>
          <ClearIcon color="action" />
        </IconButton>
      </HeaderWrapper>

      <ContentWrapper>
        <StatusBox status={order?.status}>
          <StatusBoxHeader>
            <DoubleArrowOutlinedIcon
              sx={{
                color: 'rgba(173, 185, 190, 1)',
                fontSize: '1.1rem',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <Typography sx={{ fontWeight: '500' }}>
              {t('order')} #: {order?.code}
            </Typography>
            <Divider>|</Divider>
            <Typography color="rgba(0, 0, 0, 0.6)" fontSize={'0.8rem'}>
              {t('roundtrip.created_by')} {order?._createdBy?.fullName}
            </Typography>
            <div style={{ marginLeft: 'auto' }}>
              <StatusChips
                showTooltip={true}
                status={order?.status}
                updatedBy={order?._updatedStatusBy?.fullName}
                createdBy={order?._createdBy?.fullName}
              />
            </div>
          </StatusBoxHeader>
          <StatusBoxClientOrder>
            {t('order_details.client_order')} #:{' '}
            <span style={{ fontWeight: '700' }}>{order?._customer?.code}</span>
          </StatusBoxClientOrder>
          <StatusBoxBranch>
            <div
              style={{
                border: 'solid thin rgba(224, 224, 224, 1)',
                padding: '5px',
                marginRight: '10px',
              }}
            >
              <MapsHomeWorkIcon
                sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '1.6rem' }}
              />
            </div>
            <Typography fontWeight={'500'} fontSize={'1rem'}>
              {order?.cache?._deck?.location?.name}
            </Typography>
          </StatusBoxBranch>
        </StatusBox>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList variant="fullWidth" onChange={handleChange}>
                <Tab label="DELIVERY INFORMATION" value="1" />
                <Tab label="TRACK ORDER" value="2" />
                <Tab
                  label={
                    <>
                      <span>{t('order_notes')}</span>
                    </>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <DeliveryInformationTab order={order} />
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                padding: 0,
                height: '300px',
                '&.MuiTabPanel-root .MuiBox-root': { overflowY: 'hidden' },
              }}
            >
              <TrackOrderTab orderId={order?._id} />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <OrderNotesTab orderId={order?._id} />
            </TabPanel>
          </TabContext>
        </Box>
      </ContentWrapper>

      <FooterWrapper>
        <Grid
          container
          sx={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}
        >
          {editableStatuses.includes(order?.status) ? (
            <StyledButton color="primary" variant="contained">
              {t('EDIT ORDER')}
            </StyledButton>
          ) : (
            <StyledButton color="primary" variant="contained">
              {t('REVIEW ORDER')}
            </StyledButton>
          )}
        </Grid>
      </FooterWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(1, 1, 1, 0.12);
`;

const ContentWrapper = styled.div`
  margin: 24px 20px;
`;

const FooterWrapper = styled.div`
  height: 62px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: solid 1px rgba(1, 1, 1, 0.12);
  background-color: white;
`;

interface StatusBoxProps {
  status?: string;
}

const StatusBox = styled.div<StatusBoxProps>`
  border-radius: 8px;
  padding: 16px;
  border: solid thin ${props => getStatusColors(props.status).border};
  background-color: ${props => getStatusColors(props.status)['box-background']};
  margin-bottom: 10px;
`;

const StatusBoxHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBoxClientOrder = styled(Typography)`
  font-size: 0.8rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500 !important;
`;

const StatusBoxBranch = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Divider = styled.div`
  margin: 0 8px;
  color: rgba(224, 224, 224, 1);
`;

const StyledTab = styled(Tab)`
  &.Mui-selected {
    background-color: rgba(131, 42, 255, 0.04);
  }
`;

const StyledButton = styled(Button)`
  width: 350px;
  font-weight: 500;
  border-radius: 42px;
  margin-left: 12px;
  font-size: 0.92rem;
`;
