// @ts-nocheck
export const updateRoundtripsCache = (dispatch, roundtripApis, updateRt, pageRows) => {
    dispatch(
        roundtripApis.util.updateQueryData(
            'getRoundTripsCluster',
            pageRows,
            draft => {
                draft.roundtrips = draft.roundtrips.map(rt => {
                    if (rt._id == updateRt.id) {
                        return { ...updateRt };
                    }
                    return rt;
                });
            },
        ),
    );
}