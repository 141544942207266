import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  Polyline,
  OverlayViewF,
  Circle,
  RectangleF,
  Marker,
} from '@react-google-maps/api';
import { ClientOrderMarker } from './components/ClientOrderMarker';
import {
  useGetRoundTripsClusterQuery,
  useLazyGetDriverLocationsQuery,
  useGetWarehouseInfoQuery
} from 'common/services/roundtripApi';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { MapWrapper } from '../styles/map';
import { MarkerStep, MarkerVehicle } from 'app/components/MapMarkers';
import { useSubscribeObject } from 'common/hooks/ws';
import { defaultMapStyle } from '../../../../../styles/mapStyles';
import { centroidLabel } from '../../../../../utils/geo';
import { getMostRecentItem } from '../../../../helpers/helpers';
import { LeftMenu } from './components/LeftMenu';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Stack } from '@mui/material';
import { isEmpty, orderBy } from 'lodash';
import { filterRoundtripBySearch } from 'app/helpers/roundtripHelper';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import If from 'app/components/If';
import { useQueryParams } from '../../../../../common/hooks/useQueryParams';
import { useGetClientsOrdersQuery } from 'common/services/roundtripApi';
import cluster from 'assets/img/map/CustomerMap/cluster.svg';
import { themes } from 'styles/theme/themes';
import styled, { keyframes } from 'styled-components';
import { CustomTooltip } from './components/CustomTooltip';
import { DropZone } from './components/DropZone';
import AssignedRoundtripsPopover from './components/AssigneClientOrderPopover';
import { AddClientOrderPopover } from './components/AddClientOrderPopover';
import { RoundtripsType } from '../../data/types';
import { WeatherTag } from './components/WeatherTag';
import { useDebounce } from 'common/hooks/useDebounce';
import { MapActionButton } from './components/MapActionButton';
import { MapZoomButton } from './components/MapZoomButton';
import IsolateRoundtripIcon from 'assets/img/map/buttons/IsolateRoundtrip.svg';
import IsolateRoundtripActive from 'assets/img/map/buttons/IsolateRoundtripActive.svg';
import TrackTruck from 'assets/img/map/buttons/TrackTruck.svg'
import TrackTruckActive from 'assets/img/map/buttons/TrackTruckActive.svg'
import PolylinePoints from 'assets/img/map/marker/PolylinePoints.svg'
import PolylineArrow from 'assets/img/map/marker/PolylineArrow.svg'
import UnlinkWarehouse from 'assets/img/map/buttons/UnlinkWarehouse.svg';
import LinkWarehouse from 'assets/img/map/buttons/LinkWarehouse.svg';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { FilterChips } from 'app/components/FilterChips';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface Props {
  clusterId: string;
  defaultLocation?: any;
  onSelectOrder?: Function;
  unassignedOrders?: any;
  onDisplayOrder?: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy?: any;
  setRoundTripCopy?: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  setOrderInitCount?: any;
  orderInitCount?: any;
  roundTripsCount?: any;
  updateRoundtripStatus?: Function;
  userContext?: AgentCategories;
  handleClickOpen?: () => void;
  handleClickOpenDelete?: () => void;
  viewMode?: boolean;
  search?: string;
  setAddModalOpen?: StateSetter<boolean>;
  LegendComponent?: JSX.Element;
  setRoundtripEdit?: StateSetter<any>;
  date: string;
  filters?: any;
  setFilters?: any;
}

export const Map: React.FC<Props> = ({
  onSelectOrder,
  clusterId,
  defaultLocation,
  unassignedOrders,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  setRoundTripCopy,
  requestOrderData,
  orders,
  setOrders,
  setOrderInitCount,
  orderInitCount,
  roundTripsCount,
  updateRoundtripStatus = () => {},
  userContext = AgentCategories.Logistic,
  handleClickOpenDelete = () => {},
  handleClickOpen = () => {},
  viewMode,
  search = '',
  setAddModalOpen,
  LegendComponent,
  setRoundtripEdit = () => {},
  date,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  });

  const [map, setMap] = React.useState<any>(null);
  const [displayedStopKey, setDisplayedStopKey] = useState('');
  const [view, setView] = useState<'poly' | 'map'>('poly');
  const [selectedRoundtrips, setSelectedRoundtrips] = useState<string[]>([]);
  const [center, setCenter] = useState({
    lat: defaultLocation?.[1] || 48,
    lng: defaultLocation?.[0] || 2,
  });
  const [zoom, setZoom] = useState(11);

  const wsMsg = useSubscribeObject('roundtrip-cluster', clusterId);
  const wsDateMessage = useSubscribeObject('roundtrip-cluster-date', date);

  const zoomedIn = useSelector(selectIsZoomedIn);

  const {
    data: clusterData,
    refetch,
    isLoading: isloadindCluster,
  } = useGetRoundTripsClusterQuery(`/${clusterId}?device=web`, {
    skip: !clusterId,
  });

  const {
    data: warehouseData_,
    refetch: refetchWarehouseInfo,
    isLoading: isloadindWarehouseInfo,
  } = useGetWarehouseInfoQuery({id: clusterId}, {
    skip: !clusterId,
  });

  const [warehouseData, setWarehouseData] = useState(warehouseData_);

  useEffect(() => {
    setWarehouseData(warehouseData_);
  }, [warehouseData_]);

  // const {
  //   data: driverLocations,
  //   refetch: refetchDriverLocations,
  //   isLoading: isLoadingDriverLocations
  // } = useGetDriverLocationsQuery(
  //   {id: "1111", query: "1111"},
  //   {
  //     skip: !clusterId,
  //   }
  // );

  const [getDriverLocations, { data: driverLocations, isLoading: isLoadingDriverLocations }] =
  useLazyGetDriverLocationsQuery();

  useEffect(() => {
    if (wsMsg && clusterId) {
      refetch();
    }
  }, [wsMsg]);

  useEffect(() => {
    if (wsDateMessage && clusterId) {
      refetch();
    }
  }, [wsDateMessage]);

  useEffect(() => {
    if (clusterId) {
      refetch();
      refetchWarehouseInfo();
    }
  }, [clusterId]);

  const ClientsOrdersQuery = useQueryParams({
    limit: '1000',
    _deck: clusterData?._deck?._id,
    clusterId: clusterId,
    application: 'bianta',
    page: 1,
  });
  const {
    data: clientsOrders,
    isLoading: isLoadingClients,
    refetch: refetchClientsOrders,
  } = useGetClientsOrdersQuery(
    { query: ClientsOrdersQuery },
    { skip: !clusterData?._deck?._id },
  );

  useEffect(() => {
    if (!clientsOrders && clusterData?._deck?._id) {
      refetchClientsOrders();
    }
  }, [clientsOrders]);
  
  const [unlinkWarehouse, setUnlinkWarehouse] = useState(false);

  const markers = useMemo(() => {
    const m: any = {
      vehicles: [],
      routes: [],
      clientsOrders: [],
      unAssignedRoundtrips: {
        center: {
          lat: clusterData?._deck.location.geometry.coordinates[1],
          lng: clusterData?._deck.location.geometry.coordinates[0],
        },
        roundtrips: [],
      },
    };

    if (clusterData?.roundtrips) {
      clusterData?.roundtrips?.forEach(roundtrip => {
        const polygonPath: any = [];
        const canceledPolygonPath: any = [];
        let lastPosition: any =
          roundtrip.status === 'parked'
            ? roundtrip._vehicle?.lastPosition
            : getMostRecentItem(
                [roundtrip._vehicle?.lastPosition, roundtrip.lastAgentPosition],
                'date',
              );

        if (lastPosition?.geometry) {
          m.vehicles.push({
            roundtripId: roundtrip._id,
            numberPlate: roundtrip._vehicle?.numberPlate,
            lastPosition,
            roundtrip,
          });
        }

        // Start place
        if (roundtrip._startPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        }
        else if (roundtrip._startPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._startPlace.location.geometry.coordinates[1],
            lng: roundtrip._startPlace.location.geometry.coordinates[0],
          });
        }

        if (!roundtrip.stops?.length) {
          m.unAssignedRoundtrips.roundtrips.push(roundtrip);
        }

        roundtrip.stops.forEach((stop, index) => {
          if (stop._place?.location?.geometry?.coordinates) {
            polygonPath.push({
              type: stop?._order?.status !== 'canceled' ? 'canceled' : '',
              lat: stop._place.location.geometry.coordinates[1],
              lng: stop._place.location.geometry.coordinates[0],
            });
          }

          if (
            stop._place?.location?.geometry?.coordinates &&
            stop?._order?.status?.toLowerCase() === "canceled"
          ) {
            canceledPolygonPath.push([
              // stop before the canceled
              {
                lat:
                  unlinkWarehouse
                    ? roundtrip.stops[index === 0 ? roundtrip.stops?.length - 1 : index === 1 ? 0 : index - 1]?._place.location.geometry.coordinates[1]
                    : roundtrip.stops[index - 1]?._place.location.geometry.coordinates[1]
                      || roundtrip._startPlace.location.geometry.coordinates[1],
                lng:
                  unlinkWarehouse
                  ? roundtrip.stops[index === 0 ? roundtrip.stops?.length - 1 : index === 1 ? 0 : index - 1]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index - 1]?._place.location.geometry.coordinates[0]
                    || roundtrip._startPlace.location.geometry.coordinates[0],
              },
              // canceled stop
              {
                lat: stop._place.location.geometry.coordinates[1],
                lng: stop._place.location.geometry.coordinates[0],
              },
              // stop after the canceled
              {
                lat:
                  unlinkWarehouse
                  ? roundtrip.stops[index === 0 ? 1 : roundtrip.stops?.length - 1 === index ? 0 : index + 1]?._place.location.geometry.coordinates[1]
                  : roundtrip.stops[index + 1]?._place.location.geometry.coordinates[1]
                    || roundtrip._endPlace.location.geometry.coordinates[1],
                lng:
                  unlinkWarehouse
                  ? roundtrip.stops[index === 0 ? 1 : roundtrip.stops?.length - 1 === index ? 0 : index + 1]?._place.location.geometry.coordinates[0]
                  : roundtrip.stops[index + 1]?._place.location.geometry.coordinates[0]
                    || roundtrip._endPlace.location.geometry.coordinates[0],
              },
            ]);
          }
        });

        if (roundtrip._endPlace && !unlinkWarehouse) {
          polygonPath.push({
            type: 'end',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        }
        else if (roundtrip._endPlace && roundtrip?.stops?.length === 0) {
          polygonPath.push({
            type: 'start',
            lat: roundtrip._endPlace.location.geometry.coordinates[1],
            lng: roundtrip._endPlace.location.geometry.coordinates[0],
          });
        }

        m.routes.push({
          options: {
            fillColor: '#9E9E9E',
            fillOpacity: 0.2,
            strokeColor: '#9E9E9E',
            strokeOpacity: 1,
            strokeWeight: 1,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
          },
          center: centroidLabel(polygonPath, false, false),
          paths: polygonPath,
          canceledPaths: canceledPolygonPath,
          code: roundtrip.code,
          roundtripId: roundtrip._id,
        });
      });

      if (clientsOrders?.docs) {
        clientsOrders?.docs?.forEach(client => {
          m.clientsOrders.push({
            clientId: client?._id,
            position: client?._deck?.location?.geometry?.coordinates || [],
            inSrts: false,
          });
        });
      }
    };
    return m;
  }, [clusterData?.roundtrips, clientsOrders, selectedRoundtrips, unlinkWarehouse]);

  const circleRadius = useMemo(() => {
    const zoomFactor = zoom - 11;
    return 1000 / Math.pow(2, zoomFactor);
  }, [zoom]);

  const circleOptions = {
    strokeColor: 'rgba(0,0,0,0)',
    fillColor: themes.default.primaryActiveColor,
    fillOpacity: 0.4,
    zIndex: 1,
    radius: circleRadius,
  };

  const handleSelectRoundtrip = useCallback((roundtripId: string) => {
    if (selectedRoundtrips.includes(roundtripId)) {
      setSelectedRoundtrips(
        selectedRoundtrips.filter(id => id !== roundtripId),
      );
    } else {
      setSelectedRoundtrips([...selectedRoundtrips, roundtripId]);

      const findVehicle = markers.vehicles.find(
        v => v.roundtripId === roundtripId,
      );

      if (findVehicle) {
        setCenter({
          lat: findVehicle.lastPosition.geometry.coordinates[1],
          lng: findVehicle.lastPosition.geometry.coordinates[0],
        });
      }
    }
  }, [selectedRoundtrips, markers.vehicles]);

  const handleClickVehicle = useCallback((vehicle: any) => {
    if (!selectedRoundtrips.includes(vehicle.roundtripId)) {
      setSelectedRoundtrips([...selectedRoundtrips, vehicle.roundtripId]);
    }

    setCenter({
      lat: vehicle.lastPosition.geometry.coordinates[1],
      lng: vehicle.lastPosition.geometry.coordinates[0],
    });
  }, [selectedRoundtrips, center]);

  const roundtrips = clusterData?.roundtrips || [];
  var displayRoutes = markers.routes;

  const [layerIndex, setLayerIndex] = useState(-1);
  const [pinnedLayers, setPinnedLayers]: any = useState([]);

  const handleOpenLayer = useCallback(index => {
    setLayerIndex(index);
  }, [layerIndex]);
  const handleCloseLayer = useCallback(index => {
    setLayerIndex(-1);
  }, [layerIndex]);
  const filteredRoundtrips = useMemo(() => {
    if (isEmpty(search)) {
      return roundtrips;
    }

    return roundtrips ? filterRoundtripBySearch(roundtrips, search, t) : [];
  }, [roundtrips, search]);

  const [hoverableRoute, setHoverableRoute] = useState<string | null>(null);

  const [selectedRoundtripDetails, setSelectedRoundtripDetails] =
    useState<any>(null);

  const [roundtripClickOnMap, setRoundtripClickOnMap] = useState<string | null>(
    null,
  );

  const [updatedDisplayRoutes, setUpdatedDisplayRoutes] =
    useState(displayRoutes);

  const [assignedOrders, setAssignedOrders]: any = useState([]);

  //On click on roundtrip on map update the selectedRoundtripDetails state to render the details pannel
  useEffect(() => {
    setRoundtripClickOnMap(null);
    roundtrips.map(rt => {
      if (rt._id === roundtripClickOnMap) {
        setSelectedRoundtripDetails(rt);
        handleRoundtripFocus(rt);
      }
    });
  }, [roundtripClickOnMap]);

  useEffect(() => {
    if (!roundtrips.find(rt => rt._id === selectedRoundtripDetails?._id)) {
      setSelectedRoundtripDetails(null);
    }
  }, [roundtrips]);

  const ref = useRef(null);
  const [selectedOrders, setSelectedOrders]: any = useState([]);
  const [selectedFullOrders, setSelectedFullOrders]: any = useState([]);

  const handleSelectOrders = useCallback((e, orderId, clientOrder) => {
    if (e !== null && selectedOrders.length > 0 && !e?.ctrlKey && !selectedOrders.includes(orderId)) {
      return setSelectedOrders([orderId]);
    }
    else if (e !== null && selectedOrders.length === 1 && selectedOrders.includes(orderId)) {
      return setSelectedOrders([]);
    }
    else if (e !== null && selectedOrders.length > 1 && selectedOrders.includes(orderId)) {
      return setSelectedOrders([orderId]);
    }

    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(prevState => [...prevState.filter(s => s !== orderId)]);
    }
    else {
      setSelectedOrders(prevState => [...prevState, orderId]);
    };

    if (selectedOrders.includes(clientOrder._id)) {
      setSelectedFullOrders(prevState => [...prevState.filter(s => s._id !== clientOrder._id)]);
    }
    else {
      setSelectedFullOrders(prevFullOrders => [...prevFullOrders, clientOrder]);
    };
  }, [selectedOrders, selectedFullOrders]);

  const handleLeftMenuOrderClick = useCallback(
    (id: string) => {
      const stop = assignedOrders?.find(s => s._id === id);
      if (stop?._place?.location?.geometry?.coordinates) {
        const coordinates = {
          lat: stop._place.location.geometry.coordinates?.[1],
          lng: stop._place.location?.geometry.coordinates?.[0],
        };
        map?.panTo(coordinates);
      }
      if (!pinnedLayers?.includes(id)) {
        setPinnedLayers([id]);
      }
    },
    [setPinnedLayers, pinnedLayers, assignedOrders, map],
  );

  useEffect(() => {
    if (roundtrips) {
      setAssignedOrders([]);
      roundtrips.map(rt => {
        let isRoundtripSelected = false;
        if (selectedRoundtripDetails?._id === rt._id) {
          isRoundtripSelected = true;
        }
        let isFiltered = false;
        updatedDisplayRoutes.map(p => {
          if (
            p.roundtripId === rt._id &&
            p.filtered === true &&
            !search?.startsWith('BC')
          ) {
            isFiltered = true;
          }
        });

        let isFilteredStop = false;
        let stopOrders = [...rt?.stops];
        stopOrders = stopOrders?.filter(stop => stop?._order);
        rt?.stops.map((st, index) => {
          const pinNumber = stopOrders?.findIndex(o => o._id === st?._id);
          if (
            search === st?._order?.code ||
            search === st?._order?._customer?.code
          ) {
            isFilteredStop = true;
          } else {
            isFilteredStop = false;
          }
          const newSt = {
            ...st,
            place: index + 1,
            isSelected: isRoundtripSelected,
            isHighlighted: pinnedLayers?.includes(st?._id),
            isFiltered: isFiltered,
            isFilteredStop: isFilteredStop,
            pinNumber: pinNumber || pinNumber === 0 ? pinNumber + 1 : null,
          };
          setAssignedOrders(prevState => [...prevState, newSt]);
        });
      });
    }
  }, [roundtrips, selectedRoundtripDetails, updatedDisplayRoutes]);

  useEffect(() => {
    displayRoutes = markers.routes;
    setUpdatedDisplayRoutes(displayRoutes);
  }, [markers]);

  const [unassignedOrders_, setAssignedOrders_] = useState(orders);

  useEffect(() => {
    setAssignedOrders_([]);
    if (unassignedOrders?.length > 0) {
      setAssignedOrders_(prevState => {
        const updatedOrders = unassignedOrders.map(order => {
          let isFilteredOrder = false;
          if (search?.toUpperCase() === order?.code) {
            isFilteredOrder = true;
          }
          return {
            ...order,
            isFilteredOrder: isFilteredOrder,
          };
        });
        return [...prevState, ...updatedOrders];
      });
    }
  }, [unassignedOrders, search]);

  const [contextMenu, setContextMenu] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<any>(null);
  const [openRoundtrip, setOpenRoundtrip] = useState<boolean>(false);

  window.oncontextmenu = e => {
    if (selectedOrders.length > 0) {
      setContextMenu(true);
      setContextMenuPosition({ x: e?.clientX, y: e?.clientY });
    }
  };

  window.onclick = () => {
    if (contextMenu) {
      setContextMenu(false);
      setContextMenuPosition(null);
    }
  };

  const [startLatLng, setStartLatLng] = useState(null);
  const [gridBoundingBox, setGridBoundingBox] = useState<any>(null);
  const [centerLatLng, setCenterLatLng] = useState<any>(null);
  const weatherLatLng = useDebounce(centerLatLng, 1000);
  const [selectionGridBoundingBox, setSelectionGridBoundingBox] =
    useState<any>(null);
  const [overlayWarehouseVisible, setOverlayWarehouseVisible] = useState(false);

  ///Multi selection drag -> show rectangle
  useEffect(() => {
    if (map) {
      google.maps.event.addListener(map, 'mousedown', e => {
        if (e?.domEvent?.shiftKey) {
          map.setOptions({ draggable: false });
          setStartLatLng(e.latLng);
          setGridBoundingBox(null);
        }
      });

      google.maps.event.addListener(map, 'mousemove', e => {
        if (
          e?.domEvent?.shiftKey &&
          (e?.domEvent?.button === 1 || e?.domEvent?.buttons === 1) &&
          startLatLng
        ) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(startLatLng);
          bounds.extend(e.latLng);
          if (bounds) {
            setSelectionGridBoundingBox({
              bounds: {
                north: bounds.getNorthEast().lat(),
                south: bounds.getSouthWest().lat(),
                east: bounds.getNorthEast().lng(),
                west: bounds.getSouthWest().lng(),
              },
            });
          }
        } else if (
          (e?.domEvent?.button === 0 || e?.domEvent?.buttons === 0) &&
          startLatLng
        ) {
          setSelectionGridBoundingBox(null);
        }
      });

      google.maps.event.addListener(map, 'mouseup', e => {
        if (!!e.latLng)
          setCenterLatLng({ lat: e?.latLng.lat(), lng: e?.latLng.lng() });

        if (e?.domEvent?.shiftKey && startLatLng) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(startLatLng);
          bounds.extend(e.latLng);

          if (bounds) {
            setGridBoundingBox({
              bounds: {
                north: bounds.getNorthEast().lat(),
                south: bounds.getSouthWest().lat(),
                east: bounds.getNorthEast().lng(),
                west: bounds.getSouthWest().lng(),
              },
            });
          }
          map.setOptions({ draggable: true });
          setSelectionGridBoundingBox(null);
        }
      });

      ///Remove rectangle on map click
      google.maps.event.addListener(map, 'click', () => {
        setGridBoundingBox(null);
        setSelectedFullOrders([]);
        setSelectedOrders([]);
        setOverlayWarehouseVisible(false);
      });
    }
  }, [map, startLatLng]);

  const handleShowSelectionBox = useCallback((e) => {
    if (map) {
      if (e?.domEvent?.shiftKey && startLatLng) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(startLatLng);
        bounds.extend(e.latLng);
        if (bounds) {
          setGridBoundingBox({
            bounds: {
              north: bounds.getNorthEast().lat(),
              south: bounds.getSouthWest().lat(),
              east: bounds.getNorthEast().lng(),
              west: bounds.getSouthWest().lng(),
            },
          });
        }
        map.setOptions({ draggable: true });
        setSelectionGridBoundingBox(null);
      }
    }
  }, []);

  useEffect(() => {
    if (gridBoundingBox) {
      unassignedOrders?.filter(order => {
        const lat = order?._deck?.location?.geometry?.coordinates[1];
        const lng = order?._deck?.location?.geometry?.coordinates[0];
        const isFilteredOrder =
          lat < gridBoundingBox.bounds.north &&
          lat > gridBoundingBox.bounds.south &&
          lng < gridBoundingBox.bounds.east &&
          lng > gridBoundingBox.bounds.west;

        if (isFilteredOrder) {
          handleSelectOrders(null, order?._id, order);
        }
      });

      assignedOrders?.filter(order => {
        const lat = order?._order?._deck?.location?.geometry?.coordinates[1];
        const lng = order?._order?._deck?.location?.geometry?.coordinates[0];
        const isFilteredOrder =
          lat < gridBoundingBox.bounds.north &&
          lat > gridBoundingBox.bounds.south &&
          lng < gridBoundingBox.bounds.east &&
          lng > gridBoundingBox.bounds.west;

        if (isFilteredOrder) {
          handleSelectOrders(null, order?._order?._id, order?._order);
        }
      });
    }
  }, [gridBoundingBox, unassignedOrders, assignedOrders]);

  useEffect(() => {
    setAssignedOrders_(orders);
  }, [orders]);

  // Update displayRoutes whenever the search changes
  useEffect(() => {
    if ((search !== '' && search?.startsWith('R')) || search?.startsWith('r')) {
      const filteredCodes = filteredRoundtrips.map(trip => trip.code);
      const updatedDisplayRoutes = displayRoutes.map(trip => ({
        ...trip,
        filtered: filteredCodes.includes(trip.code),
        options: filteredCodes.includes(trip.code)
          ? {
              ...trip.options,
              fillColor: '#2A59FF',
              fillOpacity: 0.2,
              strokeColor: '#2A59FF',
              strokeWeight: 3,
            }
          : { ...trip.options },
      }));

      setUpdatedDisplayRoutes(updatedDisplayRoutes);
    } else {
      const updatedDisplayRoutes = displayRoutes.map(trip => ({
        ...trip,
        filtered: false,
      }));

      setUpdatedDisplayRoutes(updatedDisplayRoutes);
    }
  }, [filteredRoundtrips]);

  const [markerPos, setMarkerPos]: any = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [draggingFromRt, setDraggingFromRt] = useState('');
  const [hoveringOverRt, setHoveringOverRt] = useState('');
  const [hoveredStop, setHoveredStop] = useState('');

  const [lastInteractedWithOrder, setLastInteractedWithOrder] =
    useState<any>(null);
  const [mouseOverOrder, setMouseOverOrder] = useState<any>(null);

  const handleUnselectAll = useCallback(() => {
    if (selectedRoundtripDetails !== null && pinnedLayers?.length > 0) {
      return setPinnedLayers([]);
    }
    setGridBoundingBox(null);
    setSelectedFullOrders([]);
    setSelectedOrders([]);
    setPinnedLayers([]);
    setRoundtripClickOnMap(null);
    setSelectedRoundtrips([]);
    setSelectedFullOrders([]);
    setSelectedRoundtripDetails(null);
    setMouseOverOrder(null);
  }, []);

  useEffect(() => {
    if (pinnedLayers.length === 0 || viewMode)
      return setLastInteractedWithOrder(null);
    const orderId = pinnedLayers[pinnedLayers.length - 1];
    const result1 = orders?.filter(order => order?._id === orderId);
    const result2 = assignedOrders?.filter(
      order => order?._order?._id === orderId,
    );
    const result = [result1[0] || result2[0]?._order];
    setLastInteractedWithOrder(result[0]);
    setMouseOverOrder(result[0]);
  }, [pinnedLayers]);

  const handleOpenOrder = useCallback(() => {
    if (mouseOverOrder) {
      onDisplayOrder(mouseOverOrder);
    } else if (lastInteractedWithOrder) {
      onDisplayOrder(lastInteractedWithOrder);
    }
  }, [mouseOverOrder, lastInteractedWithOrder]);

  const handleSelectStop = useCallback((direction) => {
    if (
      !selectedRoundtripDetails ||
      selectedRoundtripDetails?.stops?.length === 0
    )
      return;
    const selectedRtStopsIds: any[] = [];
    selectedRoundtripDetails.stops.map(stop => {
      if (stop.type !== 'fuel' && stop.type !== 'maintenance') {
        selectedRtStopsIds.push(stop?._order?._id);
      }
    });

    if (pinnedLayers?.length === 0 && direction === 'right') {
      setPinnedLayers([selectedRtStopsIds[0]]);
    } else if (pinnedLayers?.length === 0 && direction === 'left') {
      setPinnedLayers([selectedRtStopsIds[selectedRtStopsIds?.length - 1]]);
    } else if (pinnedLayers?.length > 0) {
      const lastPinnedLayerId = pinnedLayers[pinnedLayers?.length - 1];
      selectedRtStopsIds.map((id, index) => {
        if (id === lastPinnedLayerId) {
          if (
            direction === 'right' &&
            index === selectedRtStopsIds?.length - 1
          ) {
            setPinnedLayers([selectedRtStopsIds[0]]);
          } else if (direction === 'right') {
            setPinnedLayers([selectedRtStopsIds[index + 1]]);
          } else if (direction === 'left' && index === 0) {
            setPinnedLayers([
              selectedRtStopsIds[selectedRtStopsIds?.length - 1],
            ]);
          } else if (direction === 'left') {
            setPinnedLayers([selectedRtStopsIds[index - 1]]);
          }
        }
      });
    }
  }, [selectedRoundtripDetails, pinnedLayers]);

  const handleRoundtripSelection = useCallback((num) => {
    roundtrips.map(rt => {
      if (rt?.code) {
        const roundtripNumber = parseInt(rt?.code.replace('R', ''));
        if (roundtripNumber === parseInt(num)) {
          setSelectedRoundtripDetails(rt);
        }
      }
    });
  }, [roundtrips]);

  const handleRoundtripFocus = useCallback((roundtrip) => {
    if (!map) return;
    
    const allOrdersCoordinates: any[] = [];
    roundtrip?.stops?.map(order => {
      if (order?.type === 'fuel' || order?.type === 'maintenance') {
        return allOrdersCoordinates.push(
          order?._place?.location?.geometry?.coordinates,
        );
      }
      allOrdersCoordinates.push(
        order?._order?._deck?.location?.geometry?.coordinates,
      );
    });
    allOrdersCoordinates.push(roundtrip?._startPlace?.location?.geometry?.coordinates);

    if (allOrdersCoordinates.length === 0) setMapCenter('');

    const bounds = new window.google.maps.LatLngBounds();
    allOrdersCoordinates.forEach(location => {
      bounds.extend({
        lat: parseFloat(location[1]),
        lng: parseFloat(location[0]),
      });
    });
    map.fitBounds(bounds);
  }, [map]);

  const [mapCenter, setMapCenter] = useState<any>(center);
  const [mapZoom, setMapZoom] = useState<any>(11);

  const handleMapFocus = useCallback(() => {
    if (!map) return;

    const allOrdersCoordinates: any[] = [];

    assignedOrders?.map(order => {
      if (order?.type === 'fuel' || order?.type === 'maintenance') {
        return allOrdersCoordinates.push(
          order?._place?.location?.geometry?.coordinates,
        );
      }
      allOrdersCoordinates.push(
        order?._order?._deck?.location?.geometry?.coordinates,
      );
    });

    unassignedOrders?.map(order => {
      allOrdersCoordinates.push(order?._deck?.location?.geometry?.coordinates);
    });

    // warehouse coordinates
    allOrdersCoordinates.push(clusterData?._deck?.location?.geometry?.coordinates);

    if (allOrdersCoordinates.length === 0) setMapCenter('');

    const bounds = new window.google.maps.LatLngBounds();
    allOrdersCoordinates.forEach(location => {
      bounds.extend({
        lat: parseFloat(location[1]),
        lng: parseFloat(location[0]),
      });
    });
    if (allOrdersCoordinates.length === 1) {
      setMapCenter(allOrdersCoordinates[0]);
      setMapZoom(11);
    }
    else {
      map.fitBounds(bounds);
    }
  }, [map, mapCenter, mapZoom]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!map || count === 1) return;
    handleMapFocus();
    setCount(1);
  }, [map, assignedOrders, unassignedOrders]);

  const handleMapZoom = useCallback((direction) => {
    if (direction === 'in') setMapZoom(mapZoom + 1);
    else if (direction === 'out') setMapZoom(mapZoom - 1);
  }, []);

  const orderCardRef = useRef<any>(null);

  const shortcutsCallbacks = useMemo(() => {
    return({
      unselectAll: () => handleUnselectAll(),
      openOrder: () => handleOpenOrder(),
      selectStop: (direction: string) => handleSelectStop(direction),
      roundtripSelect: (num: string) => handleRoundtripSelection(num),
      mapFocus: () => handleMapFocus(),
      mapZoom: (direction: string) => handleMapZoom(direction),
    });
  }, 
  [
      handleUnselectAll,
      handleOpenOrder,
      handleSelectStop,
      handleRoundtripSelection,
      handleMapFocus,
      handleMapZoom
  ]);

  const [isolateRoundtrip, setIsolateRoundtrip] = useState(false);
  const [trackTruck, setTrackTruck] = useState(false);
  
  const toggleIsolateRt = useCallback(() => {
    setIsolateRoundtrip(!isolateRoundtrip);
  }, [isolateRoundtrip]);
  
  const toggleTrackTruck = useCallback(() => {
    setTrackTruck(!trackTruck);
  }, [trackTruck]);
  
  useEffect(() => {
    if (!trackTruck || !selectedRoundtripDetails) return;
    getDriverLocations({tripId: selectedRoundtripDetails._id});
  }, [trackTruck]);

  const [truckCoordinates, setTruckCoordinates] = useState<{lat: number, lng: number}[]>([]);
  const [arrowRotation, setArrowRotation] = useState(0);
  
  useEffect(() => {
    const formattedCoordinates: {lat: number, lng: number}[] = [];
    if (driverLocations?.length > 0) {
      driverLocations?.map(location => {
        formattedCoordinates.push({lat: location[1], lng: location[0]});
      });
      setTruckCoordinates(formattedCoordinates);
      if (formattedCoordinates.length > 1) {
        const bearing = calculateBearing(
          formattedCoordinates[formattedCoordinates.length - 2],
          formattedCoordinates[formattedCoordinates.length - 1]
        );
        setArrowRotation(bearing);
      }     
    }
    else {
      selectedRoundtripDetails?.stops?.map(stop => {
        formattedCoordinates.push({lat: stop?._order?._deck?.location?.geometry?.coordinates[1], lng: stop?._order?._deck?.location?.geometry?.coordinates[0]});
      });
      setTruckCoordinates(formattedCoordinates);
      if (formattedCoordinates.length > 1) {
        const bearing = calculateBearing(
          formattedCoordinates[formattedCoordinates.length - 2],
          formattedCoordinates[formattedCoordinates.length - 1]
        );
        setArrowRotation(bearing);
      };
    };
  }, [driverLocations]);

  const calculateBearing = (point1, point2) => {
    const lat1 = point1.lat * Math.PI / 180;
    const lon1 = point1.lng * Math.PI / 180;
    const lat2 = point2.lat * Math.PI / 180;
    const lon2 = point2.lng * Math.PI / 180;
  
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  
    let brng = Math.atan2(y, x);
    brng = (brng * 180 / Math.PI + 360) % 360;
  
    return brng;
  };

  const toggleUnlinkWarehouse = useCallback(() => {
    setUnlinkWarehouse(!unlinkWarehouse);
  }, [unlinkWarehouse]);

  useEffect(() => {
    if (!selectedRoundtripDetails?._id) return setIsolateRoundtrip(false);
    handleRoundtripFocus(selectedRoundtripDetails);
  }, [selectedRoundtripDetails]);

  useEffect(() => {
      setGridBoundingBox(null);
      setArrowRotation(0);
      setTruckCoordinates([]);
      setTrackTruck(false);
  }, [selectedRoundtripDetails]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const ordersCount = useMemo(() => {
    var total = 0;
    total += assignedOrders?.length || 0;
    total += unassignedOrders_?.length || 0;
    return total;
  }, [roundtrips, assignedOrders, unassignedOrders_]);

  const AssignedOrdersMarkers = useMemo(() => {
    return assignedOrders.map(order => {
      const places = {
        lat: order?._place?.location?.geometry?.coordinates[1],
        lng: order?._place?.location?.geometry?.coordinates[0],
      };
      if (order?.type === "fuel" || order?.type === "maintenance") return;
      else return (
        <React.Fragment key={order?._order?._id}>
          <ClientOrderMarker
            markerKey={order?._order?._id}
            position={places}
            isSelected={order.isSelected}
            isFiltered={order.isFiltered}
            isFilteredStop={order.isFilteredStop}
            type={RoundtripsType.Assigned}
            inSrts={order.inSrts}
            cluster={cluster}
            clusterer={clusterData}
            clientsOrders={order?._order}
            stop={order}
            index={order.place}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={clusterData?.roundtrips || roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            layerIndex={layerIndex}
            handleOpenLayer={handleOpenLayer}
            handleCloseLayer={handleCloseLayer}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            roundTripsCount={roundTripsCount}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            handleSelectOrders={handleSelectOrders}
            setAssignedOrders={setAssignedOrders}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            setDraggingFromRt={setDraggingFromRt}
            hoveredStop={hoveredStop}
            hoveredRtId={hoveringOverRt}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
          />
          {order.isFilteredStop && (
            <PulsingCircle>
              <Circle
                center={places}
                options={{
                  ...circleOptions,
                }}
              />
            </PulsingCircle>
          )}
          {hoveredStop === order?._id && (
            <Circle
              center={places}
              options={{
                ...circleOptions,
              }}
            />
          )}
        </React.Fragment>
      );
    });
  }, [assignedOrders, pinnedLayers, hoverableRoute, selectedOrders, orders]);

  const UnassignedOrdersMarkers = useMemo(() => {
    return unassignedOrders_?.map((order, index) => {
      const places = {
        lat: order?._deck?.location?.geometry?.coordinates[1],
        lng: order?._deck?.location?.geometry?.coordinates[0],
      };
      return (
        <>
          <ClientOrderMarker
            markerKey={order._id}
            position={places}
            type={RoundtripsType.Unassigned}
            inSrts={order.inSrts}
            cluster={cluster}
            clusterer={clusterData}
            clientsOrders={order}
            stop={order}
            index={index}
            onDisplayOrder={onDisplayOrder}
            accordionStatus={accordionStatus}
            setAccordionStatus={setAccordionStatus}
            roundTripCopy={roundTripCopy}
            clusterId={clusterId}
            requestOrderData={requestOrderData}
            orders={orders}
            setOrders={setOrders}
            setOrderInitCount={setOrderInitCount}
            orderInitCount={orderInitCount}
            layerIndex={layerIndex}
            handleOpenLayer={handleOpenLayer}
            handleCloseLayer={handleCloseLayer}
            pinnedLayers={pinnedLayers}
            setPinnedLayers={setPinnedLayers}
            roundTripsCount={roundTripsCount}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            handleSelectOrders={handleSelectOrders}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            markerPos={markerPos}
            setMouseOverOrder={setMouseOverOrder}
            orderCardRef={orderCardRef}
            viewMode={!!viewMode}
          />
          <If condition={order.isFilteredOrder}>
            <PulsingCircle>
              <Circle
                center={places}
                options={{
                  ...circleOptions,
                }}
              />
            </PulsingCircle>
          </If>
        </>
      );
    });
  }, [unassignedOrders_, pinnedLayers, selectedOrders, hoveringOverRt, orders]);

  const Polygons = useMemo(() => {
    return updatedDisplayRoutes?.map((p, index) => {
      let selected = false;
      if (selectedRoundtripDetails?._id === p.roundtripId) {
        selected = true;
      }
      let DropZoneCenter: number[] = p.center;
      if (p.paths.length - 2 === 1) {
        const lat = (p.paths[0].lat + p.paths[1].lat) / 2;
        const lng = (p.paths[0].lng + p.paths[1].lng) / 2;
        DropZoneCenter = [lng, lat];
      }
      return (
        <>
          {(hoverableRoute === p.roundtripId && hoverableRoute !== null) ||
          hoveringOverRt === p.roundtripId ||
          selectedRoundtripDetails?._id === p.roundtripId ? (
            <>
              {p.canceledPaths.length === 0 || !selected ? (
                <Polygon
                  ref={ref}
                  key={index}
                  paths={p.paths}
                  options={{
                    fillColor: selected
                      ? themes?.default?.primary
                      : themes?.default?.nobel,
                    fillOpacity: 0.2,
                    strokeColor: themes?.default?.strokePriamry,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: true,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 2,
                  }}
                  onMouseOut={() => {
                    setHoverableRoute(null);
                    setHoveringOverRt('');
                  }}
                  onClick={() => {
                    setRoundtripClickOnMap(p.roundtripId);
                  }}
                />
              ) : (
                p.canceledPaths.length > 0 &&
                selected && (
                  <>
                    {p.canceledPaths.map(cp => (
                      <Polygon
                        ref={ref}
                        key={index}
                        paths={cp}
                        options={{
                          fillColor: selected
                            ? 'transparent'
                            : themes?.default?.nobel,
                          fillOpacity: 0.2,
                          strokeColor: themes?.default?.redA400,
                          // strokeStyle: 'dashed',
                          strokeOpacity: 0.4,
                          strokeWeight: 3,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          geodesic: false,
                          zIndex: 2,
                        }}
                        onMouseOut={() => {
                          setHoverableRoute(null);
                          setHoveringOverRt('');
                        }}
                        onClick={() => {
                          setRoundtripClickOnMap(p.roundtripId);
                        }}
                      />
                    ))}
                    <Polygon
                      ref={ref}
                      key={index}
                      paths={p.paths.filter(path => path.type !== '')}
                      options={{
                        fillColor: selected
                          ? themes?.default?.primary
                          : themes?.default?.nobel,
                        fillOpacity: 0.2,
                        strokeColor: themes?.default?.strokePriamry,
                        strokeOpacity: 1,
                        strokeWeight: 4,
                        clickable: true,
                        draggable: false,
                        editable: false,
                        geodesic: false,
                        zIndex: 3,
                      }}
                      onMouseOut={() => {
                        setHoverableRoute(null);
                        setHoveringOverRt('');
                      }}
                      onClick={() => {
                        setRoundtripClickOnMap(p.roundtripId);
                      }}
                    />
                  </>
                )
              )}
              <CustomTooltip text={p.code} coordinates={p.center} />
              {isDragging && p.paths.length - 2 > 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : isDragging && p.paths.length - 2 === 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={DropZoneCenter}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : null}
            </>
          ) : !isolateRoundtrip ? (
            <>
              <Polygon
                key={index}
                paths={p.paths}
                options={p.options}
                onMouseOver={() => {
                  setHoverableRoute(p.roundtripId);
                  setHoveringOverRt(p.roundtripId);
                }}
                onMouseOut={() => {
                  setHoverableRoute(null);
                  setHoveringOverRt('');
                }}
              />
              {isDragging && p.paths.length - 2 > 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={p.center}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : isDragging && p.paths.length - 2 === 1 ? (
                <DropZone
                  text={p.code}
                  coordinates={DropZoneCenter}
                  zoom={zoom}
                  orders={orders}
                  setOrders={setOrders}
                  roundtripId={p.roundtripId}
                  draggingFromRt={draggingFromRt}
                  setDraggingFromRt={setDraggingFromRt}
                />
              ) : null}
            </>
          ) : null}
        </>
      );
    });
  }, [
    updatedDisplayRoutes,
    selectedRoundtripDetails,
    hoverableRoute,
    hoveringOverRt,
    isDragging,
    isolateRoundtrip,
    orders]);

  const Polylines = useMemo(() => {
    if (trackTruck && truckCoordinates?.length > 0) return(
      <>
        <Polyline
          path={truckCoordinates}
          options={{
            strokeColor: themes?.default?.purple400,
            strokeOpacity: 1.0,
            strokeWeight: 1.4,
            zIndex: 1000
          }}
        />
        {truckCoordinates?.length > 0 && truckCoordinates?.map(point => {
          return (
            <Marker
              icon={{
                url: PolylinePoints,
                size: new google.maps.Size( 8, 8 ),
                anchor: new google.maps.Point( 4, 4 )
              }}
              position={point}
              options={{
                optimized: true
              }}
            />
          )
        })}
        <OverlayViewF
          position={truckCoordinates[truckCoordinates?.length - 1 || 0]}
          mapPaneName="floatPane"
          getPixelPositionOffset={getPixelPositionOffset.bind(0, 12, 86)}
        >
          <span>
            <img
              src={PolylineArrow}
              alt=""
              style={{
                transform: `rotate(${arrowRotation}deg)`,
                transformOrigin: "bottom"
              }}
            />
          </span>
        </OverlayViewF>
      </>
    )
    else return <></>
  }, [trackTruck, driverLocations, truckCoordinates, selectedRoundtripDetails, arrowRotation]);

  const VehilcesMarkers = useMemo(() => {
    return markers?.vehicles?.map(v => (
      <MarkerVehicle
        key={v._id}
        isSelected={selectedRoundtrips?.includes(v?.roundtripId)}
        vehicle={v}
        onClick={handleClickVehicle}
      />
    ));
  }, [markers?.vehicles]);

  const WarehouseMarker = useMemo(() => {
    return clusterData?._deck?.location && (
      <MarkerStep
        label={clusterData?._deck?.shortName}
        key={clusterData?._deck?._id}
        type="deck"
        place={clusterData?._deck}
        warehouseData={warehouseData}
        roundTripsCount={roundTripsCount}
        ordersCount={ordersCount}
        overlayWarehouseVisible={overlayWarehouseVisible}
        setOverlayWarehouseVisible={setOverlayWarehouseVisible}
      />
    )
  }, [clusterData?._deck, warehouseData, ordersCount, overlayWarehouseVisible]);

  const ServiceStopsMarkers = useMemo(() => {
    return assignedOrders?.map(s => {
      if (s.type === 'maintenance' || s.type === 'fuel') {
        return (
          <MarkerStep
            type="stop"
            stopType={s.type}
            key={s._id}
            onClickOrder={onSelectOrder}
            date={s.estimatedArrivalAt || s.updatedAt}
            status={s._order?.status || s.status}
            place={s._place}
            order={s._order}
            data={s}
            index={s.place}
            displayedStopKey={displayedStopKey}
            setDisplayedStopKey={setDisplayedStopKey}
            roundTripCopy={roundTripCopy}
            setAssignedOrders={setAssignedOrders}
          ></MarkerStep>
        );
      }
    })
  }, [assignedOrders]);

  const GridBoundingBox = useMemo(() => {
    return gridBoundingBox !== null && (
      <RectangleF
        bounds={gridBoundingBox.bounds}
        options={{
          fillColor: '#2983D5',
          fillOpacity: 0.2,
          strokeColor: '#348BD8',
          strokeWeight: 2,
        }}
      />
    )
  }, [gridBoundingBox]);

  const SelectionGridBoundingBox = useMemo(() => {
    return selectionGridBoundingBox !== null && (
      <RectangleF
        onMouseUp={e => handleShowSelectionBox(e)}
        bounds={selectionGridBoundingBox.bounds}
        options={{
          fillColor: '#2983D5',
          fillOpacity: 0.1,
          strokeColor: '#348BD8',
          strokeWeight: 2,
          clickable: false,
        }}
      />
    )
  }, [selectionGridBoundingBox]);

  return isLoaded && !isloadindCluster ? (
    <MapWrapper
      id="mapContainer"
      onDragOver={e => {
        e.preventDefault();
      }}
      onDrop={() => {
        setIsDragging(false);
        setDraggingFromRt('');
      }}
    >
      <Stack direction={'row'} sx={{ height: '100%' }}>
        <If condition={!isloadindCluster}>
          <LeftMenu
            roundtrips={filteredRoundtrips}
            updateRoundtripStatus={updateRoundtripStatus}
            userContext={userContext}
            selectedRoundtrips={selectedRoundtrips}
            handleSelectRoundtrip={handleSelectRoundtrip}
            handleClickOpen={handleClickOpen}
            handleClickOpenDelete={handleClickOpenDelete}
            viewMode={viewMode || false}
            setAddModalOpen={setAddModalOpen}
            selectedRoundtripDetails={selectedRoundtripDetails}
            setSelectedRoundtripDetails={setSelectedRoundtripDetails}
            orders={orders}
            setOrders={setOrders}
            handleOrderClick={handleLeftMenuOrderClick}
            setRoundtripEdit={setRoundtripEdit}
            pinnedOrders={pinnedLayers}
            hoveringOverRt={hoveringOverRt}
            setHoveringOverRt={setHoveringOverRt}
            hoveredStop={hoveredStop}
            setHoveredStop={setHoveredStop}
            assignedOrders={assignedOrders}
          />
        </If>

        <WeatherTagWrapper viewMode={!!viewMode} zoomedIn={zoomedIn}>
          <WeatherTag latLng={weatherLatLng || center} />
        </WeatherTagWrapper>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={map => {
            setMap(map);
          }}
          options={{
            styles: defaultMapStyle,
            mapTypeControl: false,
            disableDefaultUI: true,
            keyboardShortcuts: false,
          }}
          onZoomChanged={() => {
            setZoom(map?.getZoom() || 11);
            setMapZoom(map?.getZoom() || 11);
          }}
        >
          <>
            {!viewMode && (
              <FilterChipsWrapper>
                <FilterChips filters={filters} setFilters={setFilters} />
              </FilterChipsWrapper>
            )}

            {VehilcesMarkers}

            {WarehouseMarker}

            {Polygons}

            {Polylines}

            {AssignedOrdersMarkers}
            
            {UnassignedOrdersMarkers}
            
            {ServiceStopsMarkers}

            {GridBoundingBox}

            {SelectionGridBoundingBox}

            {contextMenu && (
              <AssignedRoundtripsPopover
                contextMenu={contextMenu}
                position={contextMenuPosition}
                setOpenAddRoundtrip={setOpenRoundtrip}
              />
            )}

            {openRoundtrip && (
              <AddClientOrderPopover
                openAddRoundtrip={openRoundtrip}
                setOpenAddRoundtrip={setOpenRoundtrip}
                roundtrips={roundTripCopy}
                selectedFullOrders={selectedFullOrders}
                setSelectedFullOrders={setSelectedFullOrders}
                setSelectedOrders={setSelectedOrders}
              />
            )}

            <MapActionsWrapper viewMode={!!viewMode}>
              {selectedRoundtripDetails ? (
                <>
                  <MapActionButton
                    icon={
                      trackTruck
                        ? <img style={{ height: "18px", width: "18px" }} src={TrackTruckActive} alt=""/>
                        : <img style={{ height: "18px", width: "18px" }} src={TrackTruck} alt=""/> 
                    }
                    action={toggleTrackTruck}
                    active={trackTruck}
                    tooltip={t('track_truck')}
                  />
                  <MapActionButton
                    icon={
                      isolateRoundtrip
                        ? IsolateRoundtripActive
                        : IsolateRoundtripIcon
                    }
                    action={toggleIsolateRt}
                    active={isolateRoundtrip}
                    tooltip={t('map_actions.isolate_roundtrip')}
                  />
                </>
              ) : (
                <MapActionButton
                  icon={
                    unlinkWarehouse
                      ? <img style={{ height: "22px", width: "22px" }} src={LinkWarehouse} alt=""/>
                      : <img style={{ height: "22px", width: "22px" }} src={UnlinkWarehouse} alt=""/>
                  }
                  action={toggleUnlinkWarehouse}
                  active={unlinkWarehouse}
                  tooltip={unlinkWarehouse ? t('link_warehouse') : t('unlink_warehouse')}
                />
              )}
              <MapZoomButton mapZoom={mapZoom} setMapZoom={setMapZoom} />
            </MapActionsWrapper>

            <MapLegendWrapper viewMode={!!viewMode}>
              {React.cloneElement(LegendComponent || <></>, {
                shortcutsCallbacks: shortcutsCallbacks,
              })}
            </MapLegendWrapper>
            
            {/* {roundtrips?.map(r =>
              r.stops?.map((s, index) => {
                
              }),
            )} */}

            {/* <MapContent
              roundtrips={roundtrips}
              selectedRoundtrips={selectedRoundtrips}
              setSelectedRoundtrips={handleSelectRoundtrip}
              setView={setView}
              view={view}
            /> */}
          </>
        </GoogleMap>
      </Stack>
    </MapWrapper>
  ) : (
    <></>
  );
};

export default React.memo(Map);

const MapLegendWrapper = styled.div<{ viewMode?: boolean }>`
  position: absolute;
  bottom: ${props => (props.viewMode ? '14px' : '0')};
  left: 0;
  right: 0;
  z-index: 100;
`;

export const PulsingCircle = styled.div`
  box-shadow: 0 0 0 0 black;
  animation: pulse 2s infinite;
  cursor: pointer;
  z-index: 99;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 black;
    }
    50% {
      box-shadow: 0 0 0 5px black;
    }
    100% {
      box-shadow: 0 0 0 0 black;
    }
  }
`;

const WeatherTagWrapper = styled.div<{ viewMode: boolean; zoomedIn: boolean }>`
  position: absolute;
  top: ${props => (props.zoomedIn ? '48px' : props.viewMode ? '98px' : '48px')};
  right: 14px;
  z-index: 2000;
`;

const MapActionsWrapper = styled.div<{ viewMode: boolean }>`
  position: absolute;
  width: 32px;
  height: fit-content;
  bottom: ${props => (props.viewMode ? '72px' : '52px')};
  right: 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FilterChipsWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  > div:first-child {
    padding: 0;
  }
`;
