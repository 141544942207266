import api from './api';

export const conversationApis = api.injectEndpoints({
  endpoints: builder => ({
    postConversationMessage: builder.mutation<any, { conversationId; content }>(
      {
        query: ({ conversationId, content }) => ({
          url: `/conversations/${conversationId}/messages`,
          method: 'POST',
          body: { content },
        }),
      },
    ),
    patchConversation: builder.mutation<any, { conversationId; body }>({
      query: ({ conversationId, body }) => ({
        url: `/conversations/${conversationId}`,
        method: 'PATCH',
        body,
      }),
    }),
    getListConversations: builder.query<
      any,
      { page; contactAddressId; isArchived, text }
    >({
      query: (params: any) => ({
        url: `/conversations?page=${params.page || 1}&contactAddress=${
          params.contactAddressId || ''
        }&isArchived=${params.isArchived || ''}&text=${params.text || ''}`,
        method: 'GET',
      }),
      providesTags: ['Conversations'],
    }),
    getConversation: builder.query<any, { conversationId; page }>({
      query: (params: any) => ({
        url: `/conversations/${params.conversationId}?page=${
          params.page || 1
        }&limit=20`,
        method: 'GET',
      }),
      providesTags: ['Conversations'],
    }),
  }),
});

export const {
  usePostConversationMessageMutation,
  usePatchConversationMutation,
  useLazyGetListConversationsQuery,
  useGetListConversationsQuery,
  useGetConversationQuery,
  useLazyGetConversationQuery,
} = conversationApis;

export default conversationApis;
