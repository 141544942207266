import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PortalIcon from '@mui/icons-material/Api';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import {
  SquaresFour,
  ShoppingBagOpen,
  ClipboardText,
  Note,
  CurrencyCircleDollar,
  UsersThree,
  PhoneCall,
} from '@phosphor-icons/react';
import { MainNavItem, MenuSectionWrapper } from '../helpers';
import { CUSTOMER, ORDORIA, ORDORIA_CUSTOMER } from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { checkUserAcl } from 'common/helpers/acl';
import { Icon } from 'app/components/Icon';
import styled from 'styled-components';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';

export default function OrdoriaMenu({ theme, mode, authUser }) {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      {checkUserAcl(authUser, ['manageCustomers']) && (
        <>
          <OptionsWrapper>
            <MainNavItem
              to={ORDORIA}
              text={t('nav.mainNav.ordoria.dashboard')}
              mode={mode}
              icon={
                <Icon
                  icon={<SquaresFour />}
                  color={location.pathname === '/ordoria'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />

            <MainNavItem
              to="/ordoria/catalog"
              text={t('nav.mainNav.ordoria.catalog')}
              mode={mode}
              icon={
                <Icon
                  icon={<ShoppingBagOpen />}
                  color={location.pathname === '/ordoria/catalog'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />

            <MainNavItem
              to="/ordoria/orders"
              text={t('nav.mainNav.ordoria.orders')}
              mode={mode}
              icon={
                <Icon
                  icon={<ClipboardText />}
                  color={location.pathname === '/ordoria/orders'
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />
          </OptionsWrapper>

          <OptionsWrapper>
            <MainNavItem
              to={ORDORIA_CUSTOMER}
              text={t('nav.mainNav.customers')}
              mode={mode}
              icon={
                <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
                  <UsersThree />
                </IconWrapper>
              }
            />

            <MainNavItem
              to={'/ordoria/callplan'}
              text={t('nav.mainNav.ordoria.callplan')}
              mode={mode}
              icon={
                <PhoneCall
                  color={
                    location.pathname === '/ordoria/callplan'
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                />
              }
            />
          </OptionsWrapper>

          <MainNavItem
            to="/ordoria/notes"
            text={t('nav.mainNav.ordoria.claims')}
            mode={mode}
            icon={
              <Icon
                icon={<Note />}
                color={location.pathname === '/ordoria/notes'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
                }
                size={20}
              />
            }
          />

          <MainNavItem
            to={'/ordoria/quotations'}
            text={t('nav.mainNav.ordoria.quotations')}
            mode={mode}
            icon={
              <Icon
                icon={<CurrencyCircleDollar />}
                color={location.pathname === '/ordoria/quotations'
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
                }
                size={20}
              />
            }
          />

        </>
      )}

      {authUser?.currentAgent?._customer && (
        <MainNavItem
          to={ORDORIA}
          text={t('nav.mainNav.ordoria.portal')}
          mode={mode}
          icon={
            <PortalIcon
              sx={{
                color:
                  location.pathname === ORDORIA
                    ? theme.primaryActiveColor
                    : themes?.default?.black54,
              }}
            />
          }
        />
      )}
    </>
  );
}

const OptionsWrapper = styled.div`
  padding: 8px 0;
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;
