import {
  Form,
  FormActions,
  FormSection,
  FormWrapper,
} from 'app/components/Form/styles';
import { SubmitButton } from 'app/pages/Accessories/Components/AccessoryForm/Components/SubmitButton';
import { themes } from 'styles/theme/themes';
//import sections
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { Switch } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styled from 'styled-components';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import Box from '@mui/material/Box';
import {
  TextField,
  Grid,
  Autocomplete,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import {
  Trash,
  Flag,
  PlusCircle,
  CrosshairSimple,
} from '@phosphor-icons/react';
import {
  useLazyGetCustomerQuery,
  usePostCustomerMutation,
  useGetCustomerTypesQuery,
} from 'common/services/customerApi';
import { useGetUsersWebQuery } from 'common/services/userApi';
import { SplitButton } from 'app/components/Dropdownbutton/dropdownbtn';
import { LocationInput } from 'app/components/LocationInput';
import MapPopover from 'app/components/Mapaddclient/Add-client-map';
import { FieldComponent } from 'app/components/FieldComponent';
import {
  FooterWrapper,
  SectionWrapper,
  EditCustomerTitle,
  TitleField,
  InputFields,
  StyledTitle,
  StyledArrowBackIcon,
  ClientInformationTitle,
  ClientInformationTitleContainer,
  StyledButton,
  Gap12,
  Gap20,
} from '../styles';
interface Props {
  onClose: () => void;
  onOk?: (customerId: string) => void;
  customer: any;
  afterSubmit: any;
}

const inputStyle = {
  width: '315.5px',
  height: '37.13px',
  borderColor: '#b4b3b3',
  borderWidth: '2px',
  borderStyle: 'solid',
  outline: 'none',
};
const StyledWrapper = styled.div`
  .special-label {
    font-size: 11px !important;
    color: #b4b3b3;
    position: absolute;
    left: 10px !important;
  }
`;

export const AddCustomers: React.FC<Props> = ({
  customer,
  onClose,
  onOk,
  afterSubmit,
}) => {
  const { data: clientCategoryDetail } = useGetCustomerTypesQuery({});
  const { data: usersData } = useGetUsersWebQuery('?roles=sales');
  const [getCustomer, { data: customerDetail }] = useLazyGetCustomerQuery();
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [newEmailAddress, setNewEmailAddress] = useState<string>('');
  const [flagColors, setFlagColors] = React.useState({});
  const [locations, setLocations] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isIconClicked, setIsIconClicked] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const [iconcolor, setIconcolor] = useState(false);
  const [PhoneValue, setPhoneValue] = useState('');
  const [mobilePhone2Value, setMobilePhone2Value] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [selectedSalesValue, setSelectedSalesValue] = useState('');
  const [managernameValue, setmangernameValue] = useState('');
  const [secmanagernameValue, setsecmangernameValue] = useState('');
  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: '',
    city: '',
    department: '',
    region: '',
    continent: '',
    zipCode: '',
    formattedAddress: '',
    shortAddress: '',
    countryCode: '',
    geometry: {
      type: '',
      coordinates: [],
    },
  });
  const [loading, setLoading] = useState(false);

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  const [otherEmails, setOtherEmails] = useState<string[]>([]);
  const [switchStateValue, setSwitchState] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any>([]);
  const [latitude, setLatitude] = useState<number | null>(DEFAULT_LATITUDE);
  const [longitude, setLongitude] = useState<number | null>(DEFAULT_LONGITUDE);
  const [legalIdAltValue, setlegalIdAltValue] = useState('');

  const handleDeleteEmailAddress = index => {
    const updatedEmailAddresses = [...emailAddresses];
    updatedEmailAddresses.splice(index, 1);
    setEmailAddresses(updatedEmailAddresses);
  };

  // const handleFlagIconClick = email => {
  //   setFlagColors({
  //     ...flagColors,
  //     [email]: flagColors[email] === 'goldenrod' ? '' : 'goldenrod',
  //   });
  // };

  const handleFlagIconClick = clickedEmail => {
    const newFlagColors = {};
    for (const email of emailAddresses) {
      newFlagColors[email] = email === clickedEmail ? 'goldenrod' : '';
    }
    setFlagColors(newFlagColors);
  };

  const methods = useForm({
    defaultValues: {
      code: customer?.code,
      publicName: customer?.publicName,
      legalName: customer?.legalName,
      email: customer?.email,
      other_emails: customer?.other_emails,
      vatNumber: customer?.vatNumber,
      invoicePeriod: customer?.invoicePeriod,
      phone: customer?.phone,
      mobilePhone2: customer?.mobilePhone2,
      _customerType: customer?._customerType,
      _salesman: customer?._salesman,
      status: customer?.status,
      locations: customerDetail?.decks?.map(deck => deck.location),
      managername: customer?.managername,
      secmanagername: customer?.secmanagername,
      mainLocation: customer?.mainLocation,
      switchState: customer?.switchState,
      legalIdAlt: customer?.legalIdAlt,
    },
  });

  const { control, watch } = methods;
  const { t } = useTranslation();
  const [Addcustomer] = usePostCustomerMutation();
  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const status = watch('status');
  const email = watch('email');
  const other_emails = watch('other_emails');
  const vatNumber = watch('vatNumber');
  const phone = watch('phone');
  const mobilePhone2 = watch('mobilePhone2');
  const _customerType = watch('_customerType');
  const _salesman = watch('_salesman');
  const managername = watch('managername');
  const secmanagername = watch('secmanagername');
  const mainLocation = watch('mainLocation');
  const switchState = watch('switchState');
  const legalIdAlt = watch('legalIdAlt');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (customer?._id) {
      getCustomer(customer._id);
    }
  }, [customer?._id, mainlocationValue]);

  const handleSetLocation = (index: number, location: any) => {
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  const isValid = () => {
    const isCodeValid = !!watch('code');
    const isPublicNameValid = !!watch('publicName');
    const isLegalNameValid = !!watch('legalName');

    return isCodeValid && isPublicNameValid && isLegalNameValid;
  };

  const onSubmit = async options => {
    console.log('options', options);
    if (!isValid()) {
      return;
    }

    try {
      console.log(
        'console log all data : ',
        status,
        publicName,
        code,
        legalName,
        status,
        email,
        phone,
        mobilePhone2,
        vatNumber,
        _customerType,
        _salesman,
        managername,
        secmanagername,
        mainLocation,
        other_emails,
        switchState,
        legalIdAlt,
      );

      //primary email
      const primaryEmail = emailAddresses.find(
        email => flagColors[email] === 'goldenrod',
      );
      setLoading(true);

      const result: any = await Addcustomer({
        publicName,
        code,
        legalName,
        status: statusValue,
        // email: emailAddresses[0],
        email: primaryEmail,
        other_emails: otherEmails,
        phone: PhoneValue,
        mobilePhone2: mobilePhone2Value,
        vatNumber,
        _customerType: selectedCategoryValue,
        _salesman: selectedSalesValue,
        managername: managernameValue,
        secmanagername: secmanagernameValue,
        mainLocation: mainlocationValue,
        switchState: switchStateValue,
        legalIdAlt: legalIdAltValue,
      }).unwrap();
      setLoading(false);
      afterSubmit({
        close: options?.closeForm,
        newId: options?.refetch ? result?._id : '',
      });
      // if(options?.resetForm){
      // }

      handleClose();
    } catch (err) {
      setLoading(false);
      console.log('loading error ', err);
    }
  };

  const themegray = createTheme({
    palette: {
      secondary: {
        main: '#b3a9acd3',
      },
    },
  });

  const CustomerStatus = [
    { label: 'Preliminary', value: 'preliminary' },
    { label: 'Pending', value: 'pending' },
    { label: 'Active', value: 'active' },
    { label: 'On-watch', value: 'on-watch' },
    { label: 'Blocked', value: 'blocked' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Stopped', value: 'stopped' },
  ];

  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleLegalIdAltChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setlegalIdAltValue(event.target.value);
  };
  const handleNewEmailAddressChange = event => {
    const { value } = event.target;
    setNewEmailAddress(value);
    setIsValidEmail(validateEmail(value));

    const iconColor =
      value && isValidEmail && isIconClicked ? '#2A59FF' : '#757575';
    updateIconColor(iconColor);
  };

  const handleAddEmailAddress = () => {
    const trimmedEmail = newEmailAddress.trim();
    if (isValidEmail && trimmedEmail !== '') {
      setIsIconClicked(true);
      setEmailAddresses(prevEmailAddresses => [
        ...prevEmailAddresses,
        trimmedEmail,
      ]);
      setNewEmailAddress('');
      setOtherEmails(prevOtherEmails => [...prevOtherEmails, trimmedEmail]);

      const iconColor = newEmailAddress && isValidEmail ? '#2A59FF' : '#757575';
      updateIconColor(iconColor);
    }
  };
  const updateIconColor = color => {
    const plusCircleElement = document.getElementById('plusCircle');
    if (plusCircleElement) {
      plusCircleElement.style.color = color;
      plusCircleElement.style.cursor =
        color === '#2A59FF' ? 'pointer' : 'default';
    }
  };

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setToggle(isValid);
    setIconcolor(isValid);
    return isValid;
  };

  const handleChangeswitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);
  };
  interface Position {
    coords: {
      latitude: number;
      longitude: number;
      altitude: number | null;
      accuracy: number;
      altitudeAccuracy: number | null;
      heading: number | null;
      speed: number | null;
    };
    timestamp: number;
  }
  const handleClick1 = async () => {
    if (!clicked) {
      setClicked(true);
      try {
        const position = await new Promise<Position>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        const address = await reverseGeocode(latitude, longitude);
        if (address) {
          setQuery(address);
        } else {
          console.error('No address found for the provided coordinates.');
        }

        const response = await geocodingClient
          .reverseGeocode({
            query: [longitude, latitude],
            limit: 1,
          })
          .send();

        if (response && response.body && response.body.features) {
          let res = response.body.features[0];
          let countryCode = '';
          let regionText = '';
          let zipCode = '';
          let city = '';
          let type = '';
          let coordinates: number[] = [];

          const countryContext = res.context.find(item =>
            item.id.includes('country'),
          );
          if (countryContext) {
            countryCode = countryContext.short_code || '';
          }

          const regionContext = res.context.find(item =>
            item.id.includes('region'),
          );
          if (regionContext) {
            regionText = regionContext.text;
          }

          const zipCodeContext = res.context.find(item =>
            item.id.includes('postcode'),
          );
          if (zipCodeContext) {
            zipCode = zipCodeContext.text;
          }

          const cityContext = res.context.find(item =>
            item.id.includes('place'),
          );
          if (cityContext) {
            city = cityContext.text;
          }

          if (res.geometry && res.geometry.type && res.geometry.coordinates) {
            type = res.geometry.type;
            coordinates = res.geometry.coordinates;
          }

          setLocationvalue({
            ...mainlocationValue,
            name: res.place_name,
            city: city,
            department: '',
            region: regionText,
            continent: '',
            zipCode: zipCode,
            formattedAddress: '',
            shortAddress: '',
            countryCode: countryCode,
            geometry: {
              type: type,
              coordinates: coordinates,
            },
          });
        }
      } catch (error: any) {
        console.error('Error getting location:', error.message);
      }
    } else {
      setClicked(false);
      setQuery('');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();
      const address = data.display_name;
      return address;
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  // start

  interface Suggestion {
    name: string;
    coordinates: [number, number];
  }

  const geocodingClient = mbxGeocoding({
    accessToken:
      'pk.eyJ1IjoianJleW5hdWQiLCJhIjoiY2xkeGN0OTljMDFoODN3azlkM3UyOXA4dyJ9.gzYtNROqJfITHXZMYsoWvA',
  });

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setQuery(inputValue);

    try {
      const response = await geocodingClient
        .forwardGeocode({
          query: inputValue,
          limit: 4,
        })
        .send();

      if (response && response.body && response.body.features) {
        const suggestions = response.body.features.map(feature => ({
          name: feature.place_name,
          coordinates: feature.geometry.coordinates,
        }));

        setLocationvalue(prevState => ({
          ...prevState,
          city: inputValue,
        }));

        setSuggestions(suggestions);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };
  const handleSuggestionClick = async (suggestion: Suggestion) => {
    setLatitude(suggestion.coordinates[1]);
    setLongitude(suggestion.coordinates[0]);
    setOpen(true);
    setQuery(suggestion.name);

    const response = await geocodingClient
      .forwardGeocode({
        query: suggestion.name,
        limit: 1,
      })
      .send();

    if (response && response.body && response.body.features) {
      let res = response.body.features[0];
      let countryCode = '';
      let regionText = '';
      let zipCode = '';
      let city = '';
      let type = '';
      let coordinates: number[] = [];

      const countryContext = res.context.find(item =>
        item.id.includes('country'),
      );
      if (countryContext) {
        countryCode = countryContext.short_code || '';
      }

      const regionContext = res.context.find(item =>
        item.id.includes('region'),
      );
      if (regionContext) {
        regionText = regionContext.text;
      }

      const zipCodeContext = res.context.find(item =>
        item.id.includes('postcode'),
      );
      if (zipCodeContext) {
        zipCode = zipCodeContext.text;
      }

      const cityContext = res.context.find(item => item.id.includes('place'));
      if (cityContext) {
        city = cityContext.text;
      }

      if (res.geometry && res.geometry.type && res.geometry.coordinates) {
        type = res.geometry.type;
        coordinates = res.geometry.coordinates;
      }

      setLocationvalue({
        ...mainlocationValue,
        name: res.place_name,
        city: city,
        department: '',
        region: regionText,
        continent: '',
        zipCode: zipCode,
        formattedAddress: '',
        shortAddress: '',
        countryCode: countryCode,
        geometry: {
          type: type,
          coordinates: coordinates,
        },
      });
      setSuggestions([]);
    }
  };

  // end

  return (
    <ThemeProvider theme={themegray}>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <FormWrapper
          style={{
            height: '450px',
            minHeight: 'calc(100vh - 192px)',
            overflowY: 'auto',
          }}
        >
          <FormSection>
            <div className="title-field">
              <div className="title-field" style={{ position: 'relative' }}>
                <ClientInformationTitleContainer>
                  <ClientInformationTitle>
                    {t('Client Information')}
                  </ClientInformationTitle>
                </ClientInformationTitleContainer>

                {/* <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Client"
                          control={<Radio />}
                          label={t('Add_Client_Client')}
                        />
                        <FormControlLabel
                          value="Potential Client"
                          control={<Radio />}
                          label={t('Add_Client_Potential_Client')}
                        />
                      </RadioGroup>
                    </FormControl> */}

                <FieldComponent
                  style={{ width: '651px' }}
                  name="publicName"
                  control={control}
                  required
                  size="small"
                  label={t('Add_Client_Client_Name')}
                  color="secondary"
                  focused
                  placeholder={t('Add_Client_Enter_Public_Name')}
                  disabled={false}
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <FieldComponent
                style={{ width: '315.5px' }}
                name="code"
                control={control}
                size="small"
                label={t('Add_Client_Enter_Client_Code')}
                color="secondary"
                focused
                placeholder={t('Add_Client_Enter_Client_Code')}
                disabled={false}
                variant="outlined"
                required
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={CustomerStatus}
                    sx={{ width: '315.5px' }}
                    renderInput={params => (
                      <TextField
                        required
                        name="status"
                        {...params}
                        label={t('Add_Client_Client_Status')}
                        style={{ width: '315.5px' }}
                        size="small"
                        color="secondary"
                        focused
                        placeholder={t('Add_Client_Select_Status')}
                        variant="outlined"
                        defaultValue={'pending'}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setStatusValue(newValue?.value || '');
                    }}
                  />
                )}
              />
            </div>
          </FormSection>
          <FormSection>
            <div
              className="title-field"
              style={{
                position: 'relative',
                width: '651px',
              }}
            >
              <ClientInformationTitleContainer>
                <ClientInformationTitle style={{ width: '200px' }}>
                  {t('Add_Client_Contact_Information')}
                </ClientInformationTitle>
              </ClientInformationTitleContainer>
              <div>
                <TextField
                  style={{ width: '651px' }}
                  name="mainLocation"
                  focused
                  required
                  label={t('Add_Client_Address')}
                  color="secondary"
                  size="small"
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  placeholder={t('Add_Client_City,Street,Building')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClick1}>
                          <div>
                            {clicked ? (
                              <CrosshairSimple
                                size={25}
                                style={{ color: '#2A59FF' }}
                              />
                            ) : (
                              <CrosshairSimple
                                size={25}
                                style={{ color: '#757575' }}
                              />
                            )}
                          </div>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {suggestions.length > 0 && (
                  <div>
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{
                          cursor: 'pointer',
                          marginBottom: '5px',
                          color: '#000',
                          transition: 'color 0.3s ease',
                        }}
                        onMouseEnter={e =>
                          ((e.target as HTMLDivElement).style.color = '#2A59FF')
                        }
                        onMouseLeave={e =>
                          ((e.target as HTMLDivElement).style.color = '#000')
                        }
                      >
                        {suggestion.name}
                      </div>
                    ))}
                  </div>
                )}
                {open && (
                  <div style={{ zIndex: -1 }}>
                    <MapPopover
                      latitude={latitude}
                      longitude={longitude}
                      open={open}
                      handleClose={() => setOpen(false)}
                    />
                  </div>
                )}
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '651px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '8px',
                    }}
                  >
                    <FieldComponent
                      style={{
                        width: '611px',
                      }}
                      name="email"
                      control={control}
                      size="small"
                      label={t('Add_Client_Enter_email_address')}
                      color={toggle ? 'primary' : 'secondary'}
                      value={newEmailAddress}
                      onChange={handleNewEmailAddressChange}
                      focused
                      placeholder={t('Add_Client_Enter_email_address')}
                      disabled={false}
                      variant="outlined"
                    />
                    <PlusCircle
                      style={{
                        fontSize: '24px',
                        marginLeft: '8px',
                        marginTop: '-20px',
                        color: iconcolor ? '#2A59FF' : '#757575',
                      }}
                      onClick={() => {
                        handleAddEmailAddress();
                      }}
                      weight="fill"
                    />
                  </div>
                </div>
                <div>
                  {emailAddresses.map((email, index) => (
                    <div
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '2px',
                        border: '0.2px solid #adaaaaef',
                        borderRadius: '5px',
                        backgroundColor:
                          hoveredIndex === index ? '#FAFAFA' : 'inherit',
                      }}
                    >
                      <div>
                        {'\u00A0\u00A0'}
                        {email}
                      </div>

                      {hoveredIndex === index && (
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            padding: '0px',
                          }}
                        >
                          <IconButton>
                            <Tooltip
                              title={
                                flagColors[email] === 'goldenrod'
                                  ? 'Remove main'
                                  : 'Make as Main'
                              }
                            >
                              <Flag
                                onClick={() => handleFlagIconClick(email)}
                                style={{
                                  color: flagColors[email] || 'inherit',
                                  fontSize: '15px',
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteEmailAddress(index)}
                            style={{ color: 'red' }}
                          >
                            <Tooltip title="Delete">
                              <Trash size={12} />
                            </Tooltip>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Gap12 />
              {
                <div style={{ display: 'flex', gap: '12px' }}>
                  <StyledWrapper>
                    <PhoneInput
                      country="fr"
                      specialLabel={t('Add_Client_Mobile_Phone')}
                      inputProps={{
                        style: inputStyle,
                        onFocus: e => {
                          e.target.style.boxShadow = 'none';
                          e.target.style.borderColor = 'aaa9a9';
                        },
                        onMouseOver: e => (e.target.style.cursor = 'default'),
                        name: 'Phone',
                      }}
                      containerStyle={{ position: 'relative' }}
                      onChange={setPhoneValue}
                      value={PhoneValue}
                    />
                  </StyledWrapper>

                  <StyledWrapper>
                    <PhoneInput
                      country="fr"
                      specialLabel={t('Add_Client_Sec_Mobile_Phone')}
                      inputProps={{
                        style: inputStyle,
                        onFocus: e => {
                          e.target.style.boxShadow = 'none';
                          e.target.style.borderColor = 'aaa9a9';
                        },
                        onMouseOver: e => (e.target.style.cursor = 'default'),
                      }}
                      onChange={setMobilePhone2Value}
                      value={mobilePhone2Value}
                    />
                  </StyledWrapper>
                </div>
              }
              <Gap12 />
              <TitleField>
                <InputFields>
                  <FieldComponent
                    style={{ width: '315.5px' }}
                    name="ClientManager"
                    control={control}
                    size="small"
                    label={t('Add_Client_Manager')}
                    color="secondary"
                    focused
                    placeholder={t('Add_Clients_Manager_Full_Name')}
                    disabled={false}
                    variant="outlined"
                    onChange={event => setmangernameValue(event.target.value)}
                    value={managernameValue}
                  />
                  {
                    <FieldComponent
                      style={{ width: '315.5px' }}
                      name="SecondaryManager"
                      control={control}
                      size="small"
                      label={t('Add_Client_Secondary_Manager')}
                      color="secondary"
                      focused
                      placeholder={t('Add_Client_Sec_Man_Full_Name')}
                      disabled={false}
                      variant="outlined"
                      onChange={event =>
                        setsecmangernameValue(event.target.value)
                      }
                      value={secmanagernameValue}
                    />
                  }
                </InputFields>
              </TitleField>
              <TitleField>
                <InputFields>
                  <Autocomplete
                    disablePortal
                    id="_salesman"
                    options={
                      usersData && Array.isArray(usersData.docs)
                        ? usersData.docs.map(user => ({
                            label: `${user.firstName} ${user.lastName}`,
                            value: user.id,
                          }))
                        : []
                    }
                    onChange={(event, newValue) => {
                      setSelectedSalesValue(newValue ? newValue.value : '');
                    }}
                    sx={{ width: '315.5px' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('Add_Client_Sales')}
                        style={{ width: '315.5px' }}
                        name="_salesman"
                        size="small"
                        color="secondary"
                        focused
                        placeholder={t('Add-Client-Select-Sales')}
                        disabled={false}
                        variant="outlined"
                        value={selectedSalesValue}
                      />
                    )}
                  />
                </InputFields>
              </TitleField>
            </div>
          </FormSection>
          <FormSection>
            <div
              className="subtitle"
              style={{ fontWeight: 'bold', width: '651px' }}
            >
              <ClientInformationTitleContainer>
                <ClientInformationTitle style={{ width: '200px' }}>
                  {t('Add_Client_Business_Information')}
                </ClientInformationTitle>
              </ClientInformationTitleContainer>
              <TitleField>
                <InputFields>
                  <FieldComponent
                    style={{ width: '315.5px' }}
                    name="legalName"
                    control={control}
                    required
                    size="small"
                    label={t('Add_Client_Brand_Name')}
                    color="secondary"
                    focused
                    placeholder={t('Add_Client_Enter_Legal_Name')}
                    disabled={false}
                    variant="outlined"
                  />
                  <FieldComponent
                    style={{ width: '315.5px' }}
                    name="legalIdAlt"
                    control={control}
                    size="small"
                    label={t('Add_Client_SIREN.number')}
                    color="secondary"
                    focused
                    placeholder={t('Add_Client_Enter_SIREN_number')}
                    disabled={false}
                    variant="outlined"
                    onChange={handleLegalIdAltChange}
                  />
                </InputFields>
              </TitleField>
              <TitleField>
                <InputFields>
                  <FieldComponent
                    style={{ width: '315.5px' }}
                    name="vatNumber"
                    control={control}
                    size="small"
                    label={t('Add_Client_Intracommunity_VAT')}
                    color="secondary"
                    focused
                    placeholder={t('Add_Client_Enter_VAT_Number')}
                    disabled={false}
                    variant="outlined"
                  />
                  {/* <Autocomplete
                        disablePortal
                        id="_customerType"
                        options={selectcategory}
                        sx={{ width: 315.5 }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('Add_Client_Client_Category')}
                            style={{ width: '315.5px' }}
                            name="_customerType"
                            size="small"
                            color="secondary"
                            value={selectedCategoryValue}
                            focused
                            placeholder={t('Add_Client_Select_Category')}
                            disabled={false}
                            variant="outlined"
                          />
                        )}
                      /> */}
                </InputFields>
              </TitleField>
              <TitleField>
                <InputFields>
                  <Autocomplete
                    disablePortal
                    id="Client Group"
                    options={
                      Array.isArray(clientCategoryDetail)
                        ? clientCategoryDetail.map(item => ({
                            label: item.label,
                            value: item._id,
                          }))
                        : []
                    }
                    onChange={(event, newValue) => {
                      setSelectedCategoryValue(newValue ? newValue.value : '');
                    }}
                    sx={{ width: 315.5 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('Add_Client_Client_Group')}
                        style={{ width: '315.5px' }}
                        name="invoicePeriod"
                        size="small"
                        color="secondary"
                        focused
                        placeholder={t('Add_Client_Select_Group')}
                        disabled={false}
                        variant="outlined"
                      />
                    )}
                  />
                </InputFields>
              </TitleField>
            </div>
          </FormSection>
          <FormSection>
            <div className="title-field" style={{ width: '651px' }}>
              <ClientInformationTitleContainer>
                <ClientInformationTitle>
                  {t('Add_Client_Settings')}
                </ClientInformationTitle>
              </ClientInformationTitleContainer>{' '}
              <Box style={{ marginTop: '-10px' }}>
                <FormControlLabel
                  label={t('Add_Client_Show_prices_on_delivery_note')}
                  control={
                    <div style={{ marginLeft: '5px' }}>
                      <Switch
                        checked={switchStateValue}
                        onChange={handleChangeswitch}
                        size="small"
                        color="primary"
                      />
                    </div>
                  }
                />
              </Box>
              <Gap20 />
              {customerDetail?.decks &&
                customerDetail.decks.map((deck: any, index) => (
                  <LocationInput
                    key={`locationInput${deck._id}}`}
                    label={deck.code}
                    defaultValue={deck?.location}
                    size="small"
                    onSelect={loc => handleSetLocation(index, loc)}
                  />
                ))}
            </div>
          </FormSection>
        </FormWrapper>
      </Form>

      {/* <FooterWrapper>
                <div className="footer">
                  <StyledButton onClick={handleClose}>
                    {t('Add_Client_Cancel')}
                  </StyledButton>
                  <div className="action-buttons">
                    <Button type="submit" onClick={onSubmit}>
                      <SplitButton />
                    </Button>
                  </div>
                </div>
              </FooterWrapper> */}
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60 }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <SubmitButton
          loading={loading}
          onSubmit={onSubmit}
          disabled={false}
          isEdit={false}
          withOptions={true}
        />
      </FormActions>
    </ThemeProvider>
  );
};
