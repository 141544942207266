import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { FieldComponent } from 'app/components/FieldComponent';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';
import { dateFormater } from 'utils/dateFormater';
import {
  selectOrganization,
  selectRoles,
} from 'common/store/organization/selectors';

import {
  EditUserTitle,
  FieldWrapper,
  FooterWrapper,
  GeneralInfoField,
  GeneralInfoFieldContent,
  GeneralInfoFieldsWrapper,
  SectionWrapper,
} from '../styles';

// import { selectAuthUser } from 'app/slices/auth/selectors';
import i18next from 'i18next';
import { useUpdateUserMutation } from 'common/services/userApi';
import { selectTheme } from 'styles/theme/slice/selectors';
interface Props {
  handleClose: () => void;
  user: any;
  handleClickOpen: () => void;
  setIdUserAction: React.Dispatch<React.SetStateAction<string>>;
}

export const EditUser: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [updateUser] = useUpdateUserMutation();
  const authOrganization: any = useSelector(selectOrganization);
  const theme = useSelector(selectTheme);
  const roles = useSelector(selectRoles);
  const handleClose = () => {
    props.handleClose();
  };

  const [activated, setActivated] = useState(
    props.user.isLocked === false ? false : true,
  );

  const user = props.user;

  const prefferedAgent =
    user?.agents?.find(a => a._organization === authOrganization._id) ||
    user?.cache?.agents[authOrganization._id];

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      createdOn: '',
      updatedOn: '',
      daemonId: 'None',
      version: '',
      firstName: props.user?.firstName || '',
      lastName: props.user?.lastName || '',
      email: props.user?.email || '',
      mobileNumber: props.user?.phone || '',
      jobTitle:
        prefferedAgent?.['_role']?.localeName[
          i18next.resolvedLanguage ? i18next.resolvedLanguage : 'en'
        ] || '',
      role: props.user?.role || '',
      preferredCompany: '',
      style: '',
      password: '',
      activatedKey: '',
      status: '',
      address: props.user?.address || '-',
    },
  });

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const rolesProps = {
    options: roles,
    getOptionLabel: (option: any) =>
      option.localeName[
        i18next.resolvedLanguage ? i18next.resolvedLanguage : 'en'
      ],
  };
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const mobileNumber = watch('mobileNumber');

  const jobTitle = watch('jobTitle');
  const role = watch('role');

  const address = watch('address');
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState('');

  const onSubmit = async () => {
    try {
      await updateUser({
        id: props.user.id,
        firstName,
        lastName,
        phone: mobileNumber,
        address: address,
        jobTitle,
        role,
        isLocked: activated,
      })
        .unwrap()
        .then(() => {
          props.handleClose();
        });
    } catch (e: any) {
      console.log('e', e);
      setDisplayError(true);
      setDisplayErrorMessage(e.data.message);
    }
  };
  const [createdAt, setCreatedAt] = useState('-');
  const [updatedAt, setUpdatedAt] = useState('-');

  useEffect(() => {
    if (props.user.createdAt) {
      setCreatedAt(dateFormater(new Date(props.user.createdAt), true));
    }

    if (props.user.updatedAt) {
      setUpdatedAt(dateFormater(new Date(props.user.updatedAt), true));
    }
  }, []);
  const pending =
    props.user.lastLoginAt == null || props.user.isActive === false;
  return (
    <form style={{ width: 455 }}>
      <EditUserTitle>
        <ArrowBackIcon
          sx={{ color: theme.icon, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className="title">{t('employee.edit.title')}</div>
      </EditUserTitle>
      {props.user && (
        <>
          <div>
            <GeneralInfoFieldsWrapper>
              <GeneralInfoField>
                <GeneralInfoFieldContent>
                  <div>{t('user.edit_user.created_on')}</div>
                  <div>{createdAt}</div>
                </GeneralInfoFieldContent>
              </GeneralInfoField>
              <GeneralInfoField>
                <GeneralInfoFieldContent>
                  <div>{t('user.edit_user.updated_on')}</div>
                  <div>{updatedAt}</div>
                </GeneralInfoFieldContent>
              </GeneralInfoField>
              <GeneralInfoField>
                <GeneralInfoFieldContent>
                  <div>{t('user.edit_user.status')}</div>
                  {pending ? (
                    <Chip
                      label="Pending"
                      sx={{
                        color: theme.warningMain,
                        backgroundColor: theme.warningBackground,
                        height: '22px',
                        marginTop: '0px',
                        paddingBottom: '7px',
                      }}
                    />
                  ) : (
                    <Chip
                      label="Logged in"
                      sx={{
                        color: theme.successDark,
                        backgroundColor: theme.success,
                        height: '22px',
                        marginTop: '0px',
                        paddingBottom: '7px',
                      }}
                    />
                  )}
                </GeneralInfoFieldContent>
              </GeneralInfoField>
              <GeneralInfoField>
                <GeneralInfoFieldContent>
                  <div>{t('user.edit_user.account_status')}</div>
                  {props.user.isLocked === false ? (
                    <Chip
                      sx={{
                        height: '24px',
                        color: theme.green,
                        border: `0.063rem solid ${theme.lightSuccess50p}`,
                        marginTop: '0px',
                        paddingBottom: '7px',
                      }}
                      icon={
                        <CheckIcon
                          sx={{
                            width: '18px',
                            '&&': { color: theme.green },
                          }}
                        />
                      }
                      label="Activated"
                      variant="outlined"
                      // onClick={e => handleStatusUpdate(e, userObj)}
                    />
                  ) : (
                    <Chip
                      sx={{
                        height: '24px',
                        color: theme.errorMain,
                        border: `0.063rem solid ${theme.lightError50p}`,
                        marginTop: '0px',
                        paddingBottom: '7px',
                      }}
                      icon={
                        <CloseIcon
                          sx={{
                            width: '18px',
                            '&&': { color: theme.errorMain },
                          }}
                        />
                      }
                      label="Deactivated"
                      variant="outlined"
                      // onClick={e => handleStatusUpdate(e, userObj)}
                    />
                  )}
                </GeneralInfoFieldContent>
              </GeneralInfoField>
            </GeneralInfoFieldsWrapper>
            <SectionWrapper>
              <div className="title-field">
                <div className="title">{t('user.edit_user.user_info')}</div>

                <FieldComponent
                  style={{ width: '393px' }}
                  name="email"
                  control={control}
                  rules={{ required: true, minLength: 2 }}
                  type="email"
                  size="small"
                  label={t('common.email')}
                  defaultValue="jon.doe@email.com"
                  disabled
                  variant="outlined"
                />
              </div>

              <FieldComponent
                style={{ width: '187px' }}
                name="firstName"
                control={control}
                rules={{ required: true, minLength: 2 }}
                size="small"
                label={t('common.firstName')}
                defaultValue="John"
                variant="outlined"
              />
              <FieldComponent
                style={{ width: '187px' }}
                name="lastName"
                control={control}
                rules={{ required: true, minLength: 2 }}
                size="small"
                label={t('common.lastName')}
                defaultValue="Doe"
                variant="outlined"
              />

              <FieldComponent
                style={{ width: '187px' }}
                name="mobileNumber"
                control={control}
                rules={{ minLength: 10 }}
                size="small"
                type="phone"
                label={t('common.phone')}
                defaultValue="+33600110011"
                variant="outlined"
              />
              <FieldComponent
                style={{ width: '187px' }}
                name="address"
                control={control}
                rules={{ minLength: 2 }}
                size="small"
                label={t('common.address')}
                defaultValue="-"
                variant="outlined"
              />
            </SectionWrapper>
            <SectionWrapper>
              <div className="title-field">
                <div className="title">{t('user.edit_user.position_role')}</div>
                <FieldComponent
                  style={{ width: '187px' }}
                  name="jobTitle"
                  control={control}
                  rules={{ required: true, minLength: 2 }}
                  size="small"
                  label={t('user.jobTitle')}
                  defaultValue="Logistic Manager"
                  variant="outlined"
                />
              </div>
              <FieldWrapper>
                <Autocomplete
                  style={{ flex: 1 }}
                  {...rolesProps}
                  autoComplete={false}
                  onChange={(e, value) => {
                    setValue('role', value?.['_id'] || '');
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.key === value?.key
                  }
                  defaultValue={{
                    key: prefferedAgent?._role?.key || '',
                    localeName: prefferedAgent?._role?.localeName || {
                      en: '',
                      fr: '',
                    },
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      label="Role"
                      variant="outlined"
                    />
                  )}
                />
              </FieldWrapper>
            </SectionWrapper>
            <SectionWrapper
              style={{ justifyContent: 'flex-start', marginLeft: '32px' }}
            >
              <div className="title-field">
                <div className="title">
                  {t('user.edit_user.account_details')}
                </div>
                <FieldWrapper>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {t('user.edit_user.account_status')}
                    </FormLabel>
                    <RadioGroup
                      value={activated}
                      onChange={e => {
                        if (e.target.value === 'false') {
                          setActivated(false);
                        } else {
                          setActivated(true);
                        }
                      }}
                      className="radio_groupe"
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      style={{ width: '393px' }}
                    >
                      <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label="Active"
                        className={activated === false ? 'true' : 'false'}
                      />
                      <FormControlLabel
                        value={'true'}
                        control={<Radio />}
                        label="Deactivated"
                        className={activated === true ? 'true' : 'false'}
                      />
                    </RadioGroup>
                  </FormControl>
                </FieldWrapper>
              </div>
            </SectionWrapper>
            {/* <SectionWrapper>
              <div className="title-field">
                <div className="title">{t('user.edit_user.company_details')}</div>
                <Table>
                  <tr>
                    <td className="border_right">Company</td>
                    <td className="border_right">Email</td>
                    <td>Role</td>
                  </tr>
                  {props?.user?.['_organizations'].map(items => {
                    return (
                      <tr>
                        <td className="border_right">data1</td>
                        <td className="border_right">data2</td>
                        <td>data3</td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </SectionWrapper> */}
          </div>
          <Snackbar
            open={displayError}
            autoHideDuration={6000}
            onClose={() => setDisplayError(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              variant="filled"
              onClose={() => setDisplayError(false)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {displayErrorMessage}
            </Alert>
          </Snackbar>
          <FooterWrapper>
            <div
              className="footer"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <Button
                  onClick={() => {
                    props.handleClickOpen();
                    props.setIdUserAction(props.user.id);
                  }}
                  color="error"
                  autoFocus
                >
                  {t('common.buttons.delete_user')}
                </Button>
              </div>
              <div className="action-buttons">
                <Button onClick={handleClose} variant="outlined">
                  {t('common.buttons.cancel')}
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                  Sauvegarder
                </Button>
              </div>
            </div>
          </FooterWrapper>
        </>
      )}
    </form>
  );
};
