import { getFilterCount } from "app/helpers/helpers";
import { useDebounce } from "common/hooks/useDebounce";
import { useMemo, useState } from "react";

export const useFilterState = ({ filters }) => {
    const [filterOn, setFilterOn] = useState<HTMLButtonElement | null>(null);

    const handleCloseFilter = () => {
        setFilterOn(null);
    };
    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterOn(event.currentTarget);
    };

    const filtersCount = useMemo(() => {
        return getFilterCount(filters);
    }, [filters]);

    const debouncedFiltersCount = useDebounce(filtersCount, 1000)

    return { filterOn, handleCloseFilter, handleOpenFilter, filtersCount, debouncedFiltersCount }
}