import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';
import { Button, SxProps } from '@mui/material';
import SupportSelect from './SupportSelect';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { useUpdateOrderSupportUnitsMutation } from 'common/services/orderApi';
import { Icon } from 'app/components/Icon';
import { Info } from '@phosphor-icons/react';

interface Props {
  widthButton?: any;
  configuration?: any[];
  supportUnits: any;
  supportUnitsRequestedCapacity?: number;
  edit: any;
  updateOrderFunction?: any;
  id?: string;
  isOverLoaded?: boolean;
  tooltipDisabled?: boolean;
  refreshData?: () => void;
  displayOnHover?: boolean;
  disableButton?: boolean;
  updateOrderCache?: Function;
  roundtripId?: string;
  buttonSx?: SxProps;
}

export function IconSupportUnit(props: Props) {
  const { t } = useTranslation();
  const { updateOrderFunction } = props;

  const disabled = !!props.tooltipDisabled;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [data, setData] = useState<any>([]);
  const [hover, setHover] = useState<any>(false);
  const [keys, setKeys] = useState<any>(Object.keys(props?.supportUnits ?? {}));
  const [UpdateOrders] = useUpdateOrderSupportUnitsMutation();

  const [supportArrayCopy, setSupportArrayCopy] = useState<any>(
    props.supportUnits,
  );
  const debouncedSupportArray = useDebounce(supportArrayCopy, 500);
  const [configureArray, setConfigureArray] = useState<any>([]);
  useEffect(() => {
    if (props?.configuration !== undefined) {
      setConfigureArray(props?.configuration);
    }
  }, [props.configuration]);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    handleUpdate();
  }, [JSON.stringify(debouncedSupportArray)]);
  const handleUpdate = async () => {
    if (typeof updateOrderFunction === 'function' && props.edit) {
      await updateOrderFunction(
        props.id,
        { supportUnits: debouncedSupportArray },
        UpdateOrders,
      );
      setKeys(Object.keys(debouncedSupportArray));
      props.refreshData && props.refreshData();
    }
  };
  const handleAdd = () => {
    const newKey = ` `;
    const newSupport = { ...supportArrayCopy, [newKey]: 0 };
    setSupportArrayCopy(newSupport);
  };
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  const [supportList, setSupportList] = useState<any>(
    keys.map(key => (
      <div
        key={key}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Support key={key}>
          <SupportSelect
            edit={props.edit}
            supportArrayCopy={supportArrayCopy}
            setSupportArrayCopy={setSupportArrayCopy}
            configuration={configureArray}
            value={key}
            supportUnits={props.supportUnits}
          />
        </Support>
      </div>
    )),
  );

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.disableButton) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setData(props.supportUnits);
  }, [props.supportUnits]);

  useEffect(() => {
    setSupportList(
      keys.map(key => (
        <div
          key={key}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Support>
            <SupportSelect
              edit={props.edit}
              supportArrayCopy={supportArrayCopy}
              setSupportArrayCopy={setSupportArrayCopy}
              configuration={configureArray}
              value={key}
              supportUnits={props.supportUnits}
            />
          </Support>
        </div>
      )),
    );
  }, [keys, supportArrayCopy]);
  const open = Boolean(anchorEl);
  const theme = useSelector(selectTheme);
  const iconColor = props.isOverLoaded
    ? theme?.redStatus
    : anchorEl !== null
    ? theme?.primaryActiveColor
    : themes?.default?.textColorSecondary;
  const tooltip = props.isOverLoaded
    ? t('roundtrips.support-unit.capacity-exceeded')
    : t('view_support_units');

  useEffect(() => {
    props.updateOrderCache &&
      props.updateOrderCache(props.id, props.roundtripId, {
        supportUnits: supportArrayCopy,
      });
  }, [JSON.stringify(supportArrayCopy)]);

  return (
    <div>
      <ManagedTooltip title={tooltip} disabled={disabled}>
        <Button
          onClick={!props.displayOnHover ? handleButtonClick : () => {}}
          onMouseEnter={props.displayOnHover ? handleButtonClick : () => {}}
          sx={{
            width: props?.widthButton + 'px',
            maxWidth: props?.widthButton + 'px',
            minWidth: props?.widthButton + 'px',
            ...props.buttonSx,
          }}
        >
          <Icon icon={<Info />} color={iconColor} />
        </Button>
      </ManagedTooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div onMouseLeave={props.displayOnHover ? handlePopupClose : () => {}}>
          {supportList}
          <AddWrapper>
            {props.edit && !(keys.length === props?.configuration?.length) ? (
              <Button
                onClick={handleAdd}
                startIcon={
                  <AddOutlinedIcon
                    sx={{
                      color: theme?.primaryActiveColor,
                    }}
                  />
                }
              >
                {t('common.add')}
              </Button>
            ) : (
              <></>
            )}
          </AddWrapper>
        </div>
      </Popover>
    </div>
  );
}

export const Support = styled.div`
  border-bottom: 0.4px solid ${themes?.default?.textColorSecondary};
  margin: 0 16px;
  width: 220px;
  display: flex;
  justify-content: space-between;
  max-height: 54px;
  .title {
    height: 25px;
  }
`;

export const AddWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  width: 220px;
  display: flex;
  gap: 10px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
`;
