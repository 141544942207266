import {
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  title?: string;
  open: boolean;
  handleClose: () => void;
  drawerProps?: DrawerProps;
  width?: string;
  children: React.ReactNode | JSX.Element;
}

export const DrawerComponent = ({
  title,
  open,
  handleClose,
  drawerProps,
  width = '30%',
  children,
}: Props) => {
  const { t } = useTranslation();
  const buttonSize = '28px';
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: width },
      }}
      {...drawerProps}
    >
      <Stack height={'100%'}>
        <DrawerHeader>
          <Grid container alignItems="center" gap="20px">
            <IconButton
              onClick={handleClose}
              sx={{ width: buttonSize, height: buttonSize }}
            >
              <BackBtnWrapper>
                <Icon icon={<ArrowLeft />} />
              </BackBtnWrapper>
            </IconButton>
            <Typography fontWeight="600" fontSize="1rem">
              {t(title || '')}
            </Typography>
          </Grid>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </Stack>
    </Drawer>
  );
};

const DrawerHeader = styled.div`
  padding: 16px 24px;
  border-bottom: ${props => `1px solid ${props.theme.lightFill}`};
  position: sticky;
  top: 0;
  background-color: white;
`;
const DrawerBody = styled.div`
  flex-grow: 1;
  overflow-y: hidden;
`;

const BackBtnWrapper = styled.div`
  height: 28px;
`;
