import * as React from 'react';
import { AccordionList, StopsHeader } from 'styles/components/roundTrips';
import { IconSupportUnit } from './SupportUnit';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { Badge, IconButton, Typography } from '@mui/material';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogClearRoundtrip from 'app/pages/AddRoundTrips/components/DialogClearRoundtrip';
import { useDeleteStopMutation } from 'common/services/roundtripApi';
import { formatDeliveryHours } from 'common/helpers/place';
import styled from 'styled-components';
import { useToaster } from 'hooks/useToaster';
import { Draggable } from 'react-beautiful-dnd';
import { ActionsStop } from './components/ActionsStop';
import { deleteFunctionStop } from 'app/components/RoundTrips/function/ApiFunctions';
import StopIcon from '../../../../../components/StopIcon';
import { StatusOrder } from './StatusOrder';
import { themes } from 'styles/theme/themes';
import { useSubscribeObject } from 'common/hooks/ws';
import If from 'app/components/If';
import OrderPriority from 'app/components/OrderPriority';
import StopIndexText from 'app/components/StopIndex';
import StopNameText from 'app/components/StopNameText';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import EllipsisText from 'app/components/EllipsisText';
import { useModal } from 'app/components/Dialog/hooks';
import { AddressIcon } from 'app/components/AddressIcon';
import {
  SupportNumberWrapper,
  SupportWrapper,
} from 'app/pages/RoundTrips/styles';
import { Icon } from 'app/components/Icon';
import { Ruler, Scales } from '@phosphor-icons/react';
import { getAddressLabel } from 'app/helpers/helpers';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { Shapes } from '@phosphor-icons/react';
interface Props {
  roundtrip: any;
  updateOrderFunction?: any;
  configuration?: any;
  theme: any;
  onDisplayNotes?: Function;
  onDisplayOrder?: Function;
  onDisplayCustomer?: Function;
  typeEdit: any;
  roundTripCopy?: any;
  accordionStatus?: any;
  setAccordionStatus?: (e) => void;
  dateValue?: any;
  setRoundTripCopy?: any;
  updateFuction?: any;
  isDraggable?: boolean;
  onDisplayMap?: Function;
  triggerRoundtripDetails?: any;
  mapDisplayedStop?: any;
  setmapStop?: React.Dispatch<React.SetStateAction<any>>;
  queryParams?: string;
  orderDisabled?: boolean;
  updateOrderCache?: Function;
}
export const StopsDroware: React.FC<Props> = ({
  roundtrip,
  configuration,
  updateOrderFunction,
  theme,
  onDisplayNotes,
  onDisplayOrder,
  onDisplayCustomer,
  typeEdit,
  roundTripCopy,
  accordionStatus,
  setAccordionStatus,
  dateValue,
  setRoundTripCopy,
  updateFuction,
  isDraggable,
  onDisplayMap,
  triggerRoundtripDetails,
  mapDisplayedStop,
  setmapStop,
  queryParams,
  orderDisabled,
  updateOrderCache,
}) => {
  const { t } = useTranslation();
  const toast = useToaster();

  const lastMessage = useSubscribeObject('roundtrip', roundtrip.id);

  const { closeModal, openModal } = useModal();
  const [deleteStop] = useDeleteStopMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStop, setSelectedStop] = useState<any>({});
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [addRoundTripOpen, setAddRoundTripOpen] = useState<any>(null);
  const stopCounter = useRef(0);
  stopCounter.current = 0;

  const [statusChangeRoundTripOpen, setStatusChangeRoundTripOpen] =
    useState<any>(null);
  const [stops, setStops] = useState(roundtrip.stops);
  const hasLoadedQuantity = useMemo(() => {
    return stops?.some(stop =>
      stop?._order?.products?.some(product => product?.approvedQuantity > 0),
    );
  }, [stops]);

  const startAt = roundtrip.startAt
    ? roundtrip.startAt
    : roundtrip.defaultStartAt;
  const seconds = Math.floor(new Date(startAt)?.getTime());
  const startTime = useRef(seconds || 0);
  startTime.current = seconds || 0;

  useEffect(() => {
    setStops(roundtrip.stops);
  }, [roundtrip]);

  const handleDisplayNote = (roundtripId, stopId) => {
    if (onDisplayNotes) {
      onDisplayNotes(roundtripId, stopId);
    }
  };
  const handleDisplayOrder = order => {
    if (onDisplayOrder && order?._id) {
      onDisplayOrder(order);
    }
  };

  const handleDisplayCustomer = customer => {
    if (onDisplayCustomer && customer?._id) {
      onDisplayCustomer(customer);
    }
  };

  const handleDisplayMap = stop => {
    if (onDisplayMap) {
      onDisplayMap({
        stop: stop?._place?.location?.geometry?.coordinates,
        truck: roundtrip?._vehicle?.lastPosition?.geometry?.coordinates,
        vehicle: roundtrip?._vehicle,
      });
    }
    if (setmapStop) {
      setmapStop(stop);
    }
  };

  useEffect(() => {
    const message = JSON.parse(lastMessage);
    if (message) {
      triggerRoundtripDetails({ roundtripId: roundtrip.id });
    }
  }, [lastMessage]);

  const canEdit = useMemo(() => {
    const uneditableStatus = [
      'loaded',
      'on-way',
      'completed',
      'on-dock',
      'unloaded',
      'parked',
      'paused',
      'canceled',
    ];
    if (updateOrderFunction && !uneditableStatus.includes(roundtrip?.status)) {
      return true;
    } else {
      return false;
    }
  }, [roundtrip]);

  const getCountNotes = stopId => {
    return (
      (roundtrip.cache?.stops
        ? roundtrip.cache?.stops[stopId]?.countNotes
        : 0) || 0
    );
  };

  const handleDelete = async (stop: any, selectedRoundtrip?: any) => {
    closeModal();
    setOpenDelete(false);
    await deleteFunctionStop(deleteStop, selectedRoundtrip, stop);
    await triggerRoundtripDetails({ roundtripId: selectedRoundtrip.id });
    toast(5000, 'success', 'alerts.orders.delete_success');
  };

  if (!stops?.length) {
    return (
      <NoStopsWrapper>
        <NoStops>{t('roundtrip_has_no_stops')}</NoStops>
      </NoStopsWrapper>
    );
  }

  return (
    <div>
      <div>
        {stops?.length && (
          <StopsHeader>
            <EmptyHeader></EmptyHeader>
            <div className="stops-title sticky-stops-title">
              {t('common.stops')}
            </div>
            <div className="stops-title stops-title-md">
              {t('clients.orderCode')}
            </div>
            {/* <div className="stops-title" style={{ maxWidth: '70px' }}>
              Client Status
            </div> */}
            <div className="stops-title">
              {t('checkDocument.clientCode')} / {t('common.name')}
            </div>
            <div className="stops-title stops-title-xl">
              {t('common.address')}
            </div>
            <div className="stops-title stops-title-md">
              {t('common.delivery_time')}
            </div>
            <div className="stops-title stops-title-sm">
              {t('common.total_items')}
            </div>
            {hasLoadedQuantity && (
              <div className="stops-title stops-title-sm">
                {t('order.loaded-quantity')}
              </div>
            )}
            <div className="stops-title stops-title-sm">
              {t('roundtrips.others.weight')}
            </div>
            <div className="stops-title stops-title-sm">
              {t('roundtrips.tooltip.volume')}
            </div>

            <div className="stops-title stops-title-sm">
              {t('common.notes')}
            </div>
            <div className="stops-title stops-title-sm">
              {t('common.priority')}
            </div>
            <SupportUnitsSticky editMode={typeEdit}>
              <div className="stops-title stops-title-md">
                {t('roundtrip.support_units')}
              </div>
            </SupportUnitsSticky>
            <StatusSticky editMode={typeEdit}>
              <div className="stops-title stops-title-lg">
                {t('common.status')}
              </div>
            </StatusSticky>
            <EmptySticky editMode={typeEdit}>
              <div className="stops-title"></div>
            </EmptySticky>
          </StopsHeader>
        )}
        {stops?.map((stop, index) => {
          let hideBorder = {};
          let highlightedRow = {};
          let highlightedCell = {};
          if (roundtrip?.stops?.length - 1 === index) {
            hideBorder = {
              // borderBottom: 'unset',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            };
          }

          if (mapDisplayedStop?._id == stop?._id) {
            highlightedCell = {
              backgroundColor: theme.lightBlueActive,
            };
            highlightedRow = {
              ...highlightedCell,
              borderTop: `1px solid ${theme.primaryActiveColor}`,
              borderBottom: `1px solid ${theme.primaryActiveColor}`,
            };
          }

          const time = stop?.estimatedArrivalAt
            ? new Date(stop.estimatedArrivalAt).toLocaleTimeString('fr-FR', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
            : null;

          const stopNumber =
            stop.type !== 'fuel' && stop.type !== 'maintenance'
              ? stopCounter.current++
              : 0;

          const loadedQuantity =
            stop?._order?.products?.reduce((acc, curr) => {
              return curr.approvedQuantity ? acc + curr.approvedQuantity : acc;
            }, 0) || 0;

          return (
            <Draggable
              key={index}
              draggableId={`draggable-stop-${index}`}
              index={index}
              isDragDisabled={!isDraggable}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                  }}
                >
                  <AccordionList
                    key={stop?._id}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      ...hideBorder,
                      ...highlightedRow,
                    }}
                  >
                    <EmptyCell style={{ ...highlightedCell }}></EmptyCell>
                    <div
                      className="stops-data sticky-stops-data"
                      style={{
                        backgroundColor:
                          mapDisplayedStop?._id == stop?._id ? '#F7F8FF' : '',
                      }}
                    >
                      <StopIcon
                        type={stop?._order?.type || stop?.type}
                        orderCategory={stop?._order?.category}
                        tooltipMessage={t(
                          `common.${stop?._order?.type || stop?.type}` ||
                            'roundtrips.tooltip.collection',
                        )}
                        tooltipDisabled={orderDisabled}
                      />{' '}
                      <StopIndexText
                        type={stop?._order?.type || stop?.type}
                        index={stopNumber}
                      />
                      <span
                        style={{
                          color: theme.textColorSecondary,
                          fontSize: '0.6875rem',
                          marginLeft: 1,
                        }}
                      >
                        {time ? ` [ ${time} ]` : ''}
                      </span>
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                      className="stops-data stops-data-md code"
                      onClick={() =>
                        handleDisplayOrder({
                          ...stop?._order,
                          priority: stop?.priority,
                        })
                      }
                    >
                      <span style={{ fontWeight: 400 }}>
                        {stop?._order?.code}
                      </span>
                    </div>
                    {/* <div
                                className="stops-data"
                                style={{ maxWidth: '70px' }}
                              >
                                <ClientStatusIcon
                                  status={stop?._order?._customer?.status}
                                />
                              </div> */}
                    <div className="stops-data">
                      <StopNameText
                        stop={stop}
                        onDisplayCustomer={onDisplayCustomer}
                      />
                    </div>
                    <div className="stops-data stops-data-xl">
                      <ManagedTooltip
                        disabled={orderDisabled}
                        title={t('roundtrips.tooltip.location')}
                      >
                        <span>
                          <AddressIcon
                            onClick={() => {
                              handleDisplayMap(stop);
                            }}
                          />
                        </span>
                      </ManagedTooltip>
                      <ManagedTooltip
                        title={
                          getAddressLabel(stop?._place?.location) ||
                          stop?._place?.location?.shortAddress
                        }
                      >
                        <span>
                          {`${stop?._place?.location.zipCode}, ${stop?._place?.location.city}`}
                        </span>
                      </ManagedTooltip>
                    </div>
                    <div className="stops-data stops-data-md time">
                      <div>
                        {formatDeliveryHours(stop?._place?.deliveryHours)}
                      </div>
                    </div>
                    <div
                      className="stops-data stops-data-sm"
                      style={{
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleDisplayOrder({
                          ...stop?._order,
                          priority: stop?.priority,
                        })
                      }
                    >
                      {stop?._order?.countPackages}
                    </div>
                    {hasLoadedQuantity && (
                      <div className="stops-data stops-data-sm">
                        {loadedQuantity}
                      </div>
                    )}
                    <div
                      className="stops-data stops-data-sm"
                      style={{ overflowX: 'hidden' }}
                    >
                      <ManagedTooltip
                        title={t('roundtrips.tooltip.weight')}
                        disabled={orderDisabled}
                      >
                        <span>
                          <Icon icon={<Scales />} />
                        </span>
                      </ManagedTooltip>
                      <IconText>
                        {stop?._order?.totalWeight?.toFixed(2)} kg
                      </IconText>
                    </div>
                    <div
                      className="stops-data stops-data-sm"
                      style={{ overflowX: 'hidden' }}
                    >
                      <ManagedTooltip
                        title={t('roundtrips.tooltip.volume')}
                        disabled={orderDisabled}
                      >
                        <span>
                          <Icon icon={<Ruler />} />
                        </span>
                      </ManagedTooltip>
                      <IconText>0 ㎥</IconText>
                    </div>
                    <ManagedTooltip
                      title={t('roundtrips.tooltip.notes')}
                      disabled={orderDisabled}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div className="stops-data stops-data-sm">
                        <IconButton
                          onClick={() =>
                            handleDisplayNote(roundtrip?._id, stop?._id)
                          }
                          disableRipple
                          sx={{ padding: 0 }}
                        >
                          <If
                            condition={getCountNotes(stop?._id) > 0}
                            otherwise={<NoteOutlinedIcon />}
                          >
                            <StyledBadge
                              color="error"
                              badgeContent={getCountNotes(
                                stop?._id,
                              )?.toString()}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              overlap="circular"
                            >
                              <NoteOutlinedIcon />
                            </StyledBadge>
                          </If>
                        </IconButton>
                      </div>
                    </ManagedTooltip>
                    <div className="stops-data stops-data-sm">
                      <OrderPriority priority={stop?.priority} />
                    </div>
                    <SupportUnitsSticky editMode={typeEdit}>
                      <div className="stops-data stops-data-md">
                        <SupportWrapper>
                          <Shapes
                            size={13}
                            color={themes?.default?.iconColor}
                          />
                          <SupportNumberWrapper>
                            {stop?._order?.supportUnitsRequestedCapacity}
                          </SupportNumberWrapper>
                          <SupportUnitsPopover
                            stopId={stop?._order?.['_id']}
                            updateOrderFunction={updateOrderFunction}
                            canEdit={
                              canEdit && stop?._order?.status !== 'delivered'
                            }
                            widthButton={37}
                            supportUnits={stop?._order?.supportUnitsList}
                            tooltipDisabled={orderDisabled}
                            disableButton={
                              !(
                                stop?.type === 'delivery' ||
                                stop?.type === 'collection'
                              )
                            }
                            roundtripId={roundtrip.id}
                            updateOrderCache={updateOrderCache}
                          />
                        </SupportWrapper>
                      </div>
                    </SupportUnitsSticky>
                    <StatusSticky editMode={typeEdit}>
                      <div className="stops-data stops-data-lg">
                        <StatusOrder
                          status={
                            stop?._order ? stop?._order?.status : stop?.status
                          }
                          date={
                            stop?._order
                              ? stop._order?.updatedStatusAt
                              : stop?.updatedStatusAt
                          }
                          deliveryDistance={
                            stop?._order?.deliveryInfo?.distance
                          }
                        />
                      </div>
                    </StatusSticky>
                    <If
                      condition={!!typeEdit}
                      otherwise={
                        <EmptySticky editMode={typeEdit}>
                          <div className="stops-data"></div>
                        </EmptySticky>
                      }
                    >
                      <ActionWraper style={{ ...highlightedCell }}>
                        <ActionsStop
                          setAnchorEl={setAnchorEl}
                          onDisplayOrder={onDisplayOrder}
                          roundtrip={roundtrip}
                          typeEdit={typeEdit}
                          dateValue={dateValue}
                          stop={stop}
                          setOpenDelete={setOpenDelete}
                          accordionStatus={accordionStatus}
                          setAccordionStatus={setAccordionStatus}
                          roundTripCopy={roundTripCopy}
                          selectedStop={selectedStop}
                          setSelectedStop={setSelectedStop}
                          addRoundTripOpen={addRoundTripOpen}
                          setAddRoundTripOpen={setAddRoundTripOpen}
                          handleDelete={handleDelete}
                          openModal={openModal}
                          statusChangeRoundTripOpen={statusChangeRoundTripOpen}
                          setStatusChangeRoundTripOpen={
                            setStatusChangeRoundTripOpen
                          }
                          fromGescom={false}
                          pageRows={queryParams || ''}
                          triggerRoundtripDetails={triggerRoundtripDetails}
                        />
                      </ActionWraper>
                    </If>
                  </AccordionList>

                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          );
        })}
      </div>
      <DialogClearRoundtrip
        actionYes={async () => {
          setOpenDelete(false);
          await deleteFunctionStop(deleteStop, roundtrip, selectedStop);
          await triggerRoundtripDetails({ roundtripId: roundtrip.id });
          toast(5000, 'success', 'alerts.orders.delete_success');
          //   setOpen(false);
        }}
        open={openDelete}
        setOpen={setOpenDelete}
        description={`${t(
          'roundtrips.edit_roundtrip.confirm_delete',
        )} this stop?`}
        title={`${t('stops.deleteStop')} ?`}
        actionYesText={t('common.buttons.dialogue_delete')}
        actionNoText={t('common.buttons.close_dialogue')}
        yesButtonSx={{ color: 'red' }}
      />
    </div>
  );
};
const ActionWraper = styled.div`
  padding: 0 16px;
  right: 0;
  position: sticky;
  width: 60px;
  background-color: inherit;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  display: flex;
  align-items: center;
`;

interface StickyCellProps {
  editMode?: boolean;
}
const StickyCell = styled.div<StickyCellProps>`
  position: sticky;
  max-width: fit-content;
  margin-left: auto;
  background-color: inherit;
  z-index: 2;
  & .stops-data,
  .stops-title {
    margin-right: 0;
    margin-left: 0;
  }
  & .stops-title {
    align-items: center;
    height: 100%;
  }
  display: flex;
  align-items: center;
`;
const SupportUnitsSticky = styled(StickyCell)`
  right: ${props => (props.editMode ? '225px' : '266px')};
  & .stops-title,
  .stops-data {
    width: 152px;
    padding: 6px 0 6px 16px;
    justify-content: flex-start;
  }
`;
const StatusSticky = styled(StickyCell)`
  right: ${props => (props.editMode ? '60px' : '103px')};
  margin-left: 0;
  z-index: 2;
  & .stops-title,
  .stops-data {
    width: 175px;
    padding: 6px 16px;
    z-index: 2;
  }
`;
const EmptySticky = styled.div<StickyCellProps>`
  right: 0;
  position: sticky;
  max-width: ${props => (props.editMode ? '60px' : '103px')};
  background-color: inherit;
  z-index: 2;
  & .stops-title,
  .stops-data {
    width: ${props => (props.editMode ? '60px' : '103px')};
    margin-right: 0;
  }
`;
const EmptyHeader = styled.div`
  width: 67px;
  background-color: ${themes?.default?.whiteSmoke};
  position: sticky;
  left: 0;
  display: block;
`;
const EmptyCell = styled(EmptyHeader)`
  z-index: 2;
  background-color: rgb(255, 255, 255);
`;
export const PopOverContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  margin-left: auto;
  padding-right: 16px;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    display: 'block',
    padding: 0,
    paddingTop: '3.5px',
    background: theme.badgeRed,
    height: '16px',
    minWidth: '16px',
  },
}));

const NoStops = styled.div`
  padding: 10px 0 10px 25px;
  position: sticky;
  left: 16px;
`;
const NoStopsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 17px;
`;
const IconText = styled.div`
  padding-left: 5px;
`;
