export const hashCode = function (str = '') {
  // eslint-disable-next-line prettier/prettier
  return str.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash) + currVal.charCodeAt(0), 0);
};

export const hashObject = function (obj: { [key: string]: any }) {
  return hashCode(safeStringify(obj));
};

//stringify objects safely by removing circular references
export const safeStringify = function (obj: { [key: string]: any }): string {
  let cache: any[] = [];
  const str = JSON.stringify(obj, function (key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
    }
    return value;
  });
  cache = [];
  return str || '';
};
