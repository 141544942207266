import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/hooks/useCreateShortcut';
import { MapLegendPopover } from 'app/pages/AddRoundTrips/components/MapLegendPopover';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import { Command } from '@phosphor-icons/react';

interface ShortcutsProps {
  shortcuts: {
    key1: string;
    key2?: string;
    label: string;
    icon: JSX.Element | any;
    action: {
      callback: (num?: string) => void;
      eventName: string;
    };
  }[];
};

export const KeyboardShortcuts: React.FC<ShortcutsProps> = ({ shortcuts }) => {

  const { t } = useTranslation();
  const legends: { text: string, icon: JSX.Element | string }[] = [];

  const mapLegends = [
    {
      title: t('shortcuts'),
      legends: legends
    },
  ];

  shortcuts.forEach(shortcut => {
    if (shortcut.label || shortcut.icon) {
      legends.push({
        text: shortcut.label,
        icon: shortcut.icon
      });
    };
  });

  return (
    <>
      <MapLegendPopover
        buttonIcon={<Command />}
        content={mapLegends}
      />
      <>
        {shortcuts.map(shortcut => (
          <Shortcut shortcut={shortcut} />
        ))}
      </>
    </>
  )
};

function Shortcut({ shortcut }) {
  useCreateShortcut({
    shortcut: {
      key1: shortcut.key1,
      key2: shortcut?.key2,
      action: {
        callback: shortcut.action.callback,
        eventName: shortcut.action.eventName,
      }
    }
  });
  return <></>;
};
