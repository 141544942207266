import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    Input,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import {
    useDeleteRoundtripsNoteMutation,
    useLazyGetRoundtripsNotesQuery,
    usePatchRoundtripsNoteMutation,
    useCreateRoundtripsNoteMutation,
} from 'common/services/roundtripApi';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import ClearIcon from '@mui/icons-material/Clear';
import { useToaster } from 'hooks/useToaster';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const NoteContent = ({ open, onCloseModal, viewMode, notesData, handleAddNote, handleNoteChange, handleDeleteNote, handleUpdatedNoteChange, handleUpdateNote, note }) => {
    const authUser = useSelector(selectAuthUser);
    const theme = useSelector(selectTheme);
    const { t, i18n } = useTranslation();

    const [displayTextField, setDisplayTextField] = useState<boolean>(false);
    const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);

    const locale = i18n?.language ?? 'en'

    return (
        <Dialog
            open={open}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                '& .MuiDialog-paper': {
                    width: '657px',
                    height: '670px',
                },
            }}
        >
            <DialogContent>
                <HeaderWrapper>
                    <Typography fontWeight="500" fontSize="1.25rem">
                        {t('common.notes')}
                    </Typography>
                    <CloseIcon
                        sx={{
                            marginLeft: 'auto',
                            marginRight: '14px',
                            ':hover': { cursor: 'pointer' },
                        }}
                        onClick={onCloseModal}
                    />
                </HeaderWrapper>
                <ContentWrapper>
                    {!viewMode && (
                        <MainMessage>
                            <WriterWrapper>
                                <Avatar sx={{ marginRight: '8px', height: '40px' }}>
                                    {authUser?.fullName.charAt(0)}
                                </Avatar>
                                <Typography fontSize="1rem" fontWeight="600">
                                    {authUser?.fullName}
                                </Typography>
                            </WriterWrapper>
                            <TextField
                                label={t('add_a_note')}
                                value={note}
                                onChange={handleNoteChange}
                                multiline
                                variant="standard"
                                sx={{ width: '100%' }}
                            />
                            <ButtonWrapper>
                                <Button variant="contained" onClick={handleAddNote}>
                                    {t('common.add')}
                                </Button>
                            </ButtonWrapper>
                        </MainMessage>
                    )}
                    {notesData?.docs?.map(note => (
                        <PreviousMessageWrapper>
                            <WriterWrapper>
                                <Avatar
                                    sx={{ marginRight: '8px', height: '32px', width: '32px' }}
                                >
                                    {note._author.fullName.charAt(0)}
                                </Avatar>
                                <Typography fontSize="1rem" fontWeight="600">
                                    {note._author.fullName}
                                </Typography>
                                {authUser?.id === note._author._id && !viewMode ? (
                                    <ActionWrapper>
                                        <ActionPopover
                                            id={note._id}
                                            handleAction={(event, action) => {
                                                if (action === 'edit') {
                                                    setSelectedNoteId(note._id);
                                                    setDisplayTextField(true);
                                                } else if (action === 'delete') {
                                                    handleDeleteNote(note._id);
                                                }
                                            }}
                                            actions={[
                                                {
                                                    action: 'edit',
                                                    element: (
                                                        <>
                                                            <EditIcon sx={{ height: '15px', mr: 1, color: 'grey' }} />
                                                            <PopoverEl redStatus={theme.redStatus}>
                                                                {t('edit')}
                                                            </PopoverEl>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    action: 'delete',
                                                    element: (
                                                        <>
                                                            <DeleteIcon sx={{ height: '15px', mr: 1, color: 'grey' }} />
                                                            <PopoverEl redStatus={theme.redStatus}>
                                                                {t('delete')}
                                                            </PopoverEl>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </ActionWrapper>
                                ) : (
                                    <></>
                                )}
                            </WriterWrapper>
                            {selectedNoteId === note._id && displayTextField ? (
                                <UpdateNoteWrapper>
                                    <TextField
                                        variant="standard"
                                        multiline
                                        onChange={handleUpdatedNoteChange}
                                        defaultValue={note.content}
                                        sx={{ marginTop: 1, width: '75%' }}
                                    />
                                    <Button
                                        sx={{ height: '30px', marginLeft: 3 }}
                                        onClick={() => {
                                            handleUpdateNote(note._id)
                                            setDisplayTextField(false)
                                        }}
                                        variant="contained"
                                    >
                                        {t('common.buttons.save')}
                                    </Button>
                                    <ClearIcon
                                        sx={{
                                            color: 'grey',
                                            marginLeft: 1,
                                            ':hover': { cursor: 'pointer' },
                                        }}
                                        onClick={() => setDisplayTextField(false)}
                                    />
                                </UpdateNoteWrapper>
                            ) : (
                                <Typography
                                    fontWeight="400"
                                    fontSize="0.875rem"
                                    sx={{ color: 'rgba(0, 0, 0, 0.6)', whiteSpace: 'pre-line' }}
                                >
                                    {note.content}
                                </Typography>
                            )}
                            <Typography
                                fontWeight="600"
                                fontSize="0.75rem"
                                sx={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: '15px' }}
                            >
                                {dayjs(note.createdAt).locale(locale).fromNow()}
                            </Typography>
                        </PreviousMessageWrapper>
                    ))}
                </ContentWrapper>
                <FooterWrapper>
                    <Button onClick={onCloseModal} variant="contained">
                        {t('common.buttons.done')}
                    </Button>
                </FooterWrapper>
            </DialogContent>
        </Dialog>
    );
}

export default NoteContent;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 490px;
  margin-top: 38px;
  overflow: auto;
`;

const MainMessage = styled.div`
  width: 100%;
  border: 1px solid ${themes?.default?.dodgerBlue};
  box-shadow: 0px 3px 15px rgba(139, 139, 139, 0.1);
  border-radius: 4px;
  padding: 20px;
`;

const WriterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PreviousMessageWrapper = styled.div`
  padding: 8px;
  margin-top: 24px;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
`;

const UpdateNoteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FooterWrapper = styled.div`
  margin-top: 10px;
  float: right;
`;

