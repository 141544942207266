import { Marker, OverlayViewF } from '@react-google-maps/api';
import TruckIcon from 'app/components/TruckIcon';
import { useEffect, useMemo } from 'react';
import { getRoundtripUserAndVehicleInfo } from 'app/helpers/helpers';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import styled from "styled-components";

interface Props {
  vehicle: any;
  onClick?: any;
  isSelected?: boolean;
}

export default function MarkerVehicle({ vehicle, onClick, isSelected }: Props) {
  const userRole = Driver;
  
  const roundtripInfo = useMemo(() => {
    return getRoundtripUserAndVehicleInfo({
      agents: vehicle?.roundtrip?.agents,
      vehicle: vehicle?.numberPlate,
      role: userRole,
    });
  }, [vehicle, userRole]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });
  
  if (!vehicle?.lastPosition?.geometry?.coordinates) {
    return null;
  };
  return (
    <div 
      onClick={() => onClick?.(vehicle)}
    >
      <OverlayViewF
        mapPaneName="floatPane"
        position={{ lat: vehicle.lastPosition.geometry.coordinates[1], lng: vehicle.lastPosition.geometry.coordinates[0] }}
        getPixelPositionOffset={ getPixelPositionOffset.bind(0, 42, 34)
        }
      >
        {!roundtripInfo?.missingUser && !!roundtripInfo?.hasVehicle && (
          <TruckIcon
            driver={!!roundtripInfo?.missingUser}
            vehicle={!roundtripInfo?.hasVehicle}
            running={vehicle?.roundtrip?._vehicle?.engineRunning}
            status={vehicle?.roundtrip?._vehicle?.status}
            style={{
              position: 'relative',
              paddingLeft: '4px',
            }}
            marker={true}
          />
        )}
      </OverlayViewF>
    </div>
  );
};
