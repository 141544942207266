import React from 'react';
import { createData } from 'utils/pages/orders';
import { Tooltip } from '@mui/material';
import { StatusOrder } from '../../app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import StopIcon from 'app/components/StopIcon';
import OrderPriority from 'app/components/OrderPriority';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { IconSupportUnit } from 'app/pages/AddRoundTrips/data/RoundTripData/components/SupportUnit';
import moment from 'moment';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { PopoverEl } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import populateOrderStatusActions from './populateOrderStatusActions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import styled from 'styled-components';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import CheckIcon from '@mui/icons-material/Check';
import { selectConfiguration } from 'common/store/organization/selectors';
import { checkUserAcl } from 'common/helpers/acl';
import { getAddressLabel } from 'app/helpers/helpers';
import { AddressIcon } from 'app/components/AddressIcon';
import { Icon } from 'app/components/Icon';
import { NoteBlank, Checks, Check, X } from '@phosphor-icons/react';

interface OrderData {
  id: number | string;
  code: string;
  comment: string;
  countLines: number;
  createdAt: string;
  deleted: string;
  deliveryDate: string;
  origin: string;
  product: string;
  status: string;
  roundtripCode: string;
  roundtripPosition: string;
  supportUnits: {
    pallets: number;
    rolls: number;
    vrac: number;
  };
  supportUnitsRequestedCapacity: number;
  totalWeight: number;
  updatedAt: string;
  _customer: {
    code: string;
    createdAt: string;
    email: string;
    invoicePeriod: string;
    legalId: string;
    legalName: string;
    phone: string;
    publicName: string;
    updatedAt: string;
    vatNumber: string;
    _salesman: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  _createdBy: {
    firstName: string;
    lastName: string;
    email: string;
  };
  amountRequested: number;
  outstandingBalance: number;
}

export const useFormatOrderData = ({
  orders,
  setRows,
  setSelectedOrder,
  setOpenDrawer,
  handleOpenModal,
  onDisplayMap,
  setDetailsDrawerPage,
  queryParams,
}) => {
  const toast = useToaster();
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);
  const { t } = useTranslation();
  const handleSelectOrder = orderObj => {
    setSelectedOrder(orderObj);
    setOpenDrawer();
  };
  const handleOpenOrderNotes = orderObj => {
    setSelectedOrder(orderObj);
    setOpenDrawer();
    setDetailsDrawerPage(3);
    setTimeout(() => {
      setDetailsDrawerPage(0);
    }, 500);
  };
  const handleDisplayMap = stop => {
    if (onDisplayMap) {
      onDisplayMap(stop);
    }
  };
  const [UpdateOrders] = useUpdateOrderMutation();
  const DATE_FORMAT = 'LLL';
  React.useEffect(() => {
    const rowsDatas: any = [];

    for (const orderObj of orders) {
      const changeOrderStatus = async (status: string) => {
        await updateOrderFunction(
          orderObj.id,
          {
            status: status,
          },
          UpdateOrders,
          queryParams,
        );
        toast(3000, 'success', 'order_status_success');
      };
      const formattedUpdatedAt = window
        .dayjs(orderObj.updatedAt)
        .format(DATE_FORMAT);

      rowsDatas.push({
        data: createData(
          { value: orderObj.id },
          {
            value: (
              <If condition={orderObj.status === 'delivered'} otherwise={<></>}>
                <Tooltip title={t('orders.status.delivered')}>
                  <CheckIcon sx={{ color: themes?.default?.fruitSaladGreen }} />
                </Tooltip>
              </If>
            ),
            display: orderObj.status === 'delivered',
          },
          {
            value: (
              <StopIcon
                type={orderObj.type}
                orderCategory={orderObj.category}
                tooltipMessage={t(`common.${orderObj?.type}`)}
              />
            ),
            sort: orderObj.code,
          },
          {
            value: React.createElement('span', null, orderObj.roundtripCode),
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj._organization.name,
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj.roundtripPosition,
            ),
          },
          {
            value: <>{orderObj.code}</>,
          },
          {
            value: <OrderPriority priority={orderObj?.priority} />,
            sort: orderObj?.priorit,
          },
          {
            value: (
              <Tooltip title={orderObj._deck?.code} placement="top" arrow>
                <span>{orderObj._customer?.code}</span>
              </Tooltip>
            ),
            sort: orderObj._customer?.code,
          },

          {
            value: (
              <Tooltip title={orderObj?._customer?.publicName}>
                <ElipsisText>{orderObj?._customer?.publicName}</ElipsisText>
              </Tooltip>
            ),
            sort: orderObj?._customer?.publicName,
          },
          {
            value: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={getAddressLabel(orderObj?._deck?.location)}>
                  <ElipsisText>
                    <AddressIcon
                      onClick={() => {
                        handleDisplayMap(orderObj);
                      }}
                    />
                    {getAddressLabel(orderObj?._deck?.location)}
                  </ElipsisText>
                </Tooltip>
              </div>
            ),
          },
          {
            value: <div>{orderObj.countLines}</div>,
            sort: orderObj.countLines,
          },
          {
            value: <div>{orderObj?._createdBy?.fullName} </div>,
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj._customer?._salesman
                ? `${orderObj._customer?._salesman?.firstName} ${orderObj._customer?._salesman?.lastName}`
                : 'N/A',
            ),
          },
          {
            value: <div>{orderObj?.countPackages} </div>,
          },
          {
            value: <div>{orderObj?.totalWeight?.toFixed(2)}</div>,
          },
          {
            value: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {orderObj?.supportUnitsRequestedCapacity}
                <IconSupportUnit
                  id={orderObj?.['_id']}
                  widthButton={37}
                  supportUnits={orderObj?.supportUnits}
                  edit={false}
                />
              </div>
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj.supportUnitsRequestedCapacity,
            ),
            sort: orderObj.supportUnitsRequestedCapacity,
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.totalAmount),
            ),
            sort: orderObj.totalAmount,
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.totalAmountTax),
            ),
            sort: orderObj.totalAmountTax,
          },
          {
            value: (
              <div>{orderObj.cache?.stats?.amountPerWeight?.toFixed(2)}</div>
            ),
          },
          {
            value: (
              <div>{moment(orderObj.deliveryDate).format('DD/MM/YYYY')}</div>
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.amountRequested),
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj._customer?.outstandingBalance),
            ),
          },
          {
            value: React.createElement('span', null, formattedUpdatedAt),
            sort: orderObj.updatedAt,
          },
          {
            value:
              <PaymetStatusWrapper>
                <span>{orderObj?.paymentCollection}</span>
                {orderObj.status === 'delivered' && orderObj?.paymentCollection > 0 && (
                  <>
                    {orderObj?.paymentCollection >= orderObj?.amountRequested ? (
                      <Icon icon={<Checks />} tooltip={t('tooltip.payment_fully_collected')} color={themes?.default?.fruitSaladGreen} />
                    ) : orderObj?.paymentCollection < orderObj?.amountRequested && orderObj?.paymentCollection !== 0 ? (
                      <Icon icon={<Check />} tooltip={t('tooltip.payment_partially_collected')} color={themes?.default?.fruitSaladGreen} />
                    ) : (
                      <Icon icon={<X />} tooltip={t('tooltip.payment_not_collected')} color={themes?.default?.redA700} />
                    )}
                  </>
                )}
              </PaymetStatusWrapper>
          },
          {
            value:
              <NotesCellWrapper onClick={() => handleOpenOrderNotes(orderObj)}>
                <NoteBlank size={18} color={themes?.default?.iconColor} />
                {orderObj?.hasNotes && (
                  <NotesBlueDot />
                )}
              </NotesCellWrapper>
          },
          {
            value: <StatusOrder status={orderObj.status} />,
            sort: orderObj.status,
          },
          {
            value: (
              <div
                style={{ width: '100%' }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <NestedMenuComponent
                  id={orderObj?.id}
                  leftPosition={true}
                  actions={[
                    {
                      action: 'status',
                      nested: false,
                      onClick: () => {
                        handleSelectOrder(orderObj);
                      },
                      element: (
                        <PopoverText>
                          <PopoverEl style={{ width: '100%' }}>
                            <StyledPopoverLabel>
                              {t('orders.view_details')}
                            </StyledPopoverLabel>
                          </PopoverEl>
                        </PopoverText>
                      ),
                    },
                    {
                      action: 'assign_roundtrip',
                      nested: false,
                      onClick: () => {
                        handleOpenModal(orderObj._id, orderObj.code);
                      },
                      actions: [
                        { elements: <></>, action: 'assign_roundtrip' },
                      ],
                      element: checkUserAcl(authUser, [
                        'adminOrders',
                        'manageRoundtripsCluster',
                      ]) ? (
                        <PopoverText>
                          <PopoverEl style={{ width: '100%' }}>
                            <StyledPopoverLabel>
                              {t('orders.add_to_roundtrip')}
                            </StyledPopoverLabel>
                          </PopoverEl>
                        </PopoverText>
                      ) : null,
                    },
                    {
                      seperator: true,
                      action: 'delete',
                      element: (
                        <>
                          <div
                            className="seperator"
                            style={{
                              width: '150px',
                            }}
                          ></div>
                        </>
                      ),
                    },
                    {
                      action: 'Change_Status',
                      nested: true,
                      actions: populateOrderStatusActions({
                        changeStatus: changeOrderStatus,
                        authUser,
                        configuration,
                      }),
                      element: checkUserAcl(authUser, [
                        'adminOrders',
                        'manageRoundtripsCluster',
                      ]) ? (
                        <PopoverText>
                          <PopoverEl style={{ width: '100%' }}>
                            <StyledPopoverLabel>
                              {t('common.change_status')}
                            </StyledPopoverLabel>
                          </PopoverEl>
                        </PopoverText>
                      ) : null,
                    },
                  ]}
                />
              </div>
            ),
          },
        ),
      });
    }
    setRows(rowsDatas);
  }, [orders]);
};

const ElipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
export const PopoverText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledPopoverLabel = styled.div`
  padding-left: ${props => props.theme?.popoverLabelPadding};
`;

const PaymetStatusWrapper = styled.div`
  width: 100%;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
`;

const NotesCellWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const NotesBlueDot = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 9px;
  position: absolute;
  top: 0;
  right: 0;
  background: ${themes?.default?.primary};
`
