import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectedWarehouse } from '../Employee/types';
import WarehouseDropdown from '../Warehouse/WarehouseDropdown';
import { useModal } from '../Dialog/hooks';
import { Alert, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploadZone } from '../UploadFiles';
import { UploadedFile } from '../UploadComponent';
import styled from 'styled-components';
import { AddByFileFormProps } from './types';
import { ValidationError } from 'common/types/User';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { download } from 'utils/download';
import { useGetRole } from '../Employee/hooks';
import If from '../If';
import { updateFileType } from 'utils/upload/updateFormFileType';
import { useSelector } from 'react-redux';
import { selectDecks } from 'common/store/organization/selectors';

const formatErrorValue = (val: string) => {
  const valParts = val.split(' ');
  valParts[0] = valParts[0].split('-').join(' ');
  const firstPart = valParts.shift();
  return (
    <>
      <strong>{firstPart}</strong> {valParts.join(' ')}
    </>
  );
};

const useFormatModule = () => {
  const getRole = useGetRole();
  const { t, i18n } = useTranslation();

  const formatModule = (module: string, count = 1) => {
    const tanslateEnglish = i18n.getFixedT('en');
    const englishModule = tanslateEnglish(module).toLowerCase();

    if (englishModule.includes('sales') || englishModule.includes('driver')) {
      return getRole(englishModule, count);
    }

    return `${t(module)}${count > 1 ? 's' : ''}`;
  };

  return formatModule;
};

export const AddByFileForm: React.FC<AddByFileFormProps> = ({
  onValidate,
  onImport,
  module,
  sampleFile,
  defaultWarehouse,
  backAction,
}) => {
  const decks = useSelector(selectDecks);
  const warehouseValue = defaultWarehouse
    ? defaultWarehouse
    : decks?.[0]
    ? decks?.[0]
    : undefined;

  const [selectedWarehouse, setSelectedWarehouse] = useState<
    SelectedWarehouse | undefined
  >({
    id: warehouseValue?._id,
    label: `${warehouseValue?.name} ${
      warehouseValue?.shortName ? `(${warehouseValue?.shortName})` : ''
    }`,
  });
  const [step, setStep] = useState(!!defaultWarehouse?._id ? 1 : 0);
  const [file, setFile] = useState<File | null>(null);
  const [importDisabled, setImportDisabled] = useState(true);
  const [fatalErrors, setFatalErrors] = useState<ValidationError[]>([]);
  const [warningErrors, setWarningErrors] = useState<ValidationError[]>([]);
  const [allowedIndexes, setAllowedIndexes] = useState<number[]>([]);
  const [notAllowedIndexesFatal, setNotAllowedIndexesFatal] = useState<
    number[]
  >([]);
  const [notAllowedIndexesWarning, setNotAllowedIndexesWarning] = useState<
    number[]
  >([]);

  const proceedDisabled = useMemo(() => {
    return (step === 0 && !!!selectedWarehouse) || (step === 1 && !!!file);
  }, [file, selectedWarehouse, step]);
  const isLastStep = useMemo(() => step === 2, [step]);
  const showBadStructureError = useMemo(
    () => fatalErrors.some(f => f.key === 'import.file.structure-warning'),
    [fatalErrors],
  );

  const { setActionDisabled, setBackAction, setActionButton, closeModal } =
    useModal();
  const { t } = useTranslation();
  const formatModule = useFormatModule();

  const validateFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      let updatedFile = file;
      if (updatedFile.type === 'application/vnd.ms-excel')
        updatedFile = updateFileType(file, 'text/csv');
      formData.append('file', updatedFile);
      formData.append('warehouseId', selectedWarehouse!.id);
      formData.append('_deck', selectedWarehouse!.id);
      const {
        isImportValid,
        fatalErrors,
        warningErrors,
        allowedIndexes,
        notAllowedIndexesFatal,
        notAllowedIndexesWarning,
      } = await onValidate(formData);
      setImportDisabled(!isImportValid);
      setFatalErrors(fatalErrors);
      setWarningErrors(warningErrors);
      setAllowedIndexes(allowedIndexes);
      setNotAllowedIndexesFatal(notAllowedIndexesFatal);
      setNotAllowedIndexesWarning(notAllowedIndexesWarning);
    },
    [onValidate, selectedWarehouse],
  );

  const importFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('warehouseId', selectedWarehouse!.id);
      await onImport(formData);
    },
    [onImport, selectedWarehouse],
  );

  const onFileChange = useCallback(async (file: UploadedFile[]) => {
    setFile(file[0]);
  }, []);

  const downloadSamplefile = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      download(sampleFile);
    },
    [sampleFile],
  );

  useEffect(() => {
    setActionDisabled(proceedDisabled);
    if (isLastStep && file) {
      setActionButton({
        actionText: t('common.buttons.import'),
        async actionCallback() {
          await importFile(file);
          closeModal();
        },
        buttonProps: { disabled: importDisabled },
      });
      setBackAction({
        actionCallback() {
          setFile(null);
          setStep(prev => (prev -= 1));
        },
      });
    } else if (step) {
      setBackAction({
        actionCallback: () => {
          setFile(null);
          if (!!backAction) {
            backAction?.();
            return;
          }
          setStep(prev => (prev -= 1));
        },
      });
      if (file) {
        setActionButton({
          actionText: t('common.buttons.proceed'),
          async actionCallback() {
            await validateFile(file);
            setStep(prev => prev + 1);
          },
        });
      }
    } else {
      setActionButton({
        actionText: t('common.buttons.proceed'),
        actionCallback() {
          setStep(prev => prev + 1);
        },
        buttonProps: { disabled: !!!selectedWarehouse },
      });
      setBackAction(undefined);
    }
  }, [
    selectedWarehouse,
    proceedDisabled,
    step,
    file,
    isLastStep,
    importDisabled,
    setActionDisabled,
    setBackAction,
    setActionButton,
    t,
    closeModal,
    validateFile,
    importFile,
    backAction,
  ]);

  return (
    <>
      {step === 0 && (
        <WarehouseDropdown
          value={selectedWarehouse}
          handleChange={v => setSelectedWarehouse(v)}
        />
      )}

      {step >= 1 && (
        <Stack gap="20px">
          <span>
            {t('warehouse')}: <strong>{selectedWarehouse?.label}</strong>
          </span>

          <Stepper activeStep={step - 1}>
            <Step completed={step > 1}>
              <StepLabel>{t('import.file.excel')}</StepLabel>
            </Step>
            <Step completed={step > 2}>
              <StepLabel>{t('import.file.validation')}</StepLabel>
            </Step>
          </Stepper>

          {step === 1 && (
            <Stack gap="10px">
              <Alert severity="warning">
                {t('import.file.structure-warning')}{' '}
                <a href="#" onClick={downloadSamplefile}>
                  {t('common.links.click_here').toLowerCase()}
                </a>
              </Alert>
              <UploadZone
                maxFiles={1}
                type=""
                title=""
                description={t('upload.csv')}
                onOk={onFileChange}
                onUnOk={() => setFile(null)}
                acceptFile={{
                  'text/csv': ['.csv'],
                }}
                isUploading={false}
              />
              {file && (
                <AcceptedFiles
                  file={file}
                  statusFile={{ [file.name]: true }}
                  hideProgressBar={{ [file.name]: true }}
                  fileProgress={{ [file.name]: true }}
                  deleteFile={() => setFile(null)}
                />
              )}
            </Stack>
          )}

          {step === 2 && (
            <>
              {showBadStructureError ? (
                <Alert severity="error">
                  {t('add-by-file.dialog.file-misconfiguration')}
                </Alert>
              ) : (
                <>
                  <If condition={!!allowedIndexes.length}>
                    <Alert severity="success">
                      {allowedIndexes.length}{' '}
                      {formatModule(module, allowedIndexes.length)}{' '}
                      {t('import.success-count')}
                    </Alert>
                  </If>
                  <If condition={!!fatalErrors.length}>
                    <Alert severity="error">
                      <strong>
                        {notAllowedIndexesFatal.length}{' '}
                        {formatModule(module, notAllowedIndexesFatal.length)}{' '}
                        {t('import.warning')}
                      </strong>
                      <List>
                        {fatalErrors.map((err, i) => (
                          <li key={`${err}-${i}`}>
                            {formatErrorValue(t(err.key))}
                            {` {${err.values}}`}
                          </li>
                        ))}
                      </List>
                    </Alert>
                  </If>
                  <If condition={!!warningErrors.length}>
                    <Alert severity="warning">
                      <strong>
                        {notAllowedIndexesWarning.length}{' '}
                        {formatModule(module, notAllowedIndexesWarning.length)}{' '}
                        {t('import.warning')}
                      </strong>
                      <List>
                        {warningErrors.map((err, i) => (
                          <li key={`${err}-${i}`}>
                            {formatErrorValue(t(err.key))}
                            {` {${err.values}}`}
                          </li>
                        ))}
                      </List>
                    </Alert>
                  </If>
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default AddByFileForm;

export const List = styled.ul`
  padding-inline-start: 22px;
  margin: 0;
`;
