import { PlusCircle } from '@phosphor-icons/react';
import { IconButton } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';

interface ActionIconButtonProps {
  onClick: (e?: any) => void;
  disabled?: boolean;
  ordoria?: boolean;
  tooltip?: string;
}
export const AddIconButton = ({
  onClick,
  disabled,
  tooltip,
}: ActionIconButtonProps) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  return (
    <IconButton
      color="primary"
      onClick={onClick}
      disabled={disabled}
      sx={{ padding: '4px' }}
    >
      <Icon
        icon={<PlusCircle />}
        size={themes.default.addIconSize}
        weight="fill"
        color={theme.primary}
        tooltip={tooltip || t('common.add')}
      />
    </IconButton>
  );
};
