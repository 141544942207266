import React, { createContext, useCallback, useState } from 'react';
import GenericDialog from '../GenericDialog';
import {
  ContextProps,
  DialogActionButtonProps,
  DialogBackButtonProps,
  DialogCancelButtonProps,
  ModalOpenProps,
} from '../types';
import _ from 'lodash';
import { ButtonProps } from '@mui/material';

export const ModalContext = createContext<ContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<React.ReactNode>();
  const [action, setAction] = useState<DialogActionButtonProps>();
  const [cancel, setCancel] = useState<DialogCancelButtonProps | boolean>();
  const [back, setBack] = useState<DialogBackButtonProps>();
  const [deleteModal, setDeleteModal] = useState<boolean | undefined>(false);

  const openModal = useCallback(function ({
    title,
    content,
    action,
    cancel,
    back,
    deleteModal,
  }: ModalOpenProps) {
    setTitle(title);
    setContent(content);
    setAction(action);
    setCancel(cancel);
    setBack(back);
    setOpen(true);
    setDeleteModal(deleteModal);
  },
  []);

  const closeModal = useCallback(function () {
    setOpen(false);
    // setTitle('');
    // setContent(null);
  }, []);

  const setActionDisabled = useCallback(function (disabled = true) {
    setAction(prev => {
      if (!prev) return prev;
      const action = { ...prev };
      const buttonProps: ButtonProps = _.merge(action?.buttonProps || {}, {
        disabled,
      });
      action.buttonProps = buttonProps;
      return action;
    });
  }, []);

  const setActionButton = useCallback(function (
    action?: DialogActionButtonProps,
  ) {
    setAction(action);
  },
  []);

  const setBackAction = useCallback(function (action?: DialogBackButtonProps) {
    setBack(action);
  }, []);

  return (
    <>
      <ModalContext.Provider
        value={{
          openModal,
          closeModal,
          setActionDisabled,
          setBackAction,
          setActionButton,
        }}
      >
        <GenericDialog
          open={open}
          handleClose={closeModal}
          title={title}
          children={content}
          actionYes={action}
          actionNo={cancel}
          actionBack={back}
          deleteModal={deleteModal}
        />
        {children}
      </ModalContext.Provider>
    </>
  );
};
