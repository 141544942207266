import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import BuildIcon from '@mui/icons-material/Build';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Dayjs } from 'dayjs';
import { Existing } from './Existing';
import { Manual } from './Manual';
import { StyledMenu } from '../../../EnhancedTableToolbar/component/CustomizedMenus';
import { TemplateIntegration } from './TemplateIntegration';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import If from 'app/components/If';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';

interface Props {
  anchorEl: any;
  handleClose: any;
  warehouseId?: any;
  roundTripCopy?: any;
  allRoundtrip?: any;
}
export const Modals: React.FC<Props> = ({
  anchorEl,
  handleClose,
  warehouseId,
  roundTripCopy,
  allRoundtrip,
}) => {
  const { t } = useTranslation();

  const tabsContext = useContext(TabsContext);
  const userContext = tabsContext?.userContext;
  const dateValue = tabsContext?.dateValue;
  const begin = tabsContext?.dateBegin;
  const end = tabsContext?.dateEnd;

  const theme = useSelector(selectTheme);

  //DragFile
  const [warehouse, setWarehouse] = useState({
    code: '',
    location: '',
    name: '',
    type: '',
    _id: '',
  });

  const [openExisting, setOpenExisting] = useState(false);
  const [openTemplate, setOpenTemplate] = useState<null | boolean>(false);
  const [openManual, setOpenManual] = useState<null | boolean>(false);

  return (
    <>
      <Existing
        warehouseId={warehouseId}
        setWarehouse={setWarehouse}
        dateValue={dateValue}
        open={openExisting}
        handleClose={() => {
          setOpenExisting(false);
        }}
        warehouse={warehouse}
        begin={begin}
        end={end}
        roundTripCopy={roundTripCopy}
        allRoundtrip={allRoundtrip}
      />

      {!!openTemplate && (
        <TemplateIntegration
          warehouseId={warehouseId}
          setWarehouse={setWarehouse}
          dateValue={dateValue}
          warehouse={warehouse}
          begin={begin}
          end={end}
          roundTripCopy={roundTripCopy}
          allRoundtrip={allRoundtrip}
          open={openTemplate}
          setOpen={setOpenTemplate}
        />
      )}

      <Manual
        warehouseId={warehouseId}
        setWarehouse={setWarehouse}
        dateValue={dateValue}
        warehouse={warehouse}
        begin={begin}
        end={end}
        roundTripCopy={roundTripCopy}
        allRoundtrip={allRoundtrip}
        open={openManual}
        setOpen={setOpenManual}
      />

      {/* {setAnchorElUpload && anchorElUpload && (
        <UploadFile
          dateValue={dateValue}
          handleClickUploadCheck={e => {
            handlesetAnchorFunction(e, setAnchorElUploadCheck);
          }}
          setAnchorElUpload={setAnchorElUpload}
          anchorElUpload={anchorElUpload}
          warehouse={warehouse}
          handleClickActivate={e => {
            handlesetAnchorFunction(e, setAnchorElUploadCheck);
          }}
        />
      )}

      {handleClickUpload && (
        <UploadFIleChecking
          dateValue={dateValue}
          setAnchorElUploadCheck={setAnchorElUploadCheck}
          anchorElUploadCheck={anchorElUploadCheck}
          warehouse={warehouse}
          handleClickUpload={handleClickUpload}
        />
      )} */}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <If
          condition={allRoundtrip || userContext === AgentCategories.Logistic}
        >
          <MenuItem
            onClick={e => {
              handleClose();
              setOpenExisting(true);
            }}
            disableRipple
            aria-label="existing"
          >
            <UploadFileIcon sx={{ color: theme.icon }} />
            {t('common.buttons.existing')}
          </MenuItem>
        </If>
        <MenuItem
          onClick={e => {
            handleClose();
            setOpenTemplate(true);
          }}
          disableRipple
          aria-label="manual"
        >
          <NewspaperIcon sx={{ color: theme.icon }} />
          {t('common.buttons.template')}
        </MenuItem>
        <MenuItem
          onClick={e => {
            setOpenManual(true);
            handleClose();
          }}
          disableRipple
          aria-label="manual"
        >
          <BuildIcon sx={{ color: theme.icon }} />
          {t('common.buttons.manual')}
        </MenuItem>
      </StyledMenu>
    </>
  );
};
