export interface Accessory {
  _id: string;
  name: string;
  code: string;
  department: string;
  createdAt: string;
  updatedAt: string;
  price: number;
  _assignee: any;
  _assigneeType: string;
  notes: string;
  status: AccessoryStatus | string;
  Status: AccessoryStatus | string;
  outofservice: boolean;
  pictureUrl: { url: string; size: number; name: string }[];
  documentUrl: { url: string; size: number; name: string }[];
  _createdBy?: any;
}

export enum AssigneeType {
  employee = 'employee',
  vehicle = 'vehicle',
}

export enum AccessoryStatus {
  available = 'available',
  outOfService = 'outofservice',
  inUse = 'inuse',
}

export const AccessoryStatuses = [
  AccessoryStatus.inUse,
  AccessoryStatus.available,
  AccessoryStatus.outOfService,
];

export enum AccessoryType {
  accessories = 'accessories',
  tools = 'tools',
  paymentCards = 'paymentcards',
}

export enum UnavailableReason {
  maintenance = 'maintenance',
  outOfService = 'outofservice',
  lost = 'lost',
  other = 'other',
}
