import styled from 'styled-components';
import { useModal } from '../Dialog/hooks';
import { themes } from 'styles/theme/themes';
import { useToaster } from 'hooks/useToaster';
import { CustomerInvitationWrapper } from 'hooks/Customers/useFormatCustomersData';
import { useState } from 'react';
import { useCancelInviteMutation } from 'common/services/customerApi';
import { useTranslation } from 'react-i18next';
import { MarkunreadMailboxOutlined } from '@mui/icons-material';

const CancelButton = styled.div`
  color: ${themes.default.chipRedBg};
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 0.85rem;
  line-height: 24px;
  letter-spacing: 0.17px;
`;

export function InvitationSent({ id }: { id: string }) {
  const [hoverEl, setHoverEl] = useState<any>();

  const [cancelInvite] = useCancelInviteMutation();
  const { t } = useTranslation();
  const toast = useToaster();
  const { openModal, closeModal } = useModal();

  async function handleCancelInvitation() {
    try {
      await cancelInvite(id).unwrap();
      toast(3000, 'success', t('user.invite.cancel.success'));
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      }
    }
    closeModal();
  }

  function onCancelModal() {
    openModal({
      title: t('user.invite.cancel.title'),
      content: t('customer.invite.cancel.message'),
      cancel: true,
      action: {
        actionCallback: handleCancelInvitation,
        actionText: t('common.buttons.ok'),
      },
    });
  }

  return (
    <>
      {!!hoverEl ? (
        <CancelButton
          onMouseLeave={() => setHoverEl(null)}
          onClick={onCancelModal}
        >
          {t('common.cancel_invitation')}
        </CancelButton>
      ) : (
        <CustomerInvitationWrapper onMouseEnter={e => setHoverEl(e)}>
          <MarkunreadMailboxOutlined sx={{ width: '12px', height: '12px' }} />
          <div>{t('customer.invite_sent')}</div>
        </CustomerInvitationWrapper>
      )}
    </>
  );
}

export default InvitationSent;
