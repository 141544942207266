import { useContext, useState } from 'react';
import { LeftMenuContext } from '../LeftMenu';
import { RoundtripListItem } from './components/RoundtripListItem';
import { Stack } from '@mui/material';
import styled from 'styled-components';

export const RoundtripList = ({ setSelectedRoundtripDetails, orders, setOrders, hoveringOverRt, setHoveringOverRt }) => {
  const { roundtrips, collapsed } = useContext(LeftMenuContext);

  return (
    <>
      <Container collapsed={!!collapsed}>
        <Stack gap="12px" sx={{ padding: '0 4px 0 10px' }}>
          {roundtrips?.map(rt => {
            return (
              <div style={{ position: "relative" }}>
                <RoundtripListItem
                  key={rt._id}
                  roundtrip={rt}
                  onClick={e => {
                    setSelectedRoundtripDetails(rt);
                  }}
                  orders={orders}
                  setOrders={setOrders}
                  hoveringOverRt={hoveringOverRt}
                  setHoveringOverRt={setHoveringOverRt}
                />
              </div>
            );
          })}
        </Stack>
      </Container>
    </>
  );
};

const Container = styled.div<{collapsed}>`
  position: relative;
  height: calc(
    100vh - ${props => props.theme.tableNavHeight} -
      ${props => props.theme.toolBarHeight} - 80px
  );

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
    scrollbar-width: none;
  };
  margin-right: -14px;
  scrollbar-width: none;
  scrollbar-color: transparent;
  overflow-y: auto;
  overflow-y: auto;
  overflow-x: hidden;

  .truckIconWrapper {
    right: ${props => props.collapsed ? "2px" : "-1px"} !important;
  }
`;
