import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.organization || initialState;

export const selectOrganization = createSelector(
  [selectSlice],
  state => state.organization,
);

export const selectRoles = createSelector([selectSlice], state => state.roles);
export const selectDeliveryRoles = createSelector(
  [selectSlice],
  state => state.deliveryRoles,
);

export const selectDecks = createSelector([selectSlice], state => state.decks);

export const selectProductFamilies = createSelector(
  [selectSlice],
  state => state.productFamilies,
);

export const selectOrganizationState = createSelector(
  [selectSlice],
  state => state,
);

export const selectParkNumbersState = createSelector(
  [selectSlice],
  state => state.parkNumbers,
);

export const selectContactAddresses = createSelector(
  [selectSlice],
  state => state.contactAddresses,
);

export const personalStopReasonsState = createSelector(
  [selectSlice],
  state => state.personalStopReasons,
);

export const selectCustomerStatus = createSelector(
  [selectSlice],
  state => state.customerStatus,
);
export const selectOrderStatus = createSelector(
  [selectSlice],
  state => state.orderStatus,
);
export const selectCustomerTypes = createSelector(
  [selectSlice],
  state => state.customerTypes,
);
export const selectCreditScores = createSelector(
  [selectSlice],
  state => state.creditScores,
);
export const fuelTypes = createSelector(
  [selectSlice],
  state => state.fuelTypes,
);
export const vehicleCategories = createSelector(
  [selectSlice],
  state => state.vehicleCategories,
);
export const roundtripCodes = createSelector(
  [selectSlice],
  state => state.roundtripCodes,
);
export const agentGroups = createSelector(
  [selectSlice],
  state => state.agentGroups,
);
export const toolsLicences = createSelector(
  [selectSlice],
  state => state.toolsLicences,
);

export const selectActivityCodes = createSelector(
  [selectSlice],
  state => state.activityCodes,
);

export const agentContracts = createSelector(
  [selectSlice],
  state => state.agentContracts,
);

export const selectOrganizationActivities = createSelector(
  [selectSlice],
  state => state.activities,
);

export const selectConfiguration = createSelector([selectSlice], state => ({
  supportUnits: state.supportUnits,
  roles: state.roles,
  parkNumbers: state.parkNumbers,
  orderStatus: state.orderStatus,
  orderStatusLogistic: state.orderStatusLogistic,
  customerStatus: state.customerStatus,
  fuelTypes: state.fuelTypes,
  vehicleCategories: state.vehicleCategories,
  roundtripCodes: state.roundtripCodes,
  agentGroups: state.agentGroups,
  toolsLicences: state.toolsLicences,
  agentContracts: state.agentContracts,
  supportUnitsList: state.supportUnitsList,
  decks: state.decks,
  deckCount: state.deckCount,
}));
