import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, IconButton, Input, Stack } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';

interface Vehicle {
  key: any;
  model: any;
  numberPlate: any;
  _id: any;
}

interface VehicleSelectorProps {
  value?: any;
  supportUnits?: any;
  configuration: any;
  supportArrayCopy: any;
  setSupportArrayCopy: any;
  edit: Boolean;
}

function SupportSelect(props: VehicleSelectorProps) {
  const [isHovered, setIsHovered] = useState(true);
  const [isHoveredInput, setIsHoveredInput] = useState(false);
  const [vehicleData, setVehicleData] = useState<Vehicle | null>(null);
  // const [supportArrayValue, setSupportArrayValue] = useState(
  //   props.supportArrayCopy,
  // );

  const [supportValue, setSupportValue] = useState(props.supportArrayCopy[props.value]);

  useEffect(() => {
    props.setSupportArrayCopy(prevObj => {
      return { ...prevObj, [props.value]: supportValue };
    });
  }, [supportValue])

  const handleMouseEnter = () => {
    // setIsHovered(true);
  };
  const handleMouseLeave = () => {
    // setIsHovered(false);
  };
  const handleMouseEnterInput = () => {
    setIsHoveredInput(true);
  };
  const handleMouseLeaveInput = () => {
    setIsHoveredInput(false);
  };
  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 0 || isNaN(newValue)) {
      newValue = 0;
    }
    setSupportValue(newValue);
  };
  const handleIncrement = (value: number) => {
    setSupportValue(value);
  };
  const handleDelete = () => {
    const { [props.value]: deletedValue, ...newObject } =
      props.supportArrayCopy;
    props.setSupportArrayCopy(newObject);
  };
  const configurationProps = {
    options: props.configuration || [{ label: 'Loading...', id: 0 }],
    getOptionLabel: (option: any) => {
      return option?.key || '';
    },
  };
  return (
    <div
      style={{
        borderBottom: `0.4px solid ${themes?.default?.textColorSecondary}`,
        width: '220px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.value === 'pallets' ||
        props.value === 'rolls' ||
        props.value === 'vrac' ? (
        <>
          <p className="title">{props.value}</p>
          {props.edit ? (
            <Stack direction="row" alignItems="center">
              <IconButton
                size="small"
                onClick={() => handleIncrement(supportValue - 1)}
                disabled={supportValue <= 0}
              >
                <RemoveIcon sx={{ width: '0.7em', height: '0.7em' }} />
              </IconButton>
              {!!isHovered ? (
                <TextField
                  value={supportValue}
                  onChange={handleChangeTextField}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'center', padding: ' 2px 1px' },
                  }}
                  name="numberformat"
                  id="formatted-numberformat-input"
                  variant="outlined"
                  sx={{
                    width: '35px',
                    textAlign: 'center',
                    marginTop: '16px',
                    fontSize: '1rem',
                    height: '25px',
                    alignItems: 'center',
                  }}
                />
              ) : <SupportValue className="value">
                {props.supportArrayCopy[props.value]}
              </SupportValue>}
              <IconButton
                size="small"
                onClick={() => handleIncrement(supportValue + 1)}
              >
                <AddIcon sx={{ width: '0.7em', height: '0.7em' }} />
              </IconButton>
            </Stack>
          ) : (
            <SupportValue className="value">
              {props.supportUnits[props.value]}
            </SupportValue>
          )}
        </>
      ) : (
        <>
          {(props.edit === false || !isHovered) && (
            <>
              <p className="title">{props.value}</p>
              <p className="value">{props.supportUnits[props.value]}</p>
            </>
          )}
          {props.edit && isHovered && (
            <Dropdown>
              <div className="remove_autocomplete">
                {props.value !== 'pallets' ||
                  props.value !== 'rolls' ||
                  (props.value !== 'vrac' && (
                    <IconButton onClick={handleDelete}>
                      <RemoveCircleOutlineIcon
                        sx={{ color: themes?.default?.redStatus }}
                      />
                    </IconButton>
                  ))}

                <Autocomplete
                  style={{ flex: 1, height: '40px' }}
                  {...configurationProps}
                  autoComplete={false}
                  onChange={(e, newValue) => {
                    const newSupportArrayCopy = { ...props.supportArrayCopy };
                    delete Object.assign(newSupportArrayCopy, {
                      [newValue?.key]: newSupportArrayCopy[props.value],
                    })[props.value];
                    props.setSupportArrayCopy({
                      ...newSupportArrayCopy,
                      [newValue?.key]: props.supportArrayCopy[props.value],
                    });
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option?.['key'] === value?.key;
                  }}
                  defaultValue={{
                    key: props.value,
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name={'support'}
                      size="small"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Stack
                direction="row"
                onMouseEnter={handleMouseEnterInput}
                onMouseLeave={handleMouseLeaveInput}
              >
                {!isHovered && (props.supportArrayCopy[props.value] ?? 0)}
                {!!isHovered && props.edit && (
                  <TextField
                    defaultValue={props.supportArrayCopy[props.value] ?? 0}
                    onChange={handleChangeTextField}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'center', padding: ' 2px 1px' },
                    }}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    variant="outlined"
                    sx={{
                      width: '20px',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                  />
                )}
              </Stack>
            </Dropdown>
          )}
        </>
      )}
    </div>
  );
}

// export default React.memo(SupportSelect, (prev, next) => { return _.isEqual(prev.supportArrayCopy, next.supportArrayCopy) });
export default SupportSelect

export const Dropdown = styled.div`
  align-items: center;
  justify-content: space-between;
  .MuiButtonBase-root {
    padding-top: 1px;
  }
  width: 220px;
  display: flex;
  gap: 10px;
  .remove_autocomplete {
    display: flex;
    gap: 10px;
  }
`;

const SupportValue = styled.p`
  font-size: 1rem;
  margin-top: 16px;
  width: 35px;
  display: flex;
  justify-content: center;
  height: 25px;
  align-items: center;
`;
