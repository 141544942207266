import { Skeleton, Stack } from "@mui/material"
import If from "app/components/If";

interface StatisticCardSkeletonProps {
  withIcon?: boolean;
}

export const StatisticCardSkeleton = ({ withIcon }: StatisticCardSkeletonProps) => {
  return (
    <>
      <If condition={!!withIcon}>
        <Skeleton variant="rounded" width={30} height={33} sx={{ marginBottom: '14px' }} />
      </If>
      <Stack
        direction='row'
        gap='24px'
        alignItems='center'
        justifyContent='space-between'
      >
        <div>
          <Skeleton variant="rounded" width={85} height={17} sx={{ marginBottom: '14px' }} />
          <Skeleton variant="rounded" width={100} height={30} />
        </div>
        <div>
          <Skeleton variant="rounded" width={50} height={25} sx={{ borderRadius: '16px' }} />
        </div>
      </Stack>
    </>
  )
}