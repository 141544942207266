import {
    Box,
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Order } from 'app/components/TableComponent/index';
import { useTranslation } from 'react-i18next';
import { FixedTableHead } from '../TableComponent/helpers';

interface EnhancedTableProps {
    order: Order;
    orderBy: string;
    onRequestSort: (property: string) => void;
    isLoading: boolean;
    headCells: any;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        order,
        orderBy,
        onRequestSort,
        isLoading,
        headCells,
    } = props;
    const createSortHandler = (property: string) => () => {
        onRequestSort(property);
    };

    const { t } = useTranslation();
    const theme = useSelector(selectTheme);

    let stickyCheckbox = {
        zIndex: 1,
        backgroundColor: theme.grey,
        left: 0,
        position: 'sticky',
    };

    return (
        <FixedTableHead
            sx={{
                backgroundColor: theme.grey,
            }}
        >
            <TableRow
                sx={{
                    display: 'table-row',
                }}
            >
                {headCells.map((headCell, headCellIndex) => {
                    if (headCell.disableRow) return null;
                    let styles = {};
                    if (headCell.center) {
                        styles = { display: 'flex', justifyContent: 'center' };
                    }
                    return (
                        <TableCell
                            key={headCell.id}
                            align="left"
                            sortDirection={orderBy === headCell.sortField ? order : false}
                            sx={{
                                flex: 1,
                                padding: '6px 8px',
                                minWidth: headCell.minWidth || 0,
                                maxWidth: headCell.maxWidth || 'unset',
                                overflow: 'hidden',
                                ...styles
                            }}
                        >
                            {/* {headCell.disableSort ? (
                                <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.sortValue}
                                    direction={orderBy === headCell.sortValue ? order : 'asc'}
                                    onClick={createSortHandler(headCell.sortValue)}
                                >
                                    <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                                    {orderBy === headCell.sortValue ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            )} */}

                            <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                        </TableCell>
                    );
                })}
            </TableRow>
        </FixedTableHead>
    );
}
