import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ToolsState } from './types';

export const initialState: ToolsState = {
  zoomIn: false,
  mode: 'grid',
};

const slice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setZoomIn: (
      state,
      { payload: { status } }: PayloadAction<{ status: boolean }>,
    ) => {
      state.zoomIn = status;
    },
    setMode: (
      state,
      { payload: { mode } }: PayloadAction<{ mode: any }>,
    ) => {
      state.mode = mode;
    },
  },
  extraReducers: builder => {},
});

export const { actions: toolsActions } = slice;

export const toolsReducer = slice.reducer;
// persistReducer(persistConfig, slice.reducer);

export const useToolsSlice = () => {
  // useInjectReducer({ key: slice.name, reducer: authReducer });
  return { actions: slice.actions };
};
