export const handleRequestSort = (
  property,
  direction,
  sort,
  setDirection,
  setSort,
) => {
  const isAsc = sort === property && direction === '';
  if (setDirection) {
    setDirection(isAsc ? '-' : '');
  }
  if (property && setSort) {
    setSort(property);
  }
};