import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { SxProps, Tab } from '@mui/material';
import styled from 'styled-components';
import { VerticalDivider } from '../EnhancedTableToolbar/components/VerticalDivider';
import If from '../If';
import { useMemo } from 'react';
import { themes } from 'styles/theme/themes';

interface TabType {
  value?: string;
  label?: JSX.Element;
  isDivider?: boolean;
}

interface TabsMenuProps {
  selectedTab: string;
  handleOnSwitchTab: (e: any, tab: string) => void;
  tabs: TabType[];
  withDivider?: boolean;
}

export const TabsMenu = ({
  selectedTab,
  handleOnSwitchTab,
  tabs,
  withDivider = true,
}: TabsMenuProps) => {
  const tabItems = useMemo(() => {
    if (!withDivider) {
      return tabs;
    }

    const items: TabType[] = [];
    tabs.forEach((t, index) => {
      items.push(t);
      if (index !== tabs.length - 1) {
        items.push({
          isDivider: true,
        });
      }
    });
    return items;
  }, [tabs, withDivider]);

  return (
    <>
      <If condition={!!tabs?.length}>
        <TabContext value={selectedTab}>
          <TabList
            onChange={handleOnSwitchTab}
            sx={{
              minHeight: '30px',
              '& .MuiTabs-flexContainer': { alignItems: 'center' },
            }}
          >
            {tabItems.map((tab, index) => {
              let tabSx: SxProps = { px: 1, minHeight: '30px' };
              if (tab.value === selectedTab) {
                tabSx = {
                  ...tabSx,
                  '& svg': {
                    fill: themes.default.primaryActiveColor,
                  },
                  background: !withDivider ? themes.default.primaryActive : '',
                };
              }
              if (withDivider) {
                if (index === 0) {
                  tabSx = { ...tabSx, marginRight: '10px' };
                } else if (index !== tabs.length - 1) {
                  tabSx = { ...tabSx, marginLeft: '10px' };
                } else {
                  tabSx = { ...tabSx, mx: '10px' };
                }
              }

              if (tab.isDivider) {
                return (
                  <VerticalDivider
                    key={`divider-${index}`}
                    sx={{ height: 20 }}
                  />
                );
              } else {
                return (
                  <Tab
                    label={<TabLabel>{tab.label}</TabLabel>}
                    value={tab.value}
                    sx={{ ...tabSx }}
                    key={index}
                  />
                );
              }
            })}
          </TabList>
        </TabContext>
      </If>
    </>
  );
};

const TabLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: none;
`;
