import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const FlexComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TableNavWrapper = styled.div`
  background-color: ${themes?.default?.regularTableBg};
  width: 100%;
  position: relative;
  height: 100%;
  
  & .table-nav {
    background-color: ${themes?.default?.accordionWhiteBg};
    margin: 18px 0 7px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 600px) {
    .css-u5u2zc-MuiSnackbar-root {
      bottom: 24px;
      left: 271px;
      right: auto;
    }
  }
  .css-1exqwzz-MuiSnackbarContent-message {
    padding: 0px 0;
  }
  .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    height: 54px;
  }
  .flex_text {
    display: flex;
    gap: 5px;
  }
  .color_blue {
    color: ${themes?.default?.summerSky};
  }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
`;
export const MenuWrapper = styled.div`
  .green_circle {
    background: ${themes?.default?.greenStatus};
    width: 8px;
    height: 8px;
    border-radius: 11px;
    margin-right: 10px;
  }
  .red_circle {
    background: ${themes?.default?.redStatus};
    width: 8px;
    height: 8px;
    border-radius: 11px;
    margin-right: 10px;
  }
  .orange_circle {
    background: ${themes?.default?.warningMain};
    width: 8px;
    height: 8px;
    border-radius: 11px;
    margin-right: 10px;
  }
`;

export const TableNavTitle = styled.div`
  color: ${themes?.default?.textColorPrimary};
  font-weight: 500;
  font-size: 1.125rem;
  margin-left: 17px;
`;

interface StatusProps {
  status: boolean;
}
export const NameField = styled.div<StatusProps>(props => ({
  display: 'flex',
  // background: props.background,
  'align-items': 'center',
  '& .user-image': {
    'border-radius': '50%',
  },
  '& .disabled-container': {
    display: 'flex',
    '& .disabled-image': {
      'border-radius': '50%',
      'background-color': themes?.default?.nobel,
      height: '32px',
      width: '32px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      color: themes?.default?.accordionWhiteBg,
    },
    '& img': {
      'margin-top': '20px',
      'margin-left': '-9px',
      'border-radius': '50%',
      'background-color': themes?.default?.accordionWhiteBg,
      width: '9px',
      height: '9px',
    },
  },
  '& .name': {
    'margin-left': '8px',
    color:
      props.status === true ? themes?.default?.black : 'rgba(0, 0, 0, 0.38)',
  },
}));
export const ActiveText = styled.span<StatusProps>(props => ({
  color: props.status === true ? themes?.default?.black : 'rgba(0, 0, 0, 0.38)',
}));
