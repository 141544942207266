import styled from 'styled-components';
import { css } from 'styled-components';

export const RoundtripCode = css`
  .roundtrip_code {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const AddRoundtripWraper = styled.div`
  max-height: 300px;
  width: 240px;
  padding: 11px;
  ${RoundtripCode};
`;

export const ChangePositionWrapper = styled.div`
  height: 129px;
  width: 105px;
  position: relative;
  overflow: hidden;
`;

export const SwitchStopWrapper = styled.div`
  height: 159px;
  min-width: 188px;
  position: relative;
  overflow: hidden;
`;