import { useEffect, useMemo } from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getStatusColor } from 'app/pages/AddRoundTrips/functions';
import { StatusChips } from '../StatusChips';

import { useLazyGetOrderActivityQuery } from 'common/services/orderApi';
import {StatusOrder} from "../../../../AddRoundTrips/data/RoundTripData/components/StatusOrder";

interface TrackOrderTabProps {
  orderId: string;
  orderStatus?: string;
}

export function TrackOrderTab({ orderId, orderStatus }: TrackOrderTabProps) {
  const { i18n, t } = useTranslation();
  const [trigger, { data }] = useLazyGetOrderActivityQuery();

  useEffect(() => {
    trigger({
      id: orderId,
      query: 'limit=100',
    });
  }, [orderId, orderStatus, trigger]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const locale = i18n.language;
    return date.toLocaleDateString(locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const locale = i18n.language;
    return date.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  // Group actions by date
  const actions = useMemo(() => {
    if (!data?.docs) return [];

    return data?.docs.reduce((acc, curr) => {
      const date = formatDate(curr.createdAt);
      const time = formatTime(curr.createdAt);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({ time, status: curr.data?.newStatus, updatedBy: curr._user });
      return acc;
    }, {});
  }, [data?.docs]);

  return (
    <Box sx={{ marginTop: '25px', minHeight: 'fit-content', maxHeight: '630px', overflowY: 'auto' }}>
      {Object.keys(actions).map((date, parentIndex) => (
        <DayContainer key={parentIndex}>
          <DayContent>{date}</DayContent>
          <List>
            {actions[date].map((item, index) => (
              <ListItem key={index} sx={{ paddingLeft: '0' }}>
                <StatusBlock
                  status={item.status}
                  isLast={
                    parentIndex === actions.length - 1 &&
                    index === actions[date].length - 1
                  }
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      marginRight={'20px'}
                      color={'rgba(0, 0, 0, 0.6)'}
                    >
                      {item.time}
                    </Typography>
                    <StatusOrder
                      status={item?.status}
                    />
                  </div>
                  {item?.updatedBy?.fullName && (
                    <Typography
                      color={'rgba(0, 0, 0, 0.6)'}
                      fontSize={'0.857rem'}
                      marginTop={'10px'}
                      variant="body1"
                    >
                      {t('common.by')} {item.updatedBy.fullName}
                    </Typography>
                  )}
                </StatusBlock>
              </ListItem>
            ))}
          </List>
        </DayContainer>
      ))}
    </Box>
  );
}

const StatusBlock = styled.div<any>`
  position: relative;
  padding: 10px 0; /* Space between each status item */

  /* Line before the status (from top to the status) */
  &::before {
    ${props => (!props.isLast ? ' content: "" ' : '')};
    position: absolute;
    top: 30px;
    left: -20px; /* Adjust to position the line correctly */
    width: 2px;
    height: 100%; /* Half the height of the item */
    background-color: rgba(224, 224, 224, 1); /* Color of the line */
  }

  /* Line after the status (from status to the bottom) */
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    top: 19px;
    left: -22px;
    background-color: ${props =>
      props.isLast ? getStatusColor(props.status) : 'rgba(224,224,224,1)'};
  }
`;

const DayContainer = styled.div`
  position: relative;
  padding-left: 30px;
  overflow: hidden;
`;

const DayContent = styled.h6`
  position: relative;
  font-size: 1.143rem;
  margin-bottom: 10px;
  margin-top: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 22px;
    left: -20px;
    width: 2px;
    height: 175%;
    background-color: rgba(224, 224, 224, 1);
  }

  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    top: 8px;
    left: -23px;
    background-color: rgba(224, 224, 224, 1);
  }
`;
