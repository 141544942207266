import { Button, Stack, styled } from '@mui/material';
import { Check, Clock, DotsThree, X } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { ClusterStatus } from 'app/pages/AddRoundTrips/data/status';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { TableNavContext } from '../..';
import { HoverMenu } from './components/HoverMenu';

export const StatusButton = () => {
  const { status, isToday, disabled } = useContext(TableNavContext);
  const { t } = useTranslation();

  const [buttonHovered, setButtonHovered] = useState(false);

  const buttonSx = useMemo(() => {
    if (status === ClusterStatus.Active) {
      return {
        border: `1px solid ${themes.default.buttonDarkGreen} !important`,
        background: `${themes.default.buttonLightGreen} !important`,
        color: `${themes.default.green3} !important`,
      };
    }
    return {
      border: `1px solid ${themes.default.buttonDarkRed} !important`,
      background: `${themes.default.buttonLightRed} !important`,
      color: `${themes.default.buttonDarkRed} !important`,
    };
  }, [status]);

  const buttonText = useMemo(() => {
    if (status === ClusterStatus.Active) {
      return t('common.labels.active');
    }
    return t('common.labels.deactivated');
  }, [status, t]);

  const buttonIcon = useMemo(() => {
    if (status === ClusterStatus.Active) {
      return <Icon icon={<Check />} color={themes.default.green3} size={20} />;
    }
    return <Icon icon={<X />} color={themes.default.buttonDarkRed} size={20} />;
  }, [status]);

  return (
    <ActionButton
      size="small"
      variant="outlined"
      onMouseEnter={() => {
        setButtonHovered(true);
      }}
      onMouseLeave={() => {
        setButtonHovered(false);
      }}
      disabled={!!disabled}
      sx={buttonSx}
    >
      <HoverMenu
        element={
          <Stack direction={'row'} alignItems={'center'}>
            {buttonIcon}
            {buttonText}
            <Wrapper display={buttonHovered} status={status}>
              <Icon
                icon={<DotsThree />}
                weight="bold"
                size={themes.default?.iconSizeMedium}
              />
            </Wrapper>
          </Stack>
        }
      />
    </ActionButton>
  );
};

const ActionButton = styled(Button)`
  padding: 0.25rem 0.625rem;
  border-radius: 1.25rem;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.02875rem;
  align-items: center;
  gap: 8px;
  cursor: default;
`;

interface HoverMenuProps {
  display: boolean;
  status: ClusterStatus;
}
const Wrapper = styled('div')<HoverMenuProps>`
  opacity: ${props => (props.display ? 1 : 0)};
  display: flex;
  position: absolute;
  right: 0;
  padding-right: 8.75px;
  height: 100%;
  border-radius: 0 1.25rem 1.25rem 0;
  background: ${themes.default.buttonLightRed};
  align-items: center;
  background: ${props =>
    props.status === ClusterStatus.InActive
      ? themes.default.redLinearGradiant
      : themes.default.greenLinearGradiant};
  width: 64px;
  justify-content: end;
  transition: all 0.3s ease-in-out;
  & svg {
    fill: ${props =>
      props.status === ClusterStatus.InActive
        ? themes.default.buttonDarkRed
        : themes.default.buttonDarkGreen};
  }
`;
