import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';
import { TablePaginationWrapper } from 'app/components/TableComponent/styles';
import {
  EnhancedTableToolbarProps,
  Order,
} from 'app/components/TableComponent/helpers/types';
import {
  getComparator,
  stableSort,
  handleClick,
  handleClickAccordionEl,
  handleSelectAllClickRegular,
} from 'app/components/TableComponent/helpers/functions';
import { Data, HeadCell } from 'interfaces/users';

import { EnhancedTableHead } from '../EnhancedTableHead';
import { RegularTableFunction } from 'app/pages/AddRoundTrips/components/TableComponent/helpers/regular';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
interface Props {
  EnhancedTableToolbar: ReactElement<
    EnhancedTableToolbarProps,
    FC<EnhancedTableToolbarProps>
  >;
  isLoading: boolean;
  rows: { data: Data; accordion?: JSX.Element }[];
  page: number;
  setPage: (e) => void;
  rowsPerPage: number;
  setRowsPerPage: (e) => void;
  headCells: HeadCell[];
  type: string;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  totalPages: number;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  orderBy: string;
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  totalDocs: number;
  tableHeight?: string;
}

export const TableComponent: React.FC<Props> = ({
  EnhancedTableToolbar,
  isLoading,
  rows,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  headCells,
  selected,
  setSelected,
  type,
  totalPages,
  setOrderBy,
  orderBy,
  order,
  setOrder,
  totalDocs,
  tableHeight,
}) => {
  const { t } = useTranslation();

  let isSelected;
  isSelected = (name: string) => selected.indexOf(name) !== -1;
  const dense = true;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    if (setOrder) {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    if (property && setOrderBy) {
      setOrderBy(property);
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
      {EnhancedTableToolbar && EnhancedTableToolbar}
      <ScrollableTableContainer sx={{ height: tableHeight || '100%' }}>
        <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          {!!rows?.length && (
            <EnhancedTableHead
              onSelectAllClick={e => {
                handleSelectAllClickRegular(e, rows, setSelected);
              }}
              order={order ?? 'desc'}
              orderBy={orderBy ?? ''}
              numSelected={selected.length}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
              isLoading={isLoading}
              headCells={headCells}
            />
          )}
          <TableBody>
            {RegularTableFunction({
              getComparator,
              handleClick,
              headCells,
              isSelected,
              order,
              orderBy,
              rows,
              selected,
              setSelected,
              stableSort,
            })}
          </TableBody>
        </Table>
      </ScrollableTableContainer>
      {!!totalDocs && (
        <TablePaginationWrapper>
          <TablePagination
            labelRowsPerPage={t('rows_per_page')}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            component="div"
            count={totalDocs ?? 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => {
              return <></>;
            }}
            sx={{ '.MuiTablePagination-select': { paddingTop: '5px' } }}
          />
          <Pagination
            color="primary"
            onChange={(e, val) => {
              setPage(val);
            }}
            page={page}
            count={totalPages}
            shape="rounded"
          />
        </TablePaginationWrapper>
      )}
      {/* </Paper> */}
    </Box>
  );
};

const ScrollableTableContainer = styled(TableContainer)`
  & th {
    background: ${props => props.theme.grey};
  }
`;
