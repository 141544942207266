interface HeadCell {
  id: string;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  filter: boolean;
  key: string | null;
}

export const departmentsHeadCellsConst: HeadCell[] = [
  {
    id: 'departmentName',
    label: 'Department Name',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
  },
  {
    id: 'manager',
    label: 'Manager',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'employees',
    label: 'Employees',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'action',
    label: 'Action',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: false,
    filter: false,
    key: null,
  },
];
