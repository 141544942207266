import React from 'react';
import { createData } from 'utils/pages/addRoundTrips';
import VehicleSelector from './components/VehicleSelector';
import DriverSelector from './components/DriverSelector';
import RipperSelector from './components/RipperSelector';
import { StatusPopover } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { Rstatus } from './components/RoundtripStatus';
import { Stops } from './components/StopsBar';
import { RouteData } from './components/RouteData';
import { StopsDroware } from './components/Stops';
import { PopoverAction } from './components/PopoverAction';
import {
  Driver,
  Ripper,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';

import Constraints from '../../../../components/RoundTrips/components/Constraint';
import { getVehicleLabel } from 'utils/pages/vehicles';
import UserSelector from './components/UserSelector';
import {
  SupportNumberWrapper,
  SupportWrapper,
} from 'app/pages/RoundTrips/styles';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { getIsRoundtripOverLoaded } from 'app/helpers/helpers';
import { Shapes } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { CompletedReason } from './components/CompletedReason';
import { Stack } from '@mui/material';

export const GridData = ({
  handleClickOpen,
  addFuction,
  rowData,
  theme,
  t,
  roundtrip,
  usersData,
  vehicles,
  functionRoundTripStatus,
  roundTripStatus,
  configuration,
  deleteFunction,
  updateFuction,
  setRoundtripName,
  updateOrderFunction,
  handleClickOpenDelete,
  setRoundtripEdit,
  onDisplayNotes,
  onDisplayOrder,
  onDisplayCustomer,
  updateRoundtripData,
  roundTripCopy,
  accordionStatus,
  setAccordionStatus,
  dateValue,
  driverAgentId,
  ripperAgentId,
  setRoundTripCopy,
  onDisplayMap,
  mapDisplayedStop,
  triggerRoundtripDetails,
  setSelectedStop,
  schedule,
  clusterStatus,
  queryParams,
  isDragged,
  updateOrderCache,
  triggerRoundtripMap,
  salesAgentId,
  userContext,
}) => {
  const VehiculeProps = {
    options: !vehicles ? [{ label: 'Loading...', id: 0 }] : vehicles,
    getOptionLabel: (option: any) => getVehicleLabel(option),
  };

  const orderDragged = !!isDragged;

  const roundtripUserByRole = (role: string) => {
    return roundtrip?.agents?.find(data => data?._role?.key === role)?._user;
  };

  const driverUser = roundtripUserByRole(Driver);
  const ripperUser = roundtripUserByRole(Ripper);
  const salesUser = roundtripUserByRole(Sales);

  const handleFarDeliveredOrdersCount = () => {
    var count = 0;
    roundtrip?.stops?.map(order => {
      if (order?._order?.deliveryInfo?.distance && order?._order?.deliveryInfo?.distance > 500) {
        count++;
      };
    });
    return count;
  };

  const farDeliveredOrdersCount = handleFarDeliveredOrdersCount();

  rowData.data.push({
    data: createData(
      {
        value: roundtrip.id,
      },
      {
        value: (
          <StatusPopover
            roundtrip={roundtrip}
            functionRoundTripStatus={functionRoundTripStatus}
            tooltipDisabled={orderDragged}
            onClickRoundtrip={triggerRoundtripMap}
          />
        ),
        // sort: roundTripObj.keyPoint,
      },
      {
        value: (
          <VehicleSelector
            vehicle={roundtrip._vehicle}
            VehiculeProps={VehiculeProps}
            vehicles={vehicles}
            addFuction={updateFuction}
            roundtripId={roundtrip.id}
            updateRoundtripData={updateRoundtripData}
            schedule={schedule}
            clusterId={roundtrip._cluster}
            clusterStatus={clusterStatus}
            userContext={userContext}
          />
        ),
        empty: roundtrip?._vehicle?.numberPlate ? false : true,
        running: roundtrip?._vehicle?.engineRunning ? true : false,
        coordinates: roundtrip?._vehicle?.lastPosition?.geometry?.coordinates,
        tracked: roundtrip?._vehicle?.isTracked,
        id: roundtrip?._vehicle?._id,
        status: roundtrip?._vehicle?.status,
      },
      {
        value: (
          <UserSelector
            user={salesUser}
            userRole={Sales}
            updateRoundtripData={updateRoundtripData}
            roundTripCopy={roundtrip}
            users={usersData}
            roundtripId={roundtrip.id}
            addFuction={updateFuction}
            agentId={salesAgentId}
            schedule={schedule}
            clusterId={roundtrip._cluster}
            runningVehicle={roundtrip?._vehicle?.engineRunning}
            clusterStatus={clusterStatus}
          />
        ),
        empty: salesUser?.fullName ? false : true,
      },
      {
        value: (
          <DriverSelector
            user={driverUser}
            updateRoundtripData={updateRoundtripData}
            roundTripCopy={roundtrip}
            users={usersData}
            roundtripId={roundtrip.id}
            addFuction={updateFuction}
            driverAgentId={driverAgentId}
            ripperAgentId={ripperAgentId}
            schedule={schedule}
            clusterId={roundtrip._cluster}
            runningVehicle={roundtrip?._vehicle?.engineRunning}
            clusterStatus={clusterStatus}
          />
        ),
        empty: driverUser?.fullName ? false : true,
      },
      {
        value: (
          <RipperSelector
            user={ripperUser}
            roundtripId={roundtrip.id}
            updateRoundtripData={updateRoundtripData}
            addFuction={updateFuction}
            roundTripCopy={roundtrip}
            users={usersData}
            driverAgentId={driverAgentId}
            ripperAgentId={ripperAgentId}
          />
        ),
      },
      {
        value: (
          <Stack direction={'row'} gap={'5px'}>
            <Rstatus status={roundtrip.status} />
            <CompletedReason reason={roundtrip.completedReason} />
          </Stack>
        ),
      },
      {
        value: (
          <Stops
            tooltipDisabled={orderDragged}
            stats={roundtrip.cache?.stats}
            roundtripStatus={roundtrip.status}
            stopCount={roundtrip.stopCount}
            farDeliveredOrdersCount={farDeliveredOrdersCount}
          />
        ),
      },
      {
        value: (
          <Constraints
            weight={roundtrip?.cache?.stats?.totalWeight}
            tooltipDisabled={orderDragged}
          />
        ),
      },
      {
        value: <Constraints volume={0} tooltipDisabled={orderDragged} />,
      },
      {
        value: (
          <SupportWrapper>
            <Shapes size={13} color={themes?.default?.iconColor} />
            <SupportNumberWrapper>
              {roundtrip?._vehicle
                ? `${roundtrip?.supportUnitsRequestedCapacity || 0} / ${
                    roundtrip?._vehicle?.capacity?.supportUnit
                  } `
                : `${roundtrip?.supportUnitsRequestedCapacity || 0} `}
            </SupportNumberWrapper>
            <SupportUnitsPopover
              updateOrderFunction={updateOrderFunction}
              canEdit={false}
              widthButton={30}
              supportUnits={roundtrip?.supportUnitsList}
              tooltipDisabled={isDragged}
              isOverLoaded={getIsRoundtripOverLoaded({
                vehicle: roundtrip?._vehicle,
                capacity: roundtrip?.supportUnitsRequestedCapacity,
              })}
            />
          </SupportWrapper>
        ),
      },
      {
        value: (
          <RouteData distance={roundtrip.cache?.directions?.distance || 0} />
        ),
      },
      {
        value: (
          <RouteData
            duration={roundtrip.cache?.directions?.estimatedTimeWithStop || 0}
          />
        ),
      },
      {
        value: (
          <PopoverAction
            typeEdit={true}
            roundtrip={roundtrip}
            setRoundtripEdit={setRoundtripEdit}
            functionRoundTripStatus={functionRoundTripStatus}
            setRoundtripName={setRoundtripName}
            handleClickOpen={handleClickOpen}
            handleClickOpenDelete={handleClickOpenDelete}
          />
        ),
      },
    ),
    stopsCount: roundtrip?.cache?.stats?.stops,
    accordionRowCount: roundtrip?.stops?.length,
    accordion: (
      <StopsDroware
        dateValue={dateValue}
        roundtrip={roundtrip}
        updateOrderFunction={updateOrderFunction}
        configuration={configuration}
        theme={theme}
        onDisplayNotes={onDisplayNotes}
        onDisplayOrder={onDisplayOrder}
        onDisplayCustomer={onDisplayCustomer}
        typeEdit={true}
        roundTripCopy={roundTripCopy}
        accordionStatus={accordionStatus}
        setAccordionStatus={setAccordionStatus}
        setRoundTripCopy={setRoundTripCopy}
        updateFuction={updateFuction}
        isDraggable={true}
        onDisplayMap={onDisplayMap}
        mapDisplayedStop={mapDisplayedStop}
        triggerRoundtripDetails={triggerRoundtripDetails}
        setmapStop={setSelectedStop}
        queryParams={queryParams}
        orderDisabled={isDragged}
        updateOrderCache={updateOrderCache}
      />
    ),
  });
  return rowData;
};
