import { Chip, Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import If from 'app/components/If';
import { themes } from 'styles/theme/themes';

export function DeliveryInformationTab({ order }) {
  const { t, i18n } = useTranslation();

  const damagedItemsReasons = useMemo(() => {
    if (order?.deliveryInfo?.damagedItems) {
      if (order.deliveryInfo.damagedItems.length > 0) {
        return '';
      }
      return order.deliveryInfo.damagedItems
        .map(item => item.comment)
        .join(', ');
    } else {
      return '';
    }
  }, [order]);

  function formatDate(dateInput: string): string {
    const date = new Date(dateInput);

    // Extract each individual component
    const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const day = date.toLocaleDateString(undefined, { day: 'numeric' });
    const year = date.toLocaleDateString(undefined, { year: 'numeric' });

    return `${weekday} ${month} ${day} ${year}`;
  }

  return (
    <DeliveryInformationWrapper>
      <InfoRow>
        <Label>{t('orders.items')}</Label>
        <Detail>
          <Typography fontWeight={'500'}>
            {order?.products?.length} {t('orders.items')}
          </Typography>
        </Detail>
      </InfoRow>
      <Line />
      <InfoRow>
        <Label>{t('orders.branch')}</Label>
        <Detail>{order?.cache?._deck?.location?.name}</Detail>
      </InfoRow>
      {['pending', 'pending-updates'].includes(order?.status) && (
        <InfoRow>
          <Label>{t('order_details.delivery_information.submitted_on')}</Label>
          <Detail>
            {order?.updatedStatusAt ? order.updatedStatusAt : 'n/a'}
          </Detail>
        </InfoRow>
      )}
      {!['canceled'].includes(order?.status) && (
        <InfoRow>
          <Label>{t('order_details.delivery_information.delivery_date')}</Label>
          <Detail>{formatDate(order?.deliveryDate)}</Detail>
        </InfoRow>
      )}
      {['canceled'].includes(order?.status) && (
        <InfoRow>
          <Label>{t('order_details.delivery_information.canceled_on')}</Label>
          <Detail>{order?.updatedStatusAt}</Detail>
        </InfoRow>
      )}

      {['delivered'].includes(order?.status) && (
        <InfoRow>
          <Label>{t('order_details.delivery_information.signed_by')}</Label>
          <Detail>
            {order?.deliveryInfo?.handlerFirstName}{' '}
            {order?.deliveryInfo?.handlerLastName}
          </Detail>
        </InfoRow>
      )}
      <Line />
      {order?.confirmationCode && ['delivered'].includes(order?.status) && (
        <InfoRow>
          <Label>{t('order_details.delivery_information.pin_code')}</Label>
          <Detail>
            <HideableNumber number={order?.confirmationCode} />
          </Detail>
        </InfoRow>
      )}
      {order?.deliveryInfo?.confirmationSignatureUrl &&
        ['delivered'].includes(order?.status) && (
          <InfoRow>
            <Label>{t('order_details.delivery_information.signature')}</Label>
            <Detail>
              <img
                style={{
                  border: `dashed thin ${themes.default?.borderGrey}`,
                  maxHeight: '100px',
                }}
                src={order?.deliveryInfo?.confirmationSignatureUrl}
                alt={t('order_details.delivery_information.signature')}
              />
            </Detail>
          </InfoRow>
        )}
      {['delivered'].includes(order?.status) && <Line />}

      <InfoRow>
        <Label>
          {t('order_details.delivery_information.total_quantity_ordered')}
        </Label>
        <Detail>
          {order?.cache?.stats?.totalPackages ? (
            <>
              {order?.cache?.stats?.totalPackages} {t('orders.items')}
            </>
          ) : (
            'n/a'
          )}
        </Detail>
      </InfoRow>
      <InfoRow>
        <Label>
          {t('order_details.delivery_information.total_quantity_delivered')}
        </Label>
        <Detail>
          <Typography display={'flex'}>
            {order?.deliveryInfo?.packagesCount} {t('orders.items')}
            {damagedItemsReasons !== '' && (
              <Tooltip title={damagedItemsReasons} arrow>
                <InfoOutlinedIcon
                  sx={{ marginLeft: '15px', color: 'rgba(129, 145, 152, 1)' }}
                />
              </Tooltip>
            )}
          </Typography>
        </Detail>
      </InfoRow>
      {order?.type == 'collection' && (
        <InfoRow>
          <Label>
            {t('order_details.delivery_information.total_quantity_collected')}
          </Label>
          <Detail>
            <Typography display={'flex'}>
              {order?.cache?.stats?.totalPackages} {t('orders.items')}
            </Typography>
          </Detail>
        </InfoRow>
      )}
      <Line />
      <If
        condition={order.status !== 'delivered'}
        otherwise={
          <>
            <InfoRow>
              <Label>
                {t(
                  'order_details.delivery_information.support_units_installed',
                )}
              </Label>
              <Detail>
                {!!order?.supportUnits?.rolls && (
                  <Chip
                    label={`${t('order_details.delivery_information.rolls')} ${
                      order?.supportUnits?.rolls
                    }`}
                    size="small"
                  />
                )}
                {!!order?.supportUnits?.rolls &&
                  order?.supportUnits?.pallets &&
                  '/'}
                {!!order?.supportUnits?.pallets && (
                  <Chip
                    label={`${t(
                      'order_details.delivery_information.palettes',
                    )} ${order?.supportUnits?.pallets}`}
                    size="small"
                  />
                )}
              </Detail>
            </InfoRow>
            <InfoRow>
              <Label>
                {t(
                  'order_details.delivery_information.support_units_collected',
                )}
              </Label>
              <Detail>
                {order?.deliveryInfo?.palletesCollected && (
                  <Chip
                    label={`Rolls ${order?.deliveryInfo?.palletesCollected}`}
                    size="small"
                  />
                )}
                {order?.deliveryInfo?.palletesCollected &&
                  order?.deliveryInfo?.rolles &&
                  '/'}
                {order?.deliveryInfo?.rolles && (
                  <Chip
                    label={`Palettes ${order?.deliveryInfo?.rolles.length}`}
                    size="small"
                  />
                )}
              </Detail>
            </InfoRow>
            <Line />
          </>
        }
      >
        {(!!order?.supportUnits?.rolls || !!order?.supportUnits?.pallets) && (
          <InfoRow>
            <Label>
              {t('order_details.delivery_information.support_units')}
            </Label>
          </InfoRow>
        )}
        {!!order?.supportUnits?.rolls && (
          <InfoRow2>
            <Label>{t('order_details.delivery_information.rolls')}</Label>
            <Detail>{order?.supportUnits?.rolls}</Detail>
          </InfoRow2>
        )}
        {!!order?.supportUnits?.pallets && (
          <InfoRow2>
            <Label>{t('order_details.delivery_information.palettes')}</Label>
            <Detail>{order?.supportUnits?.pallets}</Detail>
          </InfoRow2>
        )}
        {(!!order?.supportUnits?.rolls || !!order?.supportUnits?.pallets) && (
          <Line />
        )}
      </If>
      <InfoRow>
        <Label>
          {t('order_details.delivery_information.accounting_information')}
        </Label>
      </InfoRow>
      <InfoRow2>
        <Label>{t('total_ttc')}</Label>
        {order?.cache?.stats?.totalAmountTax ? (
          <Detail fontFamily={'500'}>{`€ ${order?.cache?.stats?.totalAmountTax
            ?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</Detail>
        ) : (
          <Detail fontFamily={'500'}>n/a</Detail>
        )}
      </InfoRow2>
    </DeliveryInformationWrapper>
  );
}

function HideableNumber({ number }) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1">
        {isVisible ? number : '*'.repeat(number.toString().length)}
      </Typography>
      <IconButton
        style={{ marginLeft: '10px', padding: 0 }}
        onClick={toggleVisibility}
      >
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </div>
  );
}

const InfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px',
});

const InfoRow2 = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    font-weight: 400 !important;
  }
  p:first-child {
    font-size: 0.929rem;
    margin: 0;
  }
`;

const Label = styled(Typography)({
  fontWeight: '500 !important',
  fontSize: '1rem !important',
});

// const Label2 = styled(Typography)({
//     fontSize: '0.929rem !important'
// });

const Detail = styled(Typography)({
  textAlign: 'right',
  fontSize: '1rem !important',
});

const Line = styled('hr')({
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  border: 'none',
  height: '1px',
  margin: '15px 0',
});

const DeliveryInformationWrapper = styled.div`
  margin-top: 25px;
  height: calc(100vh - 300px);
  overflow: scroll;
`;
