import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Tab, IconButton, Typography, Tooltip } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FlagIcon from '@mui/icons-material/Flag';

import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { CheckCircleOutline, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

// Sample static images list
const staticImages = [
  'https://source.unsplash.com/random/800x600',
  'https://source.unsplash.com/random/800x601',
  'https://source.unsplash.com/random/800x602',
  'https://source.unsplash.com/random/800x603',
  'https://source.unsplash.com/random/800x604',
  'https://source.unsplash.com/random/800x605',
  'https://source.unsplash.com/random/800x606',
  'https://source.unsplash.com/random/800x607',
  'https://source.unsplash.com/random/800x608',
];

interface ProductDetailsProp {
  product: any,
  closeDrawer: any,
  onAddItem?: any,
  selectedProducts?: any,
}

export function ProductDetailsDrawer({ product, selectedProducts, closeDrawer, onAddItem }: ProductDetailsProp) {
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState('1');

  useEffect(() => {

  }, [selectedProducts]);

  const canAddItem = () => {
    //check if the product in the selected products or not to prevet user from adding the item twice
    if(selectedProducts===undefined){
      return false;
    }
    return selectedProducts?.every(orderProduct => orderProduct?._id !== product?._id);
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Wrapper>

      <HeaderWrapper>
        <IconButton onClick={closeDrawer} sx={{ mr: '8px' }}><ArrowBackIcon color="action" /></IconButton>
        <Typography fontSize="1.429rem" fontWeight="500">Back to catalog</Typography>
        <IconButton onClick={closeDrawer} sx={{ ml: 'auto' }}><ClearIcon color="action" /></IconButton>
      </HeaderWrapper>

      <ContentWrapper>

        <DetailsContainer>
          <IconContainer>
            {canAddItem() ? (
              <Tooltip title="Add to Order">
                <AddCircleOutlineIcon
                  sx={{
                    color: 'rgba(129, 145, 152, 1)',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    onAddItem(
                      product,
                      product?.cache?._category?._parentCategory?.name,
                    );
                  }} />
              </Tooltip>
            ) : (
              <CheckCircleOutline
                sx={{
                  color: '#04BC85',
                }}
              />
            )}
            
          </IconContainer>
          <VerticalLine style={{ marginRight: '6px' }} />
          <div>
            <Typography fontSize={'0.857rem'} color={'rgba(0, 0, 0, 0.6)'}># {product?.code}</Typography>
            <Typography fontSize={'1.143rem'} fontWeight={'500'}>{product?.name}</Typography>
          </div>
          <PriceContainer>
            <Typography fontSize={'1.286rem'} fontWeight={'600'}>€{product?.boxPrice}</Typography>
          </PriceContainer>
        </DetailsContainer>

        <FrequentlyOrderedContainer>
          <StyledFlagIcon />
          <Typography>Frequently Ordered</Typography>
        </FrequentlyOrderedContainer>

        <br />

        <ImageGallery />

        <div style={{overflow: 'auto', maxHeight: '300px', marginTop: '15px'}}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
              >
                <StyledTab label="DESCRIPTION" value="1" />
                <StyledTab label="INGREDIENTS" value="2" />
                <StyledTab label="NUTRITION FACTS" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <br />
              <InfoRow>
                <Label>Net Weight</Label>
                <Detail>{product?.weight} Kg</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Pallet Quantity</Label>
                <Detail>{product?.pallet} Items/Box</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Collection Unit Price</Label>
                <Detail>$0.48 each</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Size Or Cut</Label>
                <Detail>1x48</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Quantity per Layer</Label>
                <Detail>7</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Storage: Keep frozen at -18 C. Do not refreeze after defrosting</Label>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>
                  Crisp, flaky all-butter frozen pastry with a smooth dark chocolate centre.
                  <br />
                  <b>Cooking Instructions</b>
                  <br />
                  Spread out on a baking tray and leave to defrost and prove overnight at room temperature. Egg wash and bake in the oven at 190°c/ 375°f/ Gas Mark 5 until golden brown (Approximately 8-10 minutes).
                </Label>
              </InfoRow>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <br />
              <Typography>WHEAT Flour, Water, Sugar, Rapeseed Oil, Mix [WHEAT Flour, WHEAT Gluten, EGG Powder, Skimmed MILK Powder, Whey Powder (MILK), Emulsifiers: E481 (Sodium Stearoyl-2-Lactylate) And E471 (Mono-And Diglycerides Of Fatty Acids), Aroma, Colour: E160a (Carotenes)], Butter Powder (MILK), WHEAT Gluten, Yeast, Glaze [Water, Vegetable Protein, Sunflower Oil, Dextrose, Maltodextrine, Starch], EGG Powder, Salt.</Typography>
              <br />
              <Typography><b>Allergy Advice</b></Typography>
              <hr />
              <Typography>May contain sesame seeds and soya.</Typography>
              <Typography>WHEAT Flour, WHEAT Gluten, Skimmed MILK Powder</Typography>
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <br />
              <InfoRow>
                <LabelHead>Per 100g/ml</LabelHead>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Energy</Label>
                <Detail>1304 KCAL</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Fat</Label>
                <Detail>8.3 G</Detail>
              </InfoRow>
              <InfoRow>
                <Label>Of which Saturates</Label>
                <Detail>2.1 G</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Carbohydrates</Label>
                <Detail>48 G</Detail>
              </InfoRow>
              <InfoRow>
                <Label>Of which Sugars</Label>
                <Detail>8.8 G</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Protein</Label>
                <Detail>9.7 G</Detail>
              </InfoRow>
              <Line />
              <InfoRow>
                <Label>Salt</Label>
                <Detail>0.7 G</Detail>
              </InfoRow>
              <Line />
            </TabPanel>
          </TabContext>
        </div>

      </ContentWrapper>
    </Wrapper>
  )
}

const ImageGallery = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  return (
    <div style={{display: 'flex'}}>
      <div style={{ width: '60px !important', height: '293px', marginRight: '10px', position: 'relative' }}>

        <ArrowUpWrapper className="swiper-button-prev-custom"><StyledArrowUp /></ArrowUpWrapper>
        <ArrowDownWrapper className="swiper-button-next-custom"><StyledArrowDown /></ArrowDownWrapper>

        <Swiper
          navigation={{
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom',
          }}
          style={{ height: '100%' }}
          direction="vertical"
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {staticImages.map((image, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <img style={{ width: '100%', borderRadius: '4px' }} src={image} alt={`Slide ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div style={{ width: '333px', height: '293px' }}>
        <Swiper
          direction="vertical"
          style={{ height: '100%' }}
          loop={true}
          spaceBetween={10}
          navigation={false}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {staticImages.map((image, index) => (
            <SwiperSlide key={`slide-${index}`}>
              <img style={{ width: 'auto', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} src={image} alt={`Slide ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(1, 1, 1, 0.12);
`;

const ContentWrapper = styled.div`
  margin: 24px 20px;
`;

const StyledArrowUp = styled(KeyboardArrowUpOutlined)`

`;

const StyledArrowDown = styled(KeyboardArrowDownOutlined)`

`;

const ArrowUpWrapper = styled.div`
position: absolute;
width: 16px;
height: 16px;
background-color: white;
top: 4px;
border-radius: 2px;
left: 0;
right: 0;
margin: 0 auto;
z-index: 10;

svg {
  width: 100%;
  height: 100%;
  position: absolute;
  color: rgba(129, 145, 152, 1);
}
`;

const ArrowDownWrapper = styled.div`
position: absolute;
width: 16px;
height: 16px;
background-color: white;
bottom: 7px;
border-radius: 2px;
left: 0;
right: 0;
margin: 0 auto;
z-index: 10;

svg {
  width: 100%;
  height: 100%;
  position: absolute;
  color: rgba(129, 145, 152, 1);
}
`;





const InfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px'
});

const LabelHead = styled(Typography)({
  fontWeight: '500 !important',
  fontSize: '1.143rem !important',
});

const Label = styled(Typography)({
  fontWeight: '400 !important',
  fontSize: '1rem !important',
  color: 'rgba(0, 0, 0, 0.87)'
});

const Detail = styled(Typography)({
  textAlign: 'right',
  fontWeight: '500 !important',
  fontSize: '1rem !important'
});

const Line = styled('hr')({
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  border: 'none',
  height: '1px',
  margin: '10px 0'
});






const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

const VerticalLine = styled.div`
  height: 35px;
  width: 1px;
  background-color: rgba(224, 224, 224, 1);
`;

const PriceContainer = styled.div`
  margin-left: auto;
`;

const FrequentlyOrderedContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: rgba(38, 166, 154, 1);
  margin-right: 5px;
`;

const StyledTab = styled(Tab)`
  &.Mui-selected {
    background-color: rgba(131, 42, 255, 0.04);
  }
`;