import {
  Drawer,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import If from 'app/components/If';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Rstatus } from 'app/pages/AddRoundTrips/data/RoundTripData/components/RoundtripStatus';
import RoundtripMap from '../RoundtripMap';
import { MapWrapper } from 'app/pages/Dashboard/styles';
import { useLazyGetRoundTripDetailsQuery } from 'common/services/roundtripApi';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSubscribeObject } from 'common/hooks/ws';
import dayjs from 'dayjs';
import { useGetRoundtripsByDateQuery } from 'common/services/statsApi';
import { SwipeableDrowerOrder } from 'app/pages/AddRoundTrips/components/SwipeableDrowerOrder';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { StatusPopover } from 'app/components/TableComponent/helpers/Components/StatusPopover';

interface RoundtripItem {
  id: string;
  status: string;
  completionPercentage: number;
  code: string;
  co2Consumption: number;
}

interface RoundtripDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  roundtripId: string | null;
  roundtrips?: RoundtripItem[];
}

interface RoundtripDrawerContextProps {
  pinnedOrders: string[];
  setPinnedOrders: StateSetter<string[]>;
}

export const RoundtripDrawerContext =
  createContext<RoundtripDrawerContextProps>({
    pinnedOrders: [],
    setPinnedOrders: () => {},
  });

export const RoundtripDrawer = ({
  isOpen,
  handleClose,
  roundtripId,
  roundtrips,
}: RoundtripDrawerProps) => {
  const [getRoundtrip, { data: roundtripData, isFetching }] =
    useLazyGetRoundTripDetailsQuery();
  const [getPreviousRoundtrip, { data: previousRoundtrip }] =
    useLazyGetRoundTripDetailsQuery();

  //cached previous roundtrips for variations
  const previousDate = dayjs().subtract(1, 'week')?.format('YYYY-MM-DD');
  const { data: roundtripsPreviousData } = useGetRoundtripsByDateQuery(
    `?beginAt=${previousDate}&endAt=${previousDate}`,
  );

  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [selectedRoundtripId, setSelectedRoundtripId] = useState<string>(
    roundtripId || '',
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [pinnedOrders, setPinnedOrders] = useState<string[]>([]);

  const handleRoundtripChange = (event: SelectChangeEvent) => {
    setSelectedRoundtripId(event.target.value as string);
  };

  const getVariationPercentage = (prev, current) => {
    const previousCount = prev || 0;
    const count = current || 0;

    if (previousCount === 0) {
      return count === 0 ? 0 : 100;
    } else if (count === 0) {
      return -100;
    }
    const variation = Number(
      (((count - previousCount) / previousCount) * 100).toFixed(2),
    );
    return isNaN(variation) ? 0 : variation;
  };

  const getAmountPerWeight = stops => {
    if (!stops?.length) {
      return 0;
    }
    const totalAmount = stops?.reduce((total, stop) => {
      return total + (+stop._order?.cache?.stats?.totalAmount || 0);
    }, 0);
    const totalWeight = stops?.reduce((total, stop) => {
      return total + (+stop._order?.cache?.stats?.totalWeight || 0);
    }, 0);
    return ((totalAmount || 0) / (totalWeight || 1))?.toFixed(2);
  };

  const previousRoundtripId = useMemo(() => {
    return roundtripsPreviousData?.roundtrips?.find(
      rt => rt.code === roundtripData?.code,
    )?.id;
  }, [roundtripData, roundtripsPreviousData]);

  const roundtrip = useMemo(() => {
    const amountPerWeight = getAmountPerWeight(roundtripData?.stops);
    const variations = {
      stops: getVariationPercentage(
        previousRoundtrip?.cache?.stats?.stops,
        roundtripData?.cache?.stats?.stops,
      ),
      weight: getVariationPercentage(
        previousRoundtrip?.cache?.stats?.totalWeight,
        roundtripData?.cache?.stats?.totalWeight,
      ),
      distance: getVariationPercentage(
        previousRoundtrip?.cache?.directions?.distance,
        roundtripData?.cache?.directions?.distance,
      ),
      amountPerWeight: getVariationPercentage(
        getAmountPerWeight(previousRoundtrip?.stops),
        amountPerWeight,
      ),
      co2Consumption: getVariationPercentage(
        previousRoundtrip?.co2Consumption,
        roundtripData?.co2Consumption,
      ),
    };
    return {
      ...roundtripData,
      amountPerWeight,
      variations,
    };
  }, [roundtripData, previousRoundtrip]);

  const wsMessage = useSubscribeObject('roundtrip', selectedRoundtripId || '');
  useEffect(() => {
    selectedRoundtripId && getRoundtrip({ roundtripId: selectedRoundtripId });
  }, [wsMessage]);

  useEffect(() => {
    selectedRoundtripId && getRoundtrip({ roundtripId: selectedRoundtripId });
  }, [selectedRoundtripId]);

  useEffect(() => {
    previousRoundtripId &&
      getPreviousRoundtrip({ roundtripId: previousRoundtripId });
  }, [previousRoundtripId]);

  useEffect(() => {
    if (roundtripId) setSelectedRoundtripId(roundtripId);
  }, [roundtripId]);

  const renderRoundtripInfo = useCallback(roundtrip => {
    if (selectedRoundtripId && selectedRoundtripId === roundtrip.id) return (
      <StatusPopoverContainer>
        <StatusPopover
          roundtrip={roundtripData}
          functionRoundTripStatus={() => {}}
          notEdit={!false}
          minimal={true}
          drawer={true}
        />
      </StatusPopoverContainer>
    )
    else return (
      <>
        <StatusPopover
          roundtrip={roundtrip}
          functionRoundTripStatus={() => {}}
          notEdit={!false}
          minimal={true}
          drawer={true}
        />
        {/* <Typography fontSize="1rem" fontWeight="700" marginRight={1}>
          {roundtrip.code}
        </Typography>
        <Rstatus
          status={roundtrip.status}
          style={{
            fontSize: '0.7rem',
            padding: '0.15938rem 0.2125rem',
            height: 'auto',
            width: 'auto',
          }}
        /> */}
      </>
    );
  }, [roundtripData]);

  return (
    <RoundtripDrawerContext.Provider
      value={{
        pinnedOrders,
        setPinnedOrders,
      }}
    >
      <Drawer anchor="right" open={!!isOpen} onClose={handleClose}>
        <DragDropContext>
          <Droppable droppableId="droppable" isDropDisabled={true}>
            {(provided, snapshot) => (
              <DrawerWrapper
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <If condition={selectedRoundtripId !== null}>
                  <DrawerHeader>
                    <ArrowBackIcon
                      color="action"
                      fontSize="small"
                      onClick={handleClose}
                      sx={{ cursor: 'pointer' }}
                    />
                    <If
                      condition={!!roundtrips?.length}
                      otherwise={
                        <If condition={!isFetching}>
                          {renderRoundtripInfo(roundtrip)}
                        </If>
                      }
                    >
                      <Select
                        open={isSelectOpen}
                        variant="outlined"
                        color="info"
                        defaultValue={roundtripId || ''}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            border: isSelectOpen
                              ? `1px solid ${themes.default.borderGrey}`
                              : 0,
                          },
                          marginLeft: '10px',
                          height: '36px',
                          padding: 0,
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onMouseEnter={() => {
                          setIsSelectOpen(true);
                        }}
                        onChange={handleRoundtripChange}
                        disabled={isFetching}
                      >
                        {roundtrips?.map(rt => (
                          <MenuItem
                            value={rt.id}
                            selected={rt.id === selectedRoundtripId}
                          >
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              justifyContent={'start'}
                            >
                              {renderRoundtripInfo(rt)}
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </If>
                  </DrawerHeader>
                  <If condition={!!roundtrip?._id}>
                    <MapWrapper>
                      <RoundtripMap
                        onSelectOrder={order => setSelectedOrder(order)}
                        isFetching={isFetching}
                        roundtrip={roundtrip}
                      />
                    </MapWrapper>
                  </If>

                  <SwipeableDrowerOrder
                    selectedOrder={selectedOrder}
                    handleCloseOrderDrawer={() => {
                      setSelectedOrder(null);
                    }}
                    viewMode={true}
                  />
                </If>
              </DrawerWrapper>
            )}
          </Droppable>
        </DragDropContext>
      </Drawer>
    </RoundtripDrawerContext.Provider>
  );
};

const DrawerWrapper = styled.div`
  width: 95vw;
  height: 100%;
  overflow: hidden;
`;

const DrawerHeader = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

const StatusPopoverContainer = styled.div`
  .rtCode {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.15000000596046448px;
  }
`;
