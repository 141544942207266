import React, { createContext, useCallback, useState } from 'react';
import { LoadingAlert } from '..';

interface ShowLoadingProps {
  message: string;
}

interface LoadingALertContextProps {
  showLoading: ({ message }: ShowLoadingProps) => void;
  hideLoading: () => void;
}

export const LoadingAlertContext = createContext<LoadingALertContextProps | undefined>(undefined);

export const LoadingAlertProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const showLoading = useCallback(function ({
    message
  }: ShowLoadingProps) {
    setMessage(message);
    setOpen(true);
  },
    []);

  const hideLoading = useCallback(function () {
    setOpen(false);
    setMessage('');
  }, []);

  return (
    <>
      <LoadingAlertContext.Provider
        value={{
          showLoading,
          hideLoading
        }}
      >
        <LoadingAlert condition={open} message={message} />
        {children}
      </LoadingAlertContext.Provider>
    </>
  );
};
