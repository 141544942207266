import { useEffect } from 'react';

export function useSetHeads(headCells, setHeads) {
  useEffect(() => {
    const head: any[] = [];
    headCells.map((value, index) => {
      if (value.filter === true) {
        head.push(value);
      }
    });
    setHeads(head);
  }, [headCells]);
  return 0;
}
