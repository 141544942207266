import { styled } from '@material-ui/core';
import NoResultsIcon from 'assets/img/no-results.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  icon?: string;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
}

const Title = styled('p')({
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: '160%',
  textAlign: 'center',
  letterSpacing: '0.15px',
});

const SubTitle = styled('p')({
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '143%',
  textAlign: 'center',
  letterSpacing: '0.17px',
});

export const NoResults: React.FC<Props> = ({
  icon = NoResultsIcon,
  title = 'no_results',
  subTitle = 'try_adjusting_filters',
  children,
}) => {
  const { t } = useTranslation();
  title = t(title);
  subTitle = t(subTitle);

  return (
    <>
      <img src={icon} alt="no-results" />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {children}
    </>
  );
};

export default NoResults;
