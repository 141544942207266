import { DotsThree } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { ActionPopover } from 'app/components/Popover';
import { useState } from 'react';
import { PopoverEl } from '../../data/RoundTripData/components/Popover';
import { themes } from 'styles/theme/themes';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { TemplateSaveModal } from '../TemplateSaveModal';

interface ActionsMenuProps {
  handleSaveTemplate: Function;
  handlePrint: Function;
}

export const ActionsMenu = ({
  handleSaveTemplate,
  handlePrint,
}: ActionsMenuProps) => {
  const { t } = useTranslation();
  const [openTemplatesModal, setOpenTemplatesModal] = useState<boolean>(false);

  const handleOpenSaveAsTemplate = () => {
    setOpenTemplatesModal(true);
  };

  const handleCloseSaveAsTemplate = () => {
    setOpenTemplatesModal(false);
  };

  return (
    <>
      <ActionPopover
        id="right-side-popover-templates"
        iconAction={
          <Icon
            icon={<DotsThree />}
            weight="bold"
            size={themes.default?.dotsIconSize}
          />
        }
        anchorOriginVertical="bottom"
        anchorOriginHorizontal="left"
        handleAction={(event, action) => {
          if (action === 'template') {
            handleOpenSaveAsTemplate();
          } else if (action === 'print') {
            handlePrint();
          }
        }}
        actions={[
          {
            action: 'template',
            element: (
              <PopoverEl
                style={{
                  paddingLeft: themes?.default?.popoverLabelPadding,
                  width: '100%',
                  paddingRight: `${parseInt(themes?.default?.popoverLabelPadding.split('px')[0])*2}px`,
                }}
              >
                <Typography sx={{fontSize: '0.8125rem'}}>
                  {t('roundtrip.templates.popover_title.save_as_template')}
                </Typography>
              </PopoverEl>
            ),
          },
          {
            action: 'print',
            element: (
              <PopoverEl
                style={{
                  paddingLeft: themes?.default?.popoverLabelPadding,
                  width: '100%',
                }}
              >
                <Typography sx={{fontSize: '0.8125rem'}}>Print</Typography>
              </PopoverEl>
            ),
          },
        ]}
      />

      <If condition={openTemplatesModal}>
        <TemplateSaveModal
          open={openTemplatesModal}
          handleClose={handleCloseSaveAsTemplate}
          onSaveTemplate={handleSaveTemplate}
        />
      </If>
    </>
  );
};
