interface Data {
  id: { value: number | string };
  name: { value: JSX.Element; sort?: string };
  email: { value: JSX.Element; sort?: string };
  telephone: { value: JSX.Element; sort?: string };
  role: { value: JSX.Element; sort?: string };
  status: { value: JSX.Element };
  action: { value: JSX.Element };
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  filter: boolean;
  key: string | null;
  sortField?: string;
}
export const userHeadCellsConst: HeadCell[] = [
  // const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    sortField: 'lastName',
    label: 'Name',
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'email',
    sortField: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'telephone',
    sortField: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'Telephone',
    disableRow: false,
    canChangeView: true,
    disableSort: true,
    filter: false,
    key: null,
  },
  {
    id: 'role',
    sortField: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
    disableRow: false,
    canChangeView: false,
    filter: true,
    disableSort: true,
    key: 'roles',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'last_active',
    disableRow: false,
    disableSort: true,
    canChangeView: false,
    filter: false,
    key: null,
  },
  {
    id: 'action',
    numeric: true,
    disableSort: true,
    disablePadding: false,
    label: 'Action',
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
  },
];
