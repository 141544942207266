import api from './api';
import { omit } from 'lodash';
import { ContactData } from '../types/Customer';

export const customerApis = api.injectEndpoints({
  endpoints: builder => ({
    getCustomers: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customers?${urlQuery}`,
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicDetails: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/details`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicOrders: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/orders?${params.orderQuery}`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicOrder: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/order/${params.orderId}`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    updateCustomer: builder.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/customers/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Customers'],
    }),
    cancelInvite: builder.mutation<void, any>({
      query: id => ({
        url: `/customers/${id}/cancel-invitation`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Customers'],
    }),
    patchDeck: builder.mutation<void, any>({
      query: ({ id, deckId, ...body }) => ({
        url: `/customers/${id}/deck/${deckId}`,
        method: 'PATCH',
        body,
      }),
    }),
    contact: builder.mutation<any, ContactData>({
      query: (body: any) => ({
        url: `/customers/pub/${body.token}/contact`,
        method: 'POST',
        headers: {
          'x-bamptee-password': body.password,
        },
        body: omit(body, ['token', 'password']),
      }),
    }),
    patchCustomerPublic: builder.mutation<void, any>({
      query: body => ({
        url: `/customers/pub/${body.token}`,
        method: 'PATCH',
        headers: {
          'x-bamptee-password': body.password,
        },
        body: omit(body, ['token', 'password']),
      }),
    }),
    getCustomerFiles: builder.query<any, any>({
      query: body => ({
        url: `/customers/pub/${body.token}/files?${body?.query || ''}&type=${
          body.type
        }`,
        method: 'GET',
        headers: {
          'x-bamptee-password': body.password,
        },
      }),
    }),
    viewCustomerFile: builder.query<any, any>({
      query: ({ token, password, fileId }) => ({
        url: `/customers/pub/${token}/files/${fileId}/view`,
        method: 'GET',
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    uploadCustomer: builder.mutation<
      any,
      {
        formData: FormData;
        token: string;
        password: string;
        preserveName: boolean;
        type?: string;
      }
    >({
      query: ({ formData, token, preserveName, type, password }) => {
        return {
          url: `/customers/pub/${token}/upload?type=${type}&preserveName=${preserveName}`,
          method: 'POST',
          body: formData,
          headers: {
            'x-bamptee-password': password,
          },
        };
      },
    }),
    getCustomerNewDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/new-documents?${query}`,
        method: 'GET',
      }),
    }),
    getCustomerCompletedDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/completed-documents?${query}`,
        method: 'GET',
      }),
    }),
    verifyDocument: builder.mutation<void, any>({
      query: ({ id, documentId, action, reason }) => ({
        url: `/customers/${id}/documents/${documentId}/${action}`,
        method: 'POST',
        body: {
          reason,
        },
      }),
    }),
    getCustomerProcessedDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/processed-documents?${query}`,
        method: 'GET',
      }),
    }),
    postConversationCustomer: builder.mutation<
      any,
      { token; subject; content; contactAddressId; files; password }
    >({
      query: ({
        token,
        subject,
        content,
        contactAddressId,
        files,
        password,
      }) => ({
        url: `/customers/pub/${token}/conversations`,
        method: 'POST',
        body: { subject, content, contactAddressId, files },
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    postConversationCustomerMessage: builder.mutation<
      any,
      { conversationId; token; content; password }
    >({
      query: ({ conversationId, token, content, password }) => ({
        url: `/customers/pub/${token}/conversations/${conversationId}/messages`,
        method: 'POST',
        body: { content },
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    getListConversationsCustomer: builder.query<
      any,
      { token; page; password; contactAddressId }
    >({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/conversations?page=${
          params.page || 1
        }&contactAddress=${params.contactAddressId || ''}`,
        method: 'GET',
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
    }),
    getConversationCustomer: builder.query<
      any,
      { token; conversationId; password; page }
    >({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/conversations/${
          params.conversationId
        }?page=${params.page || 1}&limit=20`,
        method: 'GET',
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
    }),
    getCustomer: builder.query<any, any>({
      query: id => ({
        url: `/customers/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customer', 'Customers'],
    }),
    getCustomersWeb: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customers${urlQuery}`,
      }),
      providesTags: ['Customers'],
    }),
    refreshCreditScore: builder.mutation<any, string>({
      query: id => ({
        url: `/customers/${id}/refresh-credit-safe`,
        method: 'POST',
      }),
    }),
    customersPlaces: builder.query<any, any>({
      query: urlQuery => ({
        url: `/customers/places?${urlQuery}`,
        method: 'GET',
      }),
    }),
    // postCustomer
    postCustomer: builder.mutation<void, any>({
      query: ({ ...body }) => ({
        url: `/customers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Customers'],
    }),
    //GetClientCategory
    GetCustomerTypes: builder.query({
      query: () => ({
        url: `/customer-types`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCustomerTypesQuery,
  usePostCustomerMutation,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetCustomerPublicDetailsQuery,
  useLazyGetCustomerPublicDetailsQuery,
  useGetCustomerPublicOrdersQuery,
  useLazyGetCustomerPublicOrdersQuery,
  useGetCustomerPublicOrderQuery,
  useLazyGetCustomerPublicOrderQuery,
  usePatchDeckMutation,
  useContactMutation,
  useUploadCustomerMutation,
  useUpdateCustomerMutation,
  usePatchCustomerPublicMutation,
  useGetCustomerFilesQuery,
  useLazyGetCustomerFilesQuery,
  useGetCustomerNewDocumentsQuery,
  useLazyGetCustomerNewDocumentsQuery,
  useLazyGetCustomerCompletedDocumentsQuery,
  useVerifyDocumentMutation,
  useLazyGetCustomerProcessedDocumentsQuery,
  useLazyViewCustomerFileQuery,
  usePostConversationCustomerMutation,
  usePostConversationCustomerMessageMutation,
  useLazyGetListConversationsCustomerQuery,
  useGetListConversationsCustomerQuery,
  useGetConversationCustomerQuery,
  useLazyGetConversationCustomerQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetCustomersWebQuery,
  useRefreshCreditScoreMutation,
  useCustomersPlacesQuery,
  useLazyCustomersPlacesQuery,
  useCancelInviteMutation,
} = customerApis;

export default customerApis;
