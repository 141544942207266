import { themes } from 'styles/theme/themes';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import userAvatar from 'assets/img/users/user-avatar.png';
import styled from 'styled-components';

interface Props {
  user: any;
}

export const UserImg: React.FC<Props> = ({ user }) => {
  return (
    <Wrapper>
      <img
        width="26"
        height="26"
        className="user-image"
        src={user?.pictureUrl || userAvatar}
        alt={'Employee Avatar'}
        style={
          user.onlineStatus == 'locked' ? { mixBlendMode: 'luminosity' } : {}
        }
      />
      {user.onlineStatus == 'locked' && (
        <DoNotDisturbIcon
          sx={{
            color: themes.default.redStatus,
            fontSize: 12,
            background: 'white',
            position: 'absolute',
            left: 20,
            bottom: 0,
            borderRadius: '50%',
          }}
        />
      )}
      {user.onlineStatus !== 'locked' && (
        <StatusContainer>
          <Status
            style={{
              background:
                user.onlineStatus === 'offline'
                  ? themes.default.redStatus
                  : themes.default.green3,
            }}
          />
        </StatusContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const StatusContainer = styled.div`
  width: 14px;
  aspect-ratio: 1;
  background: white;
  position: absolute;
  left: 20px;
  bottom: 0;
  border-radius: 50%;
`;

const Status = styled.div`
  margin: 2px;
  border-radius: 50%;
  aspect-ratio: 1;
`;
