import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { DriverDetails } from './DriverDetails';
import { themes } from 'styles/theme/themes';

interface Props {
  time: string;
  arriveDuration?: string;
  isDelayed?: boolean;
  type: 'blue' | 'red' | 'grey';
  left: string;
  delayEarlyNutation?: 'symbol' | 'word';
}

export const Stop: React.FC<Props> = ({
  left,
  time,
  type,
  arriveDuration,
  isDelayed,
  delayEarlyNutation,
}) => {
  const theme = useSelector(selectTheme);
  return (
    <>
      <div style={{ position: 'absolute', left, zIndex: 3 }}>
        <StopWrapper
          style={{
            position: 'relative',
            zIndex: 1,
            width: 12,
            height: 12,
            borderRadius: '50%',
            background:
              type === 'blue'
                ? `linear-gradient(166.61deg, ${themes?.default?.summerSky} -0.09%, ${themes?.default.primary} 105.26%)`
                : type === 'red'
                ? theme.orangePre
                : theme.greyDisabled,
            marginTop: 4.5,
          }}
        >
          <div
            className="popover"
            style={{ position: 'absolute', left: '-110px', bottom: '20px' }}
          >
            <DriverDetails
              style={{
                width: 'fit-content',
                padding: 'unset',
                background: themes?.default?.accordionWhiteBg,
                borderRadius: '9px',
                borderBottom: 'unset',
                paddingLeft: 10,
              }}
            />
          </div>
        </StopWrapper>
        <div
          style={{
            fontSize: '0.5625rem',
            color: themes?.default?.textColorPrimary,
            fontWeight: type === 'blue' ? 800 : 400,
            left: '50%',
            transform: 'translate(-50%, 0)',
            whiteSpace: 'nowrap',
            position: 'absolute',
            marginTop: 20,
          }}
        >
          {time}
        </div>
        {arriveDuration && (
          <div
            style={{
              fontSize: '0.5625rem',
              left: '50%',
              transform: 'translate(-50%, 0)',
              position: 'absolute',
              marginTop: 35,
              color: !isDelayed ? theme.greenStatus : theme.redStatus,
              whiteSpace: 'nowrap',
            }}
          >
            {delayEarlyNutation === 'symbol'
              ? isDelayed
                ? '+ '
                : '- '
              : isDelayed
              ? 'Late '
              : 'Early '}
            {arriveDuration}
          </div>
        )}
      </div>
    </>
  );
};

const StopWrapper = styled.div(props => ({
  cursor: 'pointer',
  '& .popover': {
    visibility: 'hidden',
  },

  '&:hover': {
    '& .popover': {
      visibility: 'unset',
    },
  },
}));
