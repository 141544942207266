import api from './api';

export const organizationApis = api.injectEndpoints({
  endpoints: builder => ({
    getOrganization: builder.query<any, void>({
      query: () => ({
        url: '/organization',
      }),
      providesTags: ['Organization'],
    }),
    getConfiguration: builder.query<any, void>({
      query: () => ({
        url: '/organization/configuration',
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['OrganizationConfiguration'],
    }),
    getWarehouses: builder.query<any, void>({
      query: () => ({
        url: '/organization/decks',
      }),
      providesTags: ['Organization'],
    }),
    patchWarehouse: builder.mutation<void, any>({
      query: ({ deckId, ...body }) => ({
        url: `/organization/decks/${deckId}`,
        method: 'PATCH',
        body: body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getOrganizationActivities: builder.query<any, void>({
      query: () => ({
        url: '/organization/activities',
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['OrganizationActivities'],
    }),
  }),
});

export const {
  useGetConfigurationQuery,
  useGetOrganizationQuery,
  useLazyGetConfigurationQuery,
  useGetWarehousesQuery,
  usePatchWarehouseMutation,
  useGetOrganizationActivitiesQuery,
  useLazyGetOrganizationActivitiesQuery,
} = organizationApis;

export default organizationApis;
