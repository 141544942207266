import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from "styles/theme/themes";

interface Props {
  icon: JSX.Element;
  title: string;
  count: number | string;
}

export const OverviewCell = ({icon, title, count}) => {
  return(
    <Wrapper>
      <IconContainer>
        {icon}
      </IconContainer>
      <LabelContainer>
        <Title>{title}</Title>
        <Count>{count}</Count>
      </LabelContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 63px;
  min-height: 85.74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51.32px;
  height: 51.32px;
  border-radius: 6.42px;
  border: 0.5px solid ${themes?.default?.gainsboro2};
  background: ${themes?.default?.accordionWhiteBg};
`;

const LabelContainer = styled.div`
  min-width: 63px;
  min-height: 28px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Title = styled.div`
  font-size: 0.5625rem;
  font-weight: 500;
  letter-spacing: 0.5655974745750427px;
  color: ${themes?.default?.black};
`;

const Count = styled.div`
  width: 18px;
  height: 17px;
  padding: 2px 4px 2px 4px;
  border-radius: 3.07px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  letter-spacing: 0.1599999964237213px;
  color: ${themes?.default?.black}99;
  background: ${themes?.default?.black}0A;
`;