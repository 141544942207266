import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

// navigation links
import { ALL_ROUNDTRIPS, SCHEDULED_ROUNDTRIPS, ROUNDTRIP } from 'utils/routes';

// icons
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import dayjs from 'dayjs';
import { themes } from 'styles/theme/themes';
import { GRID, MAP } from '../../TableComponent/constants';
import { TableViewPopover } from '../../../../styles/components/muitable/TableViewPopover';
import DropdownDatePicker from 'app/components/DatePicker/DropdownDatePicker';
import { selectMode } from 'app/slices/tools/selectors';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import {
  GridNine,
  MapPin,
  RoadHorizon,
  Table as TableIcon,
} from '@phosphor-icons/react';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { ZoomButton } from 'app/components/EnhancedTableToolbar/components/ZoomButton';
import { AddIconButton } from 'app/components/AddIconButton';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { Modals } from 'app/pages/RoundTrips/components/NoData/Components/Modals';
import If from 'app/components/If';
import { TabsMenu } from 'app/components/TabsMenu';
import { Icon } from 'app/components/Icon';
import { selectAuthUser } from 'common/store/auth/selectors';
import { checkUserAcl } from 'common/helpers/acl';

export const Headers = () => {
  const today = dayjs().startOf('day');

  const dispatch = useDispatch();
  const location = useLocation();

  // hooks
  const {
    dateValue,
    setDateValue,
    search,
    setSearch,
    heads,
    headCells,
    setHeadCells,
    warehouseName,
    viewMode,
    emptyData,
    anchorEl,
    setAnchorEl,
    tab,
    setTab,
  } = useContext(TabsContext);
  const selectedMode = useSelector(selectMode);
  const authUser = useSelector(selectAuthUser);
  const { t } = useTranslation();
  const navigate = useNavigate();

  //states
  const [mode, setMode] = useState<
    'grid' | 'track' | 'excel' | 'map' | 'reoptimize'
  >(selectedMode || 'grid');

  // functions
  const handleOnSwitchTab = (event, tab) => {
    event.preventDefault();
    setMode('grid');
    setTab(tab);
    dispatch(toolsActions.setMode({ mode: 'grid' }));
    navigate(tab);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <EnhancedTableToolbar
        headCells={headCells}
        setHeadCells={setHeadCells}
        heads={heads}
        title={t(`nav.mainNav.roundtrips`)}
        search={search}
        setSearch={tab === SCHEDULED_ROUNDTRIPS ? setSearch : null}
        leftMenu={
          <>
            <DropdownDatePicker
              dateValue={dateValue}
              setDateValue={setDateValue}
              disablePast={false}
              showDots={true}
              inputFormat={'MMM D, YYYY'}
            />
            <VerticalDivider sx={{ mx: '16px' }} />
            <TabsMenu
              handleOnSwitchTab={handleOnSwitchTab}
              selectedTab={tab}
              tabs={[
                {
                  value: SCHEDULED_ROUNDTRIPS,
                  label: (
                    <>
                      {viewMode ? (
                        <CheckIcon />
                      ) : (
                        <Icon icon={<RoadHorizon />} />
                      )}
                      {viewMode
                        ? t('nav.appHeader.completed')
                        : dateValue.isAfter(today)
                        ? t('nav.appHeader.scheduled')
                        : t('nav.appHeader.running')}
                    </>
                  ),
                },
                {
                  value: ALL_ROUNDTRIPS,
                  label: (
                    <>
                      <Icon icon={<GridNine />} />
                      {t('nav.appHeader.all_results')}
                    </>
                  ),
                },
              ]}
            />
          </>
        }
        actionsComponents={
          <>
            <If condition={tab === SCHEDULED_ROUNDTRIPS}>
              <TableViewPopover
                views={
                  location.pathname === `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`
                    ? []
                    : [
                        {
                          type: MAP,
                          icon: (
                            <MapPin
                              size={16}
                              color={themes?.default?.iconColor}
                            />
                          ),
                          label: t('map'),
                        },
                        {
                          type: GRID,
                          icon: (
                            <TableIcon
                              size={16}
                              color={themes?.default?.iconColor}
                            />
                          ),
                          label: t('grid'),
                        },
                      ]
                }
                tableType={mode}
                handleAction={(e, action) => {
                  // @ts-ignore
                  setMode(action);
                  dispatch(toolsActions.setMode({ mode: action }));
                }}
              />
            </If>

            <ZoomButton />

            <If
              condition={
                !viewMode &&
                !emptyData &&
                checkUserAcl(authUser, ['manageRoundtripsCluster'])
              }
            >
              <VerticalDivider />
              <AddIconButton
                onClick={e => {
                  handlesetAnchorFunction(e, setAnchorEl);
                }}
              />
            </If>
          </>
        }
      />

      {dateValue && (
        <Modals
          warehouseId={warehouseName}
          anchorEl={anchorEl}
          handleClose={handleClose}
          allRoundtrip={tab === ALL_ROUNDTRIPS}
        />
      )}
    </>
  );
};
