import { Divider, Stack, Typography } from '@mui/material';
import { Clock, DotsSixVertical } from '@phosphor-icons/react';
import EllipsisText from 'app/components/EllipsisText';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import StopIcon from 'app/components/StopIcon';
import { formatDeliveryHours } from 'common/helpers/place';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LeftMenuContext } from '../../../LeftMenu';
import { useUpdateRoundTripMutation } from 'common/services/roundtripApi';
import { useToaster } from 'hooks/useToaster';
import { RoundtripDrawerContext } from 'app/pages/Dashboard/components/RoundtripList/components/RoundtripDrawer';
import { StateSetter } from 'types';

interface Props {
  stops: any[];
  disableDrag?: boolean;
  hoveredStop?: string;
  setHoveredStop?: StateSetter<any>;
}
export const StopsList = ({ stops, disableDrag = false, hoveredStop, setHoveredStop }: Props) => {
  const { selectedRoundtripDetails, viewMode, handleOrderClick, pinnedOrders, assignedOrders, roundtrips } =
    useContext(LeftMenuContext);
  const {
    pinnedOrders: pinnedRoundtripOrders,
    setPinnedOrders: setRoundtripPinnedOrders,
  } = useContext(RoundtripDrawerContext);

  const pinnedOrderIds = useMemo(() => {
    if (pinnedRoundtripOrders?.length) {
      return pinnedRoundtripOrders;
    } else if (pinnedOrders?.length) {
      return pinnedOrders;
    }
    return [];
  }, [pinnedOrders, pinnedRoundtripOrders]);

  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const { t } = useTranslation();
  const toast = useToaster();

  const stopIndex = useRef(0);
  stopIndex.current = 0;

  const [orderedStops, setOrderdStops] = useState<any>(stops || []);

  const onDragEnd = async result => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(orderedStops);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderdStops(items);

    await updateRoundtripData({
      id: selectedRoundtripDetails._id,
      data: { stops: items },
    });
    toast(5000, 'success', 'alerts.orders.reorder_success');
  };

  useEffect(() => {
    if (stops?.length) {
      setOrderdStops(stops);
    }
  }, [stops]);

  const [draggingOver, setDraggingOver] = useState<any>(null);

  const handleDrop = async e => {

    e.preventDefault();
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);

    const assignedOrdersIds = assignedOrders?.map(order => order?._order?._id);
    const selectedAssignedOrders = srcData?.filter(order => assignedOrdersIds?.includes(order?.order?._id));
    const selectedUnassignedOrders = srcData?.filter(order => !assignedOrdersIds?.includes(order?.order?._id));

    selectedAssignedOrders?.map((assignedOrder, index) => {
      roundtrips?.map(trip => {
        const rt = trip?.stops?.map(stop => {
          if (stop?._order?._id === assignedOrder?.order?._id) assignedOrder.roundtrip = trip;
        });
      });
    });
    
    const formattedAssignedOrders = selectedAssignedOrders.map(order => ({
      type: order?.type,
      _order: order?.order,
      _place: {_id: order?._place, id: order?._place},
      roundtrip: order?.roundtrip
    }));
    const formattedUnassignedOrders = selectedUnassignedOrders.map(order => ({
      type: order?.type,
      _order: order?.order,
      _place: {_id: order?._place, id: order?._place},
    }));
    const newOrders = [...formattedAssignedOrders, ...formattedUnassignedOrders];

    if (!!formattedUnassignedOrders?.length) {
      handleAssigneToTrip(formattedUnassignedOrders)
      .then((res:any) => {
        const newOrders = res?.data?.stops;
        if (!!formattedAssignedOrders?.length) {
          handleReassigneToTrip(formattedAssignedOrders, newOrders);
        };
      });
    }
    else if (selectedAssignedOrders?.length === 1) {
      const orderToSave = selectedAssignedOrders.map(order => ({
        type: order?.type,
        _order: order?.order,
        _place: {_id: order?._place, id: order?._place},
      }));

      const items = Array.from(orderedStops);
      items.splice(draggingOver, 0, ...orderToSave);
      await updateRoundtripData({
        id: selectedRoundtripDetails._id,
        data: { stops: items },
      });

      const trip = selectedAssignedOrders[0]?.roundtrip;
      const stopsMinusSelected = trip?.stops?.filter(
        stop => stop?._order?._id !== orderToSave[0]?._order?._id
      );
      
      await updateRoundtripData({
        id: trip?._id,
        data: { stops: stopsMinusSelected },
      });
    };

    setDraggingOver(null);
    toast(5000, 'success', 'alerts.orders.reorder_success');
  };

  function handleAssigneToTrip(orders) {
    return new Promise(async (resolve:any, reject:any) => {
      try {
        const items = Array.from(orderedStops);
        items.splice(draggingOver, 0, ...orders);
        const res = await updateRoundtripData({
          id: selectedRoundtripDetails._id,
          data: { stops: items },
        });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  async function handleReassigneToTrip(orders, newOrders) {
    const readyTrips:any[] = [];
    var items:any[] = [];

    const roundtrips = orders?.map(order => order?.roundtrip);

    // remove the selected orders from their roundtrips
    roundtrips?.map(async trip => {
      if (readyTrips.includes(trip?._id)) return; 

      items = Array.from(trip?.stops);

      const ordersToRemove = orders?.filter(order => order?.roundtrip?._id === trip?._id);

      items = items.filter((item:any) => !ordersToRemove.some(removeItem =>
        item?._order?._id === removeItem?._order?._id
      ));

      await updateRoundtripData({
        id: trip._id,
        data: { stops: items },
      });

      readyTrips.push(trip?._id);
    });

    const uniqueOrders = orders.filter((order, index, self) =>
      index === self.findIndex(o => o?._order?._id === order?._order?._id)
    );

    // assign the selected orders to the dropped on roundtrip
    const items_ = Array.from(newOrders);
    items_.splice(draggingOver, 0, ...uniqueOrders);
    await updateRoundtripData({
      id: selectedRoundtripDetails._id,
      data: { stops: items_ },
    });
  };
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        isDropDisabled={!!viewMode || disableDrag}
      >
        {(provided, snapshot) => (
          <Container {...provided.droppableProps} ref={provided.innerRef}>
            <Stack gap={'0'}>
              {orderedStops.map((stop, index) => {
                if (!index) stopIndex.current = 0;
                let displayedIndex = 0;
                if (stop.type !== 'maintenance' && stop.type !== 'fuel') {
                  displayedIndex = ++stopIndex.current;
                }
                return (
                  <>
                    {index === 0 && (
                      <DroppableArea
                        draggingOver={draggingOver === index}
                        onDragEnter={() => setDraggingOver(index)}
                        onDragLeave={() => setDraggingOver(null)}
                        onDrop={handleDrop}
                      />
                    )}
                    <Draggable
                      key={stop._id}
                      draggableId={`draggable-stop-${stop._id}`}
                      index={index}
                      isDragDisabled={!!viewMode || disableDrag}
                    >
                      {(provided, snapshot) => (
                        <Stack
                          direction={'row'}
                          gap={'4px'}
                          alignItems={'center'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                          onMouseEnter={() => {if (setHoveredStop) setHoveredStop(stop._id)}}
                          onMouseLeave={() => {if (setHoveredStop) setHoveredStop('')}}
                          onDragOver={() => setDraggingOver(index + 1)}
                          onDrop={() => setDraggingOver(null)}
                        >
                          <StopCircle>
                            <If
                              condition={
                                stop.type === 'maintenance' ||
                                stop.type === 'fuel'
                              }
                              otherwise={
                                <IndexValue>{displayedIndex}</IndexValue>
                              }
                            >
                              <StopIcon
                                type={stop?.type}
                                orderCategory={stop?._order?.category}
                                tooltipMessage={t(`common.${stop?.type}`)}
                              />
                            </If>
                            <If condition={index !== orderedStops.length - 1}>
                              <VerticalLine
                                orientation="vertical"
                                sx={{ height: '20px' }}
                              />
                            </If>
                          </StopCircle>

                          <StopWrapper
                            isHighlighted={
                              pinnedOrderIds?.includes(stop?._order?._id) ||
                              pinnedOrderIds?.includes(stop?._id) ||
                              hoveredStop === stop?._id
                            }
                            onClick={() => {
                              handleOrderClick?.(stop?._order?._id || stop?._id);
                              setRoundtripPinnedOrders?.([
                                stop?._order?._id || stop?._id,
                              ]);
                            }}
                          >
                            <Stack
                              direction={'row'}
                              gap={'4px'}
                              alignItems={'center'}
                            >
                              <Icon icon={<DotsSixVertical />} />
                              <Typography fontSize={'0.75rem'} fontWeight={500}>
                                <EllipsisText
                                  width={100}
                                  text={
                                    stop._order?._customer?.code ||
                                    stop?._place?.name
                                  }
                                />
                              </Typography>
                            </Stack>

                            <Stack
                              direction={'row'}
                              gap={'4px'}
                              alignItems={'center'}
                            >
                              <div
                                style={{
                                  height: '8px',
                                  width: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '2px',
                                }}
                              >
                                <Icon icon={<Clock />} size={8} />
                              </div>
                              <Typography fontSize={'0.65rem'} fontWeight={300}>
                                {formatDeliveryHours(stop?._place?.deliveryHours)}
                              </Typography>
                            </Stack>
                          </StopWrapper>
                        </Stack>
                      )}
                    </Draggable>
                    <DroppableArea
                      draggingOver={draggingOver === index + 1}
                      onDragEnter={() => setDraggingOver(index + 1)}
                      onDragLeave={() => setDraggingOver(null)}
                      onDrop={handleDrop}
                    />
                  </>
                );
              })}
            </Stack>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Container = styled.div`
  padding-top: 8px;
`;

const StopCircle = styled.div`
  width: 24px;
  height: 24px;
  background: white;
  border: 1px solid ${props => props.theme.whisper};
  border-radius: 50%;
  position: relative;
`;

const IndexValue = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.nobel};
  font-size: 0.75rem;
`;

const VerticalLine = styled(Divider)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(20px);
  color: ${props => props.theme.whisper};
`;

interface StopWrapperProps {
  isHighlighted: boolean;
}

const StopWrapper = styled.div<StopWrapperProps>`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.isHighlighted
        ? props.theme.primaryActiveColor
        : props.theme.gainsboro2};
  background: ${props =>
    props.isHighlighted ? props.theme.lightBlueBg : 'transparent'};
`;

const DroppableArea = styled.div<{draggingOver: boolean}>`
  height: ${props => props.draggingOver ? 34 : 8}px;
  margin-left: auto;
  width: calc(100% - 28px);
  border-radius: 4px;
  background: ${props =>
    props.draggingOver ? props.theme.lightBlueBg : "transparent"};
  border: 1px solid ${props =>
      props.draggingOver
        ? props.theme.primaryActiveColor
        : "transparent"};
  transition: 0.15s ease-in-out;
`;
