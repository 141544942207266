import React from "react";
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

interface DotProps {
  tooltipTitle: string;
  circleColor?: string;
  size?: number;
}

export const OrderStatusDot: React.FC<DotProps> = ({
  tooltipTitle,
  circleColor,
  size,
}) => {
  return(
    <Tooltip title={tooltipTitle}>
      <StatusCircle color={circleColor} size={size}/>
    </Tooltip>
  );
};

const StatusCircle = styled.span<{color, size}>`
  width: ${props => props.size ? props.size : 8}px;
  height: ${props => props.size ? props.size : 8}px;
  border-radius: ${props => props.size ? props.size / 2 : 4}px;
  background: ${props => props.color};
`;