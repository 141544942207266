import { useMemo } from 'react';
import { ChangeStatusTab } from 'app/components/RoundTrips/components/ChangeStatusTab';
import { selectDateBegin } from 'common/store/roundtrips/selectors';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  RoundtripStatusesActiveDate,
  RoundtripStatusesInActiveDate,
} from '../../../status';
import { getStatusesToShow } from 'app/pages/AddRoundTrips/functions';
import {
  PULSING_COLORS,
  PULSING_STATUSES,
} from 'app/components/TableComponent/helpers/Components/StatusPopover';

export const useStatusActions = (
  setStatus,
  roundtrip,
  functionRoundTripStatus,
  roundTripStatus,
) => {
  const today = dayjs().startOf('day').format('YYYY-MM-DD');
  const date = useSelector(selectDateBegin);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const statusActionsList = useMemo(() => {
    let statuses;
    let statusActions: Array<{ action: string; element: JSX.Element }> = [];

    if (today === date) {
      statuses = getStatusesToShow(RoundtripStatusesActiveDate);
    } else {
      statuses = getStatusesToShow(RoundtripStatusesInActiveDate);
    }

    statuses?.map(status =>
      statusActions?.push({
        action: status.status,
        element: (
          <ChangeStatusTab
            action={() => {
              if (functionRoundTripStatus)
                functionRoundTripStatus(
                  status.status,
                  roundtrip.id,
                  roundTripStatus,
                  dispatch,
                  t,
                );
            }}
            color={status.color}
            title={`roundtrips.status.${status.status}`}
            pulse={
              PULSING_STATUSES.includes(status.status)
                ? PULSING_COLORS[status.status]
                : undefined
            }
          />
        ),
      }),
    );

    return statusActions;
  }, [date]);

  useEffect(() => {
    const statusActions = statusActionsList;

    setStatus(statusActions);
  }, [roundtrip]);
};
