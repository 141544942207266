/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { isValidElement, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';

import Snackbar from '@mui/material/Snackbar';
import { LoginPage } from './pages/Login/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import PrivateRoute from './components/PrivateRoute';
import { DashboardPage } from './pages/Dashboard/Loadable';
import { MainNav } from './components/MainNav';
import LogoutPage from './pages/Logout';
import styled from 'styled-components/macro';
import { AppHeader } from './components/AppHeader';
import { AuditPage } from './pages/Audit/Loadable';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthToken,
  selectAuthUser,
  selectMainMenuSize,
} from './slices/auth/selectors';
import { RoundTrips } from './pages/RoundTrips/Loadable';
import { SigninPage } from './pages/Signin/Loadable';
import { selectIsZoomedIn } from './slices/tools/selectors';
import { SignUpNamePage } from './pages/SignupName/Loadable';
import { SignUpEmailPage } from './pages/SignUpEmail/Loadable';
import { VerificationLink } from './pages/VerificationLink';
import { SignUpDetailsPage } from './pages/SignUpDetails/Loadable';
import { ResetPasswordPage } from './pages/ResetPassword/Loadable';
import { ResetPasswordOtpPage } from './pages/ResetPasswordOTP/Loadable';
import { VehiclesPage } from './pages/Vehicles/Loadable';
import { CustomersPage } from './pages/Customers/Loadable';
import { CustomerPublic } from './pages/CustomerPublic';
import { OrdersPage } from './pages/Orders/Loadable';
import { InboxPage } from './pages/Inbox/Loadable';

import {
  ANALYTICS,
  AUDIT,
  CUSTOMER,
  CUSTOMER_PUBLIC,
  DASHBOARD,
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  ORDERS,
  OTP,
  RESET,
  ROUNDTRIP_ADD,
  SIGNIN,
  SIGNUP,
  SIGNUP_DESCRIPTION,
  SIGNUP_DESCRIPTION_DETAILS,
  SIGNUP_NAME,
  UPLOAD_FILES,
  SUPPORTUNIT,
  USERS,
  VEHICLES,
  VERIFICATION_LINK,
  CHECK_DOCUMENTS,
  COMPANYPROFIL,
  INBOX,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  ALL_ROUNDTRIPS,
  ORDORIA,
  ORDERS_ACTIONS,
  LANGUAGE,
  DRIVERS,
  SALES,
  SETTINGS_USERS,
  ACCESSORIES,
  ACCESSORYINFO,
  TOOLS,
  DASHBOARD_ALL,
  PAYMENTCARDS,
  VIEWCUSTOMER,
  REPORTS,
  ORDORIA_CUSTOMER,
  REPORTS_ALL,
  PRIVACY_POLICY,
  CONTACT_US,
} from 'utils/routes';
import { UploadFiles } from './pages/UploadFilesPage/Loadable';
import { AddRoundTrips } from './pages/AddRoundTrips';
import { AnalyticsPage } from './pages/Analytics/Loadable';
import { SupportUnits } from './pages/SupportUnits/Loadable';
import { CheckDocuments } from './pages/CheckDocuments';
import { AllRoundTrips } from './pages/AllRoundtrip/Loadable';
import { selectLastToasterNotification } from '../common/store/app/selectors';
import { appActions } from '../common/store/app';
import { CompanyProfile } from './pages/Settings/CompanyProfile';
import { Alert, Portal } from '@mui/material';
import { RoundtripsTabs } from './pages/RoundtripsTabs/Loadable';
import { OrdoriaHomePage } from './pages/Ordoria/Dashboard/Loadable';
import { OrdersActionPage } from './pages/Ordoria/Orders/Loadable';
import {
  ORDORIA_CREATE_ORDERS,
  ORDORIA_VIEW_ODER_DETAIL,
  ORDORIA_SALES_VIEW_ORDER,
  ORDORIA_CALL_PLAN,
} from './pages/Ordoria/routes';
import { OrdoriaCreateOrdersPage } from './pages/Ordoria/CreateOrders/Loadable';
import { Settings } from './pages/Settings';
import { LanguageCurrency } from './pages/Settings/LanguageCurrency/Loadable';
import { OrdoriaDetailPage } from './pages/Ordoria/OrderDetail/Loadable';
import { UsersPage as UsersPageSettings } from './pages/Settings/Users/Loadable';
import { UsersPage } from './pages/Users/Loadable';
import { SalesOrderDetails } from './pages/Ordoria/SalesOrderDetail';
import { OrdoriaCallPlan } from './pages/Ordoria/CallPlan/Loadable';
import { CallPlan } from './pages/Ordoria/CallPlan';
import { useIsLogistic } from 'hooks/acl';
import { Accessories } from './pages/Accessories/Loadable';
import { AccessoryInfo } from './pages/AccessoryInfo/Loadable';
import { Tools } from './pages/Tools/Loadable';
import { PaymentCards } from './pages/PaymentCards/Loadable';
import { checkUserAcl } from 'common/helpers/acl';
import { ViewCustomer } from './pages/Customers/components/ViewCustomer';
import { ReportsPage } from './pages/Reports/Loadable';
import PageViewTracker from '../functions/PageViewTracker';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { ContactUs } from './pages/ContactUs';

export function App() {
  const { i18n } = useTranslation();
  const authToken = useSelector(selectAuthToken);
  const authUser = useSelector(selectAuthUser);
  const zoomedIn = useSelector(selectIsZoomedIn);
  const lastToasterNotification = useSelector(selectLastToasterNotification);
  const dispatch = useDispatch();
  const i18nLanguage = i18n.language;
  const mainMenuSize = useSelector(selectMainMenuSize);
  const isMinify = mainMenuSize === 'minify';

  const handleCloseNotification = () => {
    dispatch(appActions.cleanLastToasterNotification());
  };

  useEffect(() => {
    if (authUser?.language && authUser.language !== i18nLanguage) {
      i18n.changeLanguage(authUser?.language);
    }
  }, [authUser?.language]);

  useEffect(() => {
    if (authUser?._id) {
      Sentry.setUser({
        id: authUser._id,
        email: authUser.email,
        segment: authUser._currentOrganization.customer ? 'customer' : 'user',
        isSuperAdmin: authUser.isSuperAdmin,
        currentOrganization: authUser._currentOrganization?.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [authUser?._id]);

  const isLogistic = useIsLogistic();

  // @ts-ignore
  return (
    <BrowserRouter>
      <PageViewTracker />
      <Helmet
        titleTemplate="%s - Bamptee"
        defaultTitle="Bamptee"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <Wrapper>
        {authToken && !zoomedIn ? <MainNav /> : null}
        <AppWrapper>
          {authToken && !zoomedIn ? <AppHeader /> : null}
          <AppContent showNav={!zoomedIn}>
            <Routes>
              <Route path={LOGIN} element={<LoginPage />} />
              <Route path={SIGNIN} element={<SigninPage />} />
              <Route path={SIGNUP} element={<SignUpEmailPage />} />
              <Route path={VERIFICATION_LINK} element={<VerificationLink />} />
              <Route path={SIGNUP_NAME} element={<SignUpNamePage />} />
              <Route
                path={SIGNUP_DESCRIPTION}
                element={<SignUpDetailsPage />}
              />
              <Route
                path={SIGNUP_DESCRIPTION_DETAILS}
                element={<SignUpDetailsPage />}
              />
              <Route path={OTP} element={<ResetPasswordOtpPage />} />
              <Route path={RESET} element={<ResetPasswordPage />} />
              <Route path={LOGOUT} element={<LogoutPage />} />
              <Route
                path={ROUNDTRIP_ADD}
                element={
                  <PrivateRoute>
                    <AddRoundTrips />
                  </PrivateRoute>
                }
              />

              <Route
                path={`users/driver`}
                element={
                  <PrivateRoute>
                    <UsersPage role="driver" />
                  </PrivateRoute>
                }
              />

              <Route
                path={`users/sales`}
                element={
                  <PrivateRoute>
                    <UsersPage role="sales" />
                  </PrivateRoute>
                }
              />

              <Route
                path={`${VEHICLES}/logistic`}
                element={
                  <PrivateRoute>
                    <VehiclesPage context="logistic" />
                  </PrivateRoute>
                }
              />

              <Route
                path={`${VEHICLES}/sales`}
                element={
                  <PrivateRoute>
                    <VehiclesPage context="sales" />
                  </PrivateRoute>
                }
              />
              <Route
                path={ORDERS}
                element={
                  <PrivateRoute>
                    <OrdersPage />
                  </PrivateRoute>
                }
              />

              <Route
                path={CUSTOMER}
                element={
                  <PrivateRoute>
                    <CustomersPage />
                  </PrivateRoute>
                }
              />

              <Route
                path={ORDORIA_CUSTOMER}
                element={
                  <PrivateRoute>
                    <CustomersPage />
                  </PrivateRoute>
                }
              />

              <Route
                path={DASHBOARD}
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                }
              />

              <Route
                path={DASHBOARD_ALL}
                element={
                  <PrivateRoute>
                    <DashboardPage allOrganizations />
                  </PrivateRoute>
                }
              />
              <Route
                path={AUDIT}
                element={
                  <PrivateRoute>
                    <AuditPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUNDTRIP}
                element={
                  <PrivateRoute>
                    <RoundtripsTabs />
                  </PrivateRoute>
                }
              >
                <Route
                  path={SCHEDULED_ROUNDTRIPS}
                  element={
                    <PrivateRoute>
                      <RoundTrips />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ALL_ROUNDTRIPS}
                  element={
                    <PrivateRoute>
                      <AllRoundTrips />
                    </PrivateRoute>
                  }
                />
              </Route>
              {(isLogistic || checkUserAcl(authUser, ['manageResources'])) && (
                <Route
                  path={SUPPORTUNIT}
                  element={
                    <PrivateRoute>
                      <SupportUnits />
                    </PrivateRoute>
                  }
                ></Route>
              )}
              <Route
                path={ANALYTICS}
                element={
                  <PrivateRoute>
                    <AnalyticsPage />
                  </PrivateRoute>
                }
              />
              <Route path={NOT_FOUND} element={<NotFoundPage />} />
              <Route
                path={UPLOAD_FILES}
                element={
                  <PrivateRoute>
                    <UploadFiles />
                  </PrivateRoute>
                }
              />
              <Route
                path={CHECK_DOCUMENTS}
                element={
                  <PrivateRoute>
                    <CheckDocuments />
                  </PrivateRoute>
                }
              />
              <Route path="settings" element={<Settings />}>
                <Route
                  index
                  path={COMPANYPROFIL}
                  element={
                    <PrivateRoute>
                      <CompanyProfile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path={LANGUAGE}
                  element={
                    <PrivateRoute>
                      <LanguageCurrency />
                    </PrivateRoute>
                  }
                />

                <Route
                  path={SETTINGS_USERS}
                  element={
                    <PrivateRoute>
                      <UsersPageSettings />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path={INBOX}
                element={
                  <PrivateRoute>
                    <InboxPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={CUSTOMER_PUBLIC}
                element={
                  <CustomerPublic defaultToken={null} defaultPassword={null} />
                }
              />
              <Route path={ORDORIA}>
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <OrdoriaHomePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ORDORIA_CALL_PLAN}
                  element={
                    <PrivateRoute>
                      <OrdoriaCallPlan />
                    </PrivateRoute>
                  }
                />
                <Route index={false} path="orders">
                  <Route
                    path={ORDERS_ACTIONS}
                    element={
                      <PrivateRoute>
                        <OrdersActionPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ORDORIA_CREATE_ORDERS}
                    element={
                      <PrivateRoute>
                        <OrdoriaCreateOrdersPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ORDORIA_VIEW_ODER_DETAIL}
                    element={
                      <PrivateRoute>
                        <OrdoriaDetailPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ORDORIA_SALES_VIEW_ORDER}
                    element={
                      <PrivateRoute>
                        <SalesOrderDetails />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
              <Route
                path={ACCESSORIES}
                element={
                  <PrivateRoute>
                    <Accessories />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={`${ACCESSORYINFO}`}
                element={
                  <PrivateRoute>
                    <AccessoryInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path={TOOLS}
                element={
                  <PrivateRoute>
                    <Tools />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={PAYMENTCARDS}
                element={
                  <PrivateRoute>
                    <PaymentCards />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={VIEWCUSTOMER}
                element={
                  <PrivateRoute>
                    <ViewCustomer />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={REPORTS}
                element={
                  <PrivateRoute>
                    <ReportsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={REPORTS_ALL}
                element={
                  <PrivateRoute>
                    <ReportsPage allOrganizations />
                  </PrivateRoute>
                }
              />

              <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={CONTACT_US} element={<ContactUs />} />
            </Routes>

            {lastToasterNotification ? (
              <Portal>
                <CustomSnackBar
                  open
                  autoHideDuration={lastToasterNotification.duration}
                  onClose={handleCloseNotification}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  message={lastToasterNotification.message}
                  showNav={!zoomedIn}
                  isMinify={!!isMinify}
                  sx={{ position: 'absolute' }}
                >
                  <Alert
                    variant="standard"
                    onClose={handleCloseNotification}
                    severity={lastToasterNotification.severity}
                  >
                    {(!!lastToasterNotification.content &&
                      isValidElement(lastToasterNotification.content) &&
                      lastToasterNotification.content) ||
                      lastToasterNotification.message}
                  </Alert>
                </CustomSnackBar>
              </Portal>
            ) : null}
          </AppContent>
        </AppWrapper>
      </Wrapper>
      <GlobalStyle />
    </BrowserRouter>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: auto;
  background: ${props => props.theme.grey};
`;

const AppWrapper = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 0;
  overflow: hidden;
`;

interface AppContentProps {
  showNav: boolean;
  isMinify?: boolean;
}
const AppContent = styled.div<AppContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding-bottom: 5px;
  padding-top: ${p => (p.showNav ? p.theme.topBarHeight : 'unset')};
  position: relative;
`;

const CustomSnackBar = styled(Snackbar)<AppContentProps>(props => ({
  top: props.showNav
    ? `calc(${props.theme.topBarHeight} + ${props.theme.tabsBarHeight} + 5px) !important`
    : `calc(${props.theme.tabsBarHeight} + 5px) !important`,
  left: props.showNav
    ? props.isMinify
      ? `${props.theme.snackBarLeftPositionMin} !important`
      : `${props.theme.snackBarLeftPosition} !important`
    : '50%',
}));
