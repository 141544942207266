import { Data } from 'interfaces/users';

export function createData(
  id: { value: string | number },
  name: { value: JSX.Element; sort?: string },
  email: { value: JSX.Element; sort?: string },
  telephone: { value: JSX.Element; sort?: string },
  role: { value: JSX.Element; sort?: string },
  status: { value: JSX.Element; sort?: string },
  action: { value: JSX.Element },
): Data {
  return {
    id,
    name,
    email,
    telephone,
    role,
    status,
    action,
  };
}
