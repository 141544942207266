import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useQuery } from 'common/hooks/router';
import { useLazyGetCustomerPublicDetailsQuery } from 'common/services/customerApi';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Badge, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { OrdersList } from 'app/components/OrdersList';
import React from 'react';
import { InfosList } from 'app/components/InfosList';
import { DocList } from 'app/components/DocList';
import { selectAuthUser } from '../../slices/auth/selectors';
import { ContactForm } from './components/ContactForm';

import { usePatchCustomerPublicMutation } from 'common/services/customerApi';
import { Complaint } from 'app/components/Complaint';
import { checkUserAcl } from '../../../common/helpers/acl';
import { SimpleToolbar } from '../../components/SimpleToolbar';
import TermsModal from '../../components/TermsModal';
import { selectOrganizationActivities } from 'common/store/organization/selectors';
import {SIGNIN} from "../../../utils/routes";

export function CustomerPublic({ defaultToken, defaultPassword }) {
  const { t } = useTranslation();
  const { token: urlToken } = useParams();
  const urlQuery = useQuery();

  const token = defaultToken || urlToken;
  const password =
    !urlQuery.get('password') || urlQuery.get('password') === 'undefined'
      ? 'fedipat'
      : urlQuery.get('password');

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [updateCustomer] = usePatchCustomerPublicMutation();

  const authUser: any = useSelector(selectAuthUser);
  const activities: any = useSelector(selectOrganizationActivities);

  const [modalContactIsVisible, setModalContactIsVisible] = useState(false);

  const [getCustomer, { data: customer, isLoading }] =
    useLazyGetCustomerPublicDetailsQuery();

  useEffect(() => {
    if (token && password) {
      getCustomer({
        token,
        password,
      });
    }
  }, [token, password]);

  ///Set the CGV to today Date
  const handleCGV = async () => {
    await updateCustomer({
      token,
      password,
      termsAcceptedAt: new Date(),
    }).unwrap();

    getCustomer({
      token,
      password,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  if (!token || (!customer && !isLoading)) {
    return <div></div>;
  }

  if (isLoading || !customer) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  const isCustomer = authUser?.currentAgent._customer;

  const displayTermModal =
    isCustomer &&
    (!customer?.termsAcceptedAt ||
      (customer?.termsAcceptedAt &&
        customer.termsAcceptedAt < customer._organization.termsUpdatedAt));

  // TODO: remove before release client
  const displayAllTabs = checkUserAcl(authUser, ['manageCustomers']);

  if (!authUser) {
    return <Navigate to={SIGNIN} />;
  }

  return (
    <MainWrapper>
      <SimpleToolbar
        center={
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              height: '40px',
              minWidth: '400px',
            }}
          >
            <Tab label="Profil" />
            {displayAllTabs && <Tab label={t('nav.mainNav.orders')} />}
            <Tab label="Documents" />
            <Tab
              label={isCustomer && activities.countNewConversations > 0 ? (<div style={{ display: 'flex' }}>
                <LabelWrapper>{t('complaint.title')}</LabelWrapper>
                <Badge badgeContent={activities.countNewConversations} color="primary" />
              </div>) : t('complaint.title')}
            />
          </Tabs>
        }
      />

      <Wrapper>
        {!displayTermModal ? (
          <>
            {currentTabIndex == 0 && (
              <InfosList
                customer={customer}
                token={token}
                password={password}
              />
            )}
            {displayAllTabs && currentTabIndex == 1 && (
              <OrdersList token={token} password={password} />
            )}
            {(displayAllTabs && currentTabIndex == 2) ||
            (!displayAllTabs && currentTabIndex === 1) ? (
              <DocList authUser={authUser} token={token} password={password} />
            ) : null}
            {(displayAllTabs && currentTabIndex == 3) ||
            (!displayAllTabs && currentTabIndex === 2) ? (
              <Complaint
                token={token}
                password={password}
                customer={customer}
                authUser={authUser}
                onNewMessage={() => setModalContactIsVisible(true)}
              />
            ) : null}
          </>
        ) : null}
      </Wrapper>

      <Dialog open={modalContactIsVisible}>
        <DialogTitle>
          {t('customerPublic.contactForm.title', {
            name: customer?._organization?.name || '',
          })}
        </DialogTitle>
        <DialogContent>
          <ContactForm
            token={token}
            password={password}
            addresses={customer.contactAddresses}
            onOk={() => setModalContactIsVisible(false)}
          />
        </DialogContent>
      </Dialog>

      {displayTermModal && (
        <TermsModal
          open
          title={'Conditions générales de vente'}
          description={
            "Merci d'accepter les conditions générales suivantes pour accéder à votre portail client."
          }
          termsFileUrl={customer._organization.termsUrl}
          acceptLabel="Accepter les CGV"
          onAccept={handleCGV}
        />
      )}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

const LabelWrapper = styled.div`
  padding-right: 12px;
`;


const Wrapper = styled.div`
  height: 100vh;
  padding-top: 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;
