export const clientStatusColor: {
  [key: string]: { color: string; background: string };
} = {
  preliminary: {
    color: '#FBC02D',
    background: '#FBC02D1a',
  },
  pending: {
    color: '#FF6F00',
    background: '#FF6F001a',
  },
  'on-watch': {
    color: '#ED6C02',
    background: '#ED6C021a',
  },
  active: {
    color: '#04BC85',
    background: '#04BC851a',
  },
  blocked: {
    color: '#5F2B01',
    background: '#5F2B011a',
  },
  locked: {
    color: '#5F2B01',
    background: '#5F2B011a',
  },
  stopped: {
    color: '#D32F2F',
    background: '#D32F2F1a',
  },
  other: {
    color: '#757573',
    background: '#7575731a',
  },
};
