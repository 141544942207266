import { HeadCell } from 'interfaces/users';
import { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { alpha, Button, IconButton, TextField } from '@mui/material';
import { FlexComponent, ToolbarWrapper } from 'styles/components/users';
import { SearchOutlined } from '@mui/icons-material';
import { ColumnPopover } from 'app/components/TableComponent/ColumnPopover';
import { Filters } from 'app/components/Filters';
import { IconButtonComponent } from 'app/components/IconButtonComponent';
import AddIcon from '@mui/icons-material/Add';
import { EnhancedTableToolbarProps } from '../../data/types';
import { NO_DATA_REGULAR } from 'app/components/const';
import CustomizedMenus from 'app/pages/AddRoundTrips/components/EnhancedTableToolbar/component/CustomizedMenus';
import { themes } from 'styles/theme/themes';

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  changeColumnAction,
  clearAllAction,
  handleAddUserClick,
  headCells,
  heads,
  numSelected,
  search,
  selectAllAction,
  setFilters,
  setSearch,
  setUser,
  name,
  selected,
  setSelected,
  handleClickDeleteSnack,
  setHeadCells,
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      <ToolbarWrapper>
        <FlexComponent>
          <TextField
            size="small"
            margin="normal"
            // placeholder={t('common.place_holders.search')}
            id="standard-bare"
            sx={{ backgroundColor: themes?.default?.accordionWhiteBg }}
            onChange={e => {
              setSearch(e.target.value);
            }}
            value={search}
            InputProps={{
              startAdornment: (
                <IconButton sx={{ marginLeft: '-10px' }}>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />

          <ColumnPopover
            headCells={headCells}
            changeColumn={(id, value) => {
              changeColumnAction(id, value, headCells, setHeadCells);
            }}
            clearAll={() => {
              clearAllAction(headCells, setHeadCells);
            }}
            selectAll={() => {
              selectAllAction(setHeadCells, headCells);
            }}
          />
          {/*
            <Filters headCells={heads} setFilters={setFilters} />
          */}

        </FlexComponent>
        {!(name === NO_DATA_REGULAR) && (
          <FlexComponent>
            <Button
              variant="contained"
              color="primary"
              onClick={e => {
                setUser(null);
                handleAddUserClick(e);
              }}
              sx={{
                marginLeft: 'auto',
                width: '32px',
                minWidth: '32px',
                height: '32px',
                borderRadius: '100%',
                marginRight: '10px',
              }}
            >
              <AddIcon sx={{ color: themes?.default?.accordionWhiteBg }} />
            </Button>
          </FlexComponent>
        )}
      </ToolbarWrapper>
    </Toolbar>
  );
};
