import { Note } from 'app/components/Note';
import { useTranslation } from 'react-i18next';
import DialogClearRoundtrip from '../DialogClearRoundtrip';
import { useToaster } from 'hooks/useToaster';
import { themes } from 'styles/theme/themes';

export default function Dialogs({
  open,
  setOpen,
  roundtripName,
  addFuction,
  roundtripEdit,
  updateRoundtripData,

  setOpenSchedule,
  schedule,
  clusterId,
  updateRoundtripCluster,
  dispatch,
  roundtripActions,
  setClusterId,
  isToday,
  openSchedule,
  dateValue,
  setOpenUnSchedule,
  openUnSchedule,
  displayNoteModal,
  setDisplayNoteModal,
  userContext,
}) {
  const { t } = useTranslation();
  const toast = useToaster();

  return (
    <>
      <DialogClearRoundtrip
        open={open}
        setOpen={setOpen}
        title={`${t('roundtrips.edit_roundtrip.confirm_clear')} [${
          roundtripEdit?.code
        }]?`}
        description={t('roundtrips.edit_roundtrip.description_clear')}
        actionYes={() => {
          setOpen(false);
          addFuction(roundtripEdit?.id, { stops: [] }, updateRoundtripData);
          toast(3000, 'success', 'roundtrips.clear.success');
        }}
        actionYesText={t('common.buttons.dialogue_clear')}
        actionNoText={t('common.buttons.close_dialogue')}
      />
      <DialogClearRoundtrip
        actionYes={() => {
          setOpenSchedule(false);
          schedule(
            clusterId,
            updateRoundtripCluster,
            dispatch,
            roundtripActions,
            setClusterId,
            'active',
            t('alerts.roundtrips.scheduled.activate'),
            userContext,
          );
        }}
        open={openSchedule}
        setOpen={setOpenSchedule}
        title={t('drawer.modal.activate_roundtrip')}
        description={t('drawer.modal.scheduling.activate', {
          date: dateValue.format('DD/MM/YYYY'),
        })}
        actionYesText={t('common.buttons.activate')}
        actionNoText={t('common.buttons.cancel')}
        yesButtonSx={{
          background: themes?.default?.fruitSaladGreen,
          colorProp: 'success',
        }}
      />
      <DialogClearRoundtrip
        actionYes={() => {
          setOpenUnSchedule(false);
          schedule(
            clusterId,
            updateRoundtripCluster,
            dispatch,
            roundtripActions,
            setClusterId,
            'inactive',
            t('alerts.roundtrips.scheduled.deactivate'),
            userContext,
          );
        }}
        open={openUnSchedule}
        setOpen={setOpenUnSchedule}
        title={t('drawer.modal.deactivate_roundtrip')}
        description={t('drawer.modal.scheduling.deactivate')}
        actionYesText={t('common.buttons.deactivate')}
        actionNoText={t('common.buttons.cancel')}
        yesButtonSx={{
          background: themes?.default?.Cinnabar,
          colorProp: 'error',
        }}
      />
      <Note
        viewMode={false}
        open={displayNoteModal.isVisible}
        roundtripId={displayNoteModal.roundtripId}
        stopId={displayNoteModal.stopId}
        onCloseModal={() =>
          setDisplayNoteModal({
            isVisible: false,
            roundtripId: null,
            stopId: null,
          })
        }
      />
    </>
  );
}
