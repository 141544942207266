import { ActionPopover } from 'app/components/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';

interface EllipsisProps {
  status: string;
  orderId: number;
  handleOrderDetailsDrawerOpen: Function;
  createdBySales: boolean;
  handleDeleteOrderDialogOpen: Function;
  handleSaveAsTemplate: Function;
  handleAddNote: Function;
  handleCancelOrderDialogOpen?: Function;
}

export function InsideOrderEllipsisSales({
  status,
  createdBySales,
  orderId,
  handleOrderDetailsDrawerOpen,
  handleDeleteOrderDialogOpen,
  handleSaveAsTemplate,
  handleAddNote,
  handleCancelOrderDialogOpen
}: EllipsisProps) {
  const actions = getStatusActions(status, createdBySales);

  ///if the user connected is a sale or an admin, and the created order is true, then prevent ellipsis to not render if it's empty (in this case pending don't have ellipsis)
  const shouldRenderActionPopover = (
    status !== 'blocked'
  );

  return shouldRenderActionPopover ? (
    <ActionPopover
      id={status}
      styling={{
        margin: '0',
      }}
      iconAction={<MoreHorizIcon />}
      handleAction={(event, action) => {
        if (action === 'orderInfo' && handleOrderDetailsDrawerOpen) {
          handleOrderDetailsDrawerOpen();
        } else if (action === 'delete' && handleDeleteOrderDialogOpen) {
          handleDeleteOrderDialogOpen();
        } else if (action === 'saveTemplate' && handleSaveAsTemplate) {
          handleSaveAsTemplate();
        } else if (action === 'addNotes' && handleAddNote) {
          handleAddNote();
        } else if (action ==='cancel' && handleCancelOrderDialogOpen) {
          handleCancelOrderDialogOpen();
        }
      }}
      actions={actions}
    />
  ) : null;
}

const getStatusActions = (status, createdBySales) => {
  ///User connected as a SALESMAN
  ///Check if the order has been created by salesman
  if (createdBySales === true) {
    switch (status) {
      case 'initiated':
      case 'initiated-customer':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'delete',
            element: (
              <Typography
                sx={{
                  color: 'rgb(194,63,56)',
                  padding: '0px 12px 0px 12px',
                }}
              >
                Delete
              </Typography>
            ),
          },
        ];
      case 'validated':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'cancel',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                  color: 'rgb(194,63,56)',
                }}
              >
                Cancel Order
              </Typography>
            ),
          },
        ];
      case 'confirmed':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'cancel',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                  color: 'rgb(194,63,56)',
                }}
              >
                Cancel Order
              </Typography>
            ),
          },
        ];
      case 'prepared':
      case 'loaded':
      case 'ready-for-delivery':
      case 'on-the-road':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'cancel',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                  color: 'rgb(194,63,56)',
                }}
              >
                Cancel Order
              </Typography>
            ),
          },
        ];
      case 'delivered':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
        ];
      case 'canceled':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'delete',
            element: (
              <Typography
                sx={{
                  color: 'rgb(194,63,56)',
                  padding: '0px 12px 0px 12px',
                }}
              >
                Delete
              </Typography>
            ),
          },
        ];
      default:
        return [];
    }
    ///Else the order has been created by customer
  } else {
    switch (status) {
      case 'initiated':
      case 'initiated-customer':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'delete',
            element: (
              <Typography
                sx={{
                  color: 'rgb(194,63,56)',
                  padding: '0px 12px 0px 12px',
                }}
              >
                Delete
              </Typography>
            ),
          },
        ];
      case 'pending':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
        ];
      case 'pending':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
        ];
      case 'pending-updates':
      case 'pending-customer':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'cancel',
            element: (
              <Typography
                sx={{
                  color: 'rgb(194,63,56)',
                  padding: '0px 12px 0px 12px',
                }}
              >
                Cancel Order
              </Typography>
            ),
          },
        ];
      case 'validated':
      case 'confirmed':
      case 'prepared':
      case 'loaded':
      case 'ready-for-delivery':
      case 'on-the-road':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
          {
            action: 'cancel',
            element: (
              <Typography
                sx={{
                  color: 'rgb(194,63,56)',
                  padding: '0px 12px 0px 12px',
                }}
              >
                Cancel Order
              </Typography>
            ),
          },
        ];
      case 'delivered':
      case 'canceled':
        return [
          {
            action: 'orderInfo',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Order Info
              </Typography>
            ),
          },
          {
            action: 'addNotes',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Add Notes
              </Typography>
            ),
          },
          {
            action: 'saveTemplate',
            element: (
              <Typography
                sx={{
                  padding: '0px 12px 0px 12px',
                }}
              >
                Save as Template
              </Typography>
            ),
          },
        ];
      default:
        return [];
    }
  }
};
