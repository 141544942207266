import { Button, Drawer, TextField, Typography } from '@mui/material';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface DepartmentDrawerProps {
  openModal: boolean;
  closeModal: () => void;
  department?: object;
}

export function DepartmentDrawer({
  openModal,
  closeModal,
  department,
}: DepartmentDrawerProps) {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={closeModal}
      PaperProps={{
        sx: { width: '473px' },
      }}
    >
      <Typography
        fontSize="1rem"
        fontWeight="400"
        sx={{ padding: '16px 16px 16px 20px' }}
      >
        {department
          ? t('settings.departments.edit.title')
          : t('settings.departments.add.title')}
      </Typography>
      <TextFieldWrapper>
        <TextField
          label={t('settings.departments.add.textfields.departmentName')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
        />
        <TextField
          label={t('settings.departments.add.textfields.manager')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
        />
        <TextField
          label={t('settings.departments.add.textfields.employees')}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
        />
      </TextFieldWrapper>
      <FooterWrapper>
        <ButtonWrapper>
          <Button variant="outlined" sx={{ mr: '12px' }} onClick={closeModal}>
            {t('common.buttons.cancel')}
          </Button>
          {department ? (
            <Button variant="contained" sx={{ mr: '20px' }}>
              {t('common.buttons.save')}
            </Button>
          ) : (
            <Button variant="contained" sx={{ mr: '20px' }}>
              {t('common.add')}
            </Button>
          )}
        </ButtonWrapper>
      </FooterWrapper>
    </Drawer>
  );
}

const TextFieldWrapper = styled.div`
  padding: 16px 16px 16px 20px;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 5px 22px 4px ${themes?.default?.lightFill},
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 7px 8px -4px rgba(0, 0, 0, 0.2);
  height: 84px;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;
