import moment from 'moment';
export function formatDate(date: Date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}
export function timeZoneDate(date: Date) {
  const adjustedDate = moment(date);
  return adjustedDate.format('YYYY-MM-DD');
}

export function formatTime(date: Date) {
  const adjustedTime = moment(date).format('HH:mm a');
  return adjustedTime;
}
export function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.round((duration % 3600) / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export const weekday = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
