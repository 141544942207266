import { CSSProperties } from 'react';
import {
  CountDeliveryWrapper,
  DriverCode,
  StopDataWrapper,
} from 'styles/components/roundTrips/roundTripsData';
import { Completed } from '../RoundTripData/statuses';

interface Props {
  style?: CSSProperties | undefined;
  stop?: any;
}

export const DriverDetails: React.FC<Props> = ({ style, stop }) => {
  return (
    <StopDataWrapper style={style} key={1} borderRight={false}>
      <div className="delivery-wrapper">
        <div className="time">
          {'[' + window.dayjs(stop?._order?.deliveryDate + ']').format('HH:mm')}]
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <DriverCode>{stop?._order?.code}-</DriverCode>{' '}
            {stop?._order?._customer?.publicName}
          </div>
          <div>{stop?._place?.location?.name}</div>
        </div>
        <CountDeliveryWrapper>
          <div className="count">{stop?._order?.countProducts}</div>
          {stop?.status === Completed ? (
            <div className="delivery">
              Delivered at {window.dayjs(stop?.updatedStatusAt).format('HH:mm')} (-20min)
            </div>
          ) : (
            <div className="delivery">{stop?.status}</div>
          )}
        </CountDeliveryWrapper>
      </div>
    </StopDataWrapper>
  );
};
