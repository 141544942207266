import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack, ButtonProps } from '@mui/material';
import { DialogProps } from './types';
import {
  Actions,
  CancelButton,
  IconButton,
  Content,
  LargeDialog,
  Title,
} from './styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const noop = function () {};

export const GenericDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  actionBack,
  title,
  actionYes,
  actionNo,
  children,
  deleteModal,
}) => {
  const { t } = useTranslation();

  const hasActions = actionYes || actionNo;
  // Cancel and action button computed props
  // TODO: clean this code
  let cancelButtonText = '';
  let cancelButtonCallback = noop;
  let cancelButtonProps: ButtonProps = {};
  const actionButtonProps: ButtonProps = _.merge(actionYes?.buttonProps, {});
  if (typeof actionNo === 'boolean' && actionNo === true) {
    cancelButtonText = t('common.buttons.cancel');
  } else if (typeof actionNo !== 'boolean') {
    if (actionNo?.actionText) {
      cancelButtonText = actionNo.actionText;
    }
    if (actionNo?.actionCallback) {
      cancelButtonCallback = actionNo?.actionCallback;
    }
    if (actionNo?.buttonProps) {
      cancelButtonProps = actionNo?.buttonProps;
    }
  }

  return (
    <div>
      <LargeDialog
        open={open}
        onClose={() => {
          handleClose();
          cancelButtonCallback?.();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            {actionBack && (
              <IconButton
                aria-label="back"
                disableRipple
                onClick={actionBack.actionCallback}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Title>{title}</Title>
          </Stack>
          <IconButton
            aria-label="close"
            disableRipple
            onClick={() => {
              handleClose();
              cancelButtonCallback?.();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {children && <Content>{children}</Content>}
        {hasActions && (
          <Actions>
            {actionNo && (
              <CancelButton
                variant="text"
                onClick={() => {
                  cancelButtonCallback();
                  handleClose();
                }}
                {...cancelButtonProps}
              >
                {cancelButtonText}
              </CancelButton>
            )}
            {actionYes && (
              <Button
                variant="contained"
                color={deleteModal ? 'error' : 'primary'}
                onClick={() => {
                  actionYes.actionCallback();
                  // handleClose();
                }}
                autoFocus
                {...actionButtonProps}
              >
                {actionYes.actionText}
              </Button>
            )}
          </Actions>
        )}
      </LargeDialog>
    </div>
  );
};

export default GenericDialog;
