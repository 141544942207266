import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  Menu,
  Stack,
  Tooltip,
} from '@mui/material';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { Draggable } from 'react-beautiful-dnd';
import { PersonPopover } from 'app/components/RoundTrips/components/PersonPopover';
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { StatusOrder } from '../../data/RoundTripData/components/StatusOrder';
import { vehicleCondition } from '../functions/function';
import { ActionsStop } from '../../data/RoundTripData/components/components/ActionsStop';
import DialogClearRoundtrip from '../DialogClearRoundtrip';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import StopIcon from '../../../../components/StopIcon';
import MapPopover from 'app/components/MapPopover';
import { handleCloseStopMap } from '../../functions';
import { useDeleteOrderClusterMutation } from 'common/services/roundtripApi';
import { themes } from 'styles/theme/themes';
import OrderPriority from 'app/components/OrderPriority';
import { removeItemsFromOrder } from '../RightBar/function';
import { useModal } from 'app/components/Dialog/hooks';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { selectConfiguration } from 'common/store/organization/selectors';
import { OrderStatusDot } from 'app/components/OrderStatusDot';
import {
  CrosshairSimple,
  ArrowSquareRight,
  DotsThree,
  ArrowsCounterClockwise,
  HandPointing,
  PushPin,
} from '@phosphor-icons/react';
import { ChangePositionPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/ChangePositionPopover';
import { SwitchStopPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/SwitchStopPopover';
import { OrderAssignPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/OrderAssignPopover';
import { PopoverEl } from 'app/components/Popover';
import StopsList from 'app/components/RoundTrips/components/StopsList';
import {
  deleteFunctionStop,
  updateOrderFunction,
} from 'app/components/RoundTrips/function/ApiFunctions';
import { useDeleteStopMutation } from 'common/services/roundtripApi';
import {
  useLazyGetRoundTripDetailsQuery,
  useLazyGetRoundTripsClusterQuery,
} from 'common/services/roundtripApi';
import { useCreateShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/hooks/useCreateShortcut';
import { StatusDot } from "app/pages/AddRoundTrips/data/RoundTripData/components/StatusDot";

interface CardProps {
  ref?: any;
  handleChange2?: any;
  data?: any;
  stop?: any;
  isAssigned?: boolean;
  checked?: any;
  index?: any;
  onDisplayOrder?: any;
  dateValue?: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy?: any;
  clusterId?: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  orderInitCount?: any;
  setOrderInitCount?: any;
  disableDragAndDrop?: any;
  mapVersion?: any;
  pinnedLayers?: any;
  roundTripsCount?: any;
  setAssignedOrders?: any;
  disableEdit?: boolean;
}

export const ItemOrder: React.FC<CardProps> = ({
  handleChange2,
  data,
  stop,
  isAssigned,
  checked,
  index,
  onDisplayOrder,
  dateValue,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  clusterId,
  requestOrderData,
  orders,
  setOrders,
  orderInitCount,
  setOrderInitCount,
  disableDragAndDrop,
  mapVersion,
  pinnedLayers,
  roundTripsCount,
  setAssignedOrders,
  disableEdit = false,
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl_, setAnchorEl_] = useState<null | HTMLElement>(null);
  const [anchorEl_position, setAnchorEl_position] =
    useState<null | HTMLElement>(null);
  const [anchorEl_switchStop, setAnchorEl_switchStop] =
    useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedStop, setSelectedStop] = useState<any>({});
  const [addRoundTripOpen, setAddRoundTripOpen] = useState<any>(null);
  const [hover, setHover] = useState<boolean>(false);

  const configuration = useSelector(selectConfiguration);

  const [statusChangeRoundTripOpen, setStatusChangeRoundTripOpen] =
    useState<any>(null);
  const toast = useToaster();

  const handleLocationClick = e => {
    e?.stopPropagation();
    setMapOpen(true);
  };
  const myElementRef = useRef<HTMLDivElement>(null);
  const [scrollWdth, setScrollWdth] = useState(0);

  const [mapOpen, setMapOpen] = useState(false);

  useLayoutEffect(() => {
    if (myElementRef?.current) {
      const width = myElementRef.current.offsetWidth;
      setScrollWdth(width + 200 - 340);
    } else {
    }
  }, []);
  const time = `${data?._deck?.deliveryHours?.[0]?.begin} - ${data?._deck?.deliveryHours?.[0]?.end}`;
  const [deleteStopRight] = useDeleteOrderClusterMutation();

  const updateOrders = () => {
    const updatedOrders = removeItemsFromOrder(orders, [selectedStop]);
    setOrderInitCount(Math.max(orderInitCount - 1 || 0, 0));
    setOrders(updatedOrders);
  };

  const [deleteStop] = useDeleteStopMutation();

  const handleClickAddRoundTrip = (event: React.MouseEvent<any>) => {
    event?.stopPropagation();
    setAnchorEl_(event.currentTarget);
    setAddRoundTripOpen(event.currentTarget);
  };

  const handleAddRoundTripMenuClose = e => {
    e?.stopPropagation();
    setHover(false);
    setAnchorEl_(null);
  };

  const handleDisplayOrder = order => {
    if (onDisplayOrder) {
      onDisplayOrder(order);
    }
  };

  const orderStatusesList = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const { closeModal, openModal } = useModal();

  const [triggerRoundtripDetails, { data: roundtripDetails }] =
    useLazyGetRoundTripDetailsQuery();

  const handleDelete = async (stop, selectedRoundtrip) => {
    if (!isAssigned) {
      closeModal();
      setOpenDelete(false);
      updateOrders();
      const resp = await deleteStopRight({
        id: clusterId,
        orderIds: [stop._id],
      });
      toast(5000, 'success', 'alerts.orders.delete_success');
      requestOrderData();
    } else {
      closeModal();
      setOpenDelete(false);
      updateOrders();
      await deleteFunctionStop(deleteStop, selectedRoundtrip, stop);
      await triggerRoundtripDetails({ roundtripId: selectedRoundtrip.id });
      toast(5000, 'success', 'stops.snackbar.stopDeleted');
    }
  };

  const [pinned, setPinned] = useState(false);
  useEffect(() => {
    if (pinnedLayers?.includes(data?.id)) setPinned(true);
    else setPinned(false);
  }, [pinnedLayers]);

  const [rtId, setRtId] = useState('');
  const [rtStops, setRtStops]: any = useState([]);
  const [currentRt, setCurrentRt]: any = useState();

  useEffect(() => {
    roundTripCopy?.map(rt => {
      rt?.stops?.map(st => {
        if (st?._order?._id === data?._id) {
          setRtId(rt._id);
          setRtStops(rt.stops);
          setCurrentRt(rt);
        }
      });
    });
  }, [roundTripCopy]);

  const handlePinLayer = e => {
    const isMenuClick = e.currentTarget.contains(e.target);
    if (!anchorEl && isMenuClick) {
      handleChange2(data?.id, e);
    }
  };

  const changePositionBtnRef = useRef<any>(null);
  const assingToRoundtripBtnRef = useRef<any>(null);
  const switchStopBtnRef = useRef<any>(null);

  function closePopovers() {
    setAnchorEl_position(null);
    setAnchorEl_(null);
    setAnchorEl_switchStop(null);
  }

  function toggleChangePosition() {
    if (!!anchorEl_position) {
      setAnchorEl_position(null);
    } else {
      closePopovers();
      changePositionBtnRef?.current?.click();
    }
  }

  function toggleAssignToRoundtrip() {
    if (!!anchorEl_) {
      setAnchorEl_(null);
    } else {
      closePopovers();
      assingToRoundtripBtnRef?.current?.click();
    }
  }

  function toggleSwitchStop() {
    if (!!anchorEl_switchStop) {
      setAnchorEl_switchStop(null);
    } else {
      closePopovers();
      switchStopBtnRef?.current?.click();
    }
  }

  useCreateShortcut({
    shortcut: {
      key1: 'ctrl',
      key2: 'p',
      action: {
        callback: () => toggleChangePosition(),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'ctrl',
      key2: 'r',
      action: {
        callback: () => toggleAssignToRoundtrip(),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'ctrl',
      key2: 's',
      action: {
        callback: () => toggleSwitchStop(),
        eventName: 'keydown',
      },
    },
  });

  const [deliveredAt, setDeliveredAt] = useState('');

  useEffect(() => {
    if (!data?.updatedStatusAt) return;
    if (
      data?.status?.toLowerCase() === 'delivered' ||
      data?.status?.toLowerCase() === 'canceled'
    ) {
      const date = new Date(data?.updatedStatusAt);
      const time = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
      var formattedTime;
      if (time) {
        const splittedTime = time.split(' ');
        formattedTime = `${splittedTime[0]}${splittedTime[1]?.toLowerCase()}`;
      }
      setDeliveredAt(formattedTime);
    }
  }, [data]);

  return (
    <>
      <Draggable
        draggableId={data?.id}
        index={index}
        data={data}
        checked={checked}
        key={data?.id}
        isDragDisabled={
          disableDragAndDrop === true ||
          (checked?.length === 0
            ? false
            : !checked?.some(check => check.id === data?.id))
        }
      >
        {(provided, snapshot) => (
          <Data
            className="itemOrder"
            onClick={e => {
              return e.preventDefault();
            }}
            onDoubleClick={handlePinLayer}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            scrollWdth={scrollWdth}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              visibility: snapshot.isDropAnimating ? 'hidden' : 'visible',
              opacity: snapshot.isDragging ? '0.4' : '1',
            }}
            checked={checked?.some(check => check.id === data?.id)}
            mapVersion={mapVersion}
          >
            <CardBody>
              {mapVersion && (
                <div
                  style={{
                    position: 'absolute',
                    top: '6px',
                    right: '8px',
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}
                >
                  {orderStatusesList?.map(item => {
                    return (
                      item?.status === data?.status && (
                        <span style={{ alignSelf: 'flex-start' }}>
                          <StatusOrder
                            status={data?.status}
                            deliveredAt={deliveredAt}
                            minimal={true}
                            deliveryDistance={data?.deliveryInfo?.distance}
                          />
                        </span>
                      )
                    );
                  })}
                  <PushPin
                    onClick={handlePinLayer}
                    size={12}
                    weight="fill"
                    color={
                      pinned
                        ? themes?.default?.primary
                        : themes?.default?.lightGrey2
                    }
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
              )}
              <CardInfoContainer>
                {data?._deck?.deliveryHours && <label style={{marginTop: "6px"}}>{time}</label>}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <p>
                    <span className="clientCode">{data?._customer?.code}</span>
                    {` - ${data?._customer?.publicName}`}
                  </p>
                  {(data?.status?.toLowerCase() === 'delivered' ||
                    data?.status?.toLowerCase() === 'canceled') && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"
                      }}
                    >
                    </div>
                  )}
                </div>
                <p className="orderCode">
                  {t('confirmDelivery.order')}{' '}
                  <span
                    onClick={e => {
                      e?.stopPropagation();
                      handleDisplayOrder(
                        data?._order
                          ? {
                              ...data?._order,
                              priority: data?.priority || stop?.priority,
                            }
                          : {
                              ...data,
                              priority: data?.priority || stop?.priority,
                            },
                      );
                    }}
                    className="code"
                  >{`#${data?.code}`}</span>{' '}
                </p>
              </CardInfoContainer>
              <CardTagsContainer
                scrollWdth={scrollWdth}
                mapVersion={mapVersion}
              >
                <div className="container">
                  <div ref={myElementRef} className="content">
                    {data?._deck?.vehicleCondition &&
                      !!vehicleCondition(data)?.length && (
                        <div className="gray_value ">
                          {vehicleCondition(data)}
                        </div>
                      )}

                    <div className="gray_value">
                      {data?.totalWeight?.toFixed(2)} KG
                    </div>
                    <div className="gray_value">
                      {0} {`m${'\u00B3'}`}
                    </div>
                    <div className="gray_value">{data?.countLines} ITEMS</div>
                    <div
                      className="gray_value"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {stop?._order?.supportUnitsRequestedCapacity}{' '}
                      {t('home.support_units')}
                    </div>
                  </div>
                </div>
              </CardTagsContainer>
            </CardBody>
            <CardFooter>
              <div className="cardStatus">
                {orderStatusesList?.map(item => {
                  return (
                    item?.status === data?.status && (
                      <OrderStatusDot
                        tooltipTitle={t(`orders.status.${data?.status}`)}
                        circleColor={item?.color}
                        size={8}
                      />
                    )
                  );
                })}
                <div className="check">
                  <OrderPriority priority={data?.priority || stop?.priority} />
                </div>
                <StopIcon
                  type={data?.type}
                  orderCategory={data?.category}
                  tooltipMessage={t(
                    `common.${data?.type}` || 'roundtrips.tooltip.collection',
                  )}
                />
              </div>
              {mapVersion || hover ? (
                <div className="cardAction">
                  {!mapVersion && (
                    <>
                      <Tooltip title={t('roundtrip.locate_on_map')} arrow>
                        <Action onClick={handleLocationClick}>
                          <CrosshairSimple
                            size={13}
                            color={
                              mapOpen
                                ? themes?.default?.primary
                                : themes?.default?.iconColor
                            }
                          />
                        </Action>
                      </Tooltip>
                    </>
                  )}
                  {!!roundTripCopy?.length && (
                    <Tooltip title={t('orders.add_to_roundtrip')} arrow>
                      <Action
                        ref={assingToRoundtripBtnRef}
                        onClick={e => {
                          handleClickAddRoundTrip(e);
                          setSelectedStop(data);
                        }}
                      >
                        <ArrowSquareRight
                          size={13}
                          color={
                            !anchorEl_
                              ? themes?.default?.iconColor
                              : themes?.default?.primary
                          }
                        />
                      </Action>
                    </Tooltip>
                  )}
                  {mapVersion && rtStops?.length > 1 && (
                    <>
                      <Tooltip title={t('roundtrip.switch_stop')} arrow>
                        <Action
                          ref={switchStopBtnRef}
                          onClick={e => {
                            e?.stopPropagation();
                            setAnchorEl_switchStop(e.currentTarget);
                          }}
                        >
                          <ArrowsCounterClockwise
                            size={13}
                            color={
                              !anchorEl_switchStop
                                ? themes?.default?.iconColor
                                : themes?.default?.primary
                            }
                          />
                        </Action>
                      </Tooltip>
                      <Menu
                        open={!!anchorEl_switchStop}
                        anchorEl={anchorEl_switchStop}
                        onClose={(e: any) => {
                          e?.stopPropagation();
                          setAnchorEl_switchStop(null);
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <SwitchStopPopover
                          setAnchorEl_switchStop={setAnchorEl_switchStop}
                          roundTripCopy={roundTripCopy}
                          order={data}
                          currentRoundtrip={currentRt}
                          currentPosition={index}
                          rtId={rtId}
                          setAssignedOrders={setAssignedOrders}
                        />
                      </Menu>
                      <Tooltip title={t('roundtrip.change_position')} arrow>
                        <Action
                          ref={changePositionBtnRef}
                          onClick={e => {
                            e?.stopPropagation();
                            setAnchorEl_position(e.currentTarget);
                          }}
                        >
                          <HandPointing
                            size={13}
                            color={
                              !anchorEl_position
                                ? themes?.default?.iconColor
                                : themes?.default?.primary
                            }
                          />
                        </Action>
                      </Tooltip>
                      <Menu
                        open={!!anchorEl_position}
                        anchorEl={anchorEl_position}
                        onClose={(e: any) => {
                          e?.stopPropagation();
                          setAnchorEl_position(null);
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <ChangePositionPopover
                          setAnchorEl_position={setAnchorEl_position}
                          roundTripCopy={roundTripCopy}
                          order={data}
                          currentRoundtrip={currentRt}
                          currentPosition={index}
                          rtId={rtId}
                          setAssignedOrders={setAssignedOrders}
                        />
                      </Menu>
                    </>
                  )}
                  <If condition={!disableEdit}>
                    <ActionsStop
                      leftPosition={false}
                      setAnchorEl={setAnchorEl}
                      onDisplayOrder={onDisplayOrder}
                      typeEdit={true && !disableEdit}
                      dateValue={dateValue}
                      stop={stop || data}
                      setOpenDelete={setOpenDelete}
                      accordionStatus={accordionStatus}
                      setAccordionStatus={setAccordionStatus}
                      roundTripCopy={roundTripCopy}
                      selectedStop={selectedStop}
                      setSelectedStop={setSelectedStop}
                      addRoundTripOpen={addRoundTripOpen}
                      setAddRoundTripOpen={setAddRoundTripOpen}
                      statusChangeRoundTripOpen={statusChangeRoundTripOpen}
                      setStatusChangeRoundTripOpen={
                        setStatusChangeRoundTripOpen
                      }
                      orders={orders}
                      setOrders={setOrders}
                      updateOrders={updateOrders}
                      handleDelete={handleDelete}
                      openModal={openModal}
                      fromGescom={true}
                      setHover={setHover}
                      roundtrip={currentRt}
                    />
                  </If>
                </div>
              ) : null}
              <Menu
                open={!!anchorEl_}
                anchorEl={anchorEl_}
                onClose={handleAddRoundTripMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <OrderAssignPopover
                  addRoundTripOpen={addRoundTripOpen}
                  setAddRoundTripOpen={setAddRoundTripOpen}
                  roundTripCopy={roundTripCopy}
                  order={data}
                  deleteStop={() => {
                    if (!roundTripCopy[index] && updateOrders) {
                      updateOrders();
                    }
                    deleteFunctionStop(
                      deleteStop,
                      roundTripCopy[index],
                      selectedStop,
                    );
                  }}
                  currentRoundtrip={roundTripCopy}
                  accordionStatus={accordionStatus}
                  setAccordionStatus={setAccordionStatus}
                  currentPosition={index}
                  toggleAssignToRoundtrip={toggleAssignToRoundtrip}
                />
              </Menu>
            </CardFooter>
          </Data>
        )}
      </Draggable>
      <MapPopover
        open={mapOpen}
        handleClose={e => {
          setHover(false);
          handleCloseStopMap(setMapOpen);
        }}
        latitude={data?._deck?.location?.geometry?.coordinates[1]}
        longitude={data?._deck?.location?.geometry?.coordinates[0]}
        editMode={true}
      />
    </>
  );
};

const Data = styled.div<{
  scrollWdth: number;
  checked: boolean;
  mapVersion?: boolean;
}>`
  user-select: none;
  background-color: ${props =>
    props.checked
      ? themes?.default?.bgChecked
      : themes?.default?.accordionWhiteBg};
  width: ${props => (props.mapVersion ? '284px' : '234px')};
  padding: 8px;
  z-index: 2000;
  border: 1px solid
    ${props =>
      props.checked
        ? themes?.default?.checkedBorder
        : themes?.default?.lightGrey3};
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
  position: relative;

  .bottom_flex {
    display: flex;
    width: auto;
  }
  .client_name {
    overflow: overlay;
    font-size: 0.75rem;
    width: 166px;
    height: fit-content;
  }
  .data {
    display: flex;
    gap: 7px;
    width: max-content;
    max-height: 118px;
  }
  button {
    padding-top: 0px;
  }
  .gray_value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.06667px 3.83333px;
    width: fit-content;
    height: 23.13px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 3.06667px;
    font-size: 0.625rem;
    line-height: 17px;
    letter-spacing: 0.352667px;
  }
  .time {
    padding: 1px 10px;
    min-width: max-content;
    border: 1px solid ${themes?.default?.greyDisabled};
    border-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 18px;
  }
  * {
    user-select: none;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    height: 12px;
    font-size: 0.65rem;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
  }

  p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.17000000178813934px;
    color: ${themes?.default?.lightTextPrimary};
  }

  .clientCode {
    font-size: 0.75rem;
    font-weight: 700;
  }

  .orderCode {
    font-size: 0.625rem;
    font-weight: 500;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
  }

  .code {
    font-size: 0.625rem;
    font-weight: 700;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const CardTagsContainer = styled.div<{ scrollWdth; mapVersion }>`
  display: flex;
  flex-direction: column;
  gap: 7px;

  .container {
    overflow: hidden;
  }
  .content {
    display: flex;
    gap: 7px;
    flex-direction: row;
    width: max-content;
    transition: transform 0.7s;
  }
  .item {
    width: 50px;
    height: 50px;
    background-color: ${themes?.default?.veryLightGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container:hover .content {
    transform: translateX(
      ${props =>
        !props.mapVersion ? `-${props.scrollWdth}px` : `${props.scrollWdth}px`}
    );
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;

  .cardStatus,
  .cardAction {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 12px;
    max-height: 12px;
  }
`;

const Action = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const PopoverText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
