import styled from 'styled-components/macro';

export const A = styled.a`
  color: ${p => p.theme.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
  
  &.link {
    color: ${p => p.theme.text};

    &:hover {
      cursor: pointer;
      color: ${p => p.theme.primary};
      text-decoration: underline;
      opacity: 1;
    }

    &:active {
      opacity: 0.6;
    }
  }
`;
