
export function getInitials(organizationName: string) : string {
  if (!organizationName) {
    return '';
  }

  const words = organizationName?.split(' ');

  if (words.length === 1) {
    return words[0].substring(0, 2);
  }

  return words.map(word => word[0]).join('').toUpperCase();
}
