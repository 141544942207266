import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ResultDetails,
  TableNavRightIconsWrapper,
  TableNavTitleWrapper,
} from 'styles/components/roundTrips';
import { TableNavTitle } from 'styles/components/users';
import { TableViewPopover } from 'styles/components/muitable/TableViewPopover';
import {
  EXCEL,
  GRID,
  MAP,
  TRACK,
} from 'app/components/TableComponent/constants';
import BampteeLogo from 'assets/img/Menu/BampteeLogo.svg';
import { themes } from 'styles/theme/themes';
import dayjs from 'dayjs';
import { Icon } from 'app/components/Icon';
import { CalendarBlank, MapPin, Table } from '@phosphor-icons/react';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { StatusButton } from './components/StatusButton';
import { createContext, useCallback } from 'react';
import { ClusterStatus } from '../../data/status';

interface ContextProps {
  status: ClusterStatus;
  isToday: boolean;
  handleClick: Function;
  disabled: boolean;
  handleDelete: Function;
}

export const TableNavContext = createContext<ContextProps>({
  status: ClusterStatus.Active,
  isToday: false,
  handleClick: () => {},
  handleDelete: () => {},
  disabled: true,
});

export function TableNav({
  handleLogoClick,
  roundTripData,
  mode,
  dateValue,
  clusterId,
  warehouse,
  type,
  selected,
  setSelected,
  setHeadCells,
  stateHeadCellsConst,
  setType,
  setExpanded,
  excelHeadCellsConst,
  isToday,
  handleClickOpenUnSchedule,
  handleClickOpenSchedule,
  resultNbr,
  canSchedule,
  handleDelete,
}) {
  // hooks
  const { t } = useTranslation();
  const isBefore = dayjs(dateValue).isBefore(dayjs().startOf('day'));
  const dividerSx = { height: '22px', color: themes.default.grey3 };

  const handleStatusButtonClick = useCallback(() => {
    if (roundTripData?.status === ClusterStatus.Active) {
      handleClickOpenUnSchedule();
    } else {
      handleClickOpenSchedule();
    }
  }, [
    roundTripData?.status,
    handleClickOpenSchedule,
    handleClickOpenUnSchedule,
  ]);

  return (
    <div className="table-nav">
      <TableNavTitleWrapper>
        <div
          onClick={() => handleLogoClick()}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          <img src={BampteeLogo} alt="Bamptee" />
        </div>
        <TableNavTitle>{t('roundtrips.nav.planning')}</TableNavTitle>
        <Chip
          label={t('roundtrips.nav.edit_mode')}
          size="small"
          variant="outlined"
          sx={{
            marginLeft: '0.75rem',
            color: themes?.default?.grey2,
          }}
        />
      </TableNavTitleWrapper>
      <ResultDetails>
        <Tooltip
          title={t('roundtrips.nav.result_number_is', {
            number: resultNbr ?? 1,
          })}
          arrow
        >
          <div>
            {t('roundtrips.nav.result_number')} <span>{resultNbr ?? 1}</span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={
            mode === 'automatic'
              ? t('roundtrips.nav.optimized_by', { mode: 'API' })
              : t(`cluster.mode.${mode}`)
          }
          arrow
        >
          <div>
            {mode === 'automatic'
              ? `${t('common.by')} API`
              : t(`cluster.mode.${mode}`)}
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={t('roundtrips.nav.created_for', {
            date: `${dateValue?.['$D']}/${dateValue?.['$M'] + 1}/${
              dateValue?.['$y']
            }`,
          })}
          arrow
        >
          <div>
            <Icon icon={<CalendarBlank />} />
            <span>{`${dateValue?.['$D']}/${dateValue?.['$M'] + 1}`}</span>
          </div>
        </Tooltip>
        <VerticalDivider sx={{ ...dividerSx }} />
        <Tooltip
          title={t('roundtrips.nav.created_for', {
            date: warehouse?._id?.name ?? warehouse?.name,
          })}
          arrow
        >
          <div>
            <Icon icon={<MapPin />} />
            <span>{warehouse?._id?.shortName ?? warehouse?.shortName}</span>
          </div>
        </Tooltip>
      </ResultDetails>
      <TableNavRightIconsWrapper>
        <TableViewPopover
          views={[
            {
              type: MAP,
              icon: <MapPin size={16} color={themes?.default?.iconColor} />,
              label: `${t('map')}`,
            },
            {
              type: GRID,
              icon: <Table size={16} color={themes?.default?.iconColor} />,
              label: `${t('grid')}`,
            },
          ]}
          tableType={type}
          handleAction={(e, action) => {
            const selectedCopy = { ...selected };
            let objectKeys = Object.keys(selectedCopy);
            for (let i = 0; i < objectKeys.length; i++) {
              selectedCopy[objectKeys[i]] = [];
            }
            setSelected(selectedCopy);
            if (action === MAP) {
              setHeadCells(stateHeadCellsConst);
              setType(MAP);
              setExpanded({});
            }
            if (action === GRID) {
              setHeadCells(excelHeadCellsConst);
              setType(GRID);
              // setExpanded({});
            }

            if (action === TRACK) {
              setHeadCells(stateHeadCellsConst);
              setType(TRACK);
              setExpanded({});
            }
            if (action === EXCEL) {
              setHeadCells(excelHeadCellsConst);
              setType(EXCEL);
              setExpanded({});
            }
          }}
        />
        <TableNavContext.Provider
          value={{
            status: roundTripData?.status,
            isToday,
            handleClick: handleStatusButtonClick,
            disabled: isBefore || !clusterId,
            handleDelete,
          }}
        >
          <StatusButton />
        </TableNavContext.Provider>
      </TableNavRightIconsWrapper>
    </div>
  );
}
