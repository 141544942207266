import { Order } from '../types';

export function descendingComparator(a, b, orderBy) {
  if (a?.[orderBy]?.sort) {
    if (b[orderBy].sort < a[orderBy].sort) {
      return -1;
    }
    if (b[orderBy].sort > a[orderBy].sort) {
      return 1;
    }
  }
  if (b?.[orderBy]?.value < a?.[orderBy]?.value) {
    return -1;
  }
  if (b?.[orderBy]?.value > a?.[orderBy]?.value) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array: readonly [], comparator: (a, b) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0].data, b[0].data);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const getAccordionGroupInex = (rows, group) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i]?.key === group) return i;
  }
  return 0;
};
export const handleClick = (
  event: React.MouseEvent<unknown>,
  name: string,
  selected,
  setSelected,
) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected: readonly string[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  setSelected(newSelected);
};

export const handleClickAccordionEl = (
  group: string,
  name: string,
  selected,
  setSelected,
) => {
  const selectedIndex = selected[group].indexOf(name);
  let newSelected: readonly any[] = [];

  newSelected = { ...selected };

  if (selectedIndex === -1) {
    newSelected[group].push(name);
  } else {
    newSelected[group].splice(selectedIndex, 1);
  }

  setSelected(newSelected);
};

export const cloneSelectedArray = selected => {
  let selectedCopy = {};
  for (let i = 0; i < Object.keys(selected).length; i++) {
    selectedCopy[Object.keys(selected)[i]] = selected[Object.keys(selected)[i]];
  }
  return selectedCopy;
};

export const handleSelectAllClickRegular = (
  event: React.ChangeEvent<HTMLInputElement>,
  rows: { data }[],
  setSelected,
) => {
  if (event.target.checked) {
    const newSelected = rows.map(n => n.data.id.value);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
  return;
};
export const numberOfEl = rows => {
  let sum = 0;
  for (let i = 0; i < rows?.length; i++) {
    sum += rows[i]?.data?.length;
  }
  return sum;
};

export const indeterminateTable = (selected, rows) => {
  if (Object.keys(selected)?.length === 0 || selected?.length === 0)
    return false;
  if (checkedTable(selected, rows)) return false;
  if (selected?.length === 0) return false;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].data?.length === selected[rows[i].key]?.length) {
      return true;
    }
  }
  return false;
};

export const checkedTable = (selected, rows) => {
  if (Object.keys(selected)?.length === 0 || selected?.length === 0)
    return false;
  if (selected?.length === 0) return false;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].data.length > selected[rows[i].key]?.length) {
      return false;
    }
  }
  return true;
};

export const sortDirection = (direction, setDirection) => {
  if (direction === '-') setDirection('+');
  else setDirection('-');
};
