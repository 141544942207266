import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface StopIndexProps {
  type: string;
  index: number;
}

export default function StopIndexText({ type, index }: StopIndexProps) {
  const { t } = useTranslation();

  let stopIndexText = `${t('common.stop')} ${index + 1}`;

  if (type === 'fuel') {
    stopIndexText = t('fuel');
  } else if (type === 'maintenance') {
    stopIndexText = t('maintenance');
  }

  return <StopText>{stopIndexText}</StopText>;
}

const StopText = styled.div`
  margin-left: 4px;
  font-weight: 700;
`;
