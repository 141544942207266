import {
  CustomerInvitationWrapper,
  InvitationButton,
} from 'hooks/Customers/useFormatCustomersData';
import { useTranslation } from 'react-i18next';
import {
  MarkunreadMailboxOutlined,
  PersonAddAlt1Outlined,
} from '@mui/icons-material';
import {
  useCancelInvitationMutation,
  useInviteUserMutation,
} from 'common/services/userApi';
import { useToaster } from 'hooks/useToaster';
import _ from 'lodash';
import { Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useModal } from '../Dialog/hooks';
import { timeZoneDate } from 'common/utils/dates';
import moment from 'moment';

export interface EmployeeStatusProps {
  toInvite?: boolean;
  invitationSent?: boolean;
  canceled?: boolean;
  isActive: boolean;
  role: string;
  email?: string;
  id?: string;
  onInvite?: () => void;
  onCancelInvite?: () => void;
  lastViewAt: string;
}

const CancelButton = styled.div`
  color: ${themes.default.chipRedBg};
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 0.85rem;
  line-height: 24px;
  letter-spacing: 0.17px;
`;

const EmployeeStatus: React.FC<EmployeeStatusProps> = ({
  toInvite,
  invitationSent,
  canceled,
  isActive,
  role,
  email,
  id,
  onInvite,
  onCancelInvite,
  lastViewAt,
}) => {
  const [invite, setInvite] = useState(toInvite);
  const [sent, setSent] = useState(invitationSent);
  const [hoverEl, setHoverEl] = useState<any>();

  const showCancelInvite = useMemo(() => {
    if (!sent) {
      return false;
    }
    return !!hoverEl && sent;
  }, [sent, hoverEl]);

  const { t } = useTranslation();
  const toast = useToaster();
  const [inviteUser] = useInviteUserMutation();
  const [cancelInvite] = useCancelInvitationMutation();
  const { openModal, closeModal } = useModal();

  async function handleCancelInvitation() {
    try {
      await cancelInvite(id).unwrap();
      setSent(false);
      setInvite(true);
      onCancelInvite && onCancelInvite();
      toast(3000, 'success', t('user.invite.cancel.success'));
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      }
    }
    closeModal();
  }

  function onCancelModal() {
    openModal({
      title: t('user.invite.cancel.title'),
      content: t('user.invite.cancel.message'),
      cancel: true,
      action: {
        actionCallback: handleCancelInvitation,
        actionText: t('common.buttons.ok'),
      },
    });
  }

  const inviteEmployee = async ({
    role,
    email,
  }: {
    role: string;
    email?: string;
  }) => {
    try {
      if (!email) {
        return toast(3000, 'error', 'customer.invitation.missingEmail');
      }
      const response = await inviteUser({
        role,
        email,
      }).unwrap();

      if (!_.isEmpty(response)) {
        toast(3000, 'success', 'user_invited_successfully');
      }

      setSent(true);
      setInvite(false);
      onInvite && onInvite();
    } catch (e: any) {
      if (e.status === 400) {
        toast(3000, 'error', e.data?.message);
      }
    }
  };

  return (
    <>
      {invite && (
        <InvitationButton
          variant="contained"
          startIcon={
            <PersonAddAlt1Outlined sx={{ width: '12px', height: '12px' }} />
          }
          onClick={() => {
            inviteEmployee({ email, role });
          }}
        >
          {t('customer.invite')}
        </InvitationButton>
      )}

      {sent && (
        <>
          {showCancelInvite ? (
            <CancelButton
              onMouseLeave={() => setHoverEl(null)}
              onClick={onCancelModal}
            >
              {t('common.cancel_invitation')}
            </CancelButton>
          ) : (
            <CustomerInvitationWrapper onMouseEnter={e => setHoverEl(e)}>
              <MarkunreadMailboxOutlined
                sx={{ width: '12px', height: '12px' }}
              />
              <div>{t('customer.invite_sent')}</div>
            </CustomerInvitationWrapper>
          )}
        </>
      )}

      {isActive && (
        <div
          style={{
            color: themes.default.fruitSaladGreen,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <CheckIcon style={{ fontSize: 16 }} />
          {moment(new Date()).subtract(1, 'days').format('YYYY/MM/DD') ===
          moment(new Date(lastViewAt)).format('YYYY/MM/DD')
            ? 'Yesterday'
            : moment(new Date()).format('YYYY/MM/DD') ===
              moment(new Date(lastViewAt)).format('YYYY/MM/DD')
            ? 'Today'
            : moment(new Date(lastViewAt)).format('YYYY/MM/DD')}
        </div>
      )}

      {/* {canceled && (
        <Chip
          icon={<CloseIcon />}
          label={t('common.lables.cancelled')}
          variant="outlined"
          color={'error'}
          sx={{ height: 'auto' }}
        />
      )} */}
    </>
  );
};

export default EmployeeStatus;
