import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'pageview_spa',
        page_path: location.pathname + location.search,
        page_location: window.location.href,
        page_title: document.title,
      });
    }
  }, [location]);
}

function PageViewTracker() {
  usePageViews();
  return null; // This component does not render anything
}

export default PageViewTracker;
