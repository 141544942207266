import { FetchArgs, createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react';
import { ENV } from '../constants';
import { RootState } from '../types/State';
import { appActions } from '../../common/store/app';
import i18next from 'i18next';

export const apiTags = [
  'AuthToken',
  'AuthUser',
  'Users',
  'Organization',
  'OrganizationConfiguration',
  'OrganizationActivities',
  'Roundtrips',
  'Vehicles',
  'Places',
  'Activities',
  'RoundtripByAgent',
  'StatsOrders',
  'Files',
  'Orders',
  'OrderActivity',
  'Conversations',
  'RoundtripsStats',
  'RoundtripDetails',
  'Templates',
  'RoundtripsCalendar',
  'Customer',
  'Customers',
  'Products',
  'Calls',
];

const customBackOff = async (attempt = 0, maxRetries = 5) => {
  const attempts = Math.min(attempt, maxRetries);

  let delay = 400;

  if (attempt > 600) {
    delay = 2000;
  } else if (attempt > 5) {
    delay = 1000;
  }

  await new Promise(resolve => {
    setTimeout(resolve, attempts * delay);
  });
};

export function configureApi(baseUrl: string | undefined) {
  return createApi({
    reducerPath: 'api',
    baseQuery: retry(
      async (args: any, api, extraOptions) => {
        const authUser = (api.getState() as RootState).auth?.user;
        const token = (api.getState() as RootState).auth?.token;

        if (authUser) {
          if (typeof args === 'object') {
            if (args.url.indexOf('?') > -1) {
              args.url = `${args.url}&authOrganization=${authUser?._currentOrganization?._id}`;
            } else {
              args.url = `${args.url}?authOrganization=${authUser?._currentOrganization?._id}`;
            }
          } else if (typeof args === 'string') {
            if (args.indexOf('?') > -1) {
              args = `${args}&authOrganization=${authUser?._currentOrganization?._id}`;
            } else {
              args = `${args}?authOrganization=${authUser?._currentOrganization?._id}`;
            }
          }
        }

        const result = await fetchBaseQuery({
          baseUrl: baseUrl,
          prepareHeaders: headers => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            if (token) {
              headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
          },
        })(args, api, extraOptions);

        if (result.error && result.error.status === 400) {
          //@ts-ignore
          if (appActions && result.error.data.message) {
            api.dispatch(
              appActions.setLastToasterNotification({
                duration: 3000,
                severity: 'error',
                //@ts-ignore
                message: i18next.t(result.error.data.message),
              }),
            );
          }
        }

        // bail out of re-tries immediately if unauthorized,
        // because we know successive re-retries would be redundant
        if (result.error?.status && result.error?.status !== 'FETCH_ERROR') {
          retry.fail(result.error);
        }

        return result;
      },
      {
        backoff: customBackOff,
        maxRetries: 1,
      },
    ),
    tagTypes: apiTags,
    endpoints: builder => ({
      ping: builder.query<any, void>({
        query: () => ({
          url: '/',
        }),
        extraOptions: {
          refetchOnMountOrArgChange: true,
        },
      }),
    }),
  });
}

const api = configureApi(ENV.API_URL);
export const { usePingQuery } = api;
export default api;
