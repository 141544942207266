import NoRoundtrip from 'assets/img/roundTrips/no-data-roundtrip.svg';
import { IconButtonComponent } from 'app/components/IconButtonComponent';

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Text } from 'app/pages/AddRoundTrips/components/RightBarNoData';
import { themes } from 'styles/theme/themes';
interface Props {
  Icon?: string;
  open?: any;
  handleClick?: any;
  text: string;
  IconButton: any;
  textButton: string;
  imgStyle?: any;
  viewMode?: any;
}
export const NoDataComponents: React.FC<Props> = ({
  Icon,
  open,
  handleClick,
  text,
  IconButton,
  textButton,
  imgStyle,
  viewMode,
}) => {
  return (
    <>
      <img
        src={Icon ? Icon : NoRoundtrip}
        alt=""
        style={imgStyle ? imgStyle : {}}
      />
      <Text style={{ padding: '5px' }}>{text}</Text>
      {!viewMode && (
        <IconButtonComponent
          iconComponent={IconButton}
          size="small"
          style={{
            fontWeight: '500',
            margin: '0 auto',
          }}
          text={textButton}
          iconStyle={{ color: themes?.default?.accordionWhiteBg }}
          variant="contained"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          invert={true}
          aria-label="add_roundtrip"
          sx={{ width: '100%' }}
        />
      )}
    </>
  );
};
