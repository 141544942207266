import { Navigate } from 'react-router-dom';
import { LOGOUT } from './routes';

export interface ErrorType {
  status: number;
  data: {};
}
export function jwtCheckExpire(error: ErrorType) {
  if ((error as ErrorType)?.status === 401) {
    return <Navigate to={LOGOUT} />;
  }
}

export default jwtCheckExpire;
