import styled from 'styled-components';
import { Button, Popover } from '@mui/material';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { PopUpContainerFilter } from './filters';
import { TitleSorting } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusChangePopover';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';

export const Sorting = ({
  openSort,
  id,
  sortingOn,
  handleClose,
  setSorting,
  sorting,
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const [sortDirection, setSortDirection] = useState<string>('')
  const [sortValue, setSortValue] = useState<string>('deliveryDate')

  const sortValues = [{ value: 'cache._customer.publicName', label: 'name' }, { value: 'deliveryDate', label: 'date' }, { value: 'cache._customer._salesman.lastName', label: 'staff_title' }]

  useEffect(() => {
    setSorting({
      value: sortValue,
      direction: sortDirection
    })
  }, [sortDirection, sortValue])

  return (
    <Popover
      id={id}
      open={openSort}
      anchorEl={sortingOn}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PopUpContainerFilter width={'187px'}>
        <SortingTitle>{t('common.sort_by')}</SortingTitle>
        <div className="button_icon">
          <ArrowUpwardOutlinedIcon
            sx={{
              color:
                sortDirection === ''
                  ? theme?.primaryActiveColor
                  : `${themes?.default?.black}DE`,
              width: '1rem',
              height: '1rem',
              mr: 1,
            }}
          />
          <SortingLabel
            style={{
              color:
                sortDirection === ''
                  ? theme?.primaryActiveColor
                  : `${themes?.default?.black}DE`,
            }}
            onClick={() => setSortDirection('')}
          >
            {t('common.ascending')}
          </SortingLabel>
        </div>
        <div className="button_icon">
          <ArrowUpwardOutlinedIcon
            sx={{
              color:
                sortDirection === '-'
                  ? theme?.primaryActiveColor
                  : `${themes?.default?.black}DE`,
              rotate: '180deg',
              width: '1rem',
              height: '1rem',
              mr: 1,
            }}
          />
          <SortingLabel
            style={{
              color:
                sortDirection === '-'
                  ? theme?.primaryActiveColor
                  : `${themes?.default?.black}DE`,
            }}
            onClick={() => setSortDirection('-')}
          >
            {t('common.descending')}
          </SortingLabel>
        </div>
        <SeperatorRow />
        {
          sortValues.map(option => {
            return (
              <div className="button_icon">
                <SortingLabel
                  style={{
                    color:
                      sorting.value === option.value
                        ? theme?.primaryActiveColor
                        : `${themes?.default?.black}DE`,
                  }}
                  onClick={() => {
                    if (sorting.value !== option.value) {
                      setSortValue(option.value)
                    }
                    else {
                      setSortValue('')
                    }
                  }
                  }
                >
                  <CheckIcon
                    sx={{
                      mr: 1,
                      width: '1rem',
                      height: '1rem',
                      visibility: (sorting.value === option.value) ? 'visible' : 'hidden'
                    }}
                  />
                  {t(`common.${option.label}`)}
                </SortingLabel>
              </div >
            )
          })}
      </PopUpContainerFilter >
    </Popover >
  );
};

const SeperatorRow = styled.div`
  background: ${props => props.theme.seperatorBg};
  width: 171px;
  height: 1px;
`

const SortingTitle = styled.div`
    color: ${props => props.theme.black};
    margin-bottom: 10px;
    font-weight: 500;
`

const SortingLabel = styled.div`
  font-size: 0.875rem;
  width: 100%;
  cursor: pointer;
  padding: 8px 0;
`
