import styled from 'styled-components';

export function Title() {
  return (
    <Logo>
      <img src="https://bamptee-cdn.s3.eu-west-3.amazonaws.com/assets/bamptee-white.png" alt="Bamptee" />
      {/* Bamptee */}
    </Logo>
  );
}

const Logo = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  img {
    max-width: 160px;
  }
  /* font-size: 2.07rem;
  font-weight: 600;
  letter-spacing: 5.8px;
  color: #fff; */
`;
