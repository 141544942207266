import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { days } from './data/days';
import moment from 'moment';
import { Checkbox } from 'app/components/CustomCheckbox';

export function TemplateSaveModal({ handleClose, open, onSaveTemplate }) {
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [checkboxes, setCheckboxes] = useState<any>([]);

  const { t } = useTranslation();

  const handleCheckboxChange = (day: string) => {
    if (checkboxes.includes(day)) {
      setCheckboxes(checkboxes.filter((d: string) => d !== day));
    } else {
      setCheckboxes([...checkboxes, day]);
    }
  };

  ///Init today day for default selected checkbox
  function getTodayDate() {
    return moment().format('ddd');
  }

  useEffect(() => {
    setCheckboxes([getTodayDate()]);
  }, []);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();

    if (name) {
      // Convert the selected days to full names in lowercase
      const selectedFullDays = checkboxes.map((checkbox: string) => {
        const selectedDay = days.find(day => day.abbreviation === checkbox);
        return selectedDay ? selectedDay.full : '';
      });

      const formData = {
        name,
        note,
        days: selectedFullDays,
      };

      onSaveTemplate(formData);
      handleClose(true);
      setName('');
      setCheckboxes([]);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
          color: 'gray',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        fontSize="1.25"
        fontWeight="500"
        sx={{
          maxWidth: '472px',
        }}
      >
        {t('orders.actions.save_as_template')}
      </DialogTitle>
      <DialogContent>
        <Alert
          severity="warning"
          sx={{
            maxWidth: '432px',
          }}
        >
          {t('roundtrip.templates.modal.alert')}
        </Alert>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            id="template-name"
            label={t('orders.template.input.name')}
            placeholder={t('orders.template.input.name_placeholder')}
            onChange={e => setName(e.target.value)}
            required
            variant="outlined"
            fullWidth
            sx={{
              mt: '16px',
            }}
          />
          <TextField
            id="template-note"
            label={t('orders.template.input.note')}
            placeholder={t('orders.template.input.note_placeholder')}
            onChange={e => setNote(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={2.5}
          />
          <div>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '1.143rem',
                fontWeight: 400,
                lineHeight: '28px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              {t('orders.template.input.set_for')}
            </Typography>
            <div
              style={{
                width: '424px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {days.map(day => (
                <FormControlLabel
                  sx={{
                    backgroundColor: checkboxes.includes(day.abbreviation)
                      ? '#E5EBFF'
                      : '',
                    borderRadius: '4px',
                    marginRight: '15px',
                    mb: '12px',
                    width: '91px',
                    marginLeft: '0px',
                  }}
                  key={day.abbreviation}
                  control={
                    <Checkbox
                      checked={checkboxes.includes(day.abbreviation)}
                      onChange={() => handleCheckboxChange(day.abbreviation)}
                    />
                  }
                  label={t(day.abbreviation)}
                />
              ))}
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
          borderTop: 'solid 1px #0000001F',
        }}
      >
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={name === ''}
        >
          {t('orders.actions.save_as_template')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
