import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';
import If from 'app/components/If';

interface SupportSelectorProps {
  value?: any;
  supportUnits?: any;
  supportArrayCopy: any;
  setSupportArrayCopy: any;
  canEdit: boolean;
}

function SupportSelector({
  value,
  supportUnits,
  supportArrayCopy,
  setSupportArrayCopy,
  canEdit,
}: SupportSelectorProps) {
  const [supportValue, setSupportValue] = useState(value?.quantity || 0);

  useEffect(() => {
    const foundIndex = supportArrayCopy?.findIndex(
      support => support?._supportUnit?._id === value?._supportUnit?._id,
    );
    const newSupportArrayCopy = [...supportArrayCopy];

    if (foundIndex !== -1) {
      newSupportArrayCopy[foundIndex] = {
        ...newSupportArrayCopy[foundIndex],
        quantity: supportValue,
      };
      setSupportArrayCopy(newSupportArrayCopy);
    }
  }, [supportValue]);

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 0 || isNaN(newValue)) {
      newValue = 0;
    }
    setSupportValue(newValue);
  };

  const configurationSelector = useSelector(selectConfiguration);
  const supportUnitsList = configurationSelector?.supportUnitsList;

  const getListForOption = useCallback(
    (optionId: string) => {
      return (
        supportUnitsList.filter(
          obj1 =>
            !supportArrayCopy.some(
              obj2 => obj1._id === obj2._supportUnit?._id,
            ) || obj1._id === optionId,
        ) || []
      );
    },
    [supportUnitsList, supportArrayCopy],
  );

  const configurationProps = {
    getOptionLabel: (option: any) => {
      return option?.name || '';
    },
  };
  return (
    <>
      <>
        <p className="title">{value?._supportUnit?.name || ''}</p>
        <If
          condition={canEdit}
          otherwise={<p className="value">{value?.quantity || 0}</p>}
        >
          <TextField
            defaultValue={value?.quantity ?? 0}
            onChange={handleChangeTextField}
            inputProps={{
              min: 0,
              style: { textAlign: 'center', padding: ' 2px 1px' },
            }}
            name="numberformat"
            id="formatted-numberformat-input"
            variant="outlined"
            sx={{
              width: '20px',
              textAlign: 'center',
              marginTop: '16px',
            }}
          />
        </If>
      </>
    </>
  );
}

export default SupportSelector;

export const Dropdown = styled.div`
  align-items: center;
  justify-content: space-between;
  .MuiButtonBase-root {
    padding-top: 1px;
  }
  width: 220px;
  display: flex;
  gap: 30px;
`;
