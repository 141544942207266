import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import React from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  description1: string;
  description2?: string;
  close_text: string;
  handleClick?: () => void;
  action?: any;
  color:
    | 'primary'
    | 'success'
    | 'inherit'
    | 'error'
    | 'secondary'
    | 'info'
    | 'warning'
    | undefined;
  action_text: string;
}

/**
 * @deprecated this dialog is deprecated and should not used, please use the new useModal/useConfirm custom hooks instead
 * To be replaced by generic dialog and custom hooks
 */
export const Dialogue: React.FC<Props> = props => {
  const {
    open,
    handleClose,
    title,
    description1,
    description2,
    close_text,
    handleClick,
    action,
    color,
    action_text,
  } = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ fontSize: '1.25rem', width: '444px' }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description1}
          </DialogContentText>
          {description2 && (
            <DialogContentText id="alert-dialog-description">
              {description2}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: 'black',
            }}
            onClick={e => {
              handleClose();
            }}
          >
            {close_text}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              if (handleClick) handleClick();
              action();
            }}
            color={color}
            autoFocus
          >
            {action_text}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
