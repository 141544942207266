import { useEffect } from 'react';
import { Box, Grid, Pagination, Stack, Typography } from '@mui/material';
import {
  useLazyGetCustomerNewDocumentsQuery,
  useVerifyDocumentMutation,
} from 'common/services/customerApi';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import emptyState from '../../../../../assets/img/customers/no-customers.svg';
import ModalPDFViewer from 'app/components/ModalPDFViewer';
import RefuseDocumentModal from '../RefuseDocModal';
import { TableDoc } from '../../TableDoc';
import If from 'app/components/If';

export function NotTreatedDoc({ search, setSearch }) {
  const [verifyDocument] = useVerifyDocumentMutation();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [doc, setDoc] = useState<any>(null);
  const [docCgv, setDocCgv] = useState<any>(null);
  const [refuseDocument, setRefuseDocument] = useState<any>(null);

  const [triggerGetDocuments, { data: newDocuments }] =
    useLazyGetCustomerNewDocumentsQuery();
  const debouncedSearchTerm = useDebounce(search, 300);

  const handleChangeOrderPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    triggerGetDocuments(`page=${page}&text=${search}&limit=20`);
  }, [debouncedSearchTerm, page]);

  const handleVerifyDocument = async (
    customerId: string,
    documentId: string,
    action: string,
    reason?: string,
  ) => {
    await verifyDocument({
      id: customerId,
      documentId,
      action,
      reason,
    }).unwrap();

    triggerGetDocuments(`page=${page}`);
  };

  const handleOpenRefuseModal = (customerId, documentId) => {
    setRefuseDocument({ customerId, documentId });
  };

  const handleRefuseDocument = async reason => {
    await handleVerifyDocument(
      refuseDocument.customerId,
      refuseDocument.documentId,
      'refuse',
      reason,
    );

    setRefuseDocument(null);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12}>
          <If
            condition={newDocuments?.docs?.length > 0}
            otherwise={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 140px)',
                }}
              >
                <img src={emptyState} alt="empty state" />
                <Typography>{t('checkDoc.emptyState')}</Typography>
              </Box>
            }
          >
            <TableDoc
              treated={false}
              data={newDocuments}
              setDoc={setDoc}
              setDocCgv={setDocCgv}
              handleVerifyDocument={handleVerifyDocument}
              handleOpenRefuseModal={handleOpenRefuseModal}
            />

            <Stack spacing={2} sx={{ marginTop: 2 }}>
              <Pagination
                color="primary"
                onChange={handleChangeOrderPagination}
                page={page}
                count={newDocuments?.totalPages || 0}
                shape="rounded"
              />
            </Stack>
          </If>

          <ModalPDFViewer
            open={doc !== null}
            url={doc?.url}
            url2={doc?.url2}
            fileName={doc?.name}
            onClose={() => setDoc(null)}
          />
          <ModalPDFViewer
            open={docCgv !== null}
            url={docCgv}
            fileName={'CGV'}
            onClose={() => setDocCgv(null)}
          />
        </Grid>
      </Grid>

      <RefuseDocumentModal
        isVisible={refuseDocument !== null}
        onRefuse={handleRefuseDocument}
        onClose={() => setRefuseDocument(null)}
      />
    </>
  );
}
