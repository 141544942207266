import { useSelector } from 'react-redux';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { useToaster } from 'hooks/useToaster';
import { useState } from 'react';
import { useLoadingAlert } from 'app/components/LoadingAlert/hooks';

export const useExportCSV = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const authToken = useSelector(selectAuthToken);
  const toast = useToaster();
  const { showLoading, hideLoading } = useLoadingAlert();

  const [loadingCSV, setLoadingCSV] = useState(false);

  async function triggerExport(queryParams: string, fileName: string) {
    setLoadingCSV(true);
    showLoading({ message: 'loading_csv' });
    await fetch(
      `${baseUrl || 'https://api-dev.bamptee.com'}/${queryParams}&format=csv`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    )
      .then(async response => {
        setLoadingCSV(false);
        hideLoading();
        const utf8BOM = '\uFEFF';
        const csv = await response.text();
        const blob = new Blob([utf8BOM + csv], {
          type: 'text/csv;charset=utf-8;',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.csv`;
        a.click();
        URL.revokeObjectURL(url);

        toast(5000, 'success', 'csv_export_success');
      })
      .catch(e => {
        setLoadingCSV(false);
        hideLoading();
        toast(5000, 'error', e.message);
      });
  }

  return { loadingCSV, triggerExport };
};