import { Fragment, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useUpdateOrdersDateMutation } from 'common/services/orderApi';
import moment from 'moment';
import NoOrder from 'assets/img/empty-data.svg';
import CloseIcon from '@mui/icons-material/Close';
import {
  CenterDiv,
  DialogWrapper,
  Flex,
  Item,
  LeftSide,
  RightSide,
  Seperator,
} from './style';
import InfiniteScroll from 'react-infinite-scroll-component';
import ItemComponent from './Components/item_component';
import { useTranslation } from 'react-i18next';
import {
  useAddOrderClusterMutation,
  useGetRoundTripsClusterPostMutation,
} from 'common/services/roundtripApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { themes } from 'styles/theme/themes';
import OrderFilter from 'app/components/OrderFilter';
import { useQueryParams } from 'hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import styled from 'styled-components';
import { getOrderFilterCount } from 'app/helpers/helpers';
import { DialogActionsComponent } from 'app/components/DialogActionsComponent';
import DropdownDatePicker from 'app/components/DatePicker/DropdownDatePicker';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
export default function AddDialog({
  open,
  setOpen,
  orders,
  requestAddRoundTripData,
  clusterId,
  userContext,
}) {
  const tomorrow = dayjs().add(1, 'day').startOf('day');
  const today = dayjs();

  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const [roundTripsCluster] = useGetRoundTripsClusterPostMutation();
  const [searchText, setSearchText] = useState<string>('');
  const [dateBegin, setDateBegin] = useState<string>(
    tomorrow.format('YYYY-MM-DD'),
  );
  const [dateEnd, setDateEnd] = useState<string>(tomorrow.format('YYYY-MM-DD'));
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [checked, setChecked] = useState<{ [id: string]: boolean }>({});
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [lengthData, setLengthData] = useState(0);

  const [filters, setFilters] = useState<{
    priority: { value: string; label: string }[];
    status: { value: string; label: string }[];
    deliveryType: { value: string; label: string }[];
    truckType: { value: string; label: string }[];
  }>({
    priority: [],
    truckType: [],
    status: [],
    deliveryType: [],
  });

  const { filterOn, handleCloseFilter, handleOpenFilter, filtersCount } =
    useFilterState({ filters });

  const [dataItems, setDataItems] = useState<
    {
      id: string;
      priority: string;
      type: string;
      status: string;
      totalWeight: number;
      countProducts: number;
      supportUnits: { pallets: number; rolls: number; vrac: number };
    }[]
  >([]);

  useEffect(() => {
    dataItems.map(dataItem => {
      const itemId = dataItem.id;
      const foundCheckedItem = checkedItems?.find(item => item.id === itemId);

      if (checked[itemId] && !foundCheckedItem) {
        setCheckedItems(prevState => {
          return [...prevState, dataItem];
        });
      } else if (!checked[itemId]) {
        setCheckedItems(prevState => {
          return [...prevState?.filter(item => item.id !== itemId)];
        });
      }
    });
  }, [dataItems, checked]);

  let debouncedSearchTerm = useDebounce(searchText, 500);
  let debouncedFilters = useDebounce(filters, 1000);

  const FunctionRequestAddRoundTripData = async query => {
    try {
      if (dateBegin !== '') {
        const resp = await roundTripsCluster({
          pagerows: query,
          excludeIds: orders,
        }).unwrap();
        if (resp) {
          setData(resp);
        }
      }
    } catch (e: any) {
      console.log('e', e);
      if (e.status === 401) {
        console.log('e:401', e);
      }
    }
  };

  const queryParams = useQueryParams({
    limit: 5,
    beginAt: dateBegin,
    endAt: dateEnd,
    text: debouncedSearchTerm,
    priority: debouncedFilters?.priority
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    status: debouncedFilters?.status
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    type: debouncedFilters?.deliveryType
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
    vehicleCondition: debouncedFilters?.truckType
      ?.map(filter => {
        return filter.value;
      })
      ?.join(','),
  });
  const fetchData = pageNumber => {
    let query = `/${clusterId}/orders-available${queryParams}&page=${pageNumber}&agentCategory=${userContext}&application=bianta`;

    FunctionRequestAddRoundTripData(query);
  };

  const [dateValue, setDateValue] = useState<Dayjs | null>(tomorrow);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [parentChecked, setParentChecked] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    if (!dateValue?.isBefore(today.startOf('day'), 'day') && open) {
      fetchData(1);
    }
  }, [dateBegin, debouncedSearchTerm, debouncedFilters, open]);

  useEffect(() => {
    if (data?.docs?.[0]) {
      if (data?.page === 1) {
        setDataItems(data.docs);
      } else
        setDataItems(oldData => {
          const newData = data.docs.filter(
            item => !oldData.some(oldItem => oldItem.id === item.id),
          );
          return [...oldData, ...newData];
        });
      setPage(data?.page);
      setNextPage(data?.hasNextPage);
      setLengthData(data?.totalDocs);
    } else {
      setLengthData(0);
      setDataItems([]);
    }
  }, [data]);
  useEffect(() => {
    if (dataItems?.[0]) {
      const newItems = dataItems.filter(item => !checked[item.id]);
      if (newItems.length > 0) {
        setChecked(prevState => {
          const newChecked = { ...prevState };
          newItems.forEach(item => {
            newChecked[item.id] = false;
          });
          return newChecked;
        });
      }
    }
  }, [dataItems]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    setDateError(false);
    if (!isFirstRender.current) {
      setDataItems([]);
    } else {
      isFirstRender.current = false;
    }
    if (
      dateValue !== null &&
      !dateValue.isBefore(today.startOf('day'), 'day')
    ) {
      setDateBegin(dateValue.format('YYYY-MM-DD'));
      setDateEnd(dateValue.format('YYYY-MM-DD'));
    } else if (dateValue?.isBefore(today.startOf('day'), 'day')) {
      setDateError(true);
      setLengthData(0);
      setDataItems([]);
    }
  }, [dateValue]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    setChecked({ ...checked, [id]: event.target.checked });
    setParentChecked(
      Object.values({ ...checked, [id]: event.target.checked }).every(
        value => value,
      ),
    );
  };
  function uncheckById(id) {
    setChecked(prevState => ({
      ...prevState,
      [id]: false,
    }));
    setCheckedItems(prevState => {
      return [...prevState?.filter(item => item.id !== id)];
    });
  }
  const children = (
    <>
      {dataItems.map((item, itemIndex) => (
        <ItemComponent
          key={item.id}
          item={item}
          checked={checked}
          handleChange={handleChange}
          Added={false}
          uncheckById={uncheckById}
          itemIndex={itemIndex}
        />
      ))}
    </>
  );
  type GroupedItems = { [key: string]: any[] };

  const groupedItems: GroupedItems = checkedItems.reduce((acc, item) => {
    const date = moment(item?.deliveryDate).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  const sortedGroupedItems: [string, any[]][] = Object.entries(
    groupedItems,
  ).sort(([a], [b]) => {
    return moment(a).diff(moment(b));
  });

  const children2 = (
    <>
      {sortedGroupedItems.map(([date, items]) => (
        <Fragment key={date}>
          <div className="date">
            {moment(date).format('dddd MMMM D, YYYY')} [{items.length}]
          </div>
          {items &&
            items.map((item, itemIndex) => (
              <ItemComponent
                key={item.id}
                item={item}
                checked={checked}
                handleChange={handleChange}
                Added={true}
                uncheckById={uncheckById}
                itemIndex={itemIndex}
              />
            ))}
        </Fragment>
      ))}
    </>
  );
  const handleClose = () => {
    setChecked({});
    setCheckedItems([]);
    setOpen(false);
  };
  const [updatesOrderDate] = useUpdateOrdersDateMutation();
  const [addStopRight] = useAddOrderClusterMutation();

  const addApi = async () => {
    try {
      const resp = await addStopRight({
        id: clusterId,
        orderIds: checkedItems.map(item => item.id),
      }).unwrap();
      if (resp) {
        handleClose();
        requestAddRoundTripData();
        fetchData(1);
      }
    } catch (e: any) {
      if (e.status === 401) {
      }
    }
  };
  const handleScroll = throttle(event => {
    const element = event.target;

    // TODO: use a true infinite scroll
    if (
      element.scrollHeight - element.scrollTop < element.clientHeight + 40 &&
      nextPage
    ) {
      fetchData(page + 1);
    }
  }, 300);

  const filterCount = getOrderFilterCount(filters);
  return (
    <DialogWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '10px',
          }}
          id="alert-dialog-title"
        >
          {t('add_from_gescom')}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Flex>
          <LeftSide>
            <div className="left_header">
              <DropdownDatePicker
                dateValue={dateValue}
                setDateValue={setDateValue}
              />
              <DateErrorText>
                {dateError && (
                  <Typography variant="caption" color="error">
                    {t('past_date_error')}
                  </Typography>
                )}
              </DateErrorText>
              <TextField
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                placeholder={t('common.place_holders.search')}
                sx={{
                  width: '200px',
                  height: '24px',
                  '& .MuiInputBase-root': {
                    height: '24px',
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  '& .MuiInputBase-input': {
                    height: '100%',
                    padding: '6px 8px',
                  },
                  '& .MuiInputAdornment-root': {
                    height: '100%',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1rem',
                    color: themes?.default?.textColorSecondary,
                    cursor: 'pointer',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          color: themes?.default?.textColorSecondary,
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
              <FilterWrapper>
                <FilterIcon
                  filterOn={filterOn}
                  filtersCount={filtersCount}
                  handleOpenFilter={handleOpenFilter}
                  filterComponent={
                    <OrderFilter
                      filterOn={filterOn}
                      handleCloseFilter={handleCloseFilter}
                      setFilters={setFilters}
                      excludedFilters={[
                        'quantity',
                        'lines',
                        'deliveryDate',
                        'products',
                        'salesman',
                        'withDeleted',
                        'createdBy',
                      ]}
                      availableDate={dateValue?.format('YYYY-MM-DD')}
                      clusterId={clusterId}
                    />
                  }
                />
              </FilterWrapper>
            </div>
            <div className="title" style={{ marginTop: '5px' }}>
              {lengthData} {t('orders.orders_found')}{' '}
            </div>
            <div className="content top_content" onScroll={handleScroll}>
              {children}
            </div>
          </LeftSide>
          <Seperator />
          <RightSide>
            <div className="title" style={{ marginLeft: '12px' }}>
              {t('orders.added_orders')} [ {checkedItems.length} ]
            </div>
            <div className="content top_content" style={{ marginLeft: '12px' }}>
              {checkedItems.length !== 0 ? (
                children2
              ) : (
                <CenterDiv>
                  <img src={NoOrder} alt="" />
                  <p className="no_data_text">{t('no_orders_yet')}</p>
                </CenterDiv>
              )}
            </div>
          </RightSide>
        </Flex>

        <DialogActionsComponent
          handleCloseApi={handleClose}
          Action={addApi}
          textCancel={t('common.buttons.cancel')}
          textAction={t('common.add')}
          disabled={checkedItems.length === 0}
        />
      </Dialog>
    </DialogWrapper>
  );
}

const DateErrorText = styled.div`
  position: absolute;
  bottom: 0;
`;
const FilterWrapper = styled.div`
  width: 60px;
`;
