import { useState } from "react";
import { handleAccordionToggle } from "app/components/RoundTrips/function";

export const useAccordionDetailsToggle = (rows, triggerRoundtripDetails, loading?: any) => {
    const [roundtripLoading, setRoundtripLoading] = useState<string>(loading ?? '');


    const handleAccordionToggleWrapper = async (
        e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
        group: number,
        index: number,
        accordionStatus,
        setAccordionStatus,
        roundtripId,
    ) => {
        if (roundtripLoading) {
            return
        }
        handleAccordionToggle(
            e,
            group,
            index,
            accordionStatus,
            setAccordionStatus,
        )
        // if (!(rows[group] && rows[group]['data'] && rows[group]['data'][index]?.accordionRowCount)) {
        //     await getRoundtripDetails(roundtripId);
        // }
    }

    const getRoundtripDetails = async (roundtripId: string) => {
        setRoundtripLoading(roundtripId)
        await triggerRoundtripDetails({ roundtripId });
        setRoundtripLoading('')
    };

    return { handleAccordionToggleWrapper, roundtripLoading }
};
