export interface Location {
  name: string;
  formattedAddress: string;
  shortAddress: string;
  zipCode: string;
  countryCode: string;
  city?: string;
  region?: string;
  geometry: any;
}

export function formatGeocode(geocode: any): Location {
  const { geometry, formatted_address, address_components } = geocode;

  return {
    name: formatted_address,
    formattedAddress: formatted_address,
    city: address_components.find((c: any) => c.types.includes('locality'))?.long_name,
    zipCode: address_components.find((c: any) => c.types.includes('postal_code'))?.long_name,
    countryCode: address_components.find((c: any) =>
      c.types.includes('country'),
    )?.short_name,
    region:
      address_components.find((c: any) =>
        c.types.includes('administrative_area_level_1'),
      )?.long_name || null,
    shortAddress: formatted_address.split(',')[0],
    geometry: {
      type: 'Point',
      coordinates: [geometry.location.lng(), geometry.location.lat()],
    },
  };
}
