/**
 * Asynchronously loads the component for NotFoundPage
 */
import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const UsersPage = lazyLoad(
    () => import('./index'),
    module => module.Users,
    {
        fallback: <LoadingIndicator />,
    },
);
