import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useSelector } from 'react-redux';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';

export const LoadingIndicator = () => {
  const zoomedIn = useSelector(selectIsZoomedIn);

  return (
    <Backdrop
      invisible
      sx={{
        color: themes.default.accordionWhiteBg,
        zIndex: theme => theme.zIndex.drawer + 1,
        position: 'absolute',
        top: !!zoomedIn ? 0 : themes.default.topBarHeight,
      }}
      open
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
