import { IconButton, Stack, Tooltip } from '@mui/material';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { MenuStatusChange } from 'app/components/MenuStatusChange';
import { Icon } from 'app/components/Icon';
import {
  ArrowCounterClockwise,
  Broom,
  CaretDown,
  Printer,
  Trash,
} from '@phosphor-icons/react';
import { useModal } from 'app/components/Dialog/hooks';
import {
  bulkClear,
  bulkDelete,
  deleteFunction,
} from 'app/components/RoundTrips/function/ApiFunctions';
import {
  useDeleteRoundTripMutation,
  useRoundtripUpdateStatusMutation,
  useUpdateRoundTripMutation,
} from 'common/services/roundtripApi';
import { useToaster } from 'hooks/useToaster';
import styled from 'styled-components';

interface OnSelectActionsProps {
  bulkChangeStatus: Function;
  printDeliveryNote: Function;
  numSelected: number;
  selected:
    | {
        key: string;
      }[]
    | React.Dispatch<
        React.SetStateAction<
          | []
          | {
              key: string;
            }[]
        >
      >;
}

export const OnSelectActions = ({
  bulkChangeStatus,
  selected,
  numSelected,
  printDeliveryNote,
}: OnSelectActionsProps) => {
  const { t } = useTranslation();
  const toast = useToaster();

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModal();
  const { openModal: openClearModal, closeModal: closeClearModal } = useModal();

  const [roundTripStatus] = useRoundtripUpdateStatusMutation();
  const [DeleteRoundtrip] = useDeleteRoundTripMutation();
  const [updateRoundtripData] = useUpdateRoundTripMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDeleteAction = () => {
    bulkDelete(selected, deleteFunction, DeleteRoundtrip);
    toast(3000, 'success', 'roundtrips.delete-bulk.success');
    closeDeleteModal();
  };
  const handleClearAction = () => {
    bulkClear(selected, updateRoundtripData);
    toast(3000, 'success', 'roundtrips.clean-bulk.success');
    closeClearModal();
  };

  const iconSize = themes.default?.iconSizeMedium;
  return (
    <>
      <Tooltip title={t('orders.change_status')}>
        <Stack
          direction={'row'}
          gap={'2px'}
          alignItems={'center'}
          marginRight={'10px'}
          position={'relative'}
        >
          <IconButton
            sx={{
              color: themes?.default?.textColorPrimary,
              marginRight: '10px',
            }}
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={e => {
              handlesetAnchorFunction(e, setAnchorEl);
            }}
          >
            <Icon icon={<ArrowCounterClockwise />} size={iconSize} />
          </IconButton>
          <ArrowIconWrapper>
            <Icon icon={<CaretDown />} weight="fill" size={'12px'} />
          </ArrowIconWrapper>
        </Stack>
      </Tooltip>
      <MenuStatusChange
        bulkChangeStatus={bulkChangeStatus}
        selected={selected}
        roundTripStatus={roundTripStatus}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
      />
      <Tooltip title={t('print.delivery-note-button')}>
        <IconButton
          sx={{ color: themes?.default?.textColorPrimary }}
          onClick={() => {
            printDeliveryNote();
          }}
        >
          <Icon icon={<Printer />} size={iconSize} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('common.clear')}>
        <IconButton
          sx={{ color: themes?.default?.textColorPrimary }}
          aria-label="add to shopping cart"
          onClick={() => {
            openClearModal({
              title: t('clusters.clear.bulk.confirm', {
                count: numSelected,
              }),
              action: {
                actionCallback: handleClearAction,
                actionText: t('common.buttons.clean'),
              },
              cancel: true,
            });
          }}
        >
          <Icon icon={<Broom />} size={iconSize} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('delete')}>
        <IconButton
          sx={{ color: themes?.default?.textColorPrimary }}
          aria-label="add to shopping cart"
          onClick={() => {
            openDeleteModal({
              title: t('clusters.delete.bulk.confirm', {
                count: numSelected,
              }),
              action: {
                actionCallback: handleDeleteAction,
                actionText: t('common.buttons.delete'),
              },
              cancel: true,
            });
          }}
        >
          <Icon icon={<Trash />} size={iconSize} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const ArrowIconWrapper = styled.div`
  position: absolute;
  right: 0;
`;
