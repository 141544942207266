import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Popover, SxProps } from '@mui/material';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { useUpdateOrderSupportUnitsMutation } from 'common/services/orderApi';
import { Icon } from 'app/components/Icon';
import { Info } from '@phosphor-icons/react';
import SupportSelector from './components/SupportSelector';
import { selectConfiguration } from 'common/store/organization/selectors';

interface Props {
  widthButton?: any;
  supportUnits: any;
  canEdit: boolean;
  updateOrderFunction?: any;
  stopId?: string;
  isOverLoaded?: boolean;
  tooltipDisabled?: boolean;
  refreshData?: () => void;
  displayOnHover?: boolean;
  disableButton?: boolean;
  updateOrderCache?: Function;
  roundtripId?: string;
  buttonSx?: SxProps;
}

export function SupportUnitsPopover({
  widthButton,
  supportUnits,
  canEdit,
  updateOrderFunction,
  stopId,
  isOverLoaded,
  tooltipDisabled,
  refreshData,
  displayOnHover,
  disableButton,
  updateOrderCache,
  roundtripId,
  buttonSx,
}: Props) {
  const { t } = useTranslation();

  const [UpdateOrders] = useUpdateOrderSupportUnitsMutation();

  const configurationSelector = useSelector(selectConfiguration);
  const supportUnitsList = configurationSelector?.supportUnitsList;

  const displayedSupportUnits = useMemo(() => {
    return supportUnitsList?.map(support => {
      const existingSupport = supportUnits?.planned?.find(
        su => su?._supportUnit?._id === support._id,
      );
      if (existingSupport) {
        return existingSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
  }, [supportUnits?.planned, supportUnitsList]);

  const disabled = !!tooltipDisabled;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [list, setList] = useState<any>(displayedSupportUnits || []);

  const [supportArrayCopy, setSupportArrayCopy] = useState<any>(
    displayedSupportUnits || [],
  );
  const debouncedSupportArray = useDebounce(supportArrayCopy, 500);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    handleUpdate();
  }, [JSON.stringify(debouncedSupportArray)]);
  const handleUpdate = async () => {
    if (typeof updateOrderFunction === 'function' && canEdit) {
      await updateOrderFunction(
        stopId,
        {
          supportUnitsList: {
            planned: debouncedSupportArray?.filter(su => {
              return su._supportUnit;
            }),
            delivered: supportUnits?.delivered || [],
            collected: supportUnits?.collected || [],
          },
        },
        UpdateOrders,
      );
      setList(debouncedSupportArray);
      refreshData && refreshData();
    }
  };
  const [supportList, setSupportList] = useState<any>();
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disableButton) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!canEdit || !open) {
      setList(displayedSupportUnits || []);
      setSupportArrayCopy(displayedSupportUnits || []);
    }
  }, [displayedSupportUnits, open, canEdit]);

  useEffect(() => {
    if (!displayedSupportUnits?.length) {
      return;
    }
    let displayedList = [...list];
    if (!canEdit) {
      displayedList = list?.filter(support => !!support?.quantity);
    }
    setSupportList(
      displayedList?.map((key, index) => (
        <Support showBorder={index !== list?.length - 1} key={index}>
          <SupportSelector
            canEdit={canEdit}
            supportArrayCopy={supportArrayCopy}
            setSupportArrayCopy={setSupportArrayCopy}
            value={key}
            supportUnits={supportUnits}
          />
        </Support>
      )),
    );
  }, [list, supportArrayCopy]);
  const theme = useSelector(selectTheme);
  const iconColor = isOverLoaded
    ? theme?.redStatus
    : anchorEl !== null
    ? theme?.primaryActiveColor
    : themes?.default?.textColorSecondary;
  const tooltip = isOverLoaded
    ? t('roundtrips.support-unit.capacity-exceeded')
    : t('view_support_units');

  useEffect(() => {
    updateOrderCache &&
      updateOrderCache(stopId, roundtripId, {
        supportUnitsList: supportArrayCopy,
      });
  }, [JSON.stringify(supportArrayCopy)]);

  const showAdd = useMemo(() => {
    return supportUnitsList.find(
      obj1 =>
        !supportArrayCopy.some(obj2 => obj1._id === obj2._supportUnit?._id),
    );
  }, [supportUnitsList, supportArrayCopy]);

  return (
    <>
      <ManagedTooltip title={tooltip} disabled={disabled}>
        <Button
          onClick={!displayOnHover ? handleButtonClick : () => {}}
          onMouseEnter={displayOnHover ? handleButtonClick : () => {}}
          sx={{
            width: widthButton + 'px',
            maxWidth: widthButton + 'px',
            minWidth: widthButton + 'px',
            ...buttonSx,
          }}
        >
          <Info color={iconColor} size={13} style={{marginTop: '3px', marginBottom: '3px'}} />
        </Button>
      </ManagedTooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ '& .MuiPaper-root': { overflowY: 'hidden' } }}
      >
        <div onMouseLeave={displayOnHover ? handlePopupClose : () => {}}>
          { supportList?.length > 0 ? supportList : <NoSupportAttached> {t('no_support_units_attached')} </NoSupportAttached> }
        </div>
      </Popover>
    </>
  );
}

interface SupportStyledProps {
  showBorder?: boolean;
}

const Support = styled.div<SupportStyledProps>`
  border-bottom: ${props =>
    props.showBorder
      ? `0.4px solid ${props.theme?.textColorSecondary}`
      : 'none'};
  margin: 0 16px;
  width: 220px;
  display: flex;
  justify-content: space-between;
  max-height: 54px;
  .title {
    height: 25px;
  }
`;

const NoSupportAttached = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1rem;
`;
