import useWebSocket from 'react-use-websocket';
import { ENV } from '../constants';

export function useSubscribeObject(objectType: string, objectId: string, actionFilter?: any) {
  const wsBaseUrl = ENV.WS_URL;

  const { lastMessage } = useWebSocket(
    `${wsBaseUrl}/sub/${objectType}/${objectId}`,
    {
      shouldReconnect: closeEvent => {
        /*
      useWebSocket will handle unmounting for you, but this is an example of a
      case in which you would not want it to automatically reconnect
    */
        return true;
      },
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    },
  );

  if (!objectId || !lastMessage) {
    return null;
  }

  if (actionFilter?.length > 0) {
    const message = JSON.parse(lastMessage.data);

    if (!actionFilter.includes(message?.data.action)) {
      return null;
    }
  }

  return lastMessage?.data;
}
