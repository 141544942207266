import { useState } from 'react';
import {
  Collapse,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';

type MainNavItemProps = {
  text: string;
  visible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  icon?: JSX.Element;
  mode?: string;
  to?: string;
  sx?: ListItemButtonProps['sx'];
  expendNode?: JSX.Element;
  collapseNode?: JSX.Element;
  tabs?: Array<string>;
  active?: boolean;
};

export const MainNavItem = ({
  visible = true,
  text,
  icon,
  onClick,
  to,
  mode,
  expendNode,
  sx = {},
  collapseNode,
  tabs,
  active,
}: MainNavItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);

  const isActive =
    active || location.pathname === to || tabs?.includes(location.pathname);

  const handleOnClick = () => {
    if (to) {
      navigate(to);
    } else if (collapseNode) {
      setIsOpen(!isOpen);
    }
  };

  if (!visible) {
    return null;
  }

  if (mode === 'minify') {
    return (
      <Tooltip title={text} arrow placement="right">
        <span>
          <ListItemButton
            onClick={typeof onClick === 'function' ? onClick : handleOnClick}
            sx={
              !isEmpty(sx)
                ? sx
                : {
                    backgroundColor: isActive
                      ? theme.primaryActive
                      : themes?.default?.accordionWhiteBg,
                    borderRight: isActive
                      ? `3px solid ${theme.primaryActiveColor}`
                      : 'none',
                    color: isActive
                      ? theme.primaryActiveColor
                      : themes?.default?.black,

                    padding: '8px 12.5px',
                  }
            }
          >
            <Icons>
              <ListItemIcon>{icon}</ListItemIcon>
            </Icons>
            {expendNode}
          </ListItemButton>

          {collapseNode ? (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              {collapseNode}
            </Collapse>
          ) : null}
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      <ListItemButton
        onClick={typeof onClick === 'function' ? onClick : handleOnClick}
        sx={
          !isEmpty(sx)
            ? sx
            : {
                backgroundColor: isActive
                  ? theme.primaryActive
                  : themes?.default?.accordionWhiteBg,
                borderRight: isActive
                  ? `3px solid ${theme.primaryActiveColor}`
                  : 'none',
                color: isActive
                  ? theme.primaryActiveColor
                  : themes?.default?.black,
              }
        }
      >
        <ListItemIcon>
          <Icons>
            <ListItemIcon>{icon}</ListItemIcon>
          </Icons>
        </ListItemIcon>

        <ListItemText primary={text} />
        {expendNode}
      </ListItemButton>

      {collapseNode ? (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {collapseNode}
        </Collapse>
      ) : null}
    </span>
  );
};

export const Title = styled.p<{ textColorSecondary: string }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.813em;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 19px;
  color: ${props => props?.textColorSecondary};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const MenuSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

export const GeneralSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

export const ResourcesSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

export const LastSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

const Icons = styled.div`
  img,
  svg {
    padding: 5px;
    width: 34px;
    height: 34px;
    border: 0.5px solid ${themes?.default?.lightFill};
    border-radius: 4px;
  }
`;
