import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'common/store/app';
import { BampteeApplications } from 'common/store/app';
import { selectCurrentApplication } from 'common/store/app/selectors';
import { Application } from 'common/store/app/types';
import { themeActions } from '../styles/theme/slice';

export const useApplication = (authUser: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ordoriaApp = BampteeApplications.find((app: any) => app.id === 'ordoria');
  const currentApplication:Application = useSelector(selectCurrentApplication);

  useEffect(() => {
    // execute on first load
    if (location.pathname === '/' && currentApplication.id !== 'default' && location.pathname.indexOf(currentApplication.url) === -1) {
      navigate(currentApplication.url || '/');
    }
  }, []);

  useEffect(() => {
    // execute on location change
    if (authUser && location.pathname.indexOf('/ordoria') === -1 && authUser.currentAgent?.applications?.length === 1 && authUser.currentAgent?.applications?.[0] === 'ordoria') {
      if (ordoriaApp) {
        navigate(ordoriaApp?.url || '/');
        dispatch(appActions.setCurrentApplication({ application: ordoriaApp }));
        // @ts-ignore
        dispatch(themeActions.changeTheme(ordoriaApp.id));
      }
    } else {
      const findApp = BampteeApplications.find((app:any) => location.pathname.indexOf(app.url) > -1);

      if (findApp) {
        dispatch(appActions.setCurrentApplication({ application: findApp }));
        // @ts-ignore
        dispatch(themeActions.changeTheme(findApp.id));
      }
    }
  }, [location.pathname]);

  return currentApplication;
}
