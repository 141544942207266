import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';
import React from 'react';
import { NotTreatedDoc } from './components/NotTreatedDoc';
import { TreatedDoc } from './components/TreatedDoc';
import { CompletedDoc } from './components/CompletedDoc';
import { EnhancedTableToolbar } from '../../components/EnhancedTableToolbar';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { FilterDocs } from './components/FilterDocs';

export function CheckDocuments() {
  const [search, setSearch] = useState<string>('');
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  ///Toolbar Filtration states
  const [filters, setFilters] = useState<{
    salesman: { value: string; label: string }[];
  }>({
    salesman: [],
  });

  const { filterOn, handleOpenFilter, filtersCount, handleCloseFilter } =
    useFilterState({
      filters,
    });

  return (
    <MainWrapper>
      <ToolbarWrapper>
        <EnhancedTableToolbar
          title="nav.mainNav.checkDocuments"
          disabled={false}
          numSelected={0}
          search={search}
          setSearch={setSearch}
          filterComponents={
            <>
              {currentTabIndex === 2 && (
                <FilterIcon
                  filterOn={filterOn}
                  filtersCount={filtersCount}
                  handleOpenFilter={handleOpenFilter}
                  filterComponent={
                    <FilterDocs
                      filterOn={filterOn}
                      handleClose={handleCloseFilter}
                      setFilters={setFilters}
                      filters={filters}
                    />
                  }
                />
              )}
            </>
          }
          tabMenuCenter={
            <Tabs
              sx={{ minHeight: '44px' }}
              value={currentTabIndex}
              onChange={handleTabChange}
              centered
            >
              <Tab label="Non Traités" sx={{ p: 0, minHeight: '44px' }} />
              <Tab label="A revoir" sx={{ p: 0, minHeight: '44px' }} />
              <Tab label="Completés" sx={{ p: 0, minHeight: '44px' }} />
            </Tabs>
          }
        />
      </ToolbarWrapper>

      <Wrapper>
        <React.Fragment>
          {currentTabIndex === 0 && (
            <NotTreatedDoc search={search} setSearch={setSearch} />
          )}
          {currentTabIndex === 1 && (
            <TreatedDoc search={search} setSearch={setSearch} />
          )}
          {currentTabIndex === 2 && (
            <CompletedDoc search={search} filters={filters} />
          )}
        </React.Fragment>
      </Wrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: block;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  max-width: 1400px;
`;

const ToolbarWrapper = styled.div`
  width: 100%;
  display: block;
`;
