import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDeleteProductNoteMutation,
  useLazyGetProductNotesQuery,
  usePatchProductNoteMutation,
  useCreateProductNoteMutation,
} from 'common/services/orderApi';
import { useModal } from 'app/components/Dialog/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../../../../slices/auth/selectors';

function formatTime(dateInput: Date): string {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 12-hour format with AM/PM
  } as Intl.DateTimeFormatOptions;

  const date = new Date(dateInput);
  return date.toLocaleTimeString(undefined, options);
}

export function OrderNotesTab({ orderId }) {
  const [groupedNotes, setGroupedNotes] = useState({});
  const [openTextArea, setOpenTextArea] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  // const [updatedNote, setUpdatedNote] = useState<string>('');
  const [patchNote] = usePatchProductNoteMutation();
  const [validationError, setValidationError] = useState<string | null>(null);

  const { t, i18n } = useTranslation();

  function formatDate(dateInput: Date): string {
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const date = new Date(dateInput);
    const locale = i18n.language;
    return date.toLocaleDateString(locale, options);
  }

  const [createNotes] = useCreateProductNoteMutation();
  const [deleteNote] = useDeleteProductNoteMutation();
  const [trigger, { data: notesData, isLoading, isFetching }] =
    useLazyGetProductNotesQuery();
  const productId = '';

  async function handleAddNote() {
    if (note !== '') {
      await createNotes({
        orderId,
        content: note,
      })
        .unwrap()
        .then(event => {
          trigger({ orderId, productId });
          setNote('');
          // toast(5000, 'success', 'alerts.notes.add_success');
          fetchNotes();
        })
        .catch(err => console.log(err));

      // refreshData()
    }
  }

  const { closeModal, openModal } = useModal();
  const [deleteNoteId, setDeleteNoteId] = useState<string>('');

  function handleDeleteConfirmNote(noteId) {
    setDeleteNoteId(noteId);
    openModal({
      title: t('orders.notes.delete_confirmation'),
      action: {
        actionText: t('common.buttons.ok'),
        actionCallback: handleDeleteNote.bind(null, noteId),
      },
      cancel: true,
    });
  }

  function handleDeleteNote(deleteNoteId: string) {
    deleteNote({
      orderId: orderId,
      noteId: deleteNoteId,
    }).then(() => {
      fetchNotes();
      closeModal();
    });
  }

  async function handleUpdateNote(noteId: string, editedNote) {
    await patchNote({
      orderId: orderId,
      noteId: noteId,
      content: editedNote,
    })
      .unwrap()
      .then(() => {
        // toast(5000, 'success', 'alerts.notes.update_success');
        fetchNotes();
      });
  }

  const fetchNotes = () => {
    trigger({ orderId, productId });
  };

  useEffect(() => {
    trigger({ orderId, productId });
  }, [orderId, productId, trigger]);

  useEffect(() => {
    if (notesData && Array.isArray(notesData.docs)) {
      // fetchNotes();

      const grouped = notesData.docs.reduce((acc, note) => {
        // const date = new Date();
        const key = formatDate(note.createdAt);

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(note);
        return acc;
      }, {});
      setGroupedNotes(grouped);
    }

    // })();
  }, [orderId, notesData]);

  const handleOpenTextArea = bool => {
    setOpenTextArea(bool);
  };

  const handleNoteChange = note => {
    setNote(note);
  };

  const handleSaveNote = () => {
    if (!validateNote()) {
      return;
    }

    handleAddNote();

    setNote('');
    handleOpenTextArea(false);
  };

  const validateNote = () => {
    if (!note.trim()) {
      setValidationError(t('order_details.order_notes.note_cannot_be_empty'));
      return false;
    }
    if (!/^[\w\s.,!?]*$/.test(note)) {
      setValidationError(
        t('order_details.order_notes.note_contains_invalid_chars'),
      );
      return false;
    }
    if (note.length > 1000) {
      setValidationError(t('order_details.order_notes.note_is_too_long'));
      return false;
    }
    setValidationError(null);
    return true;
  };

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          marginTop: '30px',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ overflowY: 'auto' }}>
      {!openTextArea && (
        <Button
          onClick={() => setOpenTextArea(true)}
          sx={{ fontSize: '0.929rem', marginTop: '25px', fontWeight: '500' }}
          startIcon={<AddIcon />}
        >
          {t('order_details.order_notes.new_note')}
        </Button>
      )}
      {openTextArea && (
        <CustomTextField
          handleSave={handleSaveNote}
          handleNoteChange={handleNoteChange}
          handleOpenTextArea={handleOpenTextArea}
          validationError={validationError}
        />
      )}

      {isFetching && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '30px',
          }}
        >
          <CircularProgress />
        </div>
      )}

      {Object.keys(groupedNotes).map((date, index) => (
        <StyledCard key={index}>
          <CardHeader
            sx={{ padding: 0, marginTop: '15px' }}
            title={<Typography variant="h6">{date}</Typography>}
          />
          <CardContent sx={{ padding: 0, paddingBottom: '5px !important' }}>
            <ScrollableListContainer>
              <List>
                {groupedNotes[date].map((note, i) => (
                  <RenderNote
                    key={i}
                    note={note}
                    index={index}
                    handleDeleteConfirmNote={handleDeleteConfirmNote}
                    handleUpdateNote={handleUpdateNote}
                  />
                ))}
              </List>
            </ScrollableListContainer>
          </CardContent>
        </StyledCard>
      ))}
    </div>
  );
}

function RenderNote({
  note,
  index,
  handleDeleteConfirmNote,
  handleUpdateNote,
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedComment, setEditedComment] = useState<string>(note.content);

  const { t, i18n } = useTranslation();

  const authUser = useSelector(selectAuthUser);
  if (!authUser) {
    return null;
  }

  const userId = authUser.id;
  const isSuperAdmin = authUser.isSuperAdmin;

  // const [note, setNote] = useState<string>('');

  const startEditing = () => {
    setEditedComment(note.content);
    setIsEditing(true);
  };

  const handleUpdate = () => {
    handleUpdateNote(note._id, editedComment);
    setIsEditing(false);
  };

  const handleCancel = () => {
    // setEditedComment(note.content);
    setIsEditing(false);
  };

  return (
    <ListItem
      key={index}
      sx={{ paddingLeft: 0, display: 'block', marginTop: '10px' }}
    >
      <NoteHeader>
        {formatTime(note.createdAt)}
        {(note?._author.id == userId || isSuperAdmin) && (
          <Actions>
            <EditIcon
              onClick={startEditing}
              sx={{ color: 'rgba(129, 145, 152, 1)' }}
            />
            {/* <DeleteIcon onClick={() => { handleDeleteConfirmNote(note._id) }} sx={{ color: 'rgba(129, 145, 152, 1)', marginLeft: '18px' }} /> */}
            <DeleteIcon
              onClick={() => {
                handleDeleteConfirmNote(note._id);
              }}
              sx={{ color: 'rgba(129, 145, 152, 1)', marginLeft: '18px' }}
            />
          </Actions>
        )}
      </NoteHeader>
      {isEditing ? (
        <div style={{ marginBottom: '15px' }}>
          <TextField
            value={editedComment}
            onChange={e => setEditedComment(e.target.value)}
            variant="standard"
            fullWidth
            sx={{ marginBottom: '10px !important' }}
          />
          <Button
            onClick={handleUpdate}
            variant="outlined"
            size="small"
            sx={{ marginRight: '10px' }}
          >
            {t('common.buttons.save')}
          </Button>
          <Button onClick={handleCancel} variant="outlined" size="small">
            {t('common.buttons.cancel')}
          </Button>
        </div>
      ) : (
        <Comment>{note.content}</Comment>
      )}
      <CommentedBy>
        <Circle>
          <Letter>{note?._author?.firstName[0].toUpperCase()}</Letter>
        </Circle>
        <CommentedByUser>{note._author.email}</CommentedByUser>
      </CommentedBy>
    </ListItem>
  );
}

interface CustomTextFieldProps {
  handleNoteChange: (note: string) => void;
  handleOpenTextArea: (bool: boolean) => void;
  handleSave: () => void;
  validationError: string | null;
  value?: string;
}

export function CustomTextField({
  handleNoteChange,
  handleOpenTextArea,
  handleSave,
  validationError,
  value,
}: CustomTextFieldProps) {
  const { t, i18n } = useTranslation();

  return (
    <StyledBox>
      <StyledTextField
        multiline
        rows={5}
        placeholder={t('order_details.order_notes.textinput.placeholder')}
        variant="outlined"
        fullWidth
        value={value}
        onChange={e => handleNoteChange(e.target.value)}
        error={Boolean(validationError)}
        helperText={validationError}
      />
      <ButtonGroup>
        <Button
          onClick={() => {
            handleOpenTextArea(false);
            handleNoteChange('');
          }}
          size="small"
          variant="text"
          sx={{ fontSize: '0.929rem', color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={() => {
            handleSave();
            handleNoteChange('');
          }}
          size="small"
          variant="text"
          sx={{ fontSize: '0.929rem' }}
          color="primary"
          startIcon={<AddIcon />}
        >
          {t('common.add')}
        </Button>
      </ButtonGroup>
    </StyledBox>
  );
}

const NoteHeader = styled(Typography)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500 !important;
`;

const Actions = styled(Typography)`
  margin-left: auto !important;
`;

const CommentedByUser = styled(Typography)`
  color: rgba(0, 0, 0, 0.38);
  margin-left: 8px !important;
`;

const CommentedBy = styled(Typography)`
  font-weight: 500 !important;
  display: flex;
  margin-top: 5px !important;
`;

const Comment = styled(Typography)`
  font-size: 1.07rem !important;
  color: rgba(0, 0, 0, 0.6);
`;

const StyledCard = styled(Card)`
  margin-top: 10px;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0;
  &:not(:last-child) {
    border-bottom: dashed thin rgba(0, 0, 0, 0.23);
  }
`;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(240, 229, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Letter = styled.span`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
`;

const StyledBox = styled(Box)`
  margin-top: 25px;
  position: relative;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 8px;
  bottom: 24px;
  display: flex;
  gap: 8px;
`;

const StyledTextField = styled(TextField)`
  background-color: transparent;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
  }
`;

const ScrollableListContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;
