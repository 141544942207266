import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Chip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export default function DocItem({ document, onOpenDocument }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Card
      key={document.id}
      style={{
        marginBottom: 16,
        height: isMobile ? 'auto' : 61,
        width: isMobile ? '100%' : '1000px',
      }}
    >
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={7}>
            <ContentWrapper>
              {document.metadata.type === 'invoice' ? (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes?.default?.green30,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              ) : document.metadata.type === 'delivery' ? (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes?.default?.egyptianBlue,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              ) : (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes.default.carmine,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              )}
              <Typography
                sx={{ marginLeft: '20px', marginRight: '20px' }}
                color="text.secondary"
                fontSize="1rem"
              >
                {dayjs(document.metadata?.date || document.createdAt).format(
                  'L',
                )}
              </Typography>
              <Typography fontSize="1rem" fontWeight="500">
                {document.name}
              </Typography>
            </ContentWrapper>
          </Grid>
          <Grid item xs={12} md={5}>
            <ButtonWrapper>
              {document.viewAt ? (
                <ViewAtWrapper>
                  <Typography
                    sx={{
                      color: 'grey',
                      border: '1px solid grey',
                      borderRadius: '4px',
                      padding: '2px 4px',
                    }}
                  >
                    {t('customerPortal.documents.viewed', {
                      date: dayjs(document.viewAt).format('LLL'),
                    })}
                  </Typography>
                  <Button
                    onClick={() => onOpenDocument(document)}
                    size="small"
                    sx={{ height: '30px', marginLeft: '16px' }}
                  >
                    <VisibilityIcon sx={{ marginRight: '3px' }} />
                    PDF
                  </Button>
                </ViewAtWrapper>
              ) : (
                <Button
                  onClick={() => onOpenDocument(document)}
                  size="small"
                  sx={{ height: '30px', marginLeft: '16px' }}
                >
                  <VisibilityIcon sx={{ marginRight: '3px' }} />
                  PDF
                </Button>
              )}
            </ButtonWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
`;

const ViewAtWrapper = styled.div`
  display: flex;
  align-items: center;
`;
