import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Order } from 'app/components/TableComponent';
import { FixedTableHead } from 'app/components/TableComponent/helpers';
import { Checkbox } from 'app/components/CustomCheckbox';
import { useTranslation } from 'react-i18next';

interface EnhancedTableProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (property: string) => void;
  isLoading: boolean;
  headCells: any;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isLoading,
    headCells,
  } = props;
  const createSortHandler = (property: string) => () => {
    onRequestSort(property);
  };
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  if (isLoading) return <div>loading...</div>;

  let stickyCheckbox = {
    zIndex: 1,
    backgroundColor: theme.grey,
    left: 0,
    position: 'sticky',
  };

  return (
    <FixedTableHead
      sx={{
        backgroundColor: theme.grey,
      }}
    >
      <TableRow
        sx={{
          display: 'table-row',
        }}
      >
        <TableCell
          sx={{
            display: 'table-cell',
            alignItems: 'center',
            minWidth: '52px',
            ...stickyCheckbox,
          }}
          padding="checkbox"
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            sx={{
              height: 'fit-content',
            }}
          />
        </TableCell>

        {headCells.map((headCell, headCellIndex) => {
          if (headCell.disableRow) return null;
          let styles = {};
          if (headCell.center) {
            styles = { display: 'flex', justifyContent: 'center' };
          }
          return (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.sortField ? order : false}
              sx={{
                flex: 1,
                minWidth: headCell.minWidth || 0,
                maxWidth: headCell.maxWidth || 'unset',
                overflow: 'hidden',
                ...styles,
              }}
            >
              {headCell.disableSort ? (
                <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.sortField}
                  direction={orderBy === headCell.sortField ? order : 'asc'}
                  onClick={createSortHandler(headCell.sortField)}
                >
                  <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                  {orderBy === headCell.sortField ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </FixedTableHead>
  );
}
