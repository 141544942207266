import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo } from 'react';
import { capitalize } from 'lodash';
import { ErrorCode } from 'react-dropzone';

interface RejectedFilesProps {
  files: Array<any>;
  deleteFile: Function;
  uploadPage?: boolean; //If RejectedFiles from UploadPage
}
export function RejectedFiles({
  files,
  deleteFile,
  uploadPage,
}: RejectedFilesProps) {
  const { t } = useTranslation();

  const errorMap = useMemo(() => {
    return {
      'file-invalid-type': 'settings.companyProfile.errors.typeFile',
      'file-size-upload-page': 'settings.companyProfile.errors.size.UploadPage',
      'file-size-general': 'settings.companyProfile.errors.size',
      'file-too-large': 'settings.companyProfile.errors.size',
      'file-number-limit': 'settings.companyProfile.errors.numberFile',
    };
  }, []);

  const getErrorMessage = (code: ErrorCode) => {
    const fallBackMessage = capitalize(code?.replace(/-/g, ' ') || '');
    return t(errorMap[code] || fallBackMessage);
  };

  return (
    <>
      {files.map(file => (
        <FilesListWrapper key={file.file.name}>
          <UploadFileIcon
            color="error"
            sx={{ width: '28px', height: '28px', mr: '16px' }}
          />
          <div
            className="container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography fontSize="1rem" fontWeight="400" color="error">
              {t('settings.companyProfile.errors.failed')}
            </Typography>
            <div
              className="detailWrapper"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography fontSize="1rem" fontWeight="400" color="error">
                {getErrorMessage(file.errors?.[0]?.code)}
              </Typography>
              <Typography sx={{ ml: '8px' }} color="error">
                •
              </Typography>
              <Typography sx={{ ml: '8px' }} color="error">
                {t('failed')}
              </Typography>
            </div>
          </div>
          <IconButton
            sx={{ ml: 'auto' }}
            onClick={() => deleteFile(file.file.name)}
          >
            <CloseIcon color="action" />
          </IconButton>
        </FilesListWrapper>
      ))}
    </>
  );
}

const FilesListWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
`;
