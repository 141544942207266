import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import { OrderStatus } from 'app/pages/Orders/data/statuses';
import { t } from 'i18next';
export interface OrderStatusActionsProps {
  changeStatus: (status: string) => void;
  authUser: any;
  configuration: any;
}

export default function populateOrderStatusActions({
  changeStatus,
  authUser,
  configuration
}: OrderStatusActionsProps) {
  let statuses: Array<{ status: string, label?: string, classNameCircle: string, color: string }> = []

  if (authUser?.currentAgent?._role?.key === 'admin' || authUser?.currentAgent?._role?.key === 'planner') {
    statuses = getOrderStatusesToShow(configuration?.orderStatusLogistic || []);
  }
  else {
    statuses = getOrderStatusesToShow(configuration?.orderStatusLogistic || [])?.filter(status => status.status !== OrderStatus.Delivered);
  }
  return statuses.map(action => ({
    action: action.status,
    element: (
      <ItemStatusComponent
        action={() => changeStatus(action.status)}
        label={t(`orders.status.${action.label || action.status}`)}
        classNameCircle={action.classNameCircle}
        color={action.color}
      />
    ),
  }));
}
