import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../common/store/auth';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLogoutMutation } from 'common/services/authApi';
import { selectAuthUser } from 'common/store/auth/selectors';
import { SIGNIN } from 'utils/routes';

export default function LogoutPage() {
  const authUser:any = useSelector(selectAuthUser);
  const [logout, { error: logoutError }] = useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    logout({
      username: authUser.username,
      sessionId: authUser.sessionId,
    }).unwrap();
    dispatch(authActions.logout());
  }, []);

  return <Navigate to={SIGNIN} />;
}
