import { Autocomplete, Chip, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import {
  DisabledChip,
  FilterName,
  SelectOptionWrapper,
  ToggleIcon,
} from '../../styles';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from 'common/hooks/useDebounce';
import { CountChip } from '../CountChip';

interface CheckboxGrid {
  openByDefault?: boolean;
  filterName: string;
  filterItems: {
    value: string;
    label: string;
    count?: number;
    disabled?: boolean;
    color?: string;
  }[];
  selectedOptions: { value: string; label: string }[];
  placeholder?: string;
  handleFilterChange: (options: { value: string; label: string }[]) => void;
  onSearch?: (search: string) => void;
  checkboxesOptions?: boolean;
  autoCompleteProps?: any;
}

const MultiSelect = ({
  openByDefault,
  filterName,
  filterItems,
  selectedOptions,
  placeholder,
  handleFilterChange,
  onSearch,
  checkboxesOptions,
  autoCompleteProps,
}: CheckboxGrid) => {
  const isOpen = !!openByDefault;
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [search, setSearch] = useState('');

  const handleOptionSelected = (event, selectedOptions) => {
    setSearch('');
    handleFilterChange(selectedOptions);
  };
  const handleDeleteOption = option => {};

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleBlur = () => {
    setSearch('');
  };

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    onSearch && onSearch(debouncedSearch);
  }, [debouncedSearch]);
  const renderTags = (selectedOptions, getTagProps) => {
    return selectedOptions.map((option, index) => (
      <Chip
        size="small"
        key={option.value}
        label={option.label}
        onDelete={() => handleDeleteOption(option)}
        {...getTagProps({ index })}
        deleteIcon={<CloseIcon />}
        sx={{
          background: `${option?.color}20`,
          color: option?.color,
        }}
      />
    ));
  };

  const filterOptions = options => {
    if (!checkboxesOptions || checkboxesOptions === undefined) {
      return options?.filter(
        option =>
          !selectedOptions?.find(
            selectedOption => selectedOption.value === option.value,
          ),
      );
    } else {
      return options;
    }
  };

  const renderOption = (props, option) => {
    return (
      <div {...props}>
        <SelectOptionWrapper>
          <div>{option.label}</div>
          {option.disabled ? (
            <DisabledChip label="disabled" size="small" />
          ) : (
            option?.count > -1 && <CountChip count={option?.count} />
          )}
        </SelectOptionWrapper>
      </div>
    );
  };

  const autocompleteOptions = useMemo(() => {
    if (onSearch) {
      return filterItems;
    }
    return filterItems?.filter(item =>
      item?.label?.toLowerCase()?.includes(search?.toLowerCase()),
    );
  }, [search, filterItems]);

  const selectCount = useMemo(() => {
    return selectedOptions.length;
  }, [selectedOptions]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        {selectCount > 0 ? (
          <FilterName isHighlighted={true}>
            {filterName} ({selectCount})
          </FilterName>
        ) : (
          <FilterName>{filterName}</FilterName>
        )}
        {
          <ToggleIcon disableRipple>
            {open ? <RemoveIcon /> : <AddIcon />}
          </ToggleIcon>
        }
      </div>
      <If condition={open}>
        <Autocomplete
          multiple
          value={selectedOptions}
          options={autocompleteOptions}
          getOptionLabel={(option: { label: string }) => option.label}
          onChange={handleOptionSelected}
          renderTags={renderTags}
          filterOptions={filterOptions}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              label={placeholder || filterName}
              onChange={handleSearch}
              onBlur={handleBlur}
              sx={{
                fontSize: '0.766rem',
                '& .MuiAutocomplete-endAdornment': {
                  display: 'flex',
                  justifyContent: 'center',
                },
                '& button': {
                  width: 'fit-content',
                },
              }}
            />
          )}
          renderOption={renderOption}
          {...autoCompleteProps}
        />
      </If>
    </>
  );
};

export default MultiSelect;
