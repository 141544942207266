import {
  Button,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Filters } from 'app/components/Filters';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { DepartmentDrawer } from './DepartmentDrawer';
import { departmentsHeadCellsConst } from 'interfaces/departments';
import DeleteIcon from '@mui/icons-material/Delete';
import { changeColumnAction } from 'functions/changeColumnAction';
import { clearAllAction } from 'functions/clearAllAction';
import { selectAllAction } from 'functions/selectAllAction';
import { ColumnPopover } from 'app/components/TableComponent/ColumnPopover';
import { Checkbox } from 'app/components/CustomCheckbox';

export function Departments() {
  const { t } = useTranslation();
  const [heads, setHeads] = useState(departmentsHeadCellsConst);
  const [filters, setFilters] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const handleSearchTextChange = e => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
  };

  const [department, setDepartment] = useState({
    departmentName: 'test',
    Manager: 'test',
    Employees: 'test',
  });

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
  };

  return (
    <Wrapper>
      <FilterHeader>
        <TextField
          label={t('clients.orderDetails.search')}
          size="small"
          onChange={handleSearchTextChange}
          sx={{ marginRight: '24px' }}
        />
        <div
          className="containerColumns"
          style={{ marginBottom: '16px', marginRight: '24px' }}
        >
          <ColumnPopover
            headCells={heads}
            changeColumn={(id, value) => {
              changeColumnAction(id, value, heads, setHeads);
            }}
            clearAll={() => {
              clearAllAction(heads, setHeads);
            }}
            selectAll={() => {
              selectAllAction(heads, setHeads);
            }}
          />
        </div>
        <div className="containerFilter" style={{ marginBottom: '16px' }}>
          <Filters headCells={heads} setFilters={filters} />
        </div>
        <IconButtonWrapper>
          <If condition={allChecked}>
            <IconButton
              sx={{
                mb: '16px',
                mr: '8px',
              }}
            >
              <DeleteIcon />
            </IconButton>
          </If>
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            sx={{
              width: '32px',
              minWidth: '32px',
              height: '32px',
              borderRadius: '100%',
              marginBottom: '16px',
            }}
          >
            <AddIcon />
          </Button>
        </IconButtonWrapper>
        <If condition={openModal}>
          <DepartmentDrawer
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
          />
        </If>
        <If condition={openEditModal}>
          <DepartmentDrawer
            openModal={openEditModal}
            closeModal={() => setOpenEditModal(false)}
            department={department}
          />
        </If>
      </FilterHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox checked={allChecked} onChange={handleCheckedChange} />
              </TableCell>
              {departmentsHeadCellsConst.map(headCell => (
                <TableCell key={headCell.id}>
                  <If condition={headCell.disableRow === false}>
                    {headCell.label}
                  </If>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 95%;
  margin-left: 23px;
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
