import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';
import { EmailText } from 'styles/components/signin';
import { themes } from 'styles/theme/themes';

export type Props = TextFieldProps & {
  emailCheck: number;
  setEmailCheck: React.Dispatch<React.SetStateAction<number>>;
  rules?: Omit<
    RegisterOptions<any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  name: 'role' | 'jobTitle' | 'Email';
  checkResult?: { exists: boolean };
  control: Control<
    {
      Email: string;
      role: string;
      jobTitle: string;
    },
    any
  >;
  email: string;
};
export const EmailInputComponent: React.FC<Props> = ({
  emailCheck,
  setEmailCheck,
  control,
  style,
  name,
  size,
  rules,
  checkResult,
  email,
  ...props
}) => {
  return (
    <>
      <div style={style}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <>
              <TextField
                error={checkResult?.exists || emailCheck === -1 ? true : false}
                size={size}
                {...field}
                style={{ width: '100%' }}
                {...props}
                onBlur={() => {
                  setEmailCheck(email.search(/@/i));
                }}
              />
              <EmailText style={{ right: '34px', marginBottom: '16px' }}>
                @bamptee.com
              </EmailText>
            </>
          )}
        />
      </div>
      {checkResult?.exists === true && (
        <ErrorMessage>Email already exists</ErrorMessage>
      )}
      {emailCheck === -1 && (
        <ErrorMessage>You must Provide a Valid Email</ErrorMessage>
      )}
    </>
  );
};
export const ErrorMessage = styled.div`
  margin-left: 34px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: ${themes?.default?.errorMain};
`;
