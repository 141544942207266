export const userNameByRole = (role, roundTrip) => {
  return (
    roundTrip?.agents?.find(agent => agent?._role?.key === role)?._user
      ?.firstName +
    ' ' +
    roundTrip?.agents?.find(agent => agent?._role?.key === role)?._user
      ?.lastName
  );
};

