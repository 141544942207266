import { useTranslation } from "react-i18next";
import { FilterHead, FilterTitle } from "../../styles";
import { Close } from "@mui/icons-material";

interface FilterHeaderProps {
  onClearAll: (e: React.MouseEvent) => void;
}

export const FilterHeader = ({ onClearAll }: FilterHeaderProps) => {
  const { t } = useTranslation();
  return (
    <FilterHead>
      <FilterTitle>{t('common.filter_by')}</FilterTitle>
      <FilterTitle onClick={onClearAll} style={{ cursor: 'pointer' }}>
        <Close sx={{ width: '1rem', height: '1rem', mr: '5px', mb: '2px' }} />
        {t('common.buttons.clear_all')}
      </FilterTitle>
    </FilterHead>
  )
}