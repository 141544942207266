import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControlLabel,
  Typography,
  Grid,
  Switch,
  Button,
  TextField,
} from '@mui/material';

export default function ChorusForm({ isActive, serviceCode, onSubmit }) {
  const { t } = useTranslation();
  const methods = useForm({
    //mode: 'onChange',
    //reValidateMode: 'onBlur',
    defaultValues: {
      chorusIsActive: isActive,
      chorusServiceCode: serviceCode,
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const isChorusActive = watch('chorusIsActive');
  const formIsOk = isChorusActive && !isEmpty(watch('chorusServiceCode'));

  useEffect(() => {
    if (!isChorusActive) {
      onSubmit({
        chorusIsActive: false,
      });
    } else if (isChorusActive && formIsOk) {
      onSubmit({
        chorusIsActive: true,
      });
    }
  }, [isChorusActive]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        alignItems="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={4} md={3}>
          <Typography align="right">
            <strong>Activation</strong>
          </Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          <Controller
            name="chorusIsActive"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={isChorusActive !== true ? 'Désactivé' : 'Activé'}
                control={
                  <Switch {...field} checked={isChorusActive} />
                }
              />
            )}
          />
        </Grid>
      </Grid>

      {isChorusActive && (
        <>
          <Grid
            container
            alignItems="center"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4} md={3}>
              <Typography align="right">
                <strong>Code de service</strong>
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <Controller
                name="chorusServiceCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField {...field} variant="outlined" />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4} md={3} />
            <Grid item xs={8} md={9}>
              <Button
                disabled={!formIsOk}
                variant="contained"
                disableElevation
                size="large"
                type="submit"
              >
                {t('common.buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
}
