import {
  useGetCustomerPublicDetailsQuery,
  usePatchCustomerPublicMutation,
} from 'common/services/customerApi';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Alert,
  Badge,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Snackbar,
  ThemeProvider,
  Typography,
  createTheme,
  Grid,
  Switch,
  FormControlLabel,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { UploadFiles } from '../UploadFiles';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import If from '../If';
import ModalPDFViewer from '../ModalPDFViewer';
import { themes } from 'styles/theme/themes';
import { useToaster } from '../../../hooks/useToaster';
import { CheckStatus } from '../CheckStatus';
import ChorusForm from '../../pages/CustomerPublic/components/ChorusForm';

const theme = createTheme({
  palette: {
    warning: {
      main: themes?.default?.sunflower,
    },
  },
});

export function InfosList({ token, password, customer }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] =
    useState<boolean>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [updateCustomer] = usePatchCustomerPublicMutation();
  const [doc, setDoc] = useState<any>(null);
  const [customerFull, setCustomerFull] = useState<any>(customer);
  const toast = useToaster();

  const useStyles = makeStyles({
    customDialog: {
      width: '110vh',
    },
  });

  useEffect(() => {
    if (openSuccessSnackbar || openErrorSnackbar) {
      const timer = setTimeout(() => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [openSuccessSnackbar, openErrorSnackbar]);

  const classes = useStyles();

  const {
    data: customerData,
    isLoading,
    refetch,
  } = useGetCustomerPublicDetailsQuery({ token, password });

  useEffect(() => {
    if (customerData) {
      setCustomerFull(customerData);
    }
  }, [customerData]);

  const onToggleValue = async (key, value) => {
    const c = await updateCustomer({
      token,
      password,
      [key]: value,
    }).unwrap();

    setCustomerFull(c);
    toast(3000, 'success', 'common.save.success');
  };

  const handleChorusSubmit = async (values) => {
    console.log('handleChorusSubmit', values)
    const c = await updateCustomer({
      token,
      password,
      ...values,
    }).unwrap();

    setCustomerFull(c);
    toast(3000, 'success', 'common.save.success');
  }

  const getDocumentStatus = (type: string) => {
    const doc = customerData?.documents?.find(doc => doc.type === type);

    if (doc) {
      if (doc.isVerified === false && !isEmpty(doc.processedAt)) return 'error';

      return doc.isVerified === true ? 'success' : 'warning';
    }
    return 'error';
  };

  const sortedDocuments = useMemo(() => {
    const documentsCopy = [...customer.documents];
    return documentsCopy.sort((a, b) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    });
  }, [customer.documents]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const documentsStatusColor: any = {
    kbis: getDocumentStatus('kbis'),
    rib: getDocumentStatus('rib'),
    sepa: getDocumentStatus('sepa'),
    cni: getDocumentStatus('cni'),
  };

  return (
    <>
      <Wrapper>
        <Snackbar sx={{ marginLeft: '265px' }} open={openSuccessSnackbar}>
          <Alert
            variant="filled"
            severity="success"
            sx={{ width: '100%' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSuccessSnackbar(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <Snackbar sx={{ marginLeft: '265px' }} open={openErrorSnackbar}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenErrorSnackbar(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <DocIconsWrapper>
              <If condition={documentsStatusColor.kbis !== 'error'}>
                <IndicatorWrapper>
                  <ThemeProvider theme={theme}>
                    <Badge
                      color={documentsStatusColor.kbis}
                      badgeContent=""
                      sx={{ marginBottom: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        documentsStatusColor.kbis !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'kbis'),
                            )
                          : null
                      }
                    />
                  </ThemeProvider>
                  {documentsStatusColor.kbis === 'error' ? (
                    <Typography>KBIS</Typography>
                  ) : (
                    <Button
                      sx={{ height: '25px', color: themes?.default?.black }}
                      onClick={() =>
                        documentsStatusColor.kbis !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'kbis'),
                            )
                          : null
                      }
                    >
                      KBIS
                    </Button>
                  )}
                </IndicatorWrapper>
              </If>

              <If condition={documentsStatusColor.cni !== 'error'}>
                <IndicatorWrapper>
                  <ThemeProvider theme={theme}>
                    <Badge
                      color={documentsStatusColor.cni}
                      badgeContent=""
                      sx={{ marginBottom: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        documentsStatusColor.cni !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'cni'),
                            )
                          : null
                      }
                    />
                  </ThemeProvider>

                  {documentsStatusColor.cni === 'error' ? (
                    <Typography>ID</Typography>
                  ) : (
                    <Button
                      onClick={() =>
                        documentsStatusColor.cni !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'cni'),
                            )
                          : null
                      }
                      sx={{ height: '25px', color: themes?.default?.black }}
                    >
                      ID
                    </Button>
                  )}
                </IndicatorWrapper>
              </If>
              {customerData?.termsAcceptedAt ? (
                <IndicatorWrapper>
                  <Badge
                    color="success"
                    badgeContent=""
                    sx={{ marginBottom: '12px', cursor: 'pointer' }}
                    onClick={() =>
                      setDoc({
                        name: 'CGV.pdf',
                        url: customer._organization.termsUrl,
                      })
                    }
                  />
                  <Button
                    onClick={() =>
                      setDoc({
                        name: 'CGV.pdf',
                        url: customer._organization.termsUrl,
                      })
                    }
                    sx={{ height: '25px', color: themes?.default?.black }}
                  >
                    CGV
                  </Button>
                </IndicatorWrapper>
              ) : null}

              {/**
               *
              <If condition={documentsStatusColor.sepa !== 'error'}>
                <IndicatorWrapper>
                  <ThemeProvider theme={theme}>
                    <Badge
                      color={documentsStatusColor.sepa}
                      badgeContent=""
                      sx={{ marginBottom: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        documentsStatusColor.sepa !== 'error' ? (
                          setDoc(
                            sortedDocuments.find(doc => doc.type === 'sepa'),
                          )
                        ) : (
                          <></>
                        )
                      }
                    />
                  </ThemeProvider>
                  {documentsStatusColor.sepa === 'error' ? (
                    <Typography>SEPA</Typography>
                  ) : (
                    <Button
                      onClick={() =>
                        setDoc(sortedDocuments.find(doc => doc.type === 'sepa'))
                      }
                      sx={{ height: '25px', color: themes?.default?.black }}
                    >
                      SEPA
                    </Button>
                  )}
                </IndicatorWrapper>
              </If>
               */}

              <If condition={documentsStatusColor.rib !== 'error'}>
                <IndicatorWrapper>
                  <ThemeProvider theme={theme}>
                    <Badge
                      color={documentsStatusColor.rib}
                      badgeContent=""
                      sx={{ marginBottom: '12px', cursor: 'pointer' }}
                      onClick={() =>
                        documentsStatusColor.rib !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'rib'),
                            )
                          : null
                      }
                    />
                  </ThemeProvider>
                  {documentsStatusColor.rib === 'error' ? (
                    <Typography>RIB</Typography>
                  ) : (
                    <Button
                      onClick={() =>
                        documentsStatusColor.rib !== 'error'
                          ? setDoc(
                              sortedDocuments.find(doc => doc.type === 'rib'),
                            )
                          : null
                      }
                      sx={{ height: '25px', color: themes?.default?.black }}
                    >
                      RIB
                    </Button>
                  )}
                </IndicatorWrapper>
              </If>
            </DocIconsWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
          >
            <Button
              variant="contained"
              onClick={handleOpenModal}
              sx={{
                ml: '30px',
              }}
            >
              Envoyez vos documents
            </Button>
          </Grid>
        </Grid>
        {openModal && (
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth={'md'}
            classes={{ paper: classes.customDialog }}
          >
            <DialogContent>
              <UploadFiles
                customer={customer}
                paymentType={customer.defaultPaymentType}
                tokenPassword={password}
                onUploadSuccess={() => {
                  refetch();
                  setOpenModal(false);
                }}
              />
            </DialogContent>
          </Dialog>
        )}

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Information générale
            </Typography>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Code client :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography fontWeight="bold">{customer.code}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Raison Sociale :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.legalName ? customer.legalName : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Adresse de facturation :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.decks[0]?.location?.name
                    ? customer.decks[0]?.location?.name
                    : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>SIRET :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.legalId ? customer.legalId : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>TVA :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.vatNumber ? customer.vatNumber : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Mode de réglement :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.defaultPaymentType
                    ? t(`paymentTypes.${customer.defaultPaymentType}`)
                    : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <If condition={!isEmpty(customer.email)}>
                  <Typography>
                    {customer.email ? customer.email : '__'}
                    <CheckStatus
                      checked={customer.emailVerified}
                      checkTitle="Vérifié"
                      uncheckTitle="Non vérifié"
                    />
                  </Typography>
                </If>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email facturation :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <If condition={customer.billingEmail}>
                  <Typography>
                    {customer.billingEmail ? customer.billingEmail : '__'}
                    <CheckStatus
                      checked={customer.billingEmailVerified}
                      checkTitle="Vérifié"
                      uncheckTitle="Non vérifié"
                    />
                  </Typography>
                </If>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Tel :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.phone ? customer.phone : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Mobile :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.mobilePhone ? customer.mobilePhone : '__'}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ marginRight: 1, pb: 1, color: themes?.default?.darkGray }}
            >
              Adresses de Livraison
            </Typography>
            {customerData?.decks.filter((d) => d.location).map((deck, index) => (
              <>
                <DeckWrapper key={deck._id}>
                  <div>
                    <AdresseWrapper>
                      <AdresseTypoWrapper>
                        <Typography fontWeight="500" sx={{ marginRight: 1 }}>
                          Adresse n°{index + 1} :{' '}
                        </Typography>
                        <Typography>
                          {deck.location.name
                            ? deck.location.name
                            : t('customerPortal.infoSection.noAddress')}
                        </Typography>
                      </AdresseTypoWrapper>
                    </AdresseWrapper>
                  </div>
                </DeckWrapper>
                <DeliveryWrapper>
                  {deck.deliveryDays.map(deliveryDay => (
                    <Chip
                      key={deliveryDay}
                      label={t(`calendar.days.${deliveryDay}`)}
                      sx={{
                        mr: 1,
                        mb: 1,
                      }}
                      color="primary"
                    />
                  ))}
                </DeliveryWrapper>
                <DeliveryWrapper>
                  {deck.deliveryHours?.map(hour => (
                    <Chip
                      key={hour._id}
                      label={`${hour.begin} - ${hour.end}`}
                      sx={{
                        mr: '15px',
                        mb: 2,
                      }}
                    />
                  ))}
                </DeliveryWrapper>
              </>
            ))}
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Commercial
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Nom :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.fullName || 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.email || 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Tel :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.phone || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Préférences pour la reception des documents
            </Typography>
            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControlLabel
                  label={customerFull.blockDocumentsEmail !== true ? 'Activé' : 'Désactivé'}
                  control={
                    <Switch
                      checked={customerFull.blockDocumentsEmail !== true}
                      onChange={e =>
                        onToggleValue('blockDocumentsEmail', !e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Courrier Postal</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControlLabel
                  label={customerFull.blockDocumentsPaper !== true ? 'Activé' : 'Désactivé'}
                  control={
                    <Switch
                      checked={customerFull.blockDocumentsPaper !== true}
                      onChange={e =>
                        onToggleValue('blockDocumentsPaper', !e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Chorus Pro
            </Typography>

            <ChorusForm
              isActive={customerFull.chorusIsActive}
              serviceCode={customerFull.chorusServiceCode}
              onSubmit={handleChorusSubmit}
            />
          </Card>
        </div>

      </Wrapper>

      <ModalPDFViewer
        open={doc !== null}
        url={doc?.url}
        url2={doc?.url2}
        fileName={doc?.name}
        onClose={() => setDoc(null)}
      />
    </>
  );
}

const AdresseWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DocIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 50px;
  padding-top: 10px;
  width: 60px;

  @media (max-width: 600px) {
    margin-left: 8px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  margin: 0 auto;
`;

const DeckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const DeliveryWrapper = styled.div`
  margin-left: 90px;
  width: 100%;
`;

const AdresseTypoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
