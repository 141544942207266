import {
  Button,
  Chip,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Filters } from 'app/components/Filters';
import If from 'app/components/If';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { ColumnPopover } from 'app/components/TableComponent/ColumnPopover';
import { changeColumnAction } from 'functions/changeColumnAction';
import { clearAllAction } from 'functions/clearAllAction';
import { selectAllAction } from 'functions/selectAllAction';
import { WarehouseDrawer } from './WarehouseDrawer';
import {
  useGetWarehousesQuery,
  usePatchWarehouseMutation,
} from 'common/services/organizationApi';
import { warehousesHeadCellsConst } from 'interfaces/warehouses';
import { ActionPopover } from 'app/components/Popover';
import { filterItems } from 'functions/searchItemInList';
import { themes } from 'styles/theme/themes';
import MapPopover from 'app/components/MapPopover';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useToaster } from 'hooks/useToaster';
import { FixedTableHead } from 'app/components/TableComponent/helpers';
import NoResults from 'app/components/NoData/NoResults';
import { AddressIcon } from 'app/components/AddressIcon';
import { getAddressLabel } from 'app/helpers/helpers';
import EllipsisText from 'app/components/EllipsisText';
import { A } from 'app/components/A';
import { Checkbox } from 'app/components/CustomCheckbox';

export function Warehouses() {
  const toast = useToaster();
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>([]);
  const { data: warehouses, refetch, isFetching } = useGetWarehousesQuery();
  const [heads, setHeads] = useState(warehousesHeadCellsConst);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [patchWarehouse] = usePatchWarehouseMutation();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [mapCoordinates, setMapCoordinates] = useState(null);
  const [filteredWarehouses, setFilteredWarehouses] = useState<any>([]);

  const handleSearchTextChange = e => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
  };

  const handleChangeStatus = async (warehouseId: any, status: string) => {
    try {
      await patchWarehouse({
        deckId: warehouseId,
        status,
      });
      await refetch();
      if (status === 'active') {
        toast(3000, 'success', 'messages.warehouse.status.activated_success');
      } else {
        toast(3000, 'success', 'messages.warehouse.status.inactivated_success');
      }
    } catch (error) {
      toast(3000, 'error', 'messages.warehouse.status.error');
    }
  };

  function handleEditWarehouse(warehouse) {
    setOpenEditModal(true);
    setSelectedWarehouse(warehouse);
  }

  function handleCloseModal() {
    setOpenEditModal(false);
    refetch();
  }

  const handleOpenMap = warehouse => {
    setSelectedWarehouse(warehouse);
    setMapCoordinates(warehouse?.location?.geometry?.coordinates);
  };

  useEffect(() => {
    const filteredWarehouses1 = warehouses?.docs?.filter(warehouse => {
      for (const filter of filters) {
        const { operator, value, column } = filter;
        let filterBy = warehouse[column];
        if (column === 'location') {
          filterBy = warehouse.location?.name;
        } else if (column === 'hours') {
          filterBy = warehouse.openingHours?.length
            ? `${warehouse.openingHours[0]?.openingTime} - ${warehouse.openingHours[0]?.closingTime}`
            : ``;
        }
        switch (operator) {
          case 'contains':
            if (
              !filterBy ||
              !filterBy.toLowerCase().includes(value?.toLowerCase())
            ) {
              return false;
            }
            break;
          case 'equal':
            if (!filterBy || filterBy.toLowerCase() !== value?.toLowerCase()) {
              return false;
            }
            break;
        }
      }

      return true;
    });

    const filteredWarehouses2 = filterItems(filteredWarehouses1, searchText, [
      'name',
      'location.name',
    ]);

    setFilteredWarehouses(filteredWarehouses2);
  }, [filters, searchText, warehouses]);

  const chipSx = {
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    height: 'auto',
  };

  return (
    <Wrapper>
      <FilterHeader>
        <TextField
          label={t('clients.orderDetails.search')}
          size="small"
          value={searchText}
          onChange={handleSearchTextChange}
          placeholder={t('clients.orderDetails.search')}
          sx={{ marginRight: '24px' }}
          InputLabelProps={{ shrink: true }}
        />
        <div
          className="containerColumns"
          style={{ marginBottom: '16px', marginRight: '24px' }}
        >
          <ColumnPopover
            headCells={heads}
            changeColumn={(id, value) => {
              changeColumnAction(id, value, heads, setHeads);
            }}
            clearAll={() => {
              clearAllAction(heads, setHeads);
            }}
            selectAll={() => {
              selectAllAction(heads, setHeads);
            }}
          />
        </div>
        <FilterWrapper>
          <Filters headCells={heads} setFilters={setFilters} />
        </FilterWrapper>
        <IconButtonWrapper>
          {warehouses?.docs?.length > 0 && (
            <Button
              onClick={() => setOpenModal(true)}
              variant="contained"
              sx={{
                width: '32px',
                minWidth: '32px',
                height: '32px',
                borderRadius: '100%',
                marginBottom: '16px',
              }}
            >
              <AddIcon />
            </Button>
          )}
          <If condition={openModal}>
            <WarehouseDrawer
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
            />
          </If>
        </IconButtonWrapper>
      </FilterHeader>
      {filteredWarehouses?.length > 0 ? (
        <ScrollableTable>
          <Table>
            <FixedTableHead>
              <TableRow>
                <TableCell
                  padding="checkbox"
                  sx={{
                    pl: 0,
                  }}
                >
                  <Checkbox
                    checked={allChecked}
                    onChange={handleCheckedChange}
                  />
                </TableCell>
                {heads.map(
                  headCell =>
                    !headCell.disableRow && (
                      <TableCell key={headCell.id}>
                        {t(headCell.label)}
                      </TableCell>
                    ),
                )}
                <TableCell></TableCell>
              </TableRow>
            </FixedTableHead>
            <TableBody>
              {filteredWarehouses?.map(warehouse => {
                let highlightedRow: SxProps = {
                  '& td': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                };
                if (
                  selectedWarehouse?._id === warehouse._id &&
                  mapCoordinates
                ) {
                  highlightedRow = {
                    ...highlightedRow,
                    backgroundColor: theme.lightBlueActive,
                    borderTop: `2px solid ${theme.primaryActiveColor}`,
                    borderBottom: `2px solid ${theme.primaryActiveColor}`,
                  };
                }

                return (
                  <TableRow key={warehouse.id} sx={{ ...highlightedRow }}>
                    <TableCell
                      sx={{
                        pl: 0,
                      }}
                    >
                      <Checkbox color="primary" />
                    </TableCell>
                    {heads.map(head => (
                      <>
                        {!head.disableRow && head.id === 'name' && (
                          <TableCell>
                            <A
                              role="button"
                              className="link"
                              onClick={() => {
                                handleEditWarehouse(warehouse);
                              }}
                            >
                              {warehouse.name}
                            </A>
                          </TableCell>
                        )}
                        {!head.disableRow && head.id === 'initials' && (
                          <TableCell>{warehouse.shortName}</TableCell>
                        )}
                        {!head.disableRow && head.id === 'location' && (
                          <TableCell>
                            <div
                              className="locationWrapper"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => {
                                handleOpenMap(warehouse);
                              }}
                            >
                              <AddressIcon />
                              <EllipsisText
                                width={200}
                                text={getAddressLabel(warehouse?.location)}
                              />
                            </div>
                          </TableCell>
                        )}
                        {!head.disableRow && head.id === 'capacity' && (
                          <TableCell>
                            {warehouse.capacity ? warehouse.capacity : 0} m
                            <sup>3</sup>
                          </TableCell>
                        )}
                        {!head.disableRow && head.id === 'manager' && (
                          <TableCell>{warehouse.manager}</TableCell>
                        )}
                        {!head.disableRow && head.id === 'contact' && (
                          <TableCell>{warehouse.phone}</TableCell>
                        )}
                        {!head.disableRow && head.id === 'operatingHours' && (
                          <TableCell>
                            {warehouse.openingHours?.[0] ? (
                              <>
                                {warehouse.openingHours[0].openingTime}
                                <span> - </span>
                                {warehouse.openingHours[0].closingTime}
                              </>
                            ) : null}
                          </TableCell>
                        )}
                        {!head.disableRow && head.id === 'status' && (
                          <TableCell>
                            <If
                              condition={warehouse.status === 'active'}
                              otherwise={
                                <Chip
                                  label={t('inactive')}
                                  sx={{
                                    backgroundColor:
                                      themes?.default?.errorBackground,
                                    color: themes?.default?.errorMain,
                                    ...chipSx,
                                  }}
                                />
                              }
                            >
                              <Chip
                                label={t('active')}
                                sx={{
                                  backgroundColor: themes?.default?.success,
                                  color: themes?.default?.greenMain,
                                  ...chipSx,
                                }}
                              />
                            </If>
                          </TableCell>
                        )}
                      </>
                    ))}
                    <TableCell>
                      <ActionPopover
                        id={1}
                        handleAction={(event, action) => {
                          if (action === 'edit') {
                            handleEditWarehouse(warehouse);
                          }
                        }}
                        anchorOriginVertical={'bottom'}
                        anchorOriginHorizontal={'left'}
                        actions={[
                          {
                            action: 'activeOrNot',
                            onClick: () => {
                              handleChangeStatus(
                                warehouse._id,
                                warehouse.status !== 'active'
                                  ? 'active'
                                  : 'inactive',
                              );
                            },
                            element: (
                              <ActionWrapper>
                                <Typography fontSize={'0.857rem'}>
                                  {t(
                                    warehouse.status !== 'active'
                                      ? 'common.buttons.activate'
                                      : 'inactivate',
                                  )}
                                </Typography>
                              </ActionWrapper>
                            ),
                          },
                          {
                            action: 'edit',
                            element: (
                              <>
                                <ActionWrapper>
                                  <Typography fontSize={'0.857rem'}>
                                    {t('edit')}
                                  </Typography>
                                </ActionWrapper>
                              </>
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <If condition={openEditModal}>
            <WarehouseDrawer
              openModal={openEditModal}
              closeModal={handleCloseModal}
              warehouse={selectedWarehouse}
            />
          </If>
        </ScrollableTable>
      ) : (
        <If condition={!isFetching}>
          <AddWarehouseWrapper>
            <EmptyCenter>
              <NoResults />
            </EmptyCenter>
            {!warehouses?.docs?.length && (
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={() => setOpenModal(true)}
              >
                {t('common.add')}
              </Button>
            )}
          </AddWarehouseWrapper>
        </If>
      )}

      <MapPopover
        open={!!mapCoordinates}
        handleClose={() => {
          setMapCoordinates(null);
          setSelectedWarehouse({});
        }}
        latitude={mapCoordinates && mapCoordinates[1]}
        longitude={mapCoordinates && mapCoordinates[0]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 95%;
  margin-left: 23px;
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const AddWarehouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
`;

const ActionWrapper = styled.div`
  display: flex;
  padding-left: ${props => props.theme?.popoverLabelPadding};
  width: 100%;
`;
const FilterWrapper = styled.div`
  margin-bottom: 16px;
`;

const ScrollableTable = styled(TableContainer)`
  max-height: calc(100vh - 250px);

  & th {
    background: white;
  }
`;

const EmptyCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
