import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../slices/auth/selectors';
import { selectTheme } from '../../../styles/theme/slice/selectors';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import SwitchAgentButtonIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { Avatar, Divider, Select, Tooltip } from '@mui/material';
import styled from 'styled-components/macro';
import { Customization } from '../MainNav/Customizations';
import i18next from 'i18next';
import {LOGOUT, ORDORIA} from 'utils/routes';
import LogoutIcon from '@mui/icons-material/Logout';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { themes } from 'styles/theme/themes';
import { useApplication } from 'hooks/useApplication';
import { useModal } from '../Dialog/hooks';
import { stringAvatar } from 'app/helpers/helpers';
import If from '../If';
import { useSwitchAgentMutation } from 'common/services/authApi';
import { getId } from 'common/helpers/document';

interface LinkTabProps {
  label?: string;
  href?: string;
}

export function AppHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  let authUser = useSelector(selectAuthUser);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const currentApplication = useApplication(authUser);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElCustomer, setAnchorElCustomer] = React.useState<null | HTMLElement>(null);
  const [settingsDrawerIsOpen, setSettingsDrawerIsOpen] = useState(false);
  const { openModal, closeModal } = useModal();

  const [switchAgent] = useSwitchAgentMutation();

  const open = Boolean(anchorEl);
  const openCustomer = Boolean(anchorElCustomer);

  const handleOpenCustomerMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCustomer(event.currentTarget);
  };

  const handleSwitchAgent = async (agentId) => {
    setAnchorElCustomer(null);
    await switchAgent({ agentId }).unwrap();
  };

  if (!authUser) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const customerAgents = authUser.agents?.filter((a: any) => getId(a._organization) === getId(authUser?._currentOrganization) && a?._customer) || [];

  const handleLogout = () => {
    closeModal();
    handleClose();
    navigate(LOGOUT);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={settingsDrawerIsOpen}
        onClose={() => setSettingsDrawerIsOpen(false)}
      >
        <Customization setSettingsDrawerIsOpen={setSettingsDrawerIsOpen} />
      </Drawer>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          transition: '0.15s padding ease-out',
          height: theme.topBarHeight,
          minHeight: theme.topBarHeight,
          paddingLeft:
            mainMenuSize === 'minify'
              ? theme.mainNavWidthMin
              : theme.mainNavWidth,
          background: themes.default.accordionWhiteBg,
          borderBottom: `1px solid ${themes?.default?.lightFill}`,
          /* grey-shadow-web */
          boxShadow: 'none !important',
        }}
      >
        <Toolbar
          style={{ paddingRight: 0, paddingLeft: mainMenuSize === 'minify' ? '25px' : '285px', height: theme.topBarHeight, minHeight: theme.topBarHeight }}
        >
          {authUser.currentAgent?._customer && (
            <CustomerMenuWrapper>
              <Button
                id="basic-button"
                //aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                //aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenCustomerMenu}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                startIcon={customerAgents.length > 1 ? <SwitchAgentButtonIcon /> : null}
              >
                {authUser.currentAgent?._customer.code} - {authUser.currentAgent?._customer.legalName}
              </Button>

              <If condition={customerAgents.length > 1}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElCustomer}
                  open={openCustomer}
                  onClose={() => setAnchorElCustomer(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {customerAgents.map((agent: any) => (
                    <MenuItem onClick={() => handleSwitchAgent(agent._id)}>{agent?._customer.code} - {agent?._customer.legalName}</MenuItem>
                  ))}
                </Menu>
              </If>
            </CustomerMenuWrapper>
          )}
          <HemeraTabMenu>

          </HemeraTabMenu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <Actions>
            {currentApplication.id === 'ordoria' && false ? (
              <Select
                variant="outlined"
                color="info"
                defaultValue="eur"
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  marginRight: '10px',
                }}
              >
                <MenuItem value="eur">€ EUR</MenuItem>
                <MenuItem value="usd">$ USD</MenuItem>
              </Select>
            ) : (
              <></>
              // <IconButton
              //   size="large"
              //   aria-label="account of current user"
              //   aria-controls="menu-appbar"
              //   aria-haspopup="true"
              //   color="inherit"
              //   style={{ marginRight: '10px', marginBottom: '13px' }}
              // >
              //   <ChatRoundedIcon style={{ color: theme.icon }} />
              // </IconButton>
            )}

            {/* <Tooltip title={t('nav.appHeader.activity')} arrow>
              <IconButton
                size="large"
                aria-label={t('nav.appHeader.activity')}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ marginRight: '10px', marginBottom: '13px' }}
              >
                <Notifications style={{ color: theme.icon }} />
              </IconButton>
            </Tooltip> */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={e => {
                handlesetAnchorFunction(e, setAnchorEl);
              }}
              color="default"
              disableRipple
              sx={{
                paddingRight: '18px',
              }}
            >
              <UserName>
                {authUser.fullName}
                <UserRole style={{ fontWeight: 'normal' }}>
                  {authUser?.agents?.[0]?.['_role']?.localeName?.[
                    i18next?.resolvedLanguage ? i18next?.resolvedLanguage : 'en'
                  ] || ''}
                </UserRole>
              </UserName>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  background: theme.primaryLight,
                  color: theme.primary,
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  padding: '0.125rem',
                  boxSizing: 'content-box',
                }}
              >
                {stringAvatar(`${authUser.fullName}`)}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ marginTop: '-20px', marginLeft: '-10px' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div style={{ width: 236 }}>
                {/* <MenuItem onClick={handleClose}>
                  <Receipt
                    sx={{ color: theme.icon }}
                    style={{ marginRight: '20px' }}
                  />{' '}
                  {t('nav.appHeader.account.requests')}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <AccountCircle
                    sx={{ color: theme.icon }}
                    style={{ marginRight: '20px' }}
                  />{' '}
                  {t('nav.appHeader.account.profile')}
                </MenuItem>
                <Divider /> */}
                <MenuItem
                  onClick={() => {
                    openModal({
                      title: t('auth.pages.logout.confirmTitle'),
                      cancel: true,
                      action: {
                        actionCallback: handleLogout,
                        actionText: t('common.buttons.ok'),
                      },
                    });
                  }}
                >
                  <LogoutIcon
                    sx={{ color: theme.icon }}
                    style={{ marginRight: '20px' }}
                  />{' '}
                  {t('nav.appHeader.account.logout')}
                </MenuItem>
              </div>
            </Menu>
          </Actions>
        </Toolbar>

        {/* <Button
          variant="contained"
          onClick={() => setSettingsDrawerIsOpen(true)}
          disableElevation
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: theme.topBarHeight,
            height: theme.topBarHeight,
            borderRadius: 0,
          }}
        >
          <DashboardCustomize />
        </Button> */}
      </AppBar>
    </>
  );
}

const UserName = styled.div`
  display: inline-flex;
  font-size: 0.9em;
  text-align: right;
  flex-direction: column;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 0.813rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  padding-right: 8px;
  color: ${p => p.theme.blackPrimary};
  line-height: 1.2em;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomerMenuWrapper = styled.div`
  display: flex;
  padding-left: 24px;
`;

const HemeraTabMenu = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  align-self: end;
  height: 100%;
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
    height: 100%;
  }
`;

const UserRole = styled.span`
  font-weight: 300;
  color: ${p => p.theme.textLight};
`;
