import { NestedMenuComponent } from 'app/components/NestedMenu';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { TableNavContext } from '../../../..';
import { useContext, useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import { ClusterStatus } from 'app/pages/AddRoundTrips/data/status';

interface Props {
  element?: JSX.Element;
}

export const HoverMenu = ({ element }: Props) => {
  const { status, handleClick, handleDelete } = useContext(TableNavContext);
  const { t } = useTranslation();

  const actionText = useMemo(() => {
    if (status === ClusterStatus.Active) {
      return t('common.buttons.deactivate');
    }
    return t('common.buttons.activate');
  }, [t, status]);

  return (
    <NestedMenuComponent
      id="status-menu"
      iconAction={element}
      anchorOriginHorizontal={'right'}
      actions={[
        {
          action: 'status',
          nested: false,
          onClick: handleClick,
          element: <PopoverText>{actionText}</PopoverText>,
        },
        {
          seperator: true,
          action: 'seperator',
          element: <Divider />,
        },
        {
          action: 'delete',
          onClick: handleDelete,
          element: (
            <Typography color="error">
              <PopoverText>{t('delete')}</PopoverText>
            </Typography>
          ),
        },
      ]}
    />
  );
};

const PopoverText = styled('div')`
  padding-left: ${themes?.default?.popoverLabelPadding};
  font-size: 0.8125rem;
`;
