import { IconButton, LinearProgress, Typography } from "@mui/material";
import styled from "styled-components";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import If from "../If";
import { isUndefined } from "lodash";

export function AcceptedFiles({file, statusFile, hideProgressBar, fileProgress, deleteFile}) {

  return(
    <>
        <FilesListWrapper>
          <UploadFileIcon
            color="primary"
            sx={{ width: '28px', height: '28px', mr: '16px' }}
          />
          <div
            className="container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography fontSize="1rem" fontWeight="400">
              {file.name}
            </Typography>
            <div
              className="detailWrapper"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!isUndefined(file.size) && (
              <>
                <Typography>{(file.size / 1024).toFixed(2)}kb</Typography>{' '}
                <Typography sx={{ ml: '8px' }}>•</Typography>
              </>
            )}
              <Typography sx={{ ml: '8px' }}>{statusFile[file.name]}</Typography>
            </div>
            <If condition={hideProgressBar[file.name] === false} otherwise={<></>}>
              <LinearProgress
                variant="determinate"
                value={fileProgress[file.name]}
                sx={{ width: '200px', mt: '4px' }}
              />
            </If>
          </div>
          <IconButton
            sx={{ ml: 'auto' }}
            onClick={() => {deleteFile(file.name)}}
          >
            <CloseIcon color="action" />
          </IconButton>
        </FilesListWrapper>
    </>
  )
}

const FilesListWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
`;