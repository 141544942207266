import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetCustomerFilesQuery, useLazyViewCustomerFileQuery } from 'common/services/customerApi';
import styled from 'styled-components';
import {
  InputAdornment,
  MenuItem,
  Pagination,
  Stack,
  TextField,

} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ModalPDFViewer from '../ModalPDFViewer';
import DocItem from '../DocItem';

export function DocList({ authUser, token, password }) {
  const { t } = useTranslation();
  const [doc, setDoc] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [trigger, { data: documents, isLoading }] = useLazyGetCustomerFilesQuery();
  const [triggerViewFile] = useLazyViewCustomerFileQuery();

  const fileTypes = [
    { value: '', label: t('customerPortal.docs.all') },
    { value: 'invoice', label: t('customerPortal.docs.invoices') },
    { value: 'credit', label: t('customerPortal.docs.credits') },
    { value: 'return', label: t('customerPortal.docs.returns') },
  ];

  const [fileType, setFileType] = useState('');
  const query = `page=${page}&limit=10&text=${search}`;

  useEffect(() => {
    trigger({
      token,
      password,
      type: fileType,
      query,
    });
  }, [token, fileType, page, search]);

  const handleOpenFile = (doc) => {
    if (!authUser || authUser?.currentAgent._customer) {
      triggerViewFile({
        token,
        password,
        fileId: doc._id,
      });
    }

    setDoc(doc);
  }

  // Set for searchBar
  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  const handleChangeOrderPagination = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <FilterWrapper>
        <TextField
          variant="outlined"
          size="small"
          onChange={handleSearchChange}
          value={search}
          placeholder="Rechercher un document"
          sx={{ marginRight: 15, width: '250px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          size="small"
          id="outlined-select-currency"
          select
          label="Type de document"
          sx={{
            width: '242px',
          }}
          defaultValue="Tous"
          value={fileType}
          onChange={e => setFileType(e.target.value)}
        >
          {fileTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FilterWrapper>

      <DocWrapper>
        {documents?.docs.length > 0 ? (
          documents.docs.map((doc) => (
            <DocItem
              key={doc._id}
              document={doc}
              onOpenDocument={handleOpenFile}
            />
          ))
        ) : (
          <h1>No Files</h1>
        )}
        <Stack spacing={2}>
          <Pagination
            count={documents?.totalPages || 0}
            onChange={handleChangeOrderPagination}
          />
        </Stack>
      </DocWrapper>

      <ModalPDFViewer
        open={doc !== null}
        url={doc?.url}
        url2={doc?.url2}
        fileName={doc?.name}
        onClose={() => setDoc(null)}
      />
    </>
  );
}

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 15px;
`;

const DocWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
