import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ToolbarProps {
  title?: string;
  left?: any,
  center?: any,
  right?: any,
}

export const SimpleToolbar: React.FC<ToolbarProps> = (
  {
    title,
    left,
    center,
    right,
  }
) => {
  const { t } = useTranslation();

  return(
    <Wrapper>
      <LeftSection>
        <RegularPageTitle>
          {t(title || '')}
        </RegularPageTitle>
        {left}
      </LeftSection>
      <CenterSection>
        {center}
      </CenterSection>
      <RightSection>
        {right}
      </RightSection>
    </Wrapper>
  );
}

const Wrapper = styled.div.attrs({
  id: 'toolbarWrapper'
})`
  display: flex;
  
  padding: 0 0 0 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  background: #FFF;
  position: relative;
  z-index: 100;
  width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const CenterSection = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const RegularPageTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  white-space: nowrap;
`;

