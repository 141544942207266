import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';

import styled from 'styled-components';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { SearchOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { NO_DATA_REGULAR } from 'app/components/const';
import { themes } from 'styles/theme/themes';
import { Columns } from '@phosphor-icons/react';
import { Checkbox } from 'app/components/CustomCheckbox';

interface Props {
  name?: any;
  color?: string;
  handleEdit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  // headCells: readonly HeadCell[];
  headCells: any[];
  changeColumn: (id: string, value: boolean) => void;
  clearAll: () => void;
  selectAll: () => void;
}

export const ColumnPopover: React.FC<Props> = ({
  headCells: headCellsProp,
  changeColumn,
  clearAll,
  selectAll,
  name,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [search, setSearch] = React.useState<string>('');

  const headCells = [...headCellsProp].filter(c => !c.hidden);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center">
      <IconButton
        onClick={e => {
          if (name !== NO_DATA_REGULAR) handleClick(e);
        }}
        disabled={name === NO_DATA_REGULAR}
        sx={{
          fontSize: '0.875rem',
          opacity: name === NO_DATA_REGULAR ? 0.3 : 1,
          fontWeight: '500',
          cursor: name === NO_DATA_REGULAR ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Columns
          size={themes?.default?.iconSize}
          color={themes?.default?.iconColor}
        />
        {/* <ColumnText>{t('nav.table_nav.tab_columns')}</ColumnText> */}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div>
          <Typography padding="12px 12px 12px 16px" minWidth={123}>
            <TextFieldContainer
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <TextField
                size="small"
                margin="normal"
                id="standard-bare"
                onChange={e => {
                  // setSearch(e.target.value);
                  setSearch(e.target.value);
                }}
                // value={search}
                value={search}
                InputProps={{
                  startAdornment: <SearchOutlined sx={{ cursor: 'pointer' }} />,
                }}
              />
              {headCells
                .filter(headCell => {
                  return (
                    !search ||
                    t(headCell.filterLabel || headCell.label)
                      ?.toLowerCase()
                      .includes(search?.toLowerCase())
                  );
                })
                .sort((a, b) => {
                  return a.canChangeView - b.canChangeView;
                })
                .map(headCell => {
                  if (!headCell.label) return null;
                  if (!headCell.canChangeView) {
                    return (
                      <FormControlLabel
                        key={headCell.id}
                        disabled
                        control={<Checkbox defaultChecked />}
                        label={headCell.filterLabel || t(headCell.label)}
                      />
                    );
                  }
                  return (
                    <FormControlLabel
                      key={headCell.id}
                      control={<Checkbox checked={!headCell.disableRow} />}
                      label={headCell.filterLabel || t(headCell.label)}
                      onChange={(e: any) => {
                        changeColumn(headCell.id, e.target.checked as boolean);
                      }}
                    />
                  );
                })}
            </TextFieldContainer>
          </Typography>
          <PopOverFotter>
            <SelectClearEL onClick={clearAll}>
              {t('common.buttons.clear_all')}
            </SelectClearEL>
            <SelectClearEL onClick={selectAll}>
              {t('common.buttons.select_all')}
            </SelectClearEL>
          </PopOverFotter>
        </div>
      </Popover>
    </Stack>
  );
};

const PopOverFotter = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '0.125rem',

  height: '52px',

  background: props.theme.lightGrey,

  /* Inside auto layout */

  flex: 'none',
  order: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
}));

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectClearEL = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;
const ColumnText = styled.span`
  padding-left: 5px;
  color: ${props => props.theme.textColorPrimary};
`;
