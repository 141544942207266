import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const MapContentWrapper = styled.div(props => ({
  position: 'relative',
  zIndex: 10,
  padding: '0 11px',
  top: '0',
  pointerEvents: 'none',
}));

export const SearchWrapper = styled.div(props => ({
  boxShadow: `0px 5px 30px 0px ${themes?.default?.suvaGrey}40`,
  display: 'flex',
  columnGap: 15,
  alignItems: 'center',
  borderRadius: '4px',
  padding: '8px',
  backgroundColor: '#FFF',
  maxWidth: '380px',
  justifyContent: 'space-between',
  marginTop: 17,
  '& .search': {
    pointerEvents: 'all',
    height: 35,
  },
  ' & .MuiFormControl-root.MuiFormControl-marginNormal': {
    margin: 'unset',
    marginBottom: 'unset !important',
  },
  '& fieldset': {
    border: 'unset',
  },
  '& .buttons-wrapper': {
    display: 'flex',
    columnGap: 10,
    '& .button-el': {
      pointerEvents: 'all',
      width: 44,
      height: 35,
      backgroundColor: themes?.default?.accordionWhiteBg,
      // boxShadow: `0px 5px 30px ${props.theme.shadowGrayWhite}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '0.25rem',
      },
    },
  },
}));

export const PolygonLabelStyle = styled.div(props => ({
  color: props.theme.primary,
  WebkitTextStroke: `1.5px  ${themes?.default?.accordionWhiteBg}`,

  fontWeight: 700,
  fontSize: '1.75rem',
}));

export const MapWrapper = styled.div(props => ({
  maxHeight: `calc(100vh - ${props.theme.tableNavHeight} - ${props.theme.toolBarHeight})`,
  '& .marker-label': {
    fontSize: '0.5625rem !important',
    fontWeight: 700,
    width: '23px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  flexGrow: 1,
  '& .gm-style iframe + div': {
    border: '2px solid transparent !important',
  }
}));
