import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MarkerF, OverlayViewF, InfoWindowF } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useClickAway } from '@uidotdev/usehooks';
import { Chip } from '@mui/material';
import markerDeck from '../../../../assets/img/roundTrips/map-marker.svg';
import WarehouseMarker from 'assets/img/map/marker/WarehousePointMarker.svg';
import markerDefault from '../../../../assets/img/roundTrips/marker-step-default.svg';
import markerCompleted from '../../../../assets/img/roundTrips/marker-step-completed.svg';
import markerInProgress from '../../../../assets/img/roundTrips/marker-step-inprogress.svg';
import markerCanceled from '../../../../assets/img/roundTrips/marker-step-canceled.svg';
import markerUnassigned from '../../../../assets/img/roundTrips/marker-step-unassigned.svg';
import { StatusOrder } from '../../../pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { selectTheme } from '../../../../styles/theme/slice/selectors';
import MaintenancePin from 'assets/img/map/CustomerMap/MaintenancePin.png';
import FuelPin from 'assets/img/map/CustomerMap/FuelPin.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useToaster } from 'hooks/useToaster';
import { useUpdateRoundTripMutation } from 'common/services/roundtripApi';
import { themes } from "styles/theme/themes";
import { WarehouseInfoPopover } from "app/components/WarehouseInfoPopover";

interface MarkerStepProps {
  place: any;
  status?: string;
  label?: string;
  stopType?: string;
  order?: any;
  data?: any;
  date?: any;
  onClickOrder?: Function;
  type: string;
  customIcon?: string;
  index?: any;
  displayedStopKey?: string;
  setDisplayedStopKey?: React.Dispatch<React.SetStateAction<string>>;
  roundTripCopy?: any;
  setAssignedOrders?: any;
  warehouseData?: any;
  roundTripsCount?: any;
  ordersCount?: any;
  overlayWarehouseVisible?: boolean;
  setOverlayWarehouseVisible?: any;
}

export default function MarkerStep({
  place,
  status,
  type,
  label,
  order,
  data,
  date,
  onClickOrder,
  stopType,
  customIcon,
  index,
  displayedStopKey,
  setDisplayedStopKey,
  roundTripCopy,
  setAssignedOrders,
  warehouseData,
  roundTripsCount,
  ordersCount,
  overlayWarehouseVisible,
  setOverlayWarehouseVisible
}: MarkerStepProps) {
  const { i18n, t } = useTranslation();
  const theme = useSelector(selectTheme);
  const [displayInfoWindow, setDisplayInfoWindow] = useState(false);
  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const toast = useToaster();

  const ref = useClickAway(() => {
    setDisplayInfoWindow(false);
  });

  const [rtId, setRtId] = useState('');
  const [rtStops, setRtStops]: any = useState([]);
  const [currentRt, setCurrentRt]: any = useState();

  useEffect(() => {
    roundTripCopy?.map(rt => {
      rt?.stops.map(st => {
        if (st?._id === data?._id) {
          setRtId(rt._id);
          setRtStops(rt.stops);
          setCurrentRt(rt);
        }
      });
    });
  }, [roundTripCopy]);
  
  const handleDragStart_ = e => {
    const orderData = {
      oldIndex: index,
      orderId: data?._id,
      isAssigned: true,
    };
    const orderDataString = JSON.stringify(orderData);
    e.dataTransfer.setData('application/json', orderDataString);
  }

  const drop = e => {
    e.preventDefault();
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);
    const destIndex = parseInt(e.target.attributes.alt.value) - 1;
    const srcIndex = srcData.oldIndex - 1;
    const srcId = srcData.orderId;
    const destId = e.target.id;
    
    let rtOrdersIds:string[] = [];
    currentRt.stops.map(stop => {
      if (stop.type === 'maintenance' || stop.type === 'fuel') {
        rtOrdersIds.push(stop._id);
      }
      else if ( stop._order ) {
        rtOrdersIds.push(stop._order._id);
      }
      else {
        return;
      }
    });

    if (rtOrdersIds.includes(srcId) && rtOrdersIds.includes(destId)) {
      handleSwitchStop(e, srcIndex, destIndex);
    }
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height),
  });

  const handleSwitchStop = async (e, oldIndex, newIndex) => {
    e?.stopPropagation();
    if (currentRt?.stops && oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
      const from = currentRt.stops[oldIndex];
      const to = currentRt.stops[newIndex];
      const newOrder = [...currentRt.stops];

      newOrder[newIndex] = from;
      newOrder[oldIndex] = to;
      
      setAssignedOrders(newOrder);
      await updateRoundtripData({
        id: rtId,
        data: { stops: newOrder },
      });
      toast(5000, 'success', 'roundtrip.switched_stop');
    };
  };

  if (!place?.location?.geometry?.coordinates) {
    return null;
  }

  const position = {
    lat: place.location.geometry.coordinates[1],
    lng: place.location.geometry.coordinates[0],
  };

  if (type === 'deck') {
    return (
      <OverlayViewF
        position={{
          lat: place.location.geometry.coordinates[1],
          lng: place.location.geometry.coordinates[0],
        }}
        mapPaneName="floatPane"
        getPixelPositionOffset={getPixelPositionOffset.bind(0, 48, 44)}
      >
        <WarehouseIconContainer
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setOverlayWarehouseVisible(true);
          }}
        >
          <img src={WarehouseMarker} alt="" />
        </WarehouseIconContainer>
        {overlayWarehouseVisible && (
          <InfoWindowF
            position={position}
            options={{
              pixelOffset: new google.maps.Size(0 , 384),
              zIndex: 101,
              // disableAutoPan: true
            }}
          >
            <WarehouseInfoPopover
              warehouseData={warehouseData}
              roundTripsCount={roundTripsCount}
              ordersCount={ordersCount}
            />
          </InfoWindowF>
        )}
      </OverlayViewF>
    );
  }

  let icon = markerDefault;

  if (stopType === 'maintenance') {
    icon = MaintenancePin;
  }
  else if (stopType === 'fuel') {
    icon = FuelPin;
  }

  const handleOrderClick = () => {
    if (order && typeof onClickOrder === 'function') {
      onClickOrder(order);
    }
  };

  const formattedDate = date
    ? new Date(date).toLocaleTimeString(i18n.language, {
      hour: i18n.language === 'fr' ? '2-digit' : 'numeric',
      minute: '2-digit',
      hourCycle: i18n.language === 'fr' ? 'h24' : 'h12',
    })
    : null;

  const diff = date
    ? window.dayjs(date).diff(window.dayjs(order?.updatedStatusAt), 'minute')
    : null;

  const handleDisplayStopWindow = (display: boolean, key: string) => {
    setDisplayInfoWindow(display);
    setDisplayedStopKey && setDisplayedStopKey(key);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -10]}}]}}} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#616161E5',
      color: '#FFFFFF',
      maxWidth: 220,
      fontSize: '0.625rem',
      borderRadius: '4px',
      letterSpacing: '0px',
    },
  }));

  return (
    <>
      {stopType === 'maintenance' || stopType === 'fuel' ? (
        <OverlayViewF
          position={position}
          mapPaneName="floatPane"
          getPixelPositionOffset={getPixelPositionOffset.bind(0, 22, 26)}
        >
          <InfoWindowF
            position={position}
            options={{
              pixelOffset: new google.maps.Size(0, 12),
              zIndex: 100,
              disableAutoPan: true,
            }}
          >
            <HtmlTooltip
              title={
                <>
                  <TooltipTitle color="inherit">{data?._place?.location?.city}</TooltipTitle>
                  <TooltipContent>{data?._place?.name}</TooltipContent>
                </>
              }
            >
              <div>
                {stopType === 'maintenance' ? (
                  <img
                    id={data._id}
                    src={MaintenancePin}
                    alt={index}
                    draggable={true}
                    onDragStart={handleDragStart_}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={drop}
                    style={{
                      width: '22px',
                      height: '26px',
                    }}
                  />
                ) : stopType === 'fuel' ? (
                  <img
                    id={data._id}
                    src={FuelPin}
                    alt={index}
                    draggable={true}
                    onDragStart={handleDragStart_}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={drop}
                    style={{
                      width: '22px',
                      height: '26px',
                    }}
                  />
                ) : null}
              </div>
            </HtmlTooltip>
          </InfoWindowF>
        </OverlayViewF>
      ) : null}
    </>
  );
}

const StopDetail = styled.div`
  width: 248px;
  position: relative;
  padding: 4px 4px 12px 4px;
  line-height: 1.3;

  strong {
    display: block;
    font-weight: 700;
  }
`;

const OrderCode = styled.div`
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 8px;
  line-height: 10px;
`;

const BottomLine = styled.div`
  display: flex;
`;

const DateItem = styled.div`
  position: absolute;
  color: ${props => props.theme.textLight};
  right: 16px;
  top: 0px;
`;

const TooltipTitle = styled.div`
  font-weight: 500;
`;

const TooltipContent = styled.div`
  font-weight: 400;
`;

const WarehouseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 32px;
  margin: 10px;
  cursor: pointer;
`;