import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';
import { RootState } from '../../types/State';

const selectSlice = (state: RootState) => state.roundtrips || initialState;
export const selectStop = createSelector([selectSlice], state => state.stop);
export const selectDateBegin = createSelector(
  [selectSlice],
  state => state.dateBegin,
);
export const selectDateEnd = createSelector(
  [selectSlice],
  state => state.dateEnd,
);
export const selectEditingRoundtripVehicle = createSelector(
  [selectSlice],
  state => state.editingRoundtripVehicle,
);
export const selectAccordionStatus = createSelector(
  [selectSlice],
  state => state.accordionStatus,
);
export const selectEditingRoundtripUser = createSelector(
  [selectSlice],
  state => state.editingRoundtripUser,
);
export const selectClusterId = createSelector(
  [selectSlice],
  state => state.clusterId,
);

export const selectDateValue = createSelector(
  [selectSlice],
  state => state.dateValue,
);

export const selectEditOrders = createSelector(
  [selectSlice],
  state => state.editOrders,
);

export const selectInformRoundtripText = createSelector(
  [selectSlice],
  state => state.informRoundtripText,
);

export const selectPendingRequestStopIds = createSelector(
  [selectSlice],
  state => state.pendingRequestStopIds,
);

export const selectDisableActions = createSelector(
  [selectSlice],
  state => state.disableActions,
);
