import React from "react";
import styled from "styled-components";
import { themes } from "styles/theme/themes";
import { Icon } from "app/components/Icon";
import { ButtonBase } from "@mui/material";
import { Question } from "@phosphor-icons/react";
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  icon: JSX.Element | string;
  action: () => void;
  disabled?: boolean;
};

export const ChangeOrderButton: React.FC<ButtonProps> = ({
  icon,
  action,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <ChangeOrderBtnWrapper disabled={disabled}>
      <ButtonBase
        sx={{
          height: "24px",
          minWidth: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transitionDuration: "0.15s"
        }}
        onClick={action}
        disabled={disabled}
      >
        {typeof(icon) === "string" ? (
          <Icon
            icon={<img draggable={false} src={icon} alt="isolate rt" />}
            size={12}
            color={themes?.default?.secondaryIconColor}
          />
        ) : (
          <Icon
            icon={icon}
            size={12}
            color={themes?.default?.secondaryIconColor}
          />
        )}
      </ButtonBase>
    </ChangeOrderBtnWrapper>
  )
};

const ChangeOrderBtnWrapper = styled.div<{disabled?: boolean}>`
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themes?.default?.accordionWhiteBg};
  border: 0.5px solid ${themes?.default?.gainsboro2};
  };
  transition-duration: 0.15s;
  opacity: ${props => props.disabled ? "0.5" : "1" };

  &:hover {
    background: ${props => props.disabled ? "" : themes?.default?.lightBlueBg};
  }
`;