import {
  Alert,
  Autocomplete,
  Button,
  Snackbar,
  TextField,
} from '@mui/material';

import { Controller, useForm } from 'react-hook-form';

import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { EmailInputComponent } from 'app/components/EmailInputComponent';
import {
  useInviteUserMutation,
  useLazyCheckEmailQuery,
} from 'common/services/userApi';
import {
  EditUserTitle,
  FieldWrapper,
  FooterWrapper,
  SectionWrapper,
} from '../styles';
import { useGetConfigurationQuery } from 'common/services/organizationApi';
import { selectAuthUser } from 'app/slices/auth/selectors';
import i18next from 'i18next';
import { email_expression } from 'app/components/SignUp/variables';
import { useDebounce } from 'common/hooks/useDebounce';
import { selectTheme } from 'styles/theme/slice/selectors';
import { selectRoles } from 'common/store/organization/selectors';
interface Props {
  handleClose: () => void;
}

export const AddUser: React.FC<Props> = props => {
  const roles = useSelector(selectRoles);

  const theme = useSelector(selectTheme);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState('');
  const [emailCheck, setEmailCheck] = useState<number>(1);

  const handleClose = () => {
    props.handleClose();
  };
  const [trigger, { data: checkResult, isLoading, error }] =
    useLazyCheckEmailQuery();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      Email: '',
      role: '',
      jobTitle: '',
    },
  });
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;
  const email = watch('Email');
  const role = watch('role');
  const jobTitle = watch('jobTitle');
  const debouncedEmailTerm = useDebounce(email, 300);

  useEffect(() => {
    const triggerApiCall = async () => {
      const apiEndpoint = `email=${debouncedEmailTerm}`;

      // Perform API call using the `apiEndpoint` string

      trigger(apiEndpoint);

      try {
        const response = await fetch(apiEndpoint);
        // Handle response data
      } catch (error) {
        // Handle error
        console.log('error in checkemail useEffect', error);
      }
    };

    triggerApiCall();
  }, [debouncedEmailTerm]);

  const [inviteUser] = useInviteUserMutation();

  const onSubmit = async () => {
    try {
      await inviteUser({
        email: email,
        role: role,
        jobTitle: jobTitle,
      })
        .unwrap()
        .then(() => {
          handleClose();
        });

      handleClose();
    } catch (e: any) {
      console.log('e', e);
      setDisplayError(true);
      setDisplayErrorMessage(e.data.message);
    }
  };
  const rolesProps = {
    options: roles,
    getOptionLabel: (option: any) =>
      option.localeName[
        i18next.resolvedLanguage ? i18next.resolvedLanguage : 'en'
      ],
  };

  return (
    <form style={{ width: 455 }} onSubmit={handleSubmit(onSubmit)}>
      <EditUserTitle>
        <ArrowBackIcon
          sx={{ color: theme.icon, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className="title">Add New User</div>
      </EditUserTitle>

      <SectionWrapper>
        <div className="title-field">
          <div className="title" style={{ marginLeft: '-13rem' }}>
            Personal Info
          </div>
        </div>
      </SectionWrapper>
      <EmailInputComponent
        emailCheck={emailCheck}
        setEmailCheck={setEmailCheck}
        email={email}
        style={{
          width: '90%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
        }}
        name="Email"
        control={control}
        rules={{
          required: true,
          minLength: 2,
          pattern: {
            value: email_expression,
            message: 'Invalid email address',
          },
        }}
        size="small"
        label="Email"
        defaultValue=""
        variant="outlined"
        checkResult={checkResult}
      />
      <SectionWrapper>
        <div className="title-field">
          <div className="title">Position/Role</div>
          <div style={{ display: 'flex', gap: '32px' }}>
            <FieldWrapper>
              <Controller
                name="jobTitle"
                rules={{ required: true, minLength: 2 }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Job title"
                    variant="outlined"
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Autocomplete
                style={{ flex: 1 }}
                {...rolesProps}
                autoComplete={false}
                onChange={(e, value) => {
                  setValue('role', value?.key || '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name={'role'}
                    size="small"
                    label="Role"
                    variant="outlined"
                  />
                )}
              />
            </FieldWrapper>
          </div>
        </div>
      </SectionWrapper>
      <Snackbar
        open={displayError}
        autoHideDuration={6000}
        onClose={() => setDisplayError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          onClose={() => setDisplayError(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {displayErrorMessage}
        </Alert>
      </Snackbar>
      <FooterWrapper style={{ position: 'absolute', bottom: '36px' }}>
        <div className="footer">
          <div className="action-buttons">
            <Button onClick={handleClose} variant="outlined">
              CANCEL
            </Button>
            <Button
              disabled={
                !role ||
                !!errors.role ||
                !jobTitle ||
                !!errors.jobTitle ||
                !email ||
                !!errors.Email ||
                !!error ||
                emailCheck === -1
              }
              type="submit"
              variant="contained"
            >
              PROCEED
            </Button>
          </div>
        </div>
      </FooterWrapper>
    </form>
  );
};
