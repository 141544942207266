import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState, Application } from './types';
import { Position } from '../../types/Position';
import { Snackbar } from '../../types/Snackbar';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { AlertColor } from '@mui/material';
import authApi from '../../services/authApi';

export const BampteeApplications: Application[] = [
  {
    id: 'bianta',
    displayName: 'Bianta',
    url: '/bianta',
  },
  {
    id: 'ordoria',
    displayName: 'Ordoria',
    url: '/ordoria',
  },
  {
    id: 'stockee',
    displayName: 'Stockee',
    url: '/',
  },
  {
    id: 'default',
    displayName: 'Bamptee',
    url: '/',
  },
];
// hello

export const initialState: AppState = {
  currentDate: new Date().toISOString(),
  lastPosition: null,
  lastToasterNotification: null,
  selectedLanguage: 'fr',
  currentApplication: BampteeApplications[0],
  dropDownStatus: false,
  termsReaded: false,
  snackbar: {
    svg: null,
    show: true,
    description: '',
    title: '',
    status: 'unset',
  },
  cachedQueries: {},
  mutationCache: [],
  mutationResponseCache: {},
  isConnected: undefined,
  cameraScan: false,
  cameraScanKey: '',
  codeScanner: {},
  hasCameraPermission: false,
  gridPreferences: {
    customers: [
      'code',
      'legalName',
      'publicName',
      'status',
      'address',
      'phone',
      'email',
      'legalId',
      'branches',
      'amountPerWeight',
      '_salesman',
      '_customerType',
      'score',
      'invitation',
      'action',
    ],
    orders: [
      'code',
      'customerCode',
      'customer_code',
      'customer_name',
      'address',
      'createdBy',
      'quantity',
      'countLines',
      'weight',
      'delivery_date',
      'salesman',
      'payment',
      'notes',
      'status',
      'action',
    ],
    ordoriaOrders: [
      'code',
      'customerCode',
      'customer_code',
      'customer_name',
      'branch',
      'address',
      'quantity',
      'countLines',
      'weight',
      'order_discount',
      'createdBy',
      'createdOn',
      'delivery_date',
      'priority',
      'totalAmountTax',
      'caKg',
      'status',
      'action',
    ],
  },
};

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.locale('fr');

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentDate: (
      state,
      { payload: { date } }: PayloadAction<{ date: string }>,
    ) => {
      state.currentDate = new Date(date).toISOString();
    },
    setLastPosition: (
      state,
      { payload: { position } }: PayloadAction<{ position: Position }>,
    ) => {
      state.lastPosition = position;
    },
    setSelectedLanguage: (
      state,
      { payload: { language } }: PayloadAction<{ language: string }>,
    ) => {
      state.selectedLanguage = language;

      if (window?.dayjs) {
        window?.dayjs.locale(language);
      }

      dayjs.locale(language);
    },
    setLastToasterNotification: (
      state,
      {
        payload: { duration, severity, message, content },
      }: PayloadAction<{
        duration: number;
        severity: AlertColor;
        message: string;
        content?: JSX.Element;
      }>,
    ) => {
      state.lastToasterNotification = {
        duration: duration || 5000,
        severity,
        message,
        content,
      };
    },
    cleanLastToasterNotification: state => {
      state.lastToasterNotification = null;
    },
    setCurrentApplication: (
      state,
      { payload: { application } }: PayloadAction<{ application: Application }>,
    ) => {
      state.currentApplication = application;
    },
    setDropdownStatus: (
      state,
      { payload: { status } }: PayloadAction<{ status: boolean }>,
    ) => {
      state.dropDownStatus = status;
    },
    setSnackbar: (
      state,
      { payload: { snackbar } }: PayloadAction<{ snackbar: Snackbar }>,
    ) => {
      state.snackbar = snackbar;
    },
    resetSnackbar: (state, action) => {
      if (!state.snackbar) return;
      state.snackbar.show = false;
    },
    updateCache: (
      state,
      { payload: { key, data } }: PayloadAction<{ key: string; data: any }>,
    ) => {
      if (!state.cachedQueries) {
        state.cachedQueries = { [key]: { data } };
      } else {
        state.cachedQueries[key] = { data };
      }
    },
    resetCache: state => {
      state.cachedQueries = {};
    },
    setIsConnected: (
      state,
      { payload: { isConnected } }: PayloadAction<{ isConnected: boolean }>,
    ) => {
      state.isConnected = isConnected;
    },

    updateMutationResponseCache: (
      state,
      {
        payload: { identifier, data },
      }: PayloadAction<{ identifier: string; data: any }>,
    ) => {
      if (!state.mutationResponseCache[identifier]) {
        state.mutationResponseCache[identifier] = [data];
      } else {
        state.mutationResponseCache[identifier].push(data);
      }
    },
    resetIdentifierMutationResponseCache: (
      state,
      { payload: { identifier } }: PayloadAction<{ identifier: string }>,
    ) => {
      delete state.mutationResponseCache[identifier];
    },

    resetMutationResponseCache: state => {
      state.mutationResponseCache = {};
    },

    resetMutationCache: state => {
      state.mutationCache = [];
    },

    sliceMutationCache: (
      state,
      { payload: { count } }: PayloadAction<{ count: number }>,
    ) => {
      state.mutationCache = state.mutationCache.slice(count);
    },

    setCustomerGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        customers: columns,
      };
    },

    setOrderGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        orders: columns,
      };
    },

    setOrdoriaOrderGridPreferences: (
      state,
      { payload: { columns } }: PayloadAction<{ columns: string[] }>,
    ) => {
      state.gridPreferences = {
        ...initialState.gridPreferences,
        ordoriaOrders: columns,
      };
    },

    updateCameraScan: (
      state,
      {
        payload: { cameraScan, cameraScanKey },
      }: PayloadAction<{ cameraScan: boolean; cameraScanKey?: string }>,
    ) => {
      state.cameraScan = cameraScan;
      if (cameraScanKey) state.cameraScanKey = cameraScanKey;
    },

    updateCodeSCanner: (
      state,
      {
        payload: { codeScanner, key },
      }: PayloadAction<{ codeScanner: any; key: string }>,
    ) => {
      state.codeScanner[key] = codeScanner;
    },

    setCameraPermission: (
      state,
      {
        payload: { hasCameraPermission },
      }: PayloadAction<{ hasCameraPermission: boolean }>,
    ) => {
      state.hasCameraPermission = hasCameraPermission;
    },

    setTermsReaded: (
      state,
      { payload: { isRead } }: PayloadAction<{ isRead: boolean }>,
    ) => {
      state.termsReaded = isRead;
    },

    addMutationCache: (
      state,
      {
        payload: {
          slice,
          endpoint,
          param,
          dependencies,
          identifier,
          aditionalParameters,
          optimisticUpdate,
          formDataKeys,
          updateCacheStrategy, // string
          updateCacheParameters, // any

          updateParamsStrategy,
          updateParamsParameters,
        },
      }: PayloadAction<{
        slice: any;
        endpoint: string;
        param: any;
        dependencies: any[];
        identifier?: string;
        aditionalParameters?: any;
        updateCacheStrategy?: string;
        updateCacheParameters?: any;
        updateParamsStrategy?: string;
        updateParamsParameters?: any;
        optimisticUpdate?: boolean;
        formDataKeys?: string[];
      }>,
    ) => {
      state.mutationCache.push({
        slice,
        endpoint,
        param,
        dependencies,
        identifier,
        aditionalParameters,
        updateCacheStrategy,
        updateCacheParameters,
        updateParamsStrategy,
        updateParamsParameters,
        optimisticUpdate,
        formDataKeys,
      });
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        authApi.endpoints.check.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.selectedLanguage = user.language;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.me.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.selectedLanguage = response.payload?.language;
          }

          return state;
        },
      );
  },
});

const appReducer = slice.reducer;

export const { actions: appActions } = slice;

export default appReducer;
