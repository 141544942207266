import { MarkerF, OverlayView } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import MarkerRed from 'assets/img/map/CustomerMap/circleDanger.svg';
import MarkerOrange from 'assets/img/map/CustomerMap/circleWarning.svg';
import MarkerBlue from 'assets/img/map/CustomerMap/circleActive.svg';
import If from 'app/components/If';

interface MarkerClientProps {
  coordinates: [number, number];
  code?: string;
  color: string;
  name?: string;
  amountPerWeight: number;
  onToggleOpen: () => void;
  isSelected: boolean;
  turnover: boolean;
  names: boolean;
  nmbOrders: boolean;
  euroByKg: boolean;
  totalAmount: number;
  totalOrders: number;
  clusterer?: any;
}

export const MarkerClient = ({
                               coordinates,
                               code,
                               name,
                               amountPerWeight,
                               onToggleOpen,
                               isSelected,
                               turnover,
                               names,
                               nmbOrders,
                               euroByKg,
                               totalAmount,
                               totalOrders,
                               clusterer,
                               color,
                             }: MarkerClientProps) => {
  const [openLayer, setOpenLayer] = useState<boolean>(false);

  const places = {
    lat: coordinates[1],
    lng: coordinates[0],
  };

  const handleOpenLayer = () => {
    setOpenLayer(!openLayer);
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  let icon = MarkerRed;
  let textColor = 'red';

  if (color === 'orange') {
    icon = MarkerOrange;
    textColor = '#FF9800';
  } else if (color === 'blue') {
    icon = MarkerBlue;
    textColor = '#4CAF50';
  }

  useEffect(() => {
    if (isSelected === false) {
      setOpenLayer(false);
    }
  }, [isSelected]);

  function formatKNumber(number) {
    if (number < 1000) {
      return number.toFixed(1);
    } else {
      return (number / 1000).toFixed(1) + 'K';
    }
  }

  return (
    <>
      <MarkerF
        position={places}
        onClick={() => {
          handleOpenLayer();
          onToggleOpen();
        }}
        clusterer={clusterer}
        icon={icon}
      />
      {openLayer && (
        <OverlayView
          position={places}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset.bind(this, 170, 150)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              minWidth: '175px',
              minHeight: names ? '39px' : '25px',
              position: 'absolute',
              borderRadius: '4px',
              zIndex: 999,
              display: 'flex',
              flexDirection: 'column',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              visibility:
                !names && !nmbOrders && !euroByKg && !turnover
                  ? 'hidden'
                  : 'visible',
            }}
          >
            <Grid
              sx={{
                gap: '9px',
                padding: '5px 10px',
              }}
            >
              <If condition={names}>
                <Grid container alignItems="center">
                  <Typography
                    fontSize="10px"
                    fontWeight="700"
                    sx={{
                      marginRight: '4px',
                    }}
                  >
                    {code}
                  </Typography>
                  <Typography
                    fontSize="10px"
                    fontWeight="400"
                    sx={{
                      color: '#9E9E9E',
                      maxWidth: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {name}
                  </Typography>
                </Grid>
              </If>
              <Grid container alignItems="center">
                <If condition={nmbOrders}>
                  <span
                    style={{
                      marginRight: '4px',
                    }}
                  >
                    {totalOrders ?? 0} Orders
                  </span>
                </If>
                <If condition={euroByKg}>
                  <span
                    style={{
                      color: textColor,
                    }}
                  >
                    {amountPerWeight?.toFixed(1)}
                  </span>
                </If>
                <If condition={turnover}>
                  <Typography
                    fontSize="10px"
                    fontWeight="700"
                    sx={{
                      marginLeft: 'auto',
                    }}
                  >
                    € {formatKNumber(totalAmount)}
                  </Typography>
                </If>
              </Grid>
            </Grid>
          </div>
        </OverlayView>
      )}
    </>
  );
};
