import { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { LeftMenuContext } from '../../../LeftMenu';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { isEmpty } from 'lodash';
import If from 'app/components/If';
import { RoundtripCollapsed } from './components/RoundtripCollapsed';
import { RoundtripExpanded } from './components/RoundtripExpanded';
import { useAddStopRoundtripMutation } from 'common/services/roundtripApi';
import { useToaster } from 'hooks/useToaster';
import { removeItemsFromOrder } from 'app/pages/AddRoundTrips/components/RightBar/function';
import { themes } from 'styles/theme/themes';
import TruckIcon from 'app/components/TruckIcon';
import {
  deleteFunctionStop,
  updateOrderFunction,
} from 'app/components/RoundTrips/function/ApiFunctions';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { getRoundtripUserAndVehicleInfo } from 'app/helpers/helpers';

export interface RoundtripItemProps {
  roundtrip: any;
  roundtripInfo: { missingUser: boolean; hasVehicle: boolean };
  isHovered?: boolean;
}

interface Props {
  roundtrip: any;
  onClick?: (e?: any) => void;
  isSelected?: boolean;
  orders: any;
  setOrders: any;
  hoveringOverRt: string;
  setHoveringOverRt: Function;
}

export const RoundtripListItem = ({
  roundtrip,
  onClick,
  isSelected,
  orders,
  setOrders,
  hoveringOverRt,
  setHoveringOverRt,
}: Props) => {
  const { userContext, viewMode, collapsed } = useContext(LeftMenuContext);
  const toast = useToaster();

  const [isHovered, setIsHovered] = useState(false);

  const userRole = userContext === AgentCategories.Sales ? Sales : Driver;

  const roundtripInfo = useMemo(() => {
    return getRoundtripUserAndVehicleInfo({
      agents: roundtrip?.agents,
      vehicle: roundtrip?._vehicle?.numberPlate,
      role: userRole,
    });
  }, [roundtrip, userRole]);

  const [addStopRoundtrip] = useAddStopRoundtripMutation();

  const updateOrders = orderId => {
    const selectedStop = orders.filter(order => order._id === orderId)[0];
    const updatedOrders = removeItemsFromOrder(orders, selectedStop);
    setOrders(updatedOrders);
  };

  const assignOrder = async (id, data) => {
    // data?.map(order => {
    //   updateOrders(order?._order);
    // })
    await addStopRoundtrip({
      id: id,
      data: {
        stops: data,
      },
    }).unwrap();
    toast(5000, 'success', 'alerts.orders.assign_success');
  };

  const drop = async e => {
    e.preventDefault();
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);
    const destId = e.target.offsetParent.id;
    setDragOver(false);

    if (srcData.isAssigned) return;

    await assignOrder(destId, srcData);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const [dragOver, setDragOver] = useState(false);

  return (
    <>
      <Wrapper
        id={roundtrip?._id}
        onClick={onClick}
        isSelected={isSelected || hoveringOverRt === roundtrip?._id}
        onMouseEnter={() => {
          setIsHovered(true);
          setHoveringOverRt(roundtrip?._id);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setHoveringOverRt('');
        }}
        collapsed={collapsed}
        viewMode={viewMode}
        onDragOver={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnter={() => setDragOver(true)}
        onDragLeave={e => {
          const relatedTargetNode = e.relatedTarget as Node;
          if (
            !relatedTargetNode ||
            !e.currentTarget.contains(relatedTargetNode)
          ) {
            setDragOver(false);
          }
        }}
        onDrop={drop}
        style={{
          borderColor: dragOver ? themes.default.primary : '',
          background: dragOver ? '#F6F8FF' : '',
        }}
      >
        <If
          condition={!!collapsed}
          otherwise={
            <RoundtripExpanded
              roundtrip={roundtrip}
              roundtripInfo={roundtripInfo}
              isHovered={isHovered}
            />
          }
        >
          <RoundtripCollapsed
            roundtrip={roundtrip}
            roundtripInfo={roundtripInfo}
            isHovered={isHovered}
          />
        </If>
      </Wrapper>
      <TruckIconWrapper className="truckIconWrapper" collapsed={collapsed}>
        <If condition={roundtripInfo?.missingUser || !roundtripInfo?.hasVehicle}>
          <TruckIcon
            driver={!!roundtripInfo?.missingUser}
            vehicle={!roundtripInfo?.hasVehicle}
            running={roundtrip?._vehicle?.engineRunning}
            status={roundtrip?._vehicle?.status}
            style={{
              position: 'relative',
            }}
            iconStyle={ collapsed ? ({
              icon: { width: '0.7rem', height: '0.7rem' },
              img: { width: '0.7rem', height: '0.7rem' },
            }) : undefined}
          />
        </If>
      </TruckIconWrapper>
    </>
  );
};

interface WrapperProps {
  collapsed?: boolean;
  isSelected?: boolean;
  viewMode?: boolean;
}
interface ActionWrapperProps {
  isHovered?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${props => (props.collapsed ? 'row' : 'column')};
  justify-content: space-between;
  width: ${props => (props.collapsed ? '89px' : '229px')};
  overflow: hidden;
  gap: ${props => (props.collapsed ? '4px' : '8px')};
  align-items: ${props => (props.collapsed ? 'center' : 'flex-start')};
  align-self: stretch;
  border-radius: ${props => (props.collapsed ? '4px' : '8px')};
  border: 1px solid
    ${props => (props.isSelected ? props.theme.primary : props.theme.grey5)};
  font-size: 0.8125rem;
  position: relative;
  cursor: pointer;
  padding: ${props =>
    props.collapsed ? (props.viewMode ? '0 8px 0 4px' : '0 4px') : '8px'};
  & div {
    margin-right: 0;
  }
  transition: all 0.3s ease-in-out;
`;

export const ActionWrapper = styled.div<ActionWrapperProps>`
  visibility: ${props => (props.isHovered ? 'visible' : 'hidden')};
  margin-left: auto;
  text-align: end;
`;

const TruckIconWrapper = styled.div<{collapsed}>`
  position: absolute;
  top: ${props => props.collapsed ? "5px" : "7px"};
  right: ${props => props.collapsed ? "-12px" : "-15px"};
`;
