import { toolsActions } from 'app/slices/tools';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
export const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const pdfUrl = `${process.env.PUBLIC_URL}/pdf/privacy-policy.pdf`;
  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
    return () => {
      dispatch(toolsActions.setZoomIn({ status: false }));
    };
  });
  return (
    <Wrapper>
      <embed src={pdfUrl} width="100%" height="100%" type="application/pdf" />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin-bottom: -5px;
  overflow: hidden;
`;
