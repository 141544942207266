import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Typography
} from '@mui/material'
export default function RefuseDocumentModal({ isVisible, onRefuse, onClose }) {
  const [reason, setReason] = useState<string>('')
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={isVisible}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '450px',
          },
        }}
      >
        <DialogContent>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 3 }}>
            Refuser le document ?
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            onChange={(v) => setReason(v.target.value)}
            value={reason}
            placeholder={t('refuseDocumentModal.placeholder')}
            sx={{ width: '100%' }}
          />

          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
            >
              {t('cancel')}
            </Button>

            <Button
              disabled={reason === ''}
              variant="contained"
              color="primary"
              onClick={() => onRefuse(reason)}
            >
              {t('common.buttons.submit')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
