import api from './api';

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<any, string>({
      query: urlParams => ({
        url: `/products${urlParams}`,
      }),
      providesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery
} = productApi;

export default productApi;
