import { get, isEmpty } from 'lodash';
/**
 * @param items List of items that you want to search in
 * @param search Search string
 * @param columnsList List of columns that you want to search in
 * @returns List of filtered items
 */
export const filterItems = (items, search, columnsList) => {
  if (isEmpty(search)) return items;

  return items.filter((item) => {
    for (const column of columnsList) {
      if (
        get(item, column)?.toLowerCase().includes(search.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  });
};
