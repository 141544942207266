import React, { useEffect, useState } from 'react';
import { isEmpty, size, uniqBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import {
  Avatar,
  Collapse,
  Tooltip,
  IconButton,
  Badge,
  List,
} from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';
import AddIcon from '@mui/icons-material/Add';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../slices/auth/selectors';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { DashboardOutlined, SwapHorizOutlined } from '@mui/icons-material';
import { Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { appActions } from 'common/store/app';
import { MainNavItem } from './helpers';
import {
  useGetConfigurationQuery,
  useLazyGetOrganizationActivitiesQuery,
} from 'common/services/organizationApi';
import {
  SETTINGS_COMPANY_PROFILE,
  ORDORIA,
  DASHBOARD_ALL,
  REPORTS,
  REPORTS_ALL,
} from 'utils/routes';
import { checkUserAcl } from '../../../common/helpers/acl';
import { ActionPopover } from '../Popover';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LogoutIcon from '@mui/icons-material/Logout';
import BampteeLogo from '../../../assets/img/Menu/bamptee-logo-white.svg';
import BampteeLogoColor from '../../../assets/img/Menu/BampteeLogo.svg';
import OrdoriaLogoInside from 'assets/img/Menu/OrdoriaLogo.svg';
import OrdoriaLogoOutside from 'assets/img/Menu/Logo-Ordoria-lightmode2.svg';
import GescomLogo from '../../../assets/img/Menu/GescomLogo.png';
import CollapseIcon from './Common/ArrowSVG';
import { getInitials } from '../../../common/helpers/organization';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import If from '../If';
import { useSwitchOrganizationMutation } from 'common/services/authApi';
import { avatarColors, themes } from '../../../styles/theme/themes';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useApplication } from '../../../hooks/useApplication';
import { BampteeApplications } from 'common/store/app';
import { useToaster } from '../../../hooks/useToaster';
import { useSubscribeObject } from 'common/hooks/ws';
import BiantaMenu from './BiantaMenu';
import OrdoriaMenu from './OrdoriaMenu';
import { CaretDown } from '@phosphor-icons/react';
import EllipsisText from '../EllipsisText';

const ordoriaLogo = {
  inside: OrdoriaLogoInside,
  outside: OrdoriaLogoOutside,
};

const applicationsImg = {
  default: BampteeLogo,
  ordoria: ordoriaLogo,
  bianta: GescomLogo,
};

export function isActiveRoute(location: any, path: string) {
  return path === location.pathname;
}

export function isActiveNestedRoute(location: any, paths: string[]) {
  return paths?.includes(location.pathname);
}

export function OrganizationAvatar({
  isActive = false,
  isAllActive = false,
  organization,
  colorIndex,
  onClick,
}: any) {
  const isCurrentOrganization = isActive;

  return (
    <Tooltip title={organization.name} placement="right">
      <Avatar
        onClick={onClick}
        sx={{
          width: '32px',
          height: '32px',
          bgcolor: isCurrentOrganization
            ? themes.default.accordionWhiteBg
            : themes.default.blue50,
          mb: '20px',
          border: isCurrentOrganization
            ? `1px solid ${themes?.default?.accordionWhiteBg}`
            : `1px solid ${themes.default.blue50}`,
          cursor: 'pointer',
          outline: isCurrentOrganization
            ? `${
                organization._id !== 'all' && isAllActive
                  ? 'rgba(255, 255, 255, 0.5)'
                  : themes?.default?.accordionWhiteBg
              } solid 1.85px`
            : 'none',
          outlineOffset: isCurrentOrganization ? '4px' : 'none',
          color: isCurrentOrganization
            ? themes.default.primary
            : themes.default.black,
        }}
        alt={organization.name}
        imgProps={{ sx: { objectFit: 'contain' } }}
      >
        <Typography fontSize="0.75rem" fontWeight="400">
          {!isEmpty(organization.shortname)
            ? organization.shortname
            : getInitials(organization.name)}
        </Typography>
      </Avatar>
    </Tooltip>
  );
}

function LeftNav({
  applicationId,
  organizations,
  currentOrganization,
  onSwitchOrganization,
  theme,
  displayAppSelector,
  setDisplayAppSelector,
}) {
  const navigate = useNavigate();
  const toast = useToaster();
  const authUser = useSelector(selectAuthUser);
  const isAdmin = checkUserAcl(authUser, ['*']);
  const userRole = authUser?.currentAgent?._role?.key;
  const location = useLocation();
  const organizationsUniq = uniqBy(organizations, '_id');

  const isAllActive =
    location.pathname === DASHBOARD_ALL || location.pathname === REPORTS_ALL;

  const displayAllOrganizationsDashboard =
    checkUserAcl(authUser, ['admin']) &&
    currentOrganization?._organizationCluster;

  const handleSelectApplication = async applicationId => {
    setDisplayAppSelector(false);

    const app = BampteeApplications.find(app => app.id === applicationId);

    // @ts-ignore
    if (app) {
      navigate(`${app?.url || ''}`);
      toast(3000, 'info', 'application.welcome', {
        application: app.displayName,
      });
    }
  };

  const handleClickOnApplicationLogo = () => {
    // TODO: remove before release client
    if (isAdmin || userRole === 'sales') {
      setDisplayAppSelector(true);
    }
  };

  return (
    <>
      <Popover
        id="appSelector"
        open={displayAppSelector}
        onClose={() => setDisplayAppSelector(false)}
        sx={{
          position: 'absolute',
          top: '-2px',
          left: '-6px',
        }}
        PaperProps={{
          style: {
            width: '50px',
            height: '170px',
            padding: '15px 12px',
            overflow: 'hidden',
            boxSizing: 'border-box',
          },
        }}
      >
        <ImgWrapper>
          <Tooltip
            title="Bamptee"
            placement="right"
            onClick={() => handleSelectApplication('default')}
          >
            <img src={BampteeLogoColor} style={{ cursor: 'pointer' }} />
          </Tooltip>
          <Tooltip
            title="Ordoria"
            placement="right"
            onClick={() => handleSelectApplication('ordoria')}
          >
            <img src={ordoriaLogo.outside} style={{ cursor: 'pointer' }} />
          </Tooltip>
          <Tooltip title="Gescom" placement="right">
            <img
              src={GescomLogo}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSelectApplication('bianta')}
            />
          </Tooltip>
        </ImgWrapper>
      </Popover>

      <LeftMenu className={applicationId}>
        <Logo onClick={handleClickOnApplicationLogo}>
          {applicationId === 'ordoria' ? (
            <img src={ordoriaLogo.inside} alt={applicationId} />
          ) : (
            <img
              src={applicationsImg[applicationId] || applicationsImg.default}
              alt={applicationId}
            />
          )}
        </Logo>
        <AvatarWrapper>
          <AvatarUserWrapper>
            {displayAllOrganizationsDashboard && (
              <OrganizationAvatar
                key="all"
                isActive={isAllActive}
                isAllActive={isAllActive}
                organization={{
                  name: 'All',
                  shortname: 'All',
                  _id: 'all',
                }}
                colorIndex={0}
                onClick={() => navigate(DASHBOARD_ALL)}
              />
            )}
            {organizationsUniq.map((organization: any, index: number) => {
              return (
                <Tooltip
                  title={currentOrganization.name}
                  placement="right"
                  key={organization._id}
                >
                  <OrganizationAvatar
                    key={organization._id}
                    isAllActive={isAllActive}
                    organization={organization}
                    colorIndex={index + 1}
                    isActive={currentOrganization._id === organization._id}
                    onClick={() => onSwitchOrganization(organization._id)}
                  />
                </Tooltip>
              );
            })}
          </AvatarUserWrapper>
          {/* <div
            className="popover"
            style={{
              borderRadius: '4px',
              backgroundColor: themes?.default?.solitude,
            }}
          >
            <ActionPopover
              iconAction={<AddIcon sx={{ color: theme.primaryActiveColor }} />}
              id={2}
              styling={{
                width: '24px',
                height: '24px',
                justifyContent: 'center',
              }}
              handleAction={(event, action) => {
                if (action === 'create') {
                }
              }}
              anchorOriginVertical={'top'}
              anchorOriginHorizontal={'right'}
              actions={[
                {
                  action: 'SignInOther',
                  element: (
                    <PopoverWrapperActions style={{ width: '232px' }}>
                      <LoginIcon color="action" sx={{ ml: '10px' }} />
                      <Typography sx={{ ml: '8px' }}>
                        Sign In To Another Company
                      </Typography>
                    </PopoverWrapperActions>
                  ),
                },
                {
                  action: 'create',
                  element: (
                    <PopoverWrapperActions style={{ width: '232px' }}>
                      <CreateNewFolderIcon color="action" sx={{ ml: '10px' }} />
                      <Typography sx={{ ml: '8px' }}>
                        Create New Company
                      </Typography>
                    </PopoverWrapperActions>
                  ),
                },
              ]}
            />
          </div> */}
        </AvatarWrapper>
      </LeftMenu>

      <If condition={displayAppSelector}>
        <BlurBackground />
      </If>
    </>
  );
}

export function MainNav() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  let authUser = useSelector(selectAuthUser);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const [displayAppSelector, setDisplayAppSelector] = useState(false);
  const [titlePopoverOpen, setTitlePopoverOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [triggerGetActivities, { data: organizationActivities }] =
    useLazyGetOrganizationActivitiesQuery();
  useGetConfigurationQuery();

  const currentApplication = useApplication(authUser);
  const [switchOrganization] = useSwitchOrganizationMutation();

  const wsInbox = useSubscribeObject(
    'organization',
    authUser?._currentOrganization._id,
    [
      'new-message',
      'new-conversation',
      'new-customer-document',
      'check-customer-document',
    ],
  );

  useEffect(() => {
    triggerGetActivities();
  }, [wsInbox]);

  const handleDisplayAppSelector = val => {
    if (
      (val && authUser && isEmpty(authUser?.currentAgent.applications)) ||
      size(authUser?.currentAgent?.applications) > 1
    ) {
      setDisplayAppSelector(true);
    } else {
      setDisplayAppSelector(false);
    }
  };

  const handleSwitchOrganization = async (organizationId: string) => {
    const { user } = await switchOrganization({ organizationId }).unwrap();

    dispatch(
      appActions.setLastToasterNotification({
        duration: 5000,
        severity: 'success',
        message: t('auth.organizationSwitch.success', {
          organizationName: user._currentOrganization.name,
        }),
      }),
    );

    navigate(currentApplication.url);
    triggerGetActivities();
  };

  React.useEffect(() => {
    triggerGetActivities();
  }, []);

  if (!authUser) {
    return null;
  }

  const isMinify = mainMenuSize === 'minify';
  const { _organizations, _currentOrganization } = authUser;

  return (
    <>
      <WrapperMenu>
        <LeftNav
          applicationId={currentApplication.id}
          organizations={_organizations}
          currentOrganization={_currentOrganization}
          onSwitchOrganization={handleSwitchOrganization}
          theme={theme}
          setDisplayAppSelector={handleDisplayAppSelector}
          displayAppSelector={displayAppSelector}
        />
        <List
          sx={{
            width: isMinify ? theme.mainNavWidthMin : theme.mainNavWidth,
            minWidth: isMinify ? '60px' : 'auto',
            maxWidth: isMinify ? '60px' : theme.mainNavWidth,
            bgcolor: 'background.paper',
            filter: displayAppSelector ? 'blur(4)' : 'blur(0)',
            overflow: 'visible',
            transition: '0.15s all ease-out',
            borderRight: `1px solid ${themes?.default?.lightFill}`,
            boxShadow: '0px 3px 15px rgba(139, 139, 139, 0.1)',
          }}
          component="nav"
          style={{ zIndex: 2, position: 'relative' }}
          aria-labelledby="nested-list-subheader"
          subheader={
            <MainNavHeader>
              <Cover>
                {isMinify ? (
                  displayAppSelector ? (
                    <CollapseIcon classList={'expand blur'} />
                  ) : (
                    <CollapseIcon classList={'expand'} />
                  )
                ) : displayAppSelector ? (
                  <CollapseIcon classList={'collapse blur'} />
                ) : (
                  <CollapseIcon classList={'collapse'} />
                )}
              </Cover>
              <CompanyWrapper isMinify={isMinify}>
                <div
                  onClick={() => setTitlePopoverOpen(!titlePopoverOpen)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* {!isMinify && (
                    <Typography
                      fontSize="1.125rem"
                      fontWeight="700"
                      maxWidth="150px"
                    >
                      {_currentOrganization.name}
                    </Typography>
                  )} */}

                  {checkUserAcl(authUser, ['*']) ? (
                    <ActionPopover
                      id={1}
                      handleAction={(event, action) => {
                        if (action === 'settings') {
                          navigate(SETTINGS_COMPANY_PROFILE);
                        }
                      }}
                      iconAction={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: isMinify ? '4px' : '14px',
                          }}
                        >
                          <Typography
                            fontSize="1.125rem"
                            fontWeight="700"
                            maxWidth="150px"
                          >
                            <If
                              condition={!isMinify}
                              otherwise={
                                <Tooltip title={_currentOrganization.name}>
                                  {_currentOrganization.name.substr(0, 2)}
                                </Tooltip>
                              }
                            >
                              <EllipsisText
                                text={_currentOrganization.name}
                                width={140}
                              />
                            </If>
                          </Typography>
                          <CaretDown
                            weight="bold"
                            size={12}
                            color={themes?.default?.iconColor}
                            style={{
                              transitionDuration: '0.2s',
                              transform: menuOpen ? 'rotate(180deg)' : '',
                            }}
                          />
                        </div>
                      }
                      anchorOriginVertical={'bottom'}
                      anchorOriginHorizontal={'left'}
                      actions={[
                        {
                          action: 'settings',
                          element: (
                            <PopoverWrapperActions style={{ width: '218px' }}>
                              <SettingsSuggestIcon
                                color="action"
                                className="gearIcon"
                                sx={{ ml: '10px' }}
                              />
                              <Typography sx={{ ml: '10px' }}>
                                {t('account_settings')}
                              </Typography>
                            </PopoverWrapperActions>
                          ),
                        },
                        // hide dead link
                        // {
                        //   action: 'customize',
                        //   element: (
                        //     <PopoverWrapperActions style={{ width: '218px' }}>
                        //       <WidgetsIcon color="action" sx={{ ml: '10px' }} />
                        //       <Typography sx={{ ml: '10px' }}>
                        //         Customize {_currentOrganization.name}
                        //       </Typography>
                        //     </PopoverWrapperActions>
                        //   ),
                        // },
                      ]}
                      iconSx={{ paddingLeft: isMinify ? '5px' : '' }}
                      setMenuOpen={setMenuOpen}
                    />
                  ) : null}
                </div>
              </CompanyWrapper>
            </MainNavHeader>
          }
        >
          {currentApplication?.id === 'ordoria' ? (
            <OrdoriaMenu
              authUser={authUser}
              theme={theme}
              mode={mainMenuSize}
            />
          ) : (
            <BiantaMenu
              authUser={authUser}
              theme={theme}
              mode={mainMenuSize}
              organizationActivities={organizationActivities}
            />
          )}
        </List>
      </WrapperMenu>
    </>
  );
}

const Title = styled.p<{ textColorSecondary: string }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.813em;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 19px;
  color: ${props => props?.textColorSecondary};
`;
const MainNavHeader = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 23px;
  flex-direction: column;
  justify-content: center;
`;

const MenuScrollWrapper = styled.div<{ scrollBarColor }>`
  max-height: calc(100vh - 90px);
  overflow-y: hidden;
  overflow-x: hidden;

  &:hover {
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${props => props?.scrollBarColor};
    }
  }
`;

const MenuSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

const GeneralSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

const ResourcesSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

const LastSectionWrapper = styled.div`
  border-bottom: solid 1px ${themes?.default?.lightFill};
`;

interface CompanyWrapperProps {
  isMinify?: boolean;
}
const CompanyWrapper = styled.div<CompanyWrapperProps>`
  display: flex;
  width: 223.629px;
  height: 57px;
  padding: 18px 0px 16px 15px;
  align-items: center;
  flex-shrink: 0;
  .css-yvetkp-MuiButtonBase-root-MuiIconButton-root {
    padding: 0;
  }
  justify-content: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const PopoverWrapperActions = styled.div`
  display: flex;
  align-items: center;
`;

const LeftMenu = styled.div`
  z-index: 2;
  display: flex;
  height: 100vh;
  padding: 6px 10px 0px 10px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  background-color: ${themes?.light?.primary};
  border-right: 1px solid var(--divider, ${themes?.default?.lightFill});
  box-shadow: 0px 3px 15px 0px rgba(139, 139, 139, 0.1);

  &.ordoria {
    background: ${props => props.theme.primary};
  }
`;

const WrapperMenu = styled.div`
  display: flex;
`;

const AvatarWrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 150%;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
`;

const Cover = styled.div`
  .collapse {
    position: absolute;
    top: 20px;
    left: 215px;
    &:hover {
      cursor: pointer;
      transition: all 200ms ease-in;
      transform: scale(1.1);
    }
  }
  .expand {
    position: absolute;
    top: 20px;
    left: 50px;
    transform: rotate(180deg);
    &:hover {
      cursor: pointer;
      transition: all 200ms ease-in;
      transform: rotate(180deg) scale(1.1);
    }
  }
`;

const BlurBackground = styled.div`
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
`;

const ImgWrapper = styled.div`
  display: flex;
  width: 26px;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const Logo = styled.div`
  background: transparent;
  cursor: pointer;
  width: 42px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const AvatarUserWrapper = styled.div`
  margin-left: 2px;
`;
