import { Grid } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import { useMemo, useState } from 'react';
import { CustomDateRangePicker } from 'app/components/DatePicker/CustomDateRangePicker';

interface DateRangeFilterProps {
  openByDefault?: boolean;
  filterName?: string;
  dateValue: { begin: string | null; end: string | null };
  handleFilterChange: (date: {
    begin: string | null;
    end: string | null;
  }) => void;
}

const DateRangeFilter = ({
  openByDefault,
  dateValue,
  filterName,
  handleFilterChange,
}: DateRangeFilterProps) => {
  const [open, setOpen] = useState<boolean>(openByDefault || false);

  const isHighlighted = useMemo(() => {
    return !!(dateValue?.begin || dateValue?.end);
  }, [dateValue]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        <FilterName isHighlighted={isHighlighted}>{filterName}</FilterName>
        {
          <ToggleIcon disableRipple>
            {open ? <RemoveIcon /> : <AddIcon />}
          </ToggleIcon>
        }
      </div>
      <If condition={open}>
        <Grid container spacing={0} sx={{ padding: '10px 0' }}>
          <CustomDateRangePicker
            dateValue={dateValue}
            setDateValue={handleFilterChange}
            textFieldProps={{
              sx: {
                '& input': { width: '280px !important' },
                '& button': { width: 'auto' },
                '& .MuiInputBase-root': { width: '100% !important' },
              },
              placeholder: filterName,
            }}
            width={'100%'}
          />
        </Grid>
      </If>
    </>
  );
};

export default DateRangeFilter;
