import styled from 'styled-components';
import NoRoundtrip from 'assets/img/roundTrips/no-data-roundtrip.svg';
import { useTranslation } from 'react-i18next';
import { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react';
import { EnhancedTableToolbarProps } from '../../data/types';
import { Dayjs } from 'dayjs';
import { Modals } from './Components/Modals';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { useLazyGetRoundTripsClusterQuery } from 'common/services/roundtripApi';
interface Props {
  roundTripCopy?: any;
  EnhancedTableToolbar?: ReactElement<
    EnhancedTableToolbarProps,
    FC<EnhancedTableToolbarProps>
  >;
  dateValue?: Dayjs | null;
  handleClickUpload?: (event: React.MouseEvent<HTMLElement>) => void;
  setAnchorElUpload?: Dispatch<SetStateAction<HTMLElement | null>>;
  anchorElUpload?: HTMLElement | null;
  setAnchorElManual?: any;
  anchorElManual?: HTMLElement | null;
  Icon?: string;
  begin?: string;
  end?: string;
  setAnchorElActivate?: any;
  triggerByMode?: any;
  roundTripsByMode?: any;
  addRoundtrip?: boolean;
  isLoading?: boolean;
  viewMode?: boolean;
  allRoundtrip?: boolean;
  height?: string;
}
export const NoData: React.FC<Props> = ({
  EnhancedTableToolbar,
  dateValue,
  Icon,
  begin,
  end,
  setAnchorElActivate,
  viewMode,
  roundTripCopy,
  allRoundtrip,
  addRoundtrip,
  height,
}) => {
  const { t } = useTranslation();
  const [triggerByMode, { data: roundTripsByMode }] =
    useLazyGetRoundTripsClusterQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(roundtripActions.setClusterId(''));
    if (setAnchorElActivate) {
      setAnchorElActivate(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Background height={height}>
      {EnhancedTableToolbar && EnhancedTableToolbar}
      <CenterDiv>
        {/* <img src={Icon ? Icon : NoRoundtrip} alt="" />
        <Text>No roundtrips were created yet!</Text>
        <IconButtonComponent
          iconComponent={
            <KeyboardArrowUpOutlinedIcon
              sx={{
                marginLeft: '5px',
                transition: 'color 0.3s ease-in-out',
                rotate: anchorEl ? '180deg' : '0deg',
              }}
            />
          }
          size="small"
          style={{ fontWeight: '500', margin: '0 auto', width: 'fit-content' }}
          text="Create Roundtrips"
          iconStyle={{ color: themes?.default?.accordionWhiteBg }}
          variant="contained"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          invert={true}
        /> */}
        <NoDataComponents
          viewMode={viewMode}
          Icon={Icon ? Icon : NoRoundtrip}
          open={open}
          handleClick={handleClick}
          text={
            viewMode
              ? t('roundtrips.roundtrips.empty_state.view_mode')
              : t('roundtrips.roundtrips.empty_state.scheduled')
          }
          IconButton={
            !addRoundtrip ? (
              <KeyboardArrowUpOutlinedIcon
                sx={{
                  marginLeft: '5px',
                  transition: 'color 0.3s ease-in-out',
                  rotate: anchorEl ? '0deg' : '180deg',
                }}
              />
            ) : (
              <></>
            )
          }
          textButton={
            !!addRoundtrip
              ? t('cluster.add_roundtrips')
              : t('cluster.create_roundtrips')
          }
        />
        <Modals
          anchorEl={anchorEl}
          handleClose={handleClose}
          roundTripCopy={roundTripCopy}
          allRoundtrip={allRoundtrip}
        />
      </CenterDiv>
    </Background>
  );
};

interface BackgroundProps {
  height?: string;
}

const Background = styled.div<BackgroundProps>`
  background: ${props => props.theme.roundtripsGrayBackGround};
  height: ${props =>
    props?.height
      ? props.height
      : `calc(100vh - ${props.theme.topBarHeight} - ${props.theme.toolBarHeight})`};
  flex-grow: 1;
  position: relative;
`;

const CenterDiv = styled.div`
  width: max-content;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* text-align: center; */

  img {
    margin: 0 auto;
  }
`;
const Text = styled.p`
  font-weight: 400;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  line-height: normal;
`;
