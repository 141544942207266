import { setFilterCheckedValues } from "app/helpers/helpers";
import { useEffect, useState } from "react";

export function useCheckValues(
  filters
) {

  const [checkedValues, setCheckedValues] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!filters) {
      return
    }
    //If filter chips were removed update states here
    setFilterCheckedValues(filters, setCheckedValues)

  }, [filters])

  return { checkedValues, setCheckedValues }
}