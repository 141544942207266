import { ActionPopover, PopoverEl } from 'app/components/Popover';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useStatusActions } from './hook/useStatusActions';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { selectAuthUser } from 'common/store/auth/selectors';
import {
  useCalculateRoundtripRouteMutation,
  useDeleteRoundTripMutation,
  useRoundtripUpdateStatusMutation,
} from 'common/services/roundtripApi';
import { useToaster } from '../../../../../../hooks/useToaster';
import { useModal } from 'app/components/Dialog/hooks';
import { deleteFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { useTranslation } from 'react-i18next';
import {usePrint, usePrintV2} from 'hooks/RoundTrips/usePrint';
import {toQueryParams} from "../../../../../../hooks/useQueryParams";

interface Props {
  roundtrip: any;
  setRoundtripEdit?: any;
  functionRoundTripStatus: any;
  setRoundtripName?: any;
  handleClickOpen: any;
  handleClickOpenDelete: any;
  typeEdit?: any;
  icon?: JSX.Element;
}

export const PopoverAction: React.FC<Props> = ({
  roundtrip,
  setRoundtripEdit = () => { },
  functionRoundTripStatus,
  setRoundtripName = () => { },
  handleClickOpen,
  typeEdit,
  icon,
}) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const authUser = useSelector(selectAuthUser);
  const toast = useToaster();
  const [roundTripStatus] = useRoundtripUpdateStatusMutation();

  const [calculate] = useCalculateRoundtripRouteMutation();
  const [status, setStatus] = useState<any[]>([]);
  useStatusActions(
    setStatus,
    roundtrip,
    functionRoundTripStatus,
    roundTripStatus,
  );

  const { openModal, closeModal } = useModal();
  const [DeleteRoundtrip] = useDeleteRoundTripMutation();

  const handleDelete = () => {
    deleteFunction(roundtrip?.id, DeleteRoundtrip);
    toast(3000, 'success', t('alerts.roundtrip.delete'));
    closeModal();
  };

  const handleOpenDeleteModal = () => {
    openModal({
      action: {
        actionText: t('common.remove'),
        actionCallback: handleDelete,
      },
      title: `${t('roundtrips.edit_roundtrip.confirm_delete')} [${roundtrip?.code
        }]?`,
      content: t('roundtrips.edit_roundtrip.description_delete'),
      deleteModal: true,
      cancel: true,
    });
  };

  const handleCalculateRoute = async () => {
    await calculate({
      roundtripId: roundtrip._id,
    }).unwrap();

    toast(3000, 'info', 'roundtrip.calculateRouteSuccess');
  };

  const [clusterId, setClusterId] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setClusterId(urlParams.get('cluster')!.replaceAll('"', ''));
  }, []);

  const { triggerPrint } = usePrint();
  const { triggerPrint: triggerPrint2 } = usePrintV2();

  const handlePrintDeliveryNotes = () => {
    const orderIds = roundtrip.stops?.map(stop => stop._order?.id);
    const query = toQueryParams({
      format: 'pdf',
      orderIds: orderIds.join(','),
    });

    triggerPrint2({
      uri: `orders/delivery-notes${query}`,
    });
  }

  const actions =
    typeEdit === true
      ? [
        {
          action: 'status',
          nested: true,
          actions: status,
          onClick: () => {
            setRoundtripName(roundtrip.code);
            setRoundtripEdit(roundtrip);
          },
          element: (
            <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
              <span className="popover-item">
                {t('orders.change_status')}
              </span>
            </PopoverEl>
          ),
        },
        {
          action: 'compute-directions',
          onClick: () => {
            handleCalculateRoute();
          },
          element:
            process.env.NODE_ENV === 'development' ||
              authUser?.isSuperAdmin ? (
              <PopoverEl
                redStatus={theme.redStatus}
                style={{ width: '100%' }}
              >
                <div className="popover-item">
                  {t('roundtrip.calculateRoute')}
                </div>
              </PopoverEl>
            ) : null,
        },
        {
          action: 'print-roadmap',
          onClick: () => {
            triggerPrint({ clusterId, roundtripId: roundtrip._id });
          },
          element: (
            <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
              <div className="popover-item">
                {t('common.buttons.print_roadmap')}
              </div>
            </PopoverEl>
          ),
        },
        {
          action: 'print-deliveryNotes',
          onClick: handlePrintDeliveryNotes,
          element: (
            <PopoverEl redStatus={theme.redStatus} style={{ width: '100%' }}>
              <div className="popover-item">
                {t('common.buttons.print_delivery_notes')}
              </div>
            </PopoverEl>
          ),
        },
        {
          action: 'clear',
          onClick: () => {
            setRoundtripName(roundtrip.code);
            setRoundtripEdit(roundtrip);
            handleClickOpen();
          },
          element: (
            <>
              <PopoverEl
                redStatus={theme.redStatus}
                style={{ width: '100%' }}
              >
                <span className="popover-item">{t('clear')}</span>
              </PopoverEl>
            </>
          ),
        },
        {
          seperator: true,
          action: 'separator',
          element: (
            <>
              <PopoverEl redStatus={theme.redStatus}>
                <div className="seperator"></div>
              </PopoverEl>
            </>
          ),
        },
        {
          action: 'delete',
          onClick: () => {
            setRoundtripName(roundtrip?.code);
            setRoundtripEdit(roundtrip);

            handleOpenDeleteModal();
          },
          element: (
            <>
              <PopoverEl
                redStatus={theme.redStatus}
                style={{ width: '100%' }}
              >
                <div className="delete popover-item">{t('delete')}</div>
              </PopoverEl>
            </>
          ),
        },
      ]
      : [
        {
          action: 'delete',
        },
      ];

  return (
    <div
      style={{ width: '100%' }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <NestedMenuComponent
        id={roundtrip.id}
        actions={actions}
        iconAction={icon}
      />
    </div>
  );
};
