import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { RoundtripState } from './types';
import roundtripApis from '../../services/roundtripApi';
import orderApi from '../../services/orderApi';

export const initialState: RoundtripState = {
  roundtrips: [],
  dateBegin: undefined,
  dateEnd: undefined,
  accordionStatus: undefined,
  dateValue: undefined,
  stop: {},
  editingRoundtripUser: false,
  editingRoundtripVehicle: false,
  clusterId: undefined,
  informRoundtripText: {},
  editOrders: false,
  pendingRequestStopIds: {},
  disableActions: false,
};
interface SetEditingRoundtripUserAction {
  type: string;
  payload: boolean;
}

const slice = createSlice({
  name: 'roundtrips',
  initialState,
  reducers: {
    setClusterId: (state, action: { payload: string }) => {
      state.clusterId = action.payload;
    },
    setDateBegin: (state, action: any) => {
      state.dateBegin = action.payload;
    },
    setDateEnd: (state, action: any) => {
      state.dateEnd = action.payload;
    },
    setAccordionStatus: (state, action: any) => {
      state.accordionStatus = action.payload;
    },
    setDateValue: (state, action: any) => {
      state.dateValue = action.payload;
    },
    setStop: (state, { payload: item }: PayloadAction<any>) => {
      state.stop = item;
    },
    setEditingRoundtripUser: (state, action: SetEditingRoundtripUserAction) => {
      state.editingRoundtripUser = action.payload;
    },
    setEditingRoundtripVehicle: (
      state,
      action: SetEditingRoundtripUserAction,
    ) => {
      state.editingRoundtripVehicle = action.payload;
    },
    updateStop: (state, { payload: item }: PayloadAction<any>) => {
      state.stop = { ...state.stop, ...item };
    },
    clearStop: state => {
      state.stop = {};
    },
    setEditOrders: (state, { payload: item }: PayloadAction<any>) => {
      state.editOrders = item;
    },
    setInformRoundtripText: (state, { payload: value }: PayloadAction<any>) => {
      state.informRoundtripText = value;
    },
    addPendingRequestStopIds: (
      state,
      { payload: stopId }: PayloadAction<any>,
    ) => {
      state.pendingRequestStopIds[stopId] = true;
    },
    removePendingRequestStopIds: (
      state,
      { payload: stopId }: PayloadAction<any>,
    ) => {
      delete state.pendingRequestStopIds[stopId];
    },
    resetPendingRequestStopIds: state => {
      state.pendingRequestStopIds = {};
    },
    setDisableActions: (
      state,
      { payload: disableActions }: PayloadAction<any>,
    ) => {
      state.disableActions = disableActions;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        roundtripApis.endpoints.stopUpdateApi.matchFulfilled,
        orderApi.endpoints.updateOrderDeliverySignature.matchFulfilled,
      ),
      (state: any, response: any) => {
        state.pendingRequestStopId = null;

        return state;
      },
    );
  },
});

const roundtripReducer = slice.reducer;

export const { actions: roundtripActions } = slice;

export default roundtripReducer;
