import { TooltipProps, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

export type ManagedTooltipProps = TooltipProps & {
  disabled?: boolean;
  style?: React.CSSProperties;
};

/**
 * mui tooltip wrapper with adaption to the move away once focuses left.
 */
export function ManagedTooltip(props: ManagedTooltipProps) {
  const [open, setopen] = useState(false);

  const disabled = props.disabled;
  props = _.omit(props, ['disabled']);

  const handleOpen = (open: boolean) => {
    setopen(disabled ? false : open);
  };

  // wrap tooltip with span to capture mouse events
  return (
    <span
      onMouseEnter={() => handleOpen(true)}
      onMouseLeave={() => handleOpen(false)}
      onClick={() => handleOpen(false)}
      style={props.style}
    >
      {/* show the original mui tooltip with all props. */}
      {/* just override the open attribute to be fully managed, and disable internal listeners */}
      <Tooltip
        {...props}
        open={open}
        // disableHoverListener
        // disableFocusListener
        sx={{ cursor: open ? 'pointer' : 'unset' }}
      />
    </span>
  );
}
