import React, { useState } from "react";
import styled from "styled-components";
import { themes } from "styles/theme/themes";
import { Icon } from "app/components/Icon";
import { ButtonBase } from "@mui/material";
import { MagnifyingGlassPlus, MagnifyingGlassMinus } from "@phosphor-icons/react";
import { useTranslation } from 'react-i18next';
import { StateSetter } from "types";

interface ButtonProps {
  mapZoom: number;
  setMapZoom: StateSetter<number>;
};

export const MapZoomButton: React.FC<ButtonProps> = ({
  mapZoom,
  setMapZoom,
}) => {
  const { t } = useTranslation();

  function handleChangeZoom(direction) {
    if (direction === "in") {
      if (mapZoom >= 22) return;
      setMapZoom(mapZoom + 1);
    }
    else if (direction === "out") {
      if (mapZoom <= 2) return;
      setMapZoom(mapZoom - 1);
    }
  };

  return (
    <MapActionBtnWrapper>
      <ButtonBase
        disabled={mapZoom >= 22}
        sx={{
          height: "32px",
          minWidth: "32px",
          borderRadius: "2.9px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transitionDuration: "0.15s",
          background: mapZoom >= 22 ?  `${themes?.default?.nobel}25` : "",
          '&:hover': {
            background: mapZoom >= 22 ?  themes?.default?.nobel : themes?.default?.lightBlueBg
          }
        }}
        onClick={() => handleChangeZoom("in")}
      >
        <Icon
          tooltipPosition="left"
          tooltipWithArrow={true}
          icon={<MagnifyingGlassPlus />}
          tooltip={t('common.zoom_in')}
          size={20}
          color={mapZoom >= 22 ?  themes?.default?.nobel : themes?.default?.grey2}
        />
      </ButtonBase>
      <HorizentalDivider />
      <ButtonBase
        disabled={mapZoom <= 2}
        sx={{
          height: "32px",
          minWidth: "32px",
          borderRadius: "2.9px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transitionDuration: "0.15s",
          background: mapZoom <= 2 ?  `${themes?.default?.nobel}25` : "",
          '&:hover': {
            background: mapZoom <= 2 ?  themes?.default?.nobel : themes?.default?.lightBlueBg
          }
        }}
        onClick={() => handleChangeZoom("out")}
      >
        <Icon
          tooltipPosition="left"
          tooltipWithArrow={true}
          icon={<MagnifyingGlassMinus />}
          tooltip={t('common.zoom_out')}
          size={20}
          color={mapZoom <= 2 ?  themes?.default?.nobel : themes?.default?.grey2}
        />
      </ButtonBase>
    </MapActionBtnWrapper>
  )
};

const MapActionBtnWrapper = styled.div`
  width: 32px;
  height: 76.54px;
  padding: 5.77px 4px;
  border-radius: 5.77px;
  border: 1.15px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${themes?.default?.accordionWhiteBg};
  border: ${themes?.default?.whisper};
  box-shadow: 0px 4px 4px 0px #00000033;
  transition-duration: 0.15s;
`;

const HorizentalDivider = styled.div`
  width: 24px;
  height: 1px;
  border: 1px solid #0000001F;
`;