import React from 'react';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PopOverElement } from 'app/components/Popover';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import If from 'app/components/If';
export const AssignedRoundtripsPopover = ({
  contextMenu,
  position,
  setOpenAddRoundtrip,
}) => {
  const theme = useSelector(selectTheme);
  return (
    <>
      <Popover
        open={contextMenu}
        anchorOrigin={{
          vertical: position.y || "center",
          horizontal: position.x || "center",
        }}
      >
        <PopOverElement
          onClick={() => setOpenAddRoundtrip(true)}
          primaryActiveColor={theme.primaryActiveColor}
          style={{
            padding: '12px 16px',
          }}
        >
          <PopOverElWrapper
            style={{
              marginRight: '27px',
            }}
          >
            Assign to Roundtrip
          </PopOverElWrapper>
          <KeyboardArrowRightIcon />
        </PopOverElement>
      </Popover>
    </>
  );
};
export default AssignedRoundtripsPopover;
