import React, { useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import { EditUser } from './components/EditUser';
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} from 'common/services/userApi';
import { userHeadCellsConst } from './data/headCellsConst';
import { Data, HeadCell } from 'interfaces/users';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormatUsersData } from 'hooks/Users/useFormatUserData';
import { useSelectedTable } from 'hooks/useSelectedTable';
import styled from 'styled-components';
import { AddUser } from './components/AddUser';
import { EnhancedTableToolbar } from './components/EnhancedTableToolbar';
import { NoData } from 'app/pages/Settings/CompanyProfile/components/Users/components/NoData';
import jwtCheckExpire from 'utils/jwtCheck';
import { ErrorType } from 'utils/jwtCheck';
import { TableComponent } from './components/TableComponent';
import { Order } from 'app/components/TableComponent/helpers/types';
import { UserDocs } from 'common/types/User';
import { Dialogue } from '../../../../../components/Dialog';
import { selectAllAction } from 'functions/selectAllAction';
import { changeColumnAction } from 'functions/changeColumnAction';
import { clearAllAction } from 'functions/clearAllAction';
import { toolsActions } from 'app/slices/tools';
import { useDispatch } from 'react-redux';
import { NO_DATA_REGULAR } from 'app/components/const';
import { useDebounce } from 'common/hooks/useDebounce';
import { useSetHeads } from 'app/pages/AddRoundTrips/hooks/useSetHeads';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import If from 'app/components/If';
import NoResults from 'app/components/NoData/NoResults';
import { Text } from 'app/pages/AddRoundTrips/components/RightBarNoData';
import { IconButtonComponent } from 'app/components/IconButtonComponent';
import NoUser from 'assets/img/users/no-user.svg';
import AddIcon from '@mui/icons-material/Add';
import { themes } from 'styles/theme/themes';
import { useResetPaginaton } from 'hooks/useResetPagniation';

let rowsData: { data: Data; accordion?: JSX.Element }[] = [];

export function Users() {
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [rowsDatas, setRowsDatas] = useState<UserDocs[]>([]);
  const [heads, setHeads] = useState<HeadCell[]>([]);
  const [filters, setFilters] = useState<
    { field: string; operator: string; value: string }[]
  >([]);
  const [headCells, setHeadCells] = useState(userHeadCellsConst);
  const [rows, setRows] =
    useState<{ data: Data; accordion?: JSX.Element }[]>(rowsData);
  const [page, setPage] = useState(1);
  const [user, setUser] = useState<UserDocs | null>(null);
  const [editUserDrawerIsOpen, setEditUserDrawerIsOpen] = useState(false);
  const [addUserDrawerIsOpen, setAddUserDrawerIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  const [selected, setSelected] = useSelectedTable(rows, false);
  const [filterString, setFilterString] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openDeleteSnack, setOpenDeleteSnack] = useState(false);
  const [openRevertSnack, setOpenRevertSnack] = useState(false);

  const [idUserAction, setIdUserAction] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('lastName');
  const [order, setOrder] = useState<Order>('asc');

  const debouncedSearchTerm = useDebounce(search, 500);
  useResetPaginaton(setPage, [debouncedSearchTerm]);

  const orderPrefix = order === 'asc' ? '' : '-';
  const apiEndpoint = `filters=${filterString}&page=${page}&limit=${rowsPerPage}&text=${debouncedSearchTerm}&sort=${orderPrefix}${orderBy}`;
  const {
    data: users,
    isLoading,
    error,
    isFetching,
    refetch: trigger,
  } = useGetUsersQuery(apiEndpoint);

  useEffect(() => {
    jwtCheckExpire(error as ErrorType);
  }, [error]);

  useEffect(() => {
    if (users && users.docs) {
      setRowsDatas(users.docs);
    }
  }, [users]);

  // end reading data
  useEffect(() => {
    if (filters && filters[0]) {
      setFilterString('');
      setFilterString(JSON.stringify(filters));
    }
  }, [filters]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  const { t } = useTranslation();

  const handleEditUserClick = () => {
    setEditUserDrawerIsOpen(true);
  };

  const handleAddUserClick = () => {
    setAddUserDrawerIsOpen(true);
  };

  const [deleteUser] = useDeleteUserMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useFormatUsersData({
    handleEditUserClick,
    isLoading,
    rowsData,
    setRows,
    setUser,
    users: rowsDatas,
    handleClickOpen,
    setIdUserAction,
    pageType: 'employee',
  });

  // ColumnPopover actions End

  useSetHeads(headCells, setHeads);

  const deletUserFunction = async () => {
    try {
      await deleteUser(idUserAction).unwrap();
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleClickDeleteSnack = () => {
    setOpenDeleteSnack(true);
  };

  const handleCloseDeleteSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDeleteSnack(false);
  };

  const handleCloseRevertSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenRevertSnack(false);
  };

  // alert(rows ? rows.length : undefined)

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Wrapper>
      <Dialogue
        open={open}
        handleClose={handleClose}
        title={t('employee.title_modal')}
        description1={t('employee.delete_employee.description')}
        close_text={t('common.buttons.close_dialogue')}
        handleClick={handleClickDeleteSnack}
        action={deletUserFunction}
        color="error"
        action_text={t('common.buttons.dialogue_delete')}
      />
      <Drawer
        anchor="right"
        open={editUserDrawerIsOpen}
        onClose={() => setEditUserDrawerIsOpen(false)}
      >
        <EditUser
          user={user}
          handleClose={function (): void {
            setEditUserDrawerIsOpen(false);
          }}
          handleClickOpen={handleClickOpen}
          setIdUserAction={setIdUserAction}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={addUserDrawerIsOpen}
        onClose={() => setAddUserDrawerIsOpen(false)}
      >
        <AddUser
          handleClose={function (): void {
            setAddUserDrawerIsOpen(false);
          }}
        />
      </Drawer>
      <TableComponent
        isLoading={isLoading}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        order={order}
        setOrder={setOrder}
        EnhancedTableToolbar={
          <EnhancedTableToolbar
            handleClickDeleteSnack={handleClickDeleteSnack}
            changeColumnAction={changeColumnAction}
            clearAllAction={clearAllAction}
            handleAddUserClick={handleAddUserClick}
            headCells={headCells}
            heads={heads}
            selected={selected}
            setSelected={setSelected}
            numSelected={selected.length}
            search={search}
            selectAllAction={selectAllAction}
            setFilters={setFilters}
            setSearch={setSearch}
            setUser={setUser}
            name="regular"
            setHeadCells={setHeadCells}
          />
        }
        rows={rows}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        headCells={headCells}
        type="regular"
        selected={selected}
        setSelected={setSelected}
        totalPages={users?.totalPages ? users?.totalPages : 0}
        totalDocs={users?.totalDocs ?? 0}
        tableHeight="calc(100vh - 280px)"
      />
      {!users?.totalDocs && !rows?.length && (
        <EmptyCenter>
          <If
            condition={
              (!debouncedSearchTerm || debouncedSearchTerm === '') &&
              (!search || search === '')
            }
            otherwise={<NoResults />}
          >
            <CenterDiv>
              <img src={NoUser} alt="" />
              <Text>{t('no_users_added_yet')}</Text>
              <IconButtonComponent
                size="small"
                iconComponent={
                  <AddIcon sx={{ color: themes?.default?.accordionWhiteBg }} />
                }
                onClick={handleAddUserClick}
                style={{
                  fontWeight: '500',
                  margin: '0 auto',
                  width: 'fit-content',
                }}
                text="ADD USER"
                iconStyle={{ color: themes?.default?.accordionWhiteBg }}
                variant="contained"
              />
            </CenterDiv>
          </If>
        </EmptyCenter>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 24px 23px 0px 23px;
  position: relative;
`;

export const EmptyCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const CenterDiv = styled.div`
  width: max-content;
  padding: 19% 45%;
  display: flex !important;
  flex-direction: column !important;
  img {
    width: 75px;
    margin: 0 auto;
  }
`;
